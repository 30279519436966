import { getElementAbsoluteCoords } from "./element";
import { shouldTestInside } from "./element/collision";
import { LinearElementEditor } from "./element/linearElementEditor";
import { getBoundTextElement } from "./element/textElement";
import { ElementsMap, ExcalidrawElement } from "./element/types";
import { KEYS } from "./keys";
import { GlobalPoint, LocalPoint, pointFrom } from "./packages/math";
import {
  GeometricShape,
  getClosedCurveShape,
  getCurveShape,
  getEllipseShape,
  getFreedrawShape,
  getPolygonShape,
} from "./scene/Shape";
import { ShapeCache } from "./scene/ShapeCache";

// We inline font-awesome icons in order to save on js size rather than including the font awesome react library
export const SHAPES = [
  {
    icon: (
      // fa-mouse-pointer
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.60835 3.60839L12.05 7.14173L9.20002 8.24173L8.50835 8.5084L8.24168 9.19173L7.13335 12.0501L3.60835 3.60839V3.60839ZM0.500017 0.500061L6.77501 15.5001L7.59168 15.5001L9.80002 9.80006L15.5 7.60006L15.5 6.7834L0.500017 0.500061Z"
          fill="currentColor"
        />
      </svg>
    ),
    value: "selection",
    key: KEYS.V,
    numericKey: KEYS["1"],
  },
  {
    icon: (
      // fa-square
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.8333 0.500061L2.16668 0.500061C1.25002 0.500061 0.500015 1.25006 0.500015 2.16673L0.500015 13.8334C0.500015 14.7501 1.25002 15.5001 2.16668 15.5001H13.8333C14.75 15.5001 15.5 14.7501 15.5 13.8334V2.16673C15.5 1.25006 14.75 0.500061 13.8333 0.500061ZM13.8333 13.8334H2.16668L2.16668 2.16673L13.8333 2.16673V13.8334Z"
          fill="currentColor"
        />
      </svg>
    ),
    value: "rectangle",
    key: KEYS.R,
    numericKey: KEYS["2"],
  },
  {
    icon: (
      // custom
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.4281 8.82155L11.1785 0.571974C10.5303 -0.0762068 9.46969 -0.0762068 8.8215 0.571974L0.571925 8.82155C-0.076256 9.46973 -0.076256 10.5304 0.571925 11.1786L8.8215 19.4282C9.46969 20.0763 10.5303 20.0763 11.1785 19.4282L19.4281 11.1786C20.0763 10.5304 20.0763 9.46973 19.4281 8.82155ZM10 18.2496L1.75044 10.0001L10 1.75049L18.2496 10.0001L10 18.2496Z"
          fill="currentColor"
        />
      </svg>
    ),
    value: "diamond",
    key: KEYS.D,
    numericKey: KEYS["3"],
  },
  {
    icon: (
      // fa-circle
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.00002 2.33339C12.675 2.33339 15.6667 5.32506 15.6667 9.00006C15.6667 12.6751 12.675 15.6667 9.00002 15.6667C5.32502 15.6667 2.33335 12.6751 2.33335 9.00006C2.33335 5.32506 5.32502 2.33339 9.00002 2.33339ZM9.00002 0.666725C4.40002 0.666725 0.666687 4.40006 0.666687 9.00006C0.666687 13.6001 4.40002 17.3334 9.00002 17.3334C13.6 17.3334 17.3334 13.6001 17.3334 9.00006C17.3334 4.40006 13.6 0.666725 9.00002 0.666725Z"
          fill="currentColor"
        />
      </svg>
    ),
    value: "ellipse",
    key: KEYS.E,
    numericKey: KEYS["4"],
  },
  {
    icon: (
      // fa-long-arrow-alt-right
      <svg
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.50001 1.00006C4.50001 1.46673 4.87501 1.83339 5.33334 1.83339L9.99168 1.83339L0.916678 10.9084C0.591678 11.2334 0.591678 11.7584 0.916678 12.0834C1.24168 12.4084 1.76668 12.4084 2.09168 12.0834L11.1667 3.00839V7.66673C11.1667 8.12506 11.5417 8.50006 12 8.50006C12.4583 8.50006 12.8333 8.12506 12.8333 7.66673V1.00006C12.8333 0.541727 12.4583 0.166727 12 0.166727L5.33334 0.166727C4.87501 0.166727 4.50001 0.541727 4.50001 1.00006Z"
          fill="currentColor"
        />
      </svg>
    ),
    value: "arrow",
    key: KEYS.A,
    numericKey: KEYS["5"],
  },
  {
    icon: (
      // custom
      <svg
        width="14"
        height="2"
        viewBox="0 0 14 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.6667 0.166729H0.333344V1.8334H13.6667V0.166729Z"
          fill="currentColor"
        />
      </svg>
    ),
    value: "line",
    key: [KEYS.P, KEYS.L],
    numericKey: KEYS["6"],
  },
  {
    icon: (
      // fa-pencil
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.71668 5.51673L10.4833 6.28339L2.93335 13.8334H2.16668V13.0667L9.71668 5.51673V5.51673ZM12.7167 0.500061C12.5083 0.500061 12.2917 0.583394 12.1333 0.741728L10.6083 2.26673L13.7333 5.39173L15.2583 3.86673C15.5833 3.54173 15.5833 3.01673 15.2583 2.69173L13.3083 0.741728C13.1417 0.575061 12.9333 0.500061 12.7167 0.500061V0.500061ZM9.71668 3.15839L0.500015 12.3751L0.500015 15.5001H3.62502L12.8417 6.28339L9.71668 3.15839V3.15839Z"
          fill="currentColor"
        />
      </svg>
    ),
    value: "freedraw",
    key: KEYS.X,
    numericKey: KEYS["7"],
  },
  {
    icon: (
      // fa-font
      <svg
        width="10"
        height="13"
        viewBox="0 0 10 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.6758 2.17194H6.0547L6.0547 13.0001H3.96291L3.96291 2.17194L0.350601 2.17194V0.396545L9.6758 0.396545V2.17194Z"
          fill="currentColor"
        />
      </svg>
    ),
    value: "text",
    key: KEYS.T,
    numericKey: KEYS["8"],
  },
  {
    icon: (
      // fa-formula
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 121.83 122.88"
        xmlSpace="preserve"
      >
        <g>
          <path
            d="M27.61,34.37l-4.07,4.6l0.4,1.74h10.48c-2.14,12.38-3.74,23.54-6.81,40.74c-3.67,21.94-5.78,27.33-7.03,29.3 c-1.1,1.95-2.68,2.96-4.82,2.96c-2.35,0-6.6-1.86-8.88-3.97c-0.82-0.56-1.79-0.42-2.82,0.26C2,111.74,0,114.42,0,116.82 c-0.12,3.24,4.21,6.06,8.34,6.06c3.64,0,9-2.28,14.64-7.64c7.71-7.31,13.48-17.34,18.3-39.02c3.1-13.84,4.56-22.84,6.74-35.5 l13.02-1.18l2.82-5.17H49.2C52.99,10.53,55.95,7,59.59,7c2.42,0,5.24,1.86,8.48,5.52c0.96,1.32,2.4,1.18,3.5,0.28 c1.85-1.1,4.13-3.92,4.28-6.48C75.96,3.5,72.6,0,66.82,0C61.58,0,53.55,3.5,46.8,10.38c-5.92,6.27-9.02,14.1-11.16,23.99H27.61 L27.61,34.37z M69.27,50.33c4.04-5.38,6.46-7.17,7.71-7.17c1.29,0,2.32,1.27,4.53,8.41l3.78,12.19 c-7.31,11.18-12.66,17.41-15.91,17.41c-1.08,0-2.17-0.34-2.94-1.1c-0.76-0.76-1.6-1.39-2.42-1.39c-2.68,0-6,3.25-6.06,7.28 c-0.06,4.11,2.82,7.05,6.6,7.05c6.49,0,11.98-6.37,22.58-23.26l3.1,10.45c2.66,8.98,5.78,12.81,9.68,12.81 c4.82,0,11.3-4.11,18.37-15.22l-2.96-3.38c-4.25,5.12-7.07,7.52-8.74,7.52c-1.86,0-3.49-2.84-5.64-9.82l-4.53-14.73 c2.68-3.95,5.32-7.27,7.64-9.92c2.76-3.15,4.89-4.49,6.34-4.49c1.22,0,2.28,0.52,2.94,1.25c0.87,0.96,1.39,1.41,2.42,1.41 c2.33,0,5.93-2.96,6.06-6.88c0.12-3.64-2.14-6.74-6.06-6.74c-5.92,0-11.14,5.1-21.19,20.04l-2.07-6.41 c-2.9-9-4.82-13.63-8.86-13.63c-4.7,0-11.16,5.78-17.48,14.94L69.27,50.33L69.27,50.33z"
            fill="currentColor"
          />
        </g>
      </svg>
    ),
    value: "formula",
    key: KEYS.M,
    numericKey: KEYS["9"],
  },
  {
    icon: (
      // fa-image
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-upload-cloud"
      >
        <polyline points="16 16 12 12 8 16"></polyline>
        <line x1="12" y1="12" x2="12" y2="21"></line>
        <path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path>
        <polyline points="16 16 12 12 8 16"></polyline>
      </svg>
    ),
    value: "image",
    key: KEYS.I,
    numericKey: KEYS["0"],
  },
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="collapse">
        <g data-name="Layer 2">
          <path
            d="M19 9h-2.58l3.29-3.29a1 1 0 1 0-1.42-1.42L15 7.57V5a1 1 0 0 0-1-1 1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h5a1 1 0 0 0 0-2zm-9 4H5a1 1 0 0 0 0 2h2.57l-3.28 3.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0L9 16.42V19a1 1 0 0 0 1 1 1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1z"
            data-name="collapse"
            fill="currentColor"
          ></path>
        </g>
      </svg>
    ),
    value: "compress",
    key: null,
    numericKey: KEYS["11"],
  },
];

export const WORKSPACE_SHAPES = [
  {
    icon: (
      // fa-mouse-pointer
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.60835 3.60839L12.05 7.14173L9.20002 8.24173L8.50835 8.5084L8.24168 9.19173L7.13335 12.0501L3.60835 3.60839V3.60839ZM0.500017 0.500061L6.77501 15.5001L7.59168 15.5001L9.80002 9.80006L15.5 7.60006L15.5 6.7834L0.500017 0.500061Z"
          fill="currentColor"
        />
      </svg>
    ),
    value: "selection",
    key: KEYS.V,
    numericKey: KEYS["1"],
  },
  {
    icon: (
      // fa-square
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.8333 0.500061L2.16668 0.500061C1.25002 0.500061 0.500015 1.25006 0.500015 2.16673L0.500015 13.8334C0.500015 14.7501 1.25002 15.5001 2.16668 15.5001H13.8333C14.75 15.5001 15.5 14.7501 15.5 13.8334V2.16673C15.5 1.25006 14.75 0.500061 13.8333 0.500061ZM13.8333 13.8334H2.16668L2.16668 2.16673L13.8333 2.16673V13.8334Z"
          fill="currentColor"
        />
      </svg>
    ),
    value: "rectangle",
    key: KEYS.R,
    numericKey: KEYS["2"],
  },
  {
    icon: (
      // custom
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.4281 8.82155L11.1785 0.571974C10.5303 -0.0762068 9.46969 -0.0762068 8.8215 0.571974L0.571925 8.82155C-0.076256 9.46973 -0.076256 10.5304 0.571925 11.1786L8.8215 19.4282C9.46969 20.0763 10.5303 20.0763 11.1785 19.4282L19.4281 11.1786C20.0763 10.5304 20.0763 9.46973 19.4281 8.82155ZM10 18.2496L1.75044 10.0001L10 1.75049L18.2496 10.0001L10 18.2496Z"
          fill="currentColor"
        />
      </svg>
    ),
    value: "diamond",
    key: KEYS.D,
    numericKey: KEYS["3"],
  },
  {
    icon: (
      // fa-circle
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.00002 2.33339C12.675 2.33339 15.6667 5.32506 15.6667 9.00006C15.6667 12.6751 12.675 15.6667 9.00002 15.6667C5.32502 15.6667 2.33335 12.6751 2.33335 9.00006C2.33335 5.32506 5.32502 2.33339 9.00002 2.33339ZM9.00002 0.666725C4.40002 0.666725 0.666687 4.40006 0.666687 9.00006C0.666687 13.6001 4.40002 17.3334 9.00002 17.3334C13.6 17.3334 17.3334 13.6001 17.3334 9.00006C17.3334 4.40006 13.6 0.666725 9.00002 0.666725Z"
          fill="currentColor"
        />
      </svg>
    ),
    value: "ellipse",
    key: KEYS.E,
    numericKey: KEYS["4"],
  },
  {
    icon: (
      // fa-long-arrow-alt-right
      <svg
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.50001 1.00006C4.50001 1.46673 4.87501 1.83339 5.33334 1.83339L9.99168 1.83339L0.916678 10.9084C0.591678 11.2334 0.591678 11.7584 0.916678 12.0834C1.24168 12.4084 1.76668 12.4084 2.09168 12.0834L11.1667 3.00839V7.66673C11.1667 8.12506 11.5417 8.50006 12 8.50006C12.4583 8.50006 12.8333 8.12506 12.8333 7.66673V1.00006C12.8333 0.541727 12.4583 0.166727 12 0.166727L5.33334 0.166727C4.87501 0.166727 4.50001 0.541727 4.50001 1.00006Z"
          fill="currentColor"
        />
      </svg>
    ),
    value: "arrow",
    key: KEYS.A,
    numericKey: KEYS["5"],
  },
  {
    icon: (
      // custom
      <svg
        width="14"
        height="2"
        viewBox="0 0 14 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.6667 0.166729H0.333344V1.8334H13.6667V0.166729Z"
          fill="currentColor"
        />
      </svg>
    ),
    value: "line",
    key: [KEYS.P, KEYS.L],
    numericKey: KEYS["6"],
  },
  {
    icon: (
      // fa-pencil
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.71668 5.51673L10.4833 6.28339L2.93335 13.8334H2.16668V13.0667L9.71668 5.51673V5.51673ZM12.7167 0.500061C12.5083 0.500061 12.2917 0.583394 12.1333 0.741728L10.6083 2.26673L13.7333 5.39173L15.2583 3.86673C15.5833 3.54173 15.5833 3.01673 15.2583 2.69173L13.3083 0.741728C13.1417 0.575061 12.9333 0.500061 12.7167 0.500061V0.500061ZM9.71668 3.15839L0.500015 12.3751L0.500015 15.5001H3.62502L12.8417 6.28339L9.71668 3.15839V3.15839Z"
          fill="currentColor"
        />
      </svg>
    ),
    value: "freedraw",
    key: KEYS.X,
    numericKey: KEYS["7"],
  },
  {
    icon: (
      // fa-font
      <svg
        width="10"
        height="13"
        viewBox="0 0 10 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.6758 2.17194H6.0547L6.0547 13.0001H3.96291L3.96291 2.17194L0.350601 2.17194V0.396545L9.6758 0.396545V2.17194Z"
          fill="currentColor"
        />
      </svg>
    ),
    value: "text",
    key: KEYS.T,
    numericKey: KEYS["8"],
  },
  {
    icon: (
      // fa-formula
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 121.83 122.88"
        xmlSpace="preserve"
      >
        <g>
          <path
            d="M27.61,34.37l-4.07,4.6l0.4,1.74h10.48c-2.14,12.38-3.74,23.54-6.81,40.74c-3.67,21.94-5.78,27.33-7.03,29.3 c-1.1,1.95-2.68,2.96-4.82,2.96c-2.35,0-6.6-1.86-8.88-3.97c-0.82-0.56-1.79-0.42-2.82,0.26C2,111.74,0,114.42,0,116.82 c-0.12,3.24,4.21,6.06,8.34,6.06c3.64,0,9-2.28,14.64-7.64c7.71-7.31,13.48-17.34,18.3-39.02c3.1-13.84,4.56-22.84,6.74-35.5 l13.02-1.18l2.82-5.17H49.2C52.99,10.53,55.95,7,59.59,7c2.42,0,5.24,1.86,8.48,5.52c0.96,1.32,2.4,1.18,3.5,0.28 c1.85-1.1,4.13-3.92,4.28-6.48C75.96,3.5,72.6,0,66.82,0C61.58,0,53.55,3.5,46.8,10.38c-5.92,6.27-9.02,14.1-11.16,23.99H27.61 L27.61,34.37z M69.27,50.33c4.04-5.38,6.46-7.17,7.71-7.17c1.29,0,2.32,1.27,4.53,8.41l3.78,12.19 c-7.31,11.18-12.66,17.41-15.91,17.41c-1.08,0-2.17-0.34-2.94-1.1c-0.76-0.76-1.6-1.39-2.42-1.39c-2.68,0-6,3.25-6.06,7.28 c-0.06,4.11,2.82,7.05,6.6,7.05c6.49,0,11.98-6.37,22.58-23.26l3.1,10.45c2.66,8.98,5.78,12.81,9.68,12.81 c4.82,0,11.3-4.11,18.37-15.22l-2.96-3.38c-4.25,5.12-7.07,7.52-8.74,7.52c-1.86,0-3.49-2.84-5.64-9.82l-4.53-14.73 c2.68-3.95,5.32-7.27,7.64-9.92c2.76-3.15,4.89-4.49,6.34-4.49c1.22,0,2.28,0.52,2.94,1.25c0.87,0.96,1.39,1.41,2.42,1.41 c2.33,0,5.93-2.96,6.06-6.88c0.12-3.64-2.14-6.74-6.06-6.74c-5.92,0-11.14,5.1-21.19,20.04l-2.07-6.41 c-2.9-9-4.82-13.63-8.86-13.63c-4.7,0-11.16,5.78-17.48,14.94L69.27,50.33L69.27,50.33z"
            fill="currentColor"
          />
        </g>
      </svg>
    ),
    value: "formula",
    key: KEYS.M,
    numericKey: KEYS["9"],
  },
  {
    icon: (
      // fa-image
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-upload-cloud"
      >
        <polyline points="16 16 12 12 8 16"></polyline>
        <line x1="12" y1="12" x2="12" y2="21"></line>
        <path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path>
        <polyline points="16 16 12 12 8 16"></polyline>
      </svg>
    ),
    value: "image",
    key: KEYS.I,
    numericKey: KEYS["0"],
  },
] as const;

export const SHAPES_VIEWMODE = [
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="collapse">
        <g data-name="Layer 2">
          <path
            d="M19 9h-2.58l3.29-3.29a1 1 0 1 0-1.42-1.42L15 7.57V5a1 1 0 0 0-1-1 1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h5a1 1 0 0 0 0-2zm-9 4H5a1 1 0 0 0 0 2h2.57l-3.28 3.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0L9 16.42V19a1 1 0 0 0 1 1 1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1z"
            data-name="collapse"
            fill="currentColor"
          ></path>
        </g>
      </svg>
    ),
    value: "compress",
    key: null,
    numericKey: KEYS["11"],
  },
] as const;

export const findShapeByKey = (key: string) => {
  const shape = SHAPES.find((shape, index) => {
    return (
      (shape.numericKey != null && key === shape.numericKey.toString()) ||
      (shape.key &&
        (typeof shape.key === "string"
          ? shape.key === key
          : (shape.key as readonly string[]).includes(key)))
    );
  });
  return shape?.value || null;
};

/**
 * get the pure geometric shape of an excalidraw element
 * which is then used for hit detection
 */
export const getElementShape = <Point extends GlobalPoint | LocalPoint>(
  element: ExcalidrawElement,
  elementsMap: ElementsMap,
): GeometricShape<Point> => {
  switch (element.type) {
    case "rectangle":
    case "diamond":
    case "frame":
    case "magicframe":
    case "embeddable":
    case "image":
    case "iframe":
    case "text":
    case "textWithStyles":
    case "selection":
    case "clock":
    case "countdown":
    case "chronometer":
      return getPolygonShape(element);
    case "arrow":
    case "line": {
      const roughShape =
        ShapeCache.get(element)?.[0] ??
        ShapeCache.generateElementShape(element, null)[0];
      const [, , , , cx, cy] = getElementAbsoluteCoords(element, elementsMap);

      return shouldTestInside(element)
        ? getClosedCurveShape<Point>(
            element,
            roughShape,
            pointFrom<Point>(element.x, element.y),
            element.angle,
            pointFrom(cx, cy),
          )
        : getCurveShape<Point>(
            roughShape,
            pointFrom<Point>(element.x, element.y),
            element.angle,
            pointFrom(cx, cy),
          );
    }

    case "ellipse":
      return getEllipseShape(element);

    case "freedraw": {
      const [, , , , cx, cy] = getElementAbsoluteCoords(element, elementsMap);
      return getFreedrawShape(
        element,
        pointFrom(cx, cy),
        shouldTestInside(element),
      );
    }
  }
};

export const getBoundTextShape = <Point extends GlobalPoint | LocalPoint>(
  element: ExcalidrawElement,
  elementsMap: ElementsMap,
): GeometricShape<Point> | null => {
  const boundTextElement = getBoundTextElement(element, elementsMap);

  if (boundTextElement) {
    if (element.type === "arrow") {
      return getElementShape(
        {
          ...boundTextElement,
          // arrow's bound text accurate position is not stored in the element's property
          // but rather calculated and returned from the following static method
          ...LinearElementEditor.getBoundTextElementPosition(
            element,
            boundTextElement,
            elementsMap,
          ),
        },
        elementsMap,
      );
    }
    return getElementShape(boundTextElement, elementsMap);
  }

  return null;
};
