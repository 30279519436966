import { apiDelete, apiGet, apiPost, apiPut } from ".";
import captchScreenImage from "../../data/getImage";
import { STORAGE_KEYS } from "../data/localStorage";
import { ACV_API_URL, API_URL } from "./constant";
import { storeElemetDataToDatabase } from "./storeElementData";

export const checkUserRole = (slug: string) =>
  apiGet(`${ACV_API_URL}/api/user/check-user-role?slug=${slug}`);

export const onSaveElementInDB = async (
  page: number,
  lessonId: string | null,
  pages: any | [],
  viewBackgroundColor: string,
) => {
  try {
    /* If the user clicks on the iframe and doesn't draw anything,
     we don't need to show a waiting dialog or send a message to the parent app.
    If isClicked is true and this function is called then it means the user has drawn something and 
    we need to show a waiting dialog and send a message to the parent app. */

    const isClicked = Boolean(localStorage.getItem("isClicked"));
    if (isClicked) {
      window.parent.postMessage(
        { type: "SHOW_WAITING_DIALOG", isLoading: true },
        `${process.env.REACT_APP_PARENT_APP}`,
      );
    }
    let localElement = [] as any;
    const isMyWorkSpaceData = localStorage.getItem("isMyWorkSpace");
    if (isMyWorkSpaceData === "true") {
      localElement = localStorage.getItem(
        STORAGE_KEYS.LOCAL_STORAGE_WORKSPACE_ELEMENTS,
      );
    } else {
      localElement = localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS);
    }
    const dataURL = await captchScreenImage(page);

    const postData = JSON.parse(localElement);

    if (!pages) return;

    const dataURLExist = pages?.find(
      (data: any) => String(data.page) === String(page),
    );

    if (postData) {
      dataURL
        ? postData.push({
            dataURL,
          })
        : postData.push({ dataURL: dataURLExist?.dataURL });
    }

    const userMail = JSON.parse(localStorage.getItem("user") || "{}").mail;
    const pageName = JSON.parse(localStorage.getItem("acv-state") || "{}")
      .pageName;

    const imageData = [];

    const imageFile = postData.filter((data: any) => {
      return (
        data.type === "image" ||
        data.type === "video" ||
        data.type === "audio" ||
        data.type === "formula" ||
        data.type === "textWithStyles" ||
        data.type === "mermaidDiagram" ||
        data.type === "avatar"
      );
    });

    // const isMyWorkSpace = localStorage.getItem("isMyWorkSpace");
    // if (isMyWorkSpace === "true") return;

    await storeElemetDataToDatabase(
      lessonId,
      +page,
      userMail,
      postData,
      viewBackgroundColor,
    );
  } catch (err) {
    window.parent.postMessage(
      { type: "STORE_ELEMENTS", isLoading: false },
      `${process.env.REACT_APP_PARENT_APP}`,
    );
    console.log(err);
  }
};

export const getWhiteboardStudent = (lessonId: string | null) =>
  apiGet(`${API_URL}/get-student-list/${lessonId}`);

export const postWhiteboardStudent = (body: object) => {
  return apiPost(`${API_URL}/post-student-list`, body);
};
export const addCollaborationLink = (body: object) => {
  return apiPost(`${API_URL}/add-collaboration-link`, body);
};
export const getCollaborationLink = (lessonId: string) => {
  return apiGet(`${API_URL}/get-collaboration-link/${lessonId}`);
};

export const deleteCollaborationLink = (lessonId: String) => {
  return apiDelete(`${API_URL}/delete-collaboration-link/${lessonId}`);
};

export const getLessonInfo = (lessonId: string | null, slug: string | null) => {
  return apiGet(
    `${ACV_API_URL}/api/record/get-record-by-lession/${lessonId}?slug=${slug}`,
  );
};

export const deleteWbLocalMedia = (body: {
  id: string;
  classId: string;
  email: string;
  blobNames: string[];
  type: string;
  typeOfDelete: string;
  slug: string;
  role: string;
}) => {
  return apiPut(`${API_URL}/delete-wb-collection-media`, body);
};

export const getSettingData = (slug: string) =>
  apiGet(`${process.env.REACT_APP_ACV_BACKEND_API}/api/setting?slug=${slug}`);

export const getLessonWisePdfImportedData = (lessonId: string | null) =>
  apiGet(`${API_URL}/get-lesson-wise-pdf-imported-data/${lessonId}`);

export const pdfImportDocumentCounter = (body: {
  lessonId: string | null;
  pdfId: string | null;
  slug: string | null;
  userId: string | null;
}) => {
  return apiPost(`${API_URL}/import-document`, body);
};

export const addUserStorage = (body: {
  role: string;
  email: string;
  storage: number;
  slug: string;
  extension: string;
  feature: string;
  type: string;
}) => {
  return apiPost(
    `${process.env.REACT_APP_ACV_BACKEND_API}/api/storage/add`,
    body,
  );
};
