import domToImage from "dom-to-image";
import { useEffect, useMemo, useState } from "react";
import Portall from "../../excalidraw-app/collab/Portall";
import { stringToColor } from "../../utils/stringColor";
import { useIsMobile } from "../App";
import ConfirmDialog from "../ConfirmDialog";
import { Dialog } from "../Dialog";
import { expand } from "../icons";
import { languages } from "../LayerUI";
import { SpinWheelPreviewDialog } from "../libraryItemsDialog/components/spinWheelPreviewDialog";
import { PublicSpinWheel } from "../libraryItemsDialog/tabs/library/components/publicSpinWheel";
import { Tabs } from "../tabs";
import { ToolButton, ToolButtonEnum } from "../ToolButton";
import { SpinnerTimer, SpinnerWheel } from "./components";
import ImageGeneratorButtons from "./components/imageGeneratorButtons/ImageGeneratorButtons";
import { SpinnerContext } from "./contexts";
import "./spinner.scss";
import {
  SegmentType,
  timerDuration,
  timerDuration as timerDurationType,
} from "./types";
import { MagicOutput, SpinnerLetsPlayView } from "./views";
import { ResultDialog } from "./views/letsPlay/components/dialog";
import { MinimizeSpinner } from "./views/minimizeSpinner";

enum SpinnerTabType {
  LETS_PLAY = "Let's Play",
  MAGIC_OUTPUT = "Magic Output",
  MY_LIBRARY = "My Library",
}

export const segColors = [
  "#EE4040",
  "#F0CF50",
  "#815CD1",
  "#3DA5E0",
  "#34A24F",
  "#FF9000",
  "#EE4040",
  "#F0CF50",
  "#815CD1",
  "#3DA5E0",
];

const SpinnerDialog = ({
  open,
  setOpen,
  onClose,
  insertImageOnCanvas,
  onTextWithStylesAction,
  segmentsData,
  onSelectLanguage,
  selectedlanguage,
}: {
  open: boolean;
  setOpen: (value: boolean) => void;
  onClose: () => void;
  insertImageOnCanvas: (file: File) => void;
  onTextWithStylesAction: ({
    textString,
    show,
    handleCloseDialogue,
    isAIGenerated,
    width,
  }: {
    textString: string;
    show?: boolean;
    handleCloseDialogue?: VoidFunction;
    isAIGenerated?: boolean;
    width?: number;
  }) => void;
  segmentsData: {
    isTimer: boolean;
    timerDuration: {
      [timerDuration.MINUTES]: number;
      [timerDuration.SECONDS]: number;
    };
    segments: SegmentType[];
  };
  selectedlanguage: string;
  onSelectLanguage: (language: string) => void;
}) => {
  const [segments, setSegments] = useState<SegmentType[]>([]);
  const [selectedWheelNumber, setSelectedWheelNumber] = useState<number>(1);
  const [selectedTab, setSelectedTab] = useState<string>(
    SpinnerTabType.LETS_PLAY,
  );
  const [magicChat, setMagicChat] = useState<string>("");
  const [openSaveDialog, setOpenSaveDialog] = useState<boolean>(false);
  const [maximize, setMaximize] = useState<boolean>(false);
  const [isTimer, setIsTimer] = useState<boolean>(false);
  const [minimize, setMinimize] = useState<boolean>(false);
  const [minimizeAllSpinWheel, setMinimizeAllSpinWheel] = useState<boolean>(
    false,
  );
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState<boolean>(
    false,
  );
  const [
    isOpenConfirmDialogForStartAgain,
    setIsOpenConfirmDialogForStartAgain,
  ] = useState<boolean>(false);
  const [openResultDialog, setOpenResultDialog] = useState<boolean>(false);
  const [timeDuration, setTimeDuration] = useState<{
    [timerDurationType.MINUTES]: number;
    [timerDurationType.SECONDS]: number;
  }>({
    minutes: 1,
    seconds: 0,
  });
  const [results, setResults] = useState<string[]>([]);
  const [wheelImage, setWheelImage] = useState<File>(() => new File([], ""));
  const [spinWheelPreviewDialog, setSpinWheelPreviewDialog] = useState<{
    id: string;
    title: string;
    description: string;
    isPublic: boolean;
    isTimer: boolean;
    timerDuration: {
      [timerDuration.MINUTES]: number;
      [timerDuration.SECONDS]: number;
    };
    wheels: SegmentType[];
  } | null>();
  const [search] = useState<string>("");
  const [topic, setTopic] = useState<string>("");
  const [topicGenerationLimit, setTopicGenerationLimit] = useState<number>(10);
  const [isShowFullScreen, setIsShowFullScreen] = useState<boolean>(false);
  const [suggestionsPrompt, setSuggestionsPrompt] = useState<string[]>([]);

  const isMobile = useIsMobile();
  const theme = "light";

  // useEffect(() => {
  //   if (!openResultDialog) {
  //     setResults([]);
  //   }
  // }, [openResultDialog]);

  useEffect(() => {
    const { segments, isTimer, timerDuration } = segmentsData;
    setSegments(
      segments.length > 0
        ? segments
        : [
            {
              wheel: 1,
              segment: [],
              topic: "",
              topicGenerationLimit: 10,
              results: [],
              timer: false,
              timerDuration: {
                minutes: 1,
                seconds: 0,
              },
            },
          ],
    );
    isTimer && setIsTimer(isTimer);
    timerDuration && setTimeDuration(timerDuration);
  }, [segmentsData]);

  useEffect(() => {
    const sessionData = sessionStorage.getItem("spinWheel");
    const sessionMagicData = sessionStorage.getItem("magicChat");
    if (sessionData) {
      const { segments, isTimer, timeDuration, results } = JSON.parse(
        sessionData,
      );
      setSegments(
        segments?.length > 0
          ? segments
          : [
              {
                wheel: 1,
                segment: [],
                topic: "",
                topicGenerationLimit: 10,
                results: [],
                timer: false,
                timerDuration: {
                  minutes: 1,
                  seconds: 0,
                },
              },
            ],
      );
      isTimer && setIsTimer(isTimer);
      timeDuration && setTimeDuration(timeDuration);
      results && setResults(results);
    }
    if (sessionMagicData) {
      setMagicChat(sessionMagicData);
    }
    // eslint-disable-next-line
  }, [sessionStorage]);

  const onFinished = (winner: string, index: number) => {
    const segmentData = segments.findIndex(
      (data) => Number(data.wheel) === Number(index),
    );
    if (segmentData !== -1) {
      const results_ = segments[segmentData].results;
      results_.push(winner);
    }

    setResults((prevResults) => [...prevResults, winner]);
    setTimeout(() => {
      setOpenResultDialog(true);
    }, 1500);
  };

  const spinAllWheel = () => {
    setResults([]);
    const length = segments?.filter((seg) => seg?.segment?.length > 0).length;
    for (let index = 0; index < length; index++) {
      const canvas = document.getElementById(
        `spinner-${index + 1}`,
      ) as HTMLCanvasElement;
      setTimeout(() => {
        canvas.click();
      }, 1000);
    }
  };

  useEffect(() => {
    if (maximize) {
      spinAllWheel();
      captureImage("allSpinner-root");
      // if (isIndex !== -1) {
      //   segments[Number(isIndex)].timerDuration.minutes = 1;
      //   segments[Number(isIndex)].timerDuration.seconds = 0;
      // }
    }
    // eslint-disable-next-line
  }, [maximize]);

  const [secondsSpinner, setSecondsSpinner_] = useState<
    { id: number; seconds: number; start: boolean; totalSeconds: number }[]
  >(
    Array.from({ length: 5 }).map((_, index) => ({
      id: index,
      seconds: 60,
      start: false,
      totalSeconds: 60,
    })),
  );
  const [isIndex, setIsindex] = useState<number>(
    segments.findIndex((wheel) => {
      return Number(selectedWheelNumber) === Number(wheel?.wheel);
    }),
  );

  useEffect(() => {
    setIsindex(
      segments.findIndex((wheel) => {
        return Number(selectedWheelNumber) === Number(wheel?.wheel);
      }),
    );
  }, [selectedWheelNumber, segments]);

  const [seconds_, setSeconds] = useState<number>(60);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const [isRunning, setIsRunning] = useState<boolean>(false);

  // Timer for each spinner - Set interval for each seconds
  useEffect(() => {
    if (isIndex !== -1) {
      let interval: NodeJS.Timeout | null = null;
      if (
        isActive &&
        !isPaused &&
        seconds_ > 0
        // secondsSpinner[isIndex].seconds > 0 &&
        // secondsSpinner[isIndex].start
      ) {
        interval = setInterval(() => {
          const index = secondsSpinner.findIndex((data) => data.id === isIndex);
          secondsSpinner[index].seconds = secondsSpinner[index].seconds - 1;
          setSeconds((prevSeconds) => prevSeconds - 1);
          // const minutes = Math.floor((secondsSpinner[index].seconds - 1) / 60);
          // const seconds = (secondsSpinner[index].seconds - 1) % 60;
          const minutes = Math.floor((seconds_ - 1) / 60);
          const seconds = (seconds_ - 1) % 60;
          segments[Number(isIndex)].timerDuration.minutes =
            minutes < 0 ? 0 : minutes;
          segments[Number(isIndex)].timerDuration.seconds =
            seconds < 0 ? 0 : seconds;
          timeDuration.minutes = minutes < 0 ? 0 : minutes;
          timeDuration.seconds = seconds < 0 ? 0 : seconds;
          setTimeDuration(timeDuration);
        }, 1000);
      } else if (!isActive && seconds_ !== 60 && seconds_ === 0) {
        clearInterval((interval as unknown) as NodeJS.Timeout);
        setIsActive(false);
        setIsRunning(false);
      } else if (seconds_ === 0) {
        setIsActive(false);
        setIsRunning(false);
      }

      return () => {
        if (interval) {
          clearInterval(interval);
        }
      };
    }
    // eslint-disable-next-line
  }, [isActive, isPaused, seconds_, secondsSpinner, isIndex]);

  const setSecondsSpinner = (id: number, seconds: number) => {
    const index = secondsSpinner.findIndex((data) => data.id === id);
    if (index !== -1) {
      secondsSpinner[index].seconds = seconds;
      secondsSpinner[index].totalSeconds = seconds;
    } else {
      secondsSpinner.push({ id, seconds, start: false, totalSeconds: seconds });
    }
    setSecondsSpinner_([...secondsSpinner]);
  };

  const startSpinnerTimer = () => {
    if (
      segments[Number(isIndex)]?.results?.length > 0 &&
      // segments[Number(isIndex)]?.timer
      isTimer
    ) {
      // Start the timer
      // start();
      setIsActive(true);
      secondsSpinner[isIndex].start = true;
      setSecondsSpinner_([...secondsSpinner]);
      setIsRunning(true);
      setIsPaused(false);
    }
  };

  const startTimer = () => {
    // setSeconds(seconds_);
    setIsActive(true);
    setIsindex((currentIndex) => {
      if (currentIndex !== -1) {
        secondsSpinner[currentIndex].start = true;
        setSecondsSpinner_([...secondsSpinner]);
      }
      return currentIndex;
    });
    setIsPaused(false);
    setIsRunning(true);
  };

  const pauseTimer = () => {
    setIsActive(false);
    setIsPaused(true);
    setIsindex((currentIndex) => {
      if (currentIndex !== -1) {
        secondsSpinner[currentIndex].start = false;
        setSecondsSpinner_([...secondsSpinner]);
      }
      return currentIndex;
    });
    setIsRunning(false);
  };

  const restartTimer = () => {
    setSeconds(60);
    setIsActive(true);
    setIsindex((currentIndex) => {
      if (currentIndex !== -1) {
        secondsSpinner[currentIndex].start = true;
        setSecondsSpinner_([...secondsSpinner]);
        secondsSpinner[currentIndex].seconds =
          secondsSpinner[currentIndex].totalSeconds + 2;
      }
      return currentIndex;
    });
    setIsPaused(false);
    setIsRunning(true);
  };

  const handleMinutes = (value: number) => {
    // Ensure the new value is not less than 0
    if (value >= 0) {
      // Calculate the new remaining seconds and minutes
      const totalSeconds = value * 60;
      setSeconds(totalSeconds);
      const newMinutes = Math.floor(totalSeconds / 60);
      const newSeconds = totalSeconds % 60;
      segments[
        Number(selectedWheelNumber - 1)
      ].timerDuration.minutes = newMinutes;
      segments[
        Number(selectedWheelNumber - 1)
      ].timerDuration.seconds = newSeconds;
      timeDuration.minutes = newMinutes;
      timeDuration.seconds = newSeconds;
      setTimeDuration(timeDuration);
      secondsSpinner[Number(selectedWheelNumber - 1)].seconds = totalSeconds;
    }
  };

  const captureImage = (id: string) => {
    const canvasWrapper = document.getElementById(id);
    if (!canvasWrapper) {
      return;
    }
    domToImage
      .toBlob(canvasWrapper)
      .then(async (blob: Blob | null) => {
        setWheelImage(
          new File([blob as Blob], "image.png", {
            type: "image/png",
          }),
        );
        if (!blob) {
          return null;
        }
      })
      .catch((error: any) => {
        console.error("Error converting HTML to image:", error);
      });
  };

  useEffect(() => {
    if (selectedTab === SpinnerTabType.LETS_PLAY) {
      captureImage("spinner-root");
    }
  }, [selectedWheelNumber, selectedTab, segments]);

  const startAgainSpinWheel = () => {
    sessionStorage.removeItem("spinWheel");
    setSelectedWheelNumber(1);
    setSegments([
      {
        wheel: 1,
        segment: [],
        topic: "",
        topicGenerationLimit: 10,
        results: [],
        timer: false,
        timerDuration: {
          minutes: 1,
          seconds: 0,
        },
      },
    ]);
    setIsTimer(false);
    setTimeDuration({
      minutes: 1,
      seconds: 0,
    });
    setResults([]);
    setMagicChat("");
    setTopic("");
    setTopicGenerationLimit(10);
    setSelectedTab(SpinnerTabType.LETS_PLAY);
    sessionStorage.removeItem("magicChat");
  };

  const startAgainBtn = () => {
    return !maximize ? (
      <div
        className={`${
          isMobile ? "start-again-btn-isMobile" : "start-again-btn "
        }`}
      >
        <button
          className=""
          onClick={() => {
            if (segments.some((obj) => obj.segment.length > 0) || magicChat) {
              setIsOpenConfirmDialogForStartAgain(true);
            } else {
              startAgainSpinWheel();
            }
          }}
        >
          Start Again
        </button>
      </div>
    ) : (
      <></>
    );
  };

  const renderContent = useMemo(() => {
    return (
      <>
        <Portall containerId="start-again-btn">{startAgainBtn()}</Portall>

        {maximize ? (
          <div
            style={{ height: 710, width: "100%" }}
            // className="d-flex flex-column gap-5"
          >
            <div className="timer-section d-flex justify-content-between mb-3">
              <div>
                <div className="">
                  <div
                    className="minimizeIcon d-flex align-items-center gap-2"
                    onClick={() => {
                      setMinimize(true);
                      setMinimizeAllSpinWheel(true);
                    }}
                  >
                    <div className="icon">{expand()}</div>
                    <span>Minimize</span>
                  </div>
                </div>
                <ImageGeneratorButtons
                  className={"flex-column"}
                  canvasRootId="allSpinner-root"
                  onClose={onClose}
                  insertImageOnCanvas={insertImageOnCanvas}
                  results={results}
                />
                <button
                  type="button"
                  className="spin-btn"
                  onClick={() => {
                    spinAllWheel();
                    // const selectedWheelIndex = segments.findIndex(
                    //   (data) =>
                    //     Number(data.wheel) === Number(segmentData.wheel),
                    // );
                    // segments[
                    //   Number(selectedWheelIndex)
                    // ].timerDuration.minutes = 1;
                    // segments[
                    //   Number(selectedWheelIndex)
                    // ].timerDuration.seconds = 0;
                  }}
                >
                  SPIN ALL
                </button>
              </div>
              {/* {segments[0]?.timer && ( */}
              {isTimer && (
                <SpinnerTimer
                  onlyTime={false}
                  isDisplayCheckBox={false}
                  showSmallButtons={false}
                  setSeconds={setSeconds}
                  isRunning={isRunning}
                  startTimer={startTimer}
                  restartTimer={restartTimer}
                  pauseTimer={pauseTimer}
                  handleMinutes={handleMinutes}
                  openResultDialog={openResultDialog}
                  setSecondsSpinner={setSecondsSpinner}
                />
              )}
            </div>
            <div
              className="d-flex justify-content-center align-items-center"
              // style={{ height: 710, width: "100%" }}
              style={{ width: "100%", padding: "10px 0" }}
              id="allSpinner-root"
            >
              <div
                className="d-flex gap-3 justify-content-center align-items-center flex-wrap"
                onClick={() => {
                  setResults([]);
                }}
              >
                {segments
                  ?.filter((seg) => seg?.segment?.length > 0)
                  ?.map((seg, index) => {
                    const totalWheels = segments.filter(
                      (seg) => seg?.segment?.length > 0,
                    ).length;
                    const availableWidth = window.innerWidth - 200;
                    const size = Math.max(
                      availableWidth / totalWheels - 20,
                      200,
                    );
                    return (
                      <SpinnerWheel
                        key={index}
                        segments={
                          seg?.segment.length > 0
                            ? seg?.segment
                            : ["", "", "", "", "", "", "", "", "", ""]
                        }
                        segColors={
                          seg?.segment.length > 0
                            ? seg?.segment.map((data: any, index: number) =>
                                stringToColor(`${data + index}`),
                              ) ?? segColors
                            : segColors
                        }
                        winningSegment=""
                        onFinished={(val) => onFinished(val, index + 1)}
                        primaryColor="black"
                        primaryColoraround="#ffffffb4"
                        contrastColor="white"
                        buttonText="Spin"
                        isOnlyOnce={true}
                        size={size >= 520 ? 520 : size}
                        upDuration={60}
                        downDuration={3000}
                        id={`spinner-${index + 1}`} // Unique id for each instance
                        spinnerRound={{
                          arcSize: 40,
                          fontSize: "2em",
                          moveToX: 10,
                          lineToX: 10,
                          moveToY: 40,
                          lineToY: 40,
                          lineToY2: 60,
                          segmentFontSize: ".9em",
                        }}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        ) : (
          <>
            <div
              className="spinner-play d-flex flex-wrap"
              style={{ height: 710, width: "100%" }}
            >
              <Tabs
                tabs={[
                  {
                    label: SpinnerTabType.LETS_PLAY,
                    value: SpinnerTabType.LETS_PLAY,
                    content: (
                      <SpinnerLetsPlayView
                        maximize={maximize}
                        setMaximize={setMaximize}
                        openResultDialog={openResultDialog}
                        setOpenResultDialog={setOpenResultDialog}
                        results={setResults}
                        resultsData={results}
                        setSeconds={setSeconds}
                        isRunning={isRunning}
                        startTimer={startTimer}
                        restartTimer={restartTimer}
                        pauseTimer={pauseTimer}
                        handleMinutes={handleMinutes}
                        setSecondsSpinner={setSecondsSpinner}
                        insertImageOnCanvas={insertImageOnCanvas}
                        onClose={onClose}
                        languages={languages}
                        language={selectedlanguage}
                        handleSelect={onSelectLanguage}
                        theme={theme}
                      />
                    ),
                  },
                  {
                    label: SpinnerTabType.MAGIC_OUTPUT,
                    value: SpinnerTabType.MAGIC_OUTPUT,
                    content: (
                      <MagicOutput
                        results={results}
                        onTextWithStylesAction={onTextWithStylesAction}
                        onClose={onClose}
                        insertImageOnCanvas={insertImageOnCanvas}
                        wheelImage={wheelImage}
                        setMagicChat={setMagicChat}
                        magicChat={magicChat}
                        language={selectedlanguage}
                        suggestionsPrompt={suggestionsPrompt}
                        setSuggestionsPrompt={setSuggestionsPrompt}
                      />
                    ),
                  },
                  {
                    label: SpinnerTabType.MY_LIBRARY,
                    value: SpinnerTabType.MY_LIBRARY,
                    content: (
                      <div
                        className="libraryDialog w-100"
                        style={{ height: "95%" }}
                      >
                        <div className="libraryItem_content my-3">
                          <PublicSpinWheel
                            setPreviewDialog={setSpinWheelPreviewDialog}
                            handleSpinnerDialog={(data) => {
                              setIsTimer(data?.isTimer);
                              setTimeDuration(data?.timerDuration);
                              setSegments(data?.segments);
                              setSpinWheelPreviewDialog(null);
                              setSelectedTab(SpinnerTabType.LETS_PLAY);
                            }}
                            search={search}
                            fromMyLibrary={true}
                          />
                        </div>
                      </div>
                    ),
                  },
                ]}
                tab={selectedTab}
                onChange={setSelectedTab}
                className="spinner-tabs"
                startAgainBtn={startAgainBtn}
                languages={languages}
                language={selectedlanguage}
                handleSelect={onSelectLanguage}
                theme={theme}
              />
            </div>
          </>
        )}
        {openResultDialog && (
          <ResultDialog
            open={openResultDialog}
            setOpen={setOpenResultDialog}
            results={results}
            startSpinnerTimer={startSpinnerTimer}
            setResults={setResults}
          />
        )}
        {spinWheelPreviewDialog && (
          <div className="libraryItemDialog">
            <SpinWheelPreviewDialog
              onClose={() => setSpinWheelPreviewDialog(null)}
              elements={spinWheelPreviewDialog}
              handleSpinnerDialog={(data) => {
                setIsTimer(data?.isTimer);
                setTimeDuration(data?.timerDuration);
                setSegments(data?.segments);
                setSpinWheelPreviewDialog(null);
                setSelectedTab(SpinnerTabType.LETS_PLAY);
              }}
            />
          </div>
        )}
      </>
    );
    // eslint-disable-next-line
  }, [
    segments,
    maximize,
    onClose,
    insertImageOnCanvas,
    isRunning,
    startTimer,
    restartTimer,
    pauseTimer,
    handleMinutes,
    openResultDialog,
    setSecondsSpinner,
    results,
    onTextWithStylesAction,
    wheelImage,
    magicChat,
    search,
    selectedTab,
    startSpinnerTimer,
    spinWheelPreviewDialog,
    selectedWheelNumber,
    spinAllWheel,
    onFinished,
  ]);
  // useEffect(() => {
  //   !open && setMinimize(false);
  // }, [open]);

  return (
    <SpinnerContext.Provider
      value={{
        segments,
        setSegments,
        selectedWheelNumber,
        setSelectedWheelNumber,
        setMinimize,
        setMinimizeAllSpinWheel,
        minimizeAllSpinWheel,
        setOpenSaveDialog,
        openSaveDialog,
        setTimeDuration,
        timeDuration,
        setIsTimer,
        isTimer,
        topic,
        setTopic,
        topicGenerationLimit,
        setTopicGenerationLimit,
      }}
    >
      {!minimize && (
        <Dialog
          children={renderContent}
          onCloseRequest={() => {
            if (segments.some((obj) => obj.segment.length > 0)) {
              setIsOpenConfirmDialog(true);
            } else {
              onClose();
            }
          }}
          className={
            isMobile ? "spinner-dialog spinner-dialog-mobile" : "spinner-dialog"
          }
          closeOnClickOutside={false}
          open={open}
          setOpen={setOpen}
          // title="Spinner"
          width={1500}
          backButton={maximize}
          onBack={() => {
            setMaximize(false);
            segments[Number(isIndex)].timerDuration.minutes = 1;
            segments[Number(isIndex)].timerDuration.seconds = 0;
            // timeDuration.minutes = 1;
            // timeDuration.seconds = 0;
            // setTimeDuration(timeDuration);
            pauseTimer();
          }}
          isShowFullScreenButton={!isMobile}
          setIsShowFullScreen={setIsShowFullScreen}
          isShowFullScreen={isShowFullScreen}
        />
      )}

      {minimize && (
        <MinimizeSpinner
          setSeconds={setSeconds}
          isRunning={isRunning}
          startTimer={startTimer}
          restartTimer={restartTimer}
          pauseTimer={pauseTimer}
          handleMinutes={handleMinutes}
          openResultDialog={openResultDialog}
          setSecondsSpinner={setSecondsSpinner}
          setOpen={setOpenResultDialog}
          results={results}
          setResults={setResults}
          startSpinnerTimer={startSpinnerTimer}
        />
      )}

      {isOpenConfirmDialog && (
        <ConfirmDialog
          onCancel={() => {
            // setIsOpenConfirmDialog(false);
            // sessionStorage.removeItem("spinWheel");
            // sessionStorage.removeItem("magicChat");
            // onClose();
            setIsOpenConfirmDialog(false);
          }}
          onConfirm={async () => {
            // sessionStorage.setItem("spinWheel", JSON.stringify({ segments, isTimer, timeDuration }));
            // sessionStorage.setItem("magicChat", magicChat);
            // setOpenSaveDialog(true);
            // setIsOpenConfirmDialog(false);
            sessionStorage.removeItem("spinWheel");
            sessionStorage.removeItem("magicChat");
            onClose();
          }}
          onClose={() => {
            setIsOpenConfirmDialog(false);
          }}
          confirmText="Confirm"
          title={"Are you ready to exit?"}
          open={isOpenConfirmDialog}
          setOpen={setIsOpenConfirmDialog}
          children={
            "Are you sure you want to close the spin wheel without saving changes?"
          }
          isShowInput={false}
          isEditable={false}
          closeOnClickOutside={false}
          renderButtons={
            <ToolButton
              type={ToolButtonEnum.BUTTON}
              title={"Save"}
              aria-label={"Save"}
              label={"Save"}
              onClick={() => {
                sessionStorage.setItem(
                  "spinWheel",
                  JSON.stringify({ segments, isTimer, timeDuration }),
                );
                sessionStorage.setItem("magicChat", magicChat);
                setOpenSaveDialog(true);
                setIsOpenConfirmDialog(false);
              }}
              className="confirm-dialog--close confirm-dialog--save"
              style={{ marginLeft: 0 }}
            />
          }
        />
      )}

      {isOpenConfirmDialogForStartAgain && (
        <ConfirmDialog
          onCancel={() => {
            setIsOpenConfirmDialogForStartAgain(false);
          }}
          onConfirm={async () => {
            startAgainSpinWheel();
            setIsOpenConfirmDialogForStartAgain(false);
            setSuggestionsPrompt([]);
          }}
          onClose={() => {
            setIsOpenConfirmDialogForStartAgain(false);
          }}
          confirmText="Yes, Proceed"
          title={"Start Again"}
          open={isOpenConfirmDialogForStartAgain}
          setOpen={setIsOpenConfirmDialogForStartAgain}
          children={
            "Confirm your decision before starting the spin wheel again. It will erase your current data. Are you sure you want to proceed?"
          }
          isShowInput={false}
          isEditable={false}
          closeOnClickOutside={false}
          className={`${isMobile && "start-again"}`}
        />
      )}
    </SpinnerContext.Provider>
  );
};

export default SpinnerDialog;
