import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useExcalidrawContainer } from "../components/App";
import { useCallbackRefState } from "../hooks/useCallbackRefState";
import { t } from "../i18n";
import { KEYS } from "../keys";
import { AppState } from "../types";
import "./Dialog.scss";
import { back, close, fullScreenIcon, menu, minimizeScreenIcon } from "./icons";
import { Island } from "./Island";
import { Modal } from "./Modal";

export interface DialogProps {
  children: React.ReactNode;
  className?: string;
  small?: boolean;
  onCloseRequest(): void;
  title?: React.ReactNode;
  autofocus?: boolean;
  theme?: AppState["theme"];
  closeOnClickOutside: boolean;
  isShowSidebarMenu?: boolean;
  isShowInput?: boolean;
  isEditable?: boolean;
  open: boolean;
  setOpen: Function;
  width?: number;
  backButton?: boolean;
  onBack?: () => void;
  setIsSideBarOpen?: (isSideBarOpen: boolean) => void;
  isSideBarOpen?: boolean;
  isShowFullScreenButton?: boolean;
  setIsShowFullScreen?: (val: boolean) => void;
  isShowFullScreen?: boolean;
}

export const Dialog = (props: DialogProps) => {
  const [islandNode, setIslandNode] = useCallbackRefState<HTMLDivElement>();
  const [lastActiveElement] = useState(document.activeElement);
  const { id } = useExcalidrawContainer();
  const { isShowFullScreen, setIsShowFullScreen } = props;

  useEffect(() => {
    if (!islandNode) {
      return;
    }

    const focusableElements = queryFocusableElements(islandNode);

    if (focusableElements.length > 0 && props.autofocus !== false) {
      // If there's an element other than close, focus it.
      (focusableElements[1] || focusableElements[0]).focus();
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === KEYS.TAB) {
        const focusableElements = queryFocusableElements(islandNode);
        const { activeElement } = document;
        const currentIndex = focusableElements.findIndex(
          (element) => element === activeElement,
        );

        if (currentIndex === 0 && event.shiftKey) {
          focusableElements[focusableElements.length - 1].focus();
          event.preventDefault();
        } else if (
          currentIndex === focusableElements.length - 1 &&
          !event.shiftKey
        ) {
          focusableElements[0].focus();
          event.preventDefault();
        }
      }
    };

    islandNode.addEventListener("keydown", handleKeyDown);

    return () => islandNode.removeEventListener("keydown", handleKeyDown);
  }, [islandNode, props.autofocus]);

  const queryFocusableElements = (node: HTMLElement) => {
    const focusableElements = node.querySelectorAll<HTMLElement>(
      "button, a, input, select, textarea, div[tabindex]",
    );

    return focusableElements ? Array.from(focusableElements) : [];
  };

  const onClose = () => {
    if (props.isShowInput || props.isEditable) {
      props.setOpen(true);
    } else {
      (lastActiveElement as HTMLElement).focus();
      props.onCloseRequest();
    }
  };

  return (
    <Modal
      className={`${clsx("Dialog", props.className)} ${
        isShowFullScreen ? "full-screen-modal" : ""
      }`}
      labelledBy="dialog-title"
      maxWidth={
        isShowFullScreen
          ? "100%"
          : props.small
          ? "550px"
          : props.width
          ? `${props.width}px`
          : "800px"
      }
      onCloseRequest={onClose}
      theme={props.theme}
      closeOnClickOutside={props.closeOnClickOutside}
    >
      <Island ref={setIslandNode} className="w-100">
        <div id={`${id}-dialog-title`} className="Dialog__title">
          {props.backButton && (
            <button
              className="Modal__back d-flex align-items-center justify-content-center"
              onClick={props.onBack}
              aria-label={"back"}
              style={{ height: 18, width: 18 }}
            >
              {back}
            </button>
          )}
          <span className="Dialog__titleContent">{props.title}</span>
          {props.isShowFullScreenButton && (
            <button
              className="Modal__FullScreen"
              onClick={() => {
                setIsShowFullScreen && setIsShowFullScreen(!isShowFullScreen);
              }}
            >
              {isShowFullScreen ? minimizeScreenIcon : fullScreenIcon}
            </button>
          )}
          <button
            className="Modal__close"
            onClick={onClose}
            aria-label={t("buttons.close")}
          >
            {close("black")}
          </button>
          {props.isShowSidebarMenu && (
            <button
              className="btn d-lg-none"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasExample"
              aria-controls="offcanvasExample"
              onClick={() => {
                if (props.setIsSideBarOpen) {
                  props.setIsSideBarOpen(!props.isSideBarOpen);
                }
              }}
            >
              {menu}
            </button>
          )}
        </div>
        <div className="Dialog__content">{props.children}</div>
      </Island>
    </Modal>
  );
};
