import { apiDelete, apiGet, apiPost, apiPut } from ".";
import { CommentType } from "../../types";
import { ACV_API_URL, API_URL } from "./constant";
require("dotenv").config();
const token = localStorage.getItem("access_token");
const slug = new URLSearchParams(window.location.search).get("slug");

export const storeElemetDataToDatabase = async (
  lessonId: string | null,
  page: number,
  teacherEmailId: string,
  body: Object[],
  viewBackgroundColor: string,
  order?: number,
  // imageData: any
) => {
  const lessonIds = lessonId?.replace(/\//g, "") || "";

  const c = (body.slice(0, -1) as any).every(
    (p: { page: string }) => Number(p.page) === Number(page),
  );

  if (!c) {
    console.log("Page number mismatched");
    return [];
  }
  const postData = await apiPost(
    `${API_URL}/post-whiteboard-data/${lessonIds}/${page}/${teacherEmailId}`,
    { element: body, canvasBackgroundColor: viewBackgroundColor, order },
  );

  return postData;
};

export const setPages = async (lessonId: string | null) => {
  const getPageData = await getElemetDataFromDatabase(lessonId, false);
  localStorage.setItem("pages", JSON.stringify(getPageData.result));
  localStorage.setItem("ACTIVE_PAGE", getPageData.activePage);
};

export const getElemetDataFromDatabase = (
  lessonId: string | null,
  page?: number | boolean,
) => {
  const lessonIds = lessonId?.replace(/\//g, "");
  if (page) {
    return apiGet(
      `${API_URL}/get-whiteboard-data?lessonId=${lessonIds}&page=${page}`,
    );
  } else {
    return apiGet(`${API_URL}/get-whiteboard-data?lessonId=${lessonIds}`);
  }
};

export const getUserWiseWorkspaceData = async (
  userEmail: string,
  id?: string,
) => {
  try {
    const getUserWiseData = id
      ? await apiGet(
          `${ACV_API_URL}/api/workspace/get-userwise-workspace-data?userEmail=${userEmail}&id=${id}`,
        )
      : await apiGet(
          `${ACV_API_URL}/api/workspace/get-userwise-workspace-data?userEmail=${userEmail}`,
        );
    return getUserWiseData;
  } catch (error) {
    console.error("Error fetching workspace data:", error);
    return { result: [] }; // Return an empty result on error
  }
};

export const getActiveOrRestoreWorkspace = async (userEmail: string) => {
  try {
    const getActiveOrRestoreWorkspace = await apiGet(
      `${ACV_API_URL}/api/workspace/get-activeOrRestore-workspace?userEmail=${userEmail}`,
    );
    return getActiveOrRestoreWorkspace;
  } catch (error) {
    console.error("Error fetching workspace data:", error);
    return { result: [] }; // Return an empty result on error
  }
};

export const getIdWiseWorkspaceData = async (id: string | null) => {
  try {
    const getIdWiseWorkspaceData = await apiGet(
      `${ACV_API_URL}/api/workspace/get-id-wise-workspace-data?id=${id}`,
    );
    return getIdWiseWorkspaceData;
  } catch (error) {
    console.error("Error fetching workspace data:", error);
    return { result: [] }; // Return an empty result on error
  }
};

export const getSortWorkspace = async (
  userEmail: string | null,
  sort: number | null,
  selectedWorkspaceTab?: string | "myWorkSpace",
) => {
  try {
    const getSortWorkspace = await apiGet(
      `${ACV_API_URL}/api/workspace/get-sorting-workspace?userEmail=${userEmail}&sort=${sort}&selectedWorkspaceTab=${selectedWorkspaceTab}`,
    );
    return getSortWorkspace;
  } catch (error) {
    console.error("Error fetching workspace data:", error);
    return { result: [] }; // Return an empty result on error
  }
};

export const getStudentsOrTeachersName = async (
  role: string | null,
  slug: string | null,
  email: string | null,
) => {
  try {
    const getStudentsOrTeachersName = await apiGet(
      `${ACV_API_URL}/api/workspace/get-students-teachers-name?role=${role}&slug=${slug}&email=${email}`,
    );
    return getStudentsOrTeachersName;
  } catch (error) {
    console.error("Error fetching workspace data:", error);
    return { result: [] }; // Return an empty result on error
  }
};

export const getArchiveWorkspaceData = async (userEmail: string) => {
  try {
    const getArchiveData = await apiGet(
      `${ACV_API_URL}/api/workspace/get-archive-workspace?userEmail=${userEmail}`,
    );
    return getArchiveData;
  } catch (error) {
    console.error("Error fetching workspace data:", error);
    return { result: [] }; // Return an empty result on error
  }
};

export const getOrganizationUsers = async (
  slug: string | null,
  userEmail: string | null,
) => {
  try {
    const getOrganizationUsers = await apiGet(
      `${ACV_API_URL}/api/workspace/get-organization-users?slug=${slug}&userEmail=${userEmail}`,
    );
    return getOrganizationUsers;
  } catch (error) {
    console.error("Error fetching workspace data:", error);
    return { result: [] }; // Return an empty result on error
  }
};

export const getSharedWithMeWorkspace = async (userEmail: string) => {
  try {
    const getSharedWithMeWorkspace = await apiGet(
      `${ACV_API_URL}/api/workspace/get-shared-with-me-workpsace?userEmail=${userEmail}`,
    );
    return getSharedWithMeWorkspace;
  } catch (error) {
    console.error("Error fetching workspace data:", error);
    return { result: [] }; // Return an empty result on error
  }
};

export const getFilteredTags = async (userEmail: string) => {
  try {
    const getFilteredTags = await apiGet(
      `${ACV_API_URL}/api/workspace/get-filtered-tags?userEmail=${userEmail}`,
    );
    return getFilteredTags;
  } catch (error) {
    console.error("Error fetching workspace data:", error);
    return { result: [] }; // Return an empty result on error
  }
};

export const getFilteredData = async (filterDataWithKeys: {
  tags: string[];
  author: string[];
  age: string;
}) => {
  try {
    const getFilteredData = await apiGet(
      `${ACV_API_URL}/api/workspace/get-filtered-data?userEmail=${filterDataWithKeys.author}&tags=${filterDataWithKeys.tags}&age=${filterDataWithKeys.age}`,
    );
    return getFilteredData;
  } catch (error) {
    console.error("Error fetching workspace data:", error);
    return { result: [] }; // Return an empty result on error
  }
};

export const getSharedWithMeWorkspaceByWorkspaceID = async (
  selectedWorkspaceCardID: string,
) => {
  try {
    const getSharedWithMeWorkspace = await apiGet(
      `${ACV_API_URL}/api/workspace/get-shared-with-me-workspace-by-workspaceID?workspaceId=${selectedWorkspaceCardID}`,
    );

    return getSharedWithMeWorkspace;
  } catch (error) {
    console.error("Error fetching workspace data:", error);
    return { result: [] }; // Return an empty result on error
  }
};

export const getWorkspaceUsers = async () => {
  try {
    const getSharedWithMeWorkspace = await apiGet(
      `${ACV_API_URL}/api/workspace/get-workspace-users`,
    );

    return getSharedWithMeWorkspace?.data;
  } catch (error) {
    console.error("Error fetching workspace data:", error);
    return { result: [] }; // Return an empty result on error
  }
};

export const updateWorkspaceDetails = async (
  id: string | null,
  elements: [],
  previewImage: string,
) => {
  try {
    const response = await apiPut(
      `${ACV_API_URL}/api/workspace/update-workspace/${id}`,
      { elements, previewImage },
    );
    return response;
  } catch (error) {
    console.log("updatePagesDetails:error", error);
  }
};

export const updateEmailsInSharedWorkspace = async (
  id: string | null,
  userEmail: string[] | null,
) => {
  try {
    const response = await apiPut(
      `${ACV_API_URL}/api/workspace/update-emails/${id}`,
      { userEmail },
    );
    return response;
  } catch (error) {
    console.log("updatePagesDetails:error", error);
  }
};

export const updateSharedWorkspace = async (
  id: string | null,
  roomData: {
    roomId?: string | null;
    roomKey?: string | null;
    isCollaboration?: boolean;
    workspaceId?: string | null;
  } | null,
) => {
  if (!id) return console.log("No workspace ID provided.");

  try {
    const response = await apiPut(
      `${ACV_API_URL}/api/workspace/update-shared-with-me-workpsace/${id}`,
      { roomData: roomData },
    );
    return response;
  } catch (error) {
    console.log("updateSharedWorkspace:error", error);
  }
};

export const updateWorkspaceFeildInInviteWorkspaceModal = async (
  id: string | null,
  workSpaceUser: [{ email: string | null; isActive: boolean }],
) => {
  if (!id) return console.log("No workspace ID provided.");

  try {
    const response = await apiPut(
      `${ACV_API_URL}/api/workspace/update-workspace-feild/${id}`,
      { userEmail: workSpaceUser },
    );
    return response;
  } catch (error) {
    console.log("updateSharedWorkspace:error", error);
  }
};

export const updateWorkspaceStatus = async (
  id: string | null,
  status: string,
) => {
  try {
    const response = await apiPut(`${ACV_API_URL}/api/workspace/status/${id}`, {
      status: status,
    });
    return response;
  } catch (error) {
    console.log("updatePagesDetails:error", error);
  }
};

export const updateInvitedWorkspaceStatus = async (
  id: string | null,
  status: string,
) => {
  try {
    const response = await apiPut(
      `${ACV_API_URL}/api/workspace/modify-status/${id}`,
      {
        status: status,
      },
    );
    return response;
  } catch (error) {
    console.log("updatePagesDetails:error", error);
  }
};

export const updateExpirationDateTime = async (
  id: string | null,
  expirationDate: string,
) => {
  try {
    const response = await apiPut(
      `${ACV_API_URL}/api/workspace/update-expiration-date-time/${id}`,
      {
        expirationDate: expirationDate,
      },
    );
    return response;
  } catch (error) {
    console.log("updatePagesDetails:error", error);
  }
};

export const renameWorkspace = async (id: string | null, name: string) => {
  try {
    const response = await apiPut(`${ACV_API_URL}/api/workspace/rename/${id}`, {
      name: name,
    });
    return response;
  } catch (error) {
    console.log("updatePagesDetails:error", error);
  }
};

export const deleteSelectedWorkspace = (id: string) =>
  apiDelete(`${ACV_API_URL}/api/workspace/delete-workspace/${id}`);

export const updatePagesDetails = async (
  lessonId: string | null,
  body: any,
) => {
  try {
    const response = await apiPut(
      `${API_URL}/update-whiteboard-data?lessonId=${lessonId}`,
      body,
    );
    return response;
  } catch (error) {
    console.log("updatePagesDetails:error", error);
  }
};

export const addParticipants = (body: {
  userEmail: {
    email: string;
    isActive: boolean;
    isEnabled: boolean;
  } | null;
  editingAccess: boolean;
  workspaceId: string | null;
  ownerUserEmail: string | null;
  userTimezone: string | null;
}) => {
  try {
    return apiPost(`${ACV_API_URL}/api/workspace/shared-workspace`, body);
  } catch (error) {
    console.log(error);
  }
};

export const sendMail = (body: {
  ownerUserEmail: string | null;
  ownerUserName: string | null;
  Email: string | null;
  name: string | null;
  link: string | null;
}) => {
  try {
    return apiPost(`${ACV_API_URL}/api/workspace/send-mail`, body);
  } catch (error) {
    console.log(error);
  }
};

export const delElemetDataFromDatabase = (
  id: string,
  slug: string,
  role: string,
) => {
  const lessonIds = id ? id.toString().replace(/\//g, "") : "";
  return apiDelete(
    `${API_URL}/delete-whiteboard-data?id=${lessonIds}&slug=${slug}&role=${role}`,
  );
};

export const getTagWiseTemplate = (
  tag: string,
  userEmail: string,
  offset: number,
) => {
  return apiGet(
    `${ACV_API_URL}/api/library/tag-wise-collection?tag=${tag}&userEmail=${userEmail}&slug=${slug}&limit=10&offset=${offset}`,
  );
};

export const getAuthorWiseTemplate = (userEmail: string, offset: number) => {
  return apiGet(
    `${ACV_API_URL}/api/library/author-wise-collection?userEmail=${userEmail}&slug=${slug}&limit=10&offset=${offset}`,
  );
};

export const getComments = (data: string) => {
  const collectionId = data?.trim();
  return apiGet(`${ACV_API_URL}/api/collection-comment/${collectionId}`);
};

export const createComments = (data: CommentType) => {
  return apiPost(`${ACV_API_URL}/api/collection-comment`, data);
};

export const editComments = (data: CommentType) => {
  return apiPut(`${ACV_API_URL}/api/collection-comment`, data);
};

export const deleteComments = (data: string) => {
  const id = data?.trim();
  return apiDelete(`${ACV_API_URL}/api/collection-comment/${id}`);
};

interface updatePageBody {
  pageName: String;
  data: Object;
}
interface updateActivePage {
  link: String;
  activePage: Number;
}

export const updatePageName = (body: updatePageBody) => {
  return apiPost(`${API_URL}/update-whiteboard-page-name`, body);
};

export const updatePageOrder = (body: { data: any[] }) => {
  return apiPost(`${API_URL}/update-whiteboard-page-order`, body);
};

//add current page in active session which teacher are currently working on that
export const updateActivePage = (body: updateActivePage) => {
  return apiPost(`${API_URL}/add-active-page`, body);
};
