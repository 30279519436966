import { RoughCanvas } from "roughjs/bin/canvas";
import { Point as RoughPoint } from "roughjs/bin/geometry";
import { Spreadsheet } from "./charts";
import { ClipboardData } from "./clipboard";
import type App from "./components/App";
import { MyWorkspaceSidebarItemsEnum } from "./components/myWorkSpace/views/sidebar";
import { ALLOWED_IMAGE_MIME_TYPES, MIME_TYPES } from "./constants";
import { FileSystemHandle } from "./data/filesystem";
import Library from "./data/library";
import { ImportedDataState } from "./data/types";
import { SuggestedBinding } from "./element/binding";
import { LinearElementEditor } from "./element/linearElementEditor";
import { MaybeTransformHandleType } from "./element/transformHandles";
import {
  Arrowhead,
  ChartType,
  ExcalidrawAudioElement,
  ExcalidrawAvatarImageElement,
  ExcalidrawBindableElement,
  ExcalidrawElement,
  ExcalidrawElementType,
  ExcalidrawFormulaElement,
  ExcalidrawFrameLikeElement,
  ExcalidrawIframeLikeElement,
  ExcalidrawImageElement,
  ExcalidrawLinearElement,
  ExcalidrawMagicFrameElement,
  ExcalidrawMermaidDiagramElement,
  ExcalidrawTextWithStyleElement,
  ExcalidrawVideoElement,
  FileId,
  FontFamilyValues,
  FontWeight,
  GroupId,
  NonDeleted,
  NonDeletedElementsMap,
  NonDeletedExcalidrawElement,
  PointerType,
  StrokeRoundness,
  TextAlign,
  Theme,
} from "./element/types";
import { Language } from "./i18n";
import { isOverScrollBars } from "./scene";
import { StaticCanvasRenderConfig } from "./scene/types";
import { SnapLine } from "./snapping";
import { StoreActionType } from "./store";
import { MakeBrand } from "./utility-types";
import type { ResolvablePromise } from "./utils";
import { CountdownState, StopwatchState } from "./components/App";

export type RenderableElementsMap = NonDeletedElementsMap &
  MakeBrand<"RenderableElementsMap">;

export type Point = Readonly<RoughPoint>;

export type UnsubscribeCallback = () => void;

export type Collaborator = {
  pointer?: CollaboratorPointer;
  button?: "up" | "down";
  selectedElementIds?: AppState["selectedElementIds"];
  username?: string | null;
  userState?: UserIdleState;
  color?: {
    background: string;
    stroke: string;
  };

  id?: string;
  socketId?: SocketId;
};

export type DataURL = string & { _brand: "DataURL" };

export type CollaboratorPointer = {
  x: number;
  y: number;
  tool: "pointer" | "laser";
  /**
   * Whether to render cursor + username. Useful when you only want to render
   * laser trail.
   *
   * @default true
   */
  renderCursor?: boolean;
  /**
   * Explicit laser color.
   *
   * @default string collaborator's cursor color
   */
  laserColor?: string;
};

export type BinaryFileData = {
  mimeType:
    | typeof ALLOWED_IMAGE_MIME_TYPES[number]
    // future user or unknown file type
    | typeof MIME_TYPES.binary;
  id: FileId;
  dataURL: DataURL;
  created: number;
  isPublished: boolean;
};

export type BinaryFileMetadata = Omit<BinaryFileData, "dataURL">;

export type BinaryFiles = Record<ExcalidrawElement["id"], BinaryFileData>;

export type UpdatePageDataURL = {
  page: number;
  lessonId: string;
  dataURL: string | null;
};

export type AppState = {
  appState: Omit<AppState, "offsetTop" | "offsetLeft" | "width" | "height">;
  isLoading: boolean;
  errorMessage: string | null;
  draggingElement: NonDeletedExcalidrawElement | null;
  resizingElement: NonDeletedExcalidrawElement | null;
  multiElement: NonDeleted<ExcalidrawLinearElement> | null;
  selectionElement: NonDeletedExcalidrawElement | null;
  isBindingEnabled: boolean;
  startBoundElement: NonDeleted<ExcalidrawBindableElement> | null;
  suggestedBindings: SuggestedBinding[];
  frameToHighlight: NonDeleted<ExcalidrawFrameLikeElement> | null;
  frameRendering: {
    enabled: boolean;
    name: boolean;
    outline: boolean;
    clip: boolean;
  };
  editingFrame: string | null;
  /** imageElement waiting to be placed on canvas */
  pendingImageElementId:
    | ExcalidrawAvatarImageElement["id"]
    | ExcalidrawImageElement["id"]
    | null;
  selectedElementsAreBeingDragged: boolean;
  selectedLinearElement: LinearElementEditor | null;
  elementsToHighlight: NonDeleted<ExcalidrawElement>[] | null;
  activeEmbeddable: {
    element: NonDeletedExcalidrawElement;
    state: "hover" | "active";
  } | null;
  snapLines: readonly SnapLine[];
  objectsSnapModeEnabled: boolean;
  activeTool: {
    /**
     * indicates a previous tool we should revert back to if we deselect the
     * currently active tool. At the moment applies to `eraser` and `hand` tool.
     */
    lastActiveTool: ActiveTool | null;
    locked: boolean;
  } & ActiveTool;
  // element being edited, but not necessarily added to elements array yet
  // (e.g. text element when typing into the input)
  editingElement: NonDeletedExcalidrawElement | null;
  editingLinearElement: LinearElementEditor | null;
  elementLocked: boolean;
  exportBackground: boolean;
  exportEmbedScene: boolean;
  exportWithDarkMode: boolean;
  exportScale: number;
  currentItemStrokeColor: string;
  currentItemBackgroundColor: string;
  currentItemFillStyle: ExcalidrawElement["fillStyle"];
  currentItemStrokeWidth: number;
  currentItemStrokeStyle: ExcalidrawElement["strokeStyle"];
  currentItemRoughness: number;
  currentItemRoundness: StrokeRoundness;
  currentItemOpacity: number;
  currentItemFontFamily: FontFamilyValues;
  currentItemFontSize: number;
  currentItemFontWeight: FontWeight;
  currentItemTextAlign: TextAlign;
  currentItemStrokeSharpness: ExcalidrawElement["strokeSharpness"];
  currentItemStartArrowhead: Arrowhead | null;
  currentItemEndArrowhead: Arrowhead | null;
  currentItemLinearStrokeSharpness: ExcalidrawElement["strokeSharpness"];
  currentItemArrowType: "sharp" | "round" | "elbow";
  viewBackgroundColor: string;
  scrollX: number;
  scrollY: number;
  cursorButton: "up" | "down";
  scrolledOutside: boolean;
  name: string;
  isRotating: boolean;
  zoom: Zoom;
  openMenu: "canvas" | "shape" | null;
  openPopup:
    | "canvasColorPicker"
    | "backgroundColorPicker"
    | "strokeColorPicker"
    | null;
  lastPointerDownWith: PointerType;
  selectedElementIds: { [id: string]: boolean };
  previousSelectedElementIds: { [id: string]: boolean };
  shouldCacheIgnoreZoom: boolean;
  showHelpDialog: boolean;
  toastMessage: string | null;
  zenModeEnabled: boolean;
  sidebarWrapper: boolean;
  currentPage: number;
  theme: Theme;
  gridSize: number | null;
  viewModeEnabled: boolean;
  lessonId: string | null;
  isCollaborating: boolean;
  pageName: string | null;

  /** top-most selected groups (i.e. does not include nested groups) */
  selectedGroupIds: { [groupId: string]: boolean };
  /** group being edited when you drill down to its constituent element
    (e.g. when you double-click on a group's element) */
  editingGroupId: GroupId | null;
  width: number;
  height: number;
  offsetTop: number;
  offsetLeft: number;

  isLibraryOpen: boolean;
  fileHandle: FileSystemHandle | null;
  collaborators: Map<SocketId, Collaborator>;
  showStats: boolean;
  currentChartType: ChartType;
  DBElements: [];
  isFirstLoading: boolean;

  pasteDialog:
    | {
        shown: false;
        data: null;
      }
    | {
        shown: true;
        data: Spreadsheet;
      };
  /** imageElement waiting to be placed on canvas */

  pendingVideoElement: NonDeleted<ExcalidrawVideoElement> | null;
  pendingAudioElement: NonDeleted<ExcalidrawAudioElement> | null;
  duration: string;
  files: BinaryFiles;
  videoInterval: any;
  audioInterval: any;
  videoCache: Map<
    FileId,
    {
      video: HTMLVideoElement | Promise<HTMLVideoElement>;
      mimeType: typeof ALLOWED_IMAGE_MIME_TYPES[number];
    }
  >;
  audioCache: Map<
    FileId,
    {
      audio: HTMLAudioElement | Promise<HTMLAudioElement>;
      mimeType: typeof ALLOWED_IMAGE_MIME_TYPES[number];
    }
  >;
  pendingImageElement: NonDeleted<
    | ExcalidrawAvatarImageElement
    | ExcalidrawImageElement
    | ExcalidrawFormulaElement
    | ExcalidrawTextWithStyleElement
    | ExcalidrawMermaidDiagramElement
  > | null;
  S: boolean;
  isShowLibraryTemplate: boolean;
  updatingFormulaElement: {
    id: string;
    width: number;
    height: number;
  };
  updatingTextElement: ExcalidrawTextWithStyleElement | null;
  showMathInputAndKeyboard: boolean;
  openTextEditor: boolean;
  formulaValue: string;
  isOpenVideoRecorder: boolean;
  isOpenAudioRecorder: boolean;
  notesTitle: string | null;
  notesColor: string;
  isFlexibleGroup?: boolean;
  isCollaboratingWithFlexibleGroups?: boolean;
  isShowWhiteboard?: boolean;
  isMyWorkSpace?: boolean;
  selectedWorkspaceTab?: MyWorkspaceSidebarItemsEnum;
  isShowNoPagesModel: boolean;
  whiteboardModel: boolean;
  confirmImportLibraryModel: boolean;
  updatedPageDataURL: UpdatePageDataURL;
  textUnderline: boolean;
  textItalic: boolean;
  textEditor: {
    open: boolean;
    sceneX: number;
    sceneY: number;
    value: string;
    width: number;
    height: number;
    resizeWidth: number;
    resizeHeight: number;
    resize: boolean;
  };
  editableSelectedElement: {
    elementId: string;
    isSelected: boolean;
    selectedElementIds: { [id: string]: boolean };
  };
  hasRenderedTextWithStyles: boolean;
  editingLibrary: {
    isEditing: boolean;
    libraryId: string;
    libraryInfo: CollocationType;
    libraryItems: LibraryItems;
    oldElements: ExcalidrawElement[];
    currentPageOldElements: ExcalidrawElement[];
  };
  showPublishLibraryDialog: boolean;
  showElementOnCanvasOrNot: boolean;
  pdfPageSelectionDialog: boolean;
  pdfFile: File | null;
  isFullScreen: boolean;
  isResizing: boolean;
  selectedElements: ExcalidrawTextWithStyleElement[];
  isResizableOrNot: boolean;
  defaultLibraryTab: string;
  workspaceUsers: any;
  originSnapOffset: {
    x: number;
    y: number;
  } | null;
  EditMermaidDiagramDialog: {
    open: boolean;
    syntax: string;
    id: string | null;
    x: number;
    y: number;
    fileId: string | null;
  };
  presentationMode: boolean;
  isOutlineOpen: boolean;
  presentationTheme: Theme;
  celebration: boolean;
};

export type SVGRenderConfig = {
  offsetX: number;
  offsetY: number;
  isExporting: boolean;
  exportWithDarkMode: boolean;
  renderEmbeddables: boolean;
  frameRendering: AppState["frameRendering"];
  canvasBackgroundColor: AppState["viewBackgroundColor"];
  embedsValidationStatus: EmbedsValidationStatus;
};

export type NormalizedZoomValue = number & { _brand: "normalizedZoom" };

export type ObservedAppState = ObservedStandaloneAppState &
  ObservedElementsAppState;

export type ObservedStandaloneAppState = {
  name: AppState["name"];
  viewBackgroundColor: AppState["viewBackgroundColor"];
};

export type ObservedElementsAppState = {
  editingGroupId: AppState["editingGroupId"];
  selectedElementIds: AppState["selectedElementIds"];
  selectedGroupIds: AppState["selectedGroupIds"];
  // Avoiding storing whole instance, as it could lead into state incosistencies, empty undos/redos and etc.
  editingLinearElementId: LinearElementEditor["elementId"] | null;
  // Right now it's coupled to `editingLinearElement`, ideally it should not be really needed as we already have selectedElementIds & editingLinearElementId
  selectedLinearElementId: LinearElementEditor["elementId"] | null;
};

export type Zoom = Readonly<{
  value: NormalizedZoomValue;
  translation: Readonly<{
    x: number;
    y: number;
  }>;
}>;

export type PointerCoords = Readonly<{
  x: number;
  y: number;
}>;

export type Gesture = {
  pointers: Map<number, PointerCoords>;
  lastCenter: { x: number; y: number } | null;
  initialDistance: number | null;
  initialScale: number | null;
};

export declare class GestureEvent extends UIEvent {
  readonly rotation: number;
  readonly scale: number;
}

// libraries
// -----------------------------------------------------------------------------
/** @deprecated legacy: do not use outside of migration paths */
export type LibraryItem_v1 = readonly NonDeleted<ExcalidrawElement>[];
/** @deprecated legacy: do not use outside of migration paths */
export type LibraryItems_v1 = readonly LibraryItem_v1[];

/** v2 library item */
export type LibraryItem = {
  id: string;
  status: "published" | "unpublished" | "Under Review";
  elements: readonly NonDeleted<ExcalidrawElement>[];
  /** timestamp in epoch (ms) */
  created: number;
  name?: string;
  error?: string;
  collectionId?: string | null;
};

export type LibraryType = LibraryItem & {
  elements: ExcalidrawElement[];
  svg?: SVGSVGElement | null;
  isSelected: boolean;
};

export type CustomCategies = {
  templateIds: string[];
  elements: NonDeleted<ExcalidrawElement>[];
  categoryId: string;
};

export type LibraryItems = readonly LibraryItem[];

export type Subscription = {
  audioLimit: Number;
  videoLimit: Number;
  audioRecordLimit: Number;
  videoRecordLimit: Number;
  MagicACVBar: boolean;
  Diagrams: boolean;
  SpinWheel: boolean;
  freeExternalSources: boolean;
};

// NOTE ready/readyPromise props are optional for host apps' sake (our own
// implem guarantees existence)
export type ExcalidrawAPIRefValue =
  | ExcalidrawImperativeAPI
  | {
      readyPromise?: ResolvablePromise<ExcalidrawImperativeAPI>;
      ready?: false;
    };

export interface ExcalidrawProps {
  onChange?: (
    elements: readonly ExcalidrawElement[],
    appState: AppState,
    files: BinaryFiles,
  ) => void;
  isCollaboratingWithFlexibleGroups?: boolean;
  initialData?: ImportedDataState | null | Promise<ImportedDataState | null>;
  excalidrawRef?: ForwardRef<ExcalidrawAPIRefValue>;
  onCollabButtonClick?: () => void;
  onFlexibleGroupButtonClick?: () => void;
  isCollaborating?: boolean;
  onPointerUpdate?: (payload: {
    pointer: { x: number; y: number; tool: "pointer" | "laser" };
    button: "down" | "up";
    pointersMap: Gesture["pointers"];
  }) => void;
  onPaste?: (
    data: ClipboardData,
    event: ClipboardEvent | null,
  ) => Promise<boolean> | boolean;
  renderTopRightUI?: (
    isMobile: boolean,
    appState: AppState,
  ) => JSX.Element | null;
  renderFooter?: (isMobile: boolean, appState: AppState) => JSX.Element;
  langCode?: Language["code"];
  viewModeEnabled?: boolean;
  zenModeEnabled?: boolean;
  sidebarWrapper?: boolean;
  currentPage?: number;
  pageName?: string;
  DBElements?: any;
  isFirstLoading?: boolean;
  gridModeEnabled?: boolean;
  libraryReturnUrl?: string;
  theme?: Theme;
  name?: string;
  lessonId?: string | null;
  renderCustomStats?: (
    elements: readonly NonDeletedExcalidrawElement[],
    appState: AppState,
  ) => JSX.Element;
  UIOptions?: UIOptions;
  detectScroll?: boolean;
  handleKeyboardGlobally?: boolean;
  onLibraryChange?: (libraryItems: LibraryItems) => void | Promise<any>;
  autoFocus?: boolean;
  generateIdForFile?: (file: File) => string | Promise<string>;
  removeVideoFromVideoCatch?: () => void;
  validateEmbeddable?:
    | boolean
    | string[]
    | RegExp
    | RegExp[]
    | ((link: string) => boolean | undefined);
  onLinkOpen?: (
    element: NonDeletedExcalidrawElement,
    event: CustomEvent<{
      nativeEvent: MouseEvent | React.PointerEvent<HTMLCanvasElement>;
    }>,
  ) => void;
  showHyperlinkPopup: false | "info" | "editor";
}

export type SceneData = {
  elements?: ImportedDataState["elements"];
  appState?: ImportedDataState["appState"];
  collaborators?: Map<SocketId, Collaborator>;
  commitToHistory?: boolean;
  storeAction?: StoreActionType;
};

export enum UserIdleState {
  ACTIVE = "active",
  AWAY = "away",
  IDLE = "idle",
}

export type ExportOpts = {
  saveFileToDisk?: boolean;
  onExportToBackend?: (
    exportedElements: readonly NonDeletedExcalidrawElement[],
    appState: AppState,
    files: BinaryFiles,
    canvas: HTMLCanvasElement | null,
  ) => void;
  renderCustomUI?: (
    exportedElements: readonly NonDeletedExcalidrawElement[],
    appState: AppState,
    files: BinaryFiles,
    canvas: HTMLCanvasElement | null,
  ) => JSX.Element;
};

type CanvasActions = {
  changeViewBackgroundColor?: boolean;
  clearCanvas?: boolean;
  export?: false | ExportOpts;
  loadScene?: boolean;
  saveToActiveFile?: boolean;
  theme?: boolean;
  saveAsImage?: boolean;
};

export type UIOptions = {
  canvasActions?: CanvasActions;
  tools?: {
    image: boolean;
  };
};

export type AppProps = ExcalidrawProps & {
  UIOptions: {
    canvasActions: Required<CanvasActions> & { export: ExportOpts };
  };
  detectScroll: boolean;
  handleKeyboardGlobally: boolean;
};

/** A subset of App class properties that we need to use elsewhere
 * in the app, eg Manager. Factored out into a separate type to keep DRY. */
export type AppClassProperties = {
  scene: App["scene"];
  props: AppProps;
  pasteFromClipboard: App["pasteFromClipboard"];
  files: BinaryFiles;
  interactiveCanvas: HTMLCanvasElement | null;
  canvas: HTMLCanvasElement | null;
  focusContainer(): void;
  library: Library;
  imageCache: Map<
    FileId,
    {
      image: HTMLImageElement | Promise<HTMLImageElement>;
      mimeType: typeof ALLOWED_IMAGE_MIME_TYPES[number];
    }
  >;

  addMermaidElements: App["addMermaidElements"];
  setActiveTool: App["setActiveTool"];
  scrollToContent: App["scrollToContent"];
  updateScene: App["updateScene"];
  intervalRef: React.MutableRefObject<any>;
  getName: App["getName"];
  countDownRef: React.MutableRefObject<any>;
  appStateRef: React.MutableRefObject<any>;
  chronometerScrollRef: React.MutableRefObject<any>;
  countdownStates: Map<string, CountdownState>;
  stopwatchStates: Map<string, StopwatchState>;
  countdownAnimation: any;
};

export type PointerDownState = Readonly<{
  // The first position at which pointerDown happened
  origin: Readonly<{ x: number; y: number }>;
  // Same as "origin" but snapped to the grid, if grid is on
  originInGrid: Readonly<{ x: number; y: number }>;
  // Scrollbar checks
  scrollbars: ReturnType<typeof isOverScrollBars>;
  // The previous pointer position
  lastCoords: { x: number; y: number };
  // map of original elements data
  originalElements: Map<string, NonDeleted<ExcalidrawElement>>;
  resize: {
    // Handle when resizing, might change during the pointer interaction
    handleType: MaybeTransformHandleType;
    // This is determined on the initial pointer down event
    isResizing: boolean;
    // This is determined on the initial pointer down event
    offset: { x: number; y: number };
    // This is determined on the initial pointer down event
    arrowDirection: "origin" | "end";
    // This is a center point of selected elements determined on the initial pointer down event (for rotation only)
    center: { x: number; y: number };
  };
  hit: {
    // The element the pointer is "hitting", is determined on the initial
    // pointer down event
    element: NonDeleted<ExcalidrawElement> | null;
    // The elements the pointer is "hitting", is determined on the initial
    // pointer down event
    allHitElements: NonDeleted<ExcalidrawElement>[];
    // This is determined on the initial pointer down event
    wasAddedToSelection: boolean;
    // Whether selected element(s) were duplicated, might change during the
    // pointer interaction
    hasBeenDuplicated: boolean;
    hasHitCommonBoundingBoxOfSelectedElements: boolean;
  };
  withCmdOrCtrl: boolean;
  drag: {
    // Might change during the pointer interation
    hasOccurred: boolean;
    // Might change during the pointer interation
    offset: { x: number; y: number } | null;
  };
  // We need to have these in the state so that we can unsubscribe them
  eventListeners: {
    // It's defined on the initial pointer down event
    onMove: null | ((event: PointerEvent) => void);
    // It's defined on the initial pointer down event
    onUp: null | ((event: PointerEvent) => void);
    // It's defined on the initial pointer down event
    onKeyDown: null | ((event: KeyboardEvent) => void);
    // It's defined on the initial pointer down event
    onKeyUp: null | ((event: KeyboardEvent) => void);
  };
  boxSelection: {
    hasOccurred: boolean;
  };
}>;

export type ExcalidrawImperativeAPI = {
  updateScene: InstanceType<typeof App>["updateScene"];
  resetScene: InstanceType<typeof App>["resetScene"];
  getSceneElementsIncludingDeleted: InstanceType<
    typeof App
  >["getSceneElementsIncludingDeleted"];
  history: {
    clear: InstanceType<typeof App>["resetHistory"];
  };
  scrollToContent: InstanceType<typeof App>["scrollToContent"];
  getSceneElements: InstanceType<typeof App>["getSceneElements"];
  getAppState: () => InstanceType<typeof App>["state"];
  refresh: InstanceType<typeof App>["refresh"];
  importLibrary: InstanceType<typeof App>["importLibraryFromUrl"];
  setToastMessage: InstanceType<typeof App>["setToastMessage"];
  getFiles: () => BinaryFiles;
  addFiles: (data: BinaryFileData[]) => void;
  readyPromise: ResolvablePromise<ExcalidrawImperativeAPI>;
  ready: true;
  id: string;
};

export type AuthorsCollocationType = {
  name: string;
  url: string;
};

export type TemplateFilesType = {
  id: string;
  mimeType: string;
  url: string;
  created: number;
  isPublished: boolean;
};

export type NonDeletedElement = {
  templateId: string;
  categoryId: any;
  x: number;
  width: number;
};

export type Category = {
  categoryId: any;
  elements: NonDeletedElement[];
};

export type CollectionActivities = {
  id: string;
  collectionId: string;
  userEmail: string;
  isLiked: boolean;
  isBookmarked: boolean;
  createdAt: Date;
};

export type CollocationType = {
  flatMap(
    arg0: (
      element: string,
    ) => import("./components/libraryItemsDialog").SidebarItemsProps[],
  ): any;
  map(arg0: (element: any) => void): unknown;
  id: string;
  name: string;
  description: string;
  author: AuthorsCollocationType;
  source: string;
  preview: string;
  createdAt: string;
  status: string;
  likeCount: number;
  reason: string;
  tags: string[];
  userEmail: string;
  title?: string;
  files?: [];
  category?: string[];
  CollectionActivities: CollectionActivities[];
};

export type Filter = {
  authors: string[];
  tags: string[];
};

export type CommentType = {
  id?: string;
  userEmail?: string;
  image?: string | null;
  collectionId?: string;
  name?: string;
  comment: string;
  createdAt?: string;
};

export type StockImagesType = {
  id: string;
  width: number;
  height: number;
  url: string;
  photographer: string;
  photographer_url: string;
  photographer_id: number;
  avg_color: string;
  src: {
    original: string;
    large2x: string;
    large: string;
    medium: string;
    small: string;
    portrait: string;
    landscape: string;
    tiny: string;
  };
  liked: boolean;
  alt: string;
};

export type LibraryFileType = {
  id: BinaryFileData["id"];
  mimeType: BinaryFileData["mimeType"];
  url: string;
  created: number;
};

export type Device = Readonly<{
  isSmScreen: boolean;
  isMobile: boolean;
  isTouchScreen: boolean;
  canDeviceFitSidebar: boolean;
}>;

export type LibraryData = {
  url: string;
  authorName: string;
  items: LibraryItems;
};

export type EmbedsValidationStatus = Map<
  ExcalidrawIframeLikeElement["id"],
  boolean
>;

export type ElementsPendingErasure = Set<ExcalidrawElement["id"]>;

export type ToolType =
  | "selection"
  | "rectangle"
  | "diamond"
  | "ellipse"
  | "arrow"
  | "line"
  | "freedraw"
  | "text"
  | "image"
  | "eraser"
  | "hand"
  | "frame"
  | "magicframe"
  | "embeddable"
  | "laser"
  | "formula"
  | "compress"
  | "clock"
  | "avatar";

export type ElementOrToolType = ExcalidrawElementType | ToolType | "custom";

export type ActiveTool =
  | {
      type: ToolType;
      customType: null;
    }
  | {
      type: "custom";
      customType: string;
    };

type _CommonCanvasAppState = {
  zoom: AppState["zoom"];
  scrollX: AppState["scrollX"];
  scrollY: AppState["scrollY"];
  width: AppState["width"];
  height: AppState["height"];
  viewModeEnabled: AppState["viewModeEnabled"];
  editingGroupId: AppState["editingGroupId"]; // TODO: move to interactive canvas if possible
  selectedElementIds: AppState["selectedElementIds"]; // TODO: move to interactive canvas if possible
  frameToHighlight: AppState["frameToHighlight"]; // TODO: move to interactive canvas if possible
  offsetLeft: AppState["offsetLeft"];
  offsetTop: AppState["offsetTop"];
  theme: AppState["theme"];
  pendingImageElementId: AppState["pendingImageElementId"];
};

export type StaticCanvasAppState = Readonly<
  _CommonCanvasAppState & {
    shouldCacheIgnoreZoom: AppState["shouldCacheIgnoreZoom"];
    /** null indicates transparent bg */
    viewBackgroundColor: AppState["viewBackgroundColor"] | null;
    exportScale: AppState["exportScale"];
    selectedElementsAreBeingDragged: AppState["selectedElementsAreBeingDragged"];
    gridSize: AppState["gridSize"];
    frameRendering: AppState["frameRendering"];
    selectionElement: AppState["selectionElement"];
    editingLinearElement: AppState["editingLinearElement"];
    collaborators: AppState["collaborators"];
    selectedLinearElement: AppState["selectedLinearElement"];
    multiElement: AppState["multiElement"];
    isBindingEnabled: AppState["isBindingEnabled"];
    suggestedBindings: AppState["suggestedBindings"];
    isRotating: AppState["isRotating"];
    elementsToHighlight: AppState["elementsToHighlight"];
    // SnapLines
    snapLines: AppState["snapLines"];
    zenModeEnabled: AppState["zenModeEnabled"];
    editingElement: AppState["editingElement"];
    activeEmbeddable: AppState["activeEmbeddable"];
    presentationMode: AppState["presentationMode"];
    presentationTheme: AppState["presentationTheme"];
  }
>;

export type StaticSceneRenderConfig = {
  canvas: HTMLCanvasElement;
  rc: RoughCanvas;
  elementsMap: RenderableElementsMap;
  visibleElements: readonly NonDeletedExcalidrawElement[];
  scale: number;
  appState: StaticCanvasAppState;
  renderConfig: StaticCanvasRenderConfig;
};

export type InteractiveCanvasAppState = Readonly<
  _CommonCanvasAppState & {
    // renderInteractiveScene
    activeEmbeddable: AppState["activeEmbeddable"];
    editingLinearElement: AppState["editingLinearElement"];
    selectionElement: AppState["selectionElement"];
    selectedGroupIds: AppState["selectedGroupIds"];
    selectedLinearElement: AppState["selectedLinearElement"];
    multiElement: AppState["multiElement"];
    isBindingEnabled: AppState["isBindingEnabled"];
    suggestedBindings: AppState["suggestedBindings"];
    isRotating: AppState["isRotating"];
    elementsToHighlight: AppState["elementsToHighlight"];
    // Collaborators
    collaborators: AppState["collaborators"];
    // SnapLines
    snapLines: AppState["snapLines"];
    zenModeEnabled: AppState["zenModeEnabled"];
    presentationMode: AppState["presentationMode"];
  }
>;
export type SocketId = string & { _brand: "SocketId" };

export type InteractiveCanvasRenderConfig = {
  // collab-related state
  // ---------------------------------------------------------------------------
  remoteSelectedElementIds: Map<ExcalidrawElement["id"], SocketId[]>;
  remotePointerViewportCoords: Map<SocketId, { x: number; y: number }>;
  remotePointerUserStates: Map<SocketId, UserIdleState>;
  remotePointerUsernames: Map<SocketId, string>;
  remotePointerButton: Map<SocketId, string | undefined>;
  selectionColor: string;
  // extra options passed to the renderer
  // ---------------------------------------------------------------------------
  renderScrollbars?: boolean;
};

export type KeyboardModifiersObject = {
  ctrlKey: boolean;
  shiftKey: boolean;
  altKey: boolean;
  metaKey: boolean;
};

export type UIAppState = Omit<
  AppState,
  | "suggestedBindings"
  | "startBoundElement"
  | "cursorButton"
  | "scrollX"
  | "scrollY"
>;

export type FrameNameBounds = {
  x: number;
  y: number;
  width: number;
  height: number;
  angle: number;
};

export type FrameNameBoundsCache = {
  get: (
    frameElement: ExcalidrawFrameLikeElement | ExcalidrawMagicFrameElement,
  ) => FrameNameBounds | null;
  _cache: Map<
    string,
    FrameNameBounds & {
      zoom: AppState["zoom"]["value"];
      versionNonce: ExcalidrawFrameLikeElement["versionNonce"];
    }
  >;
};
