import LanguageDetector from "i18next-browser-languagedetector";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { trackEvent } from "../analytics";
import { ErrorDialog } from "../components/ErrorDialog";
import { Tooltip } from "../components/Tooltip";
import { shield } from "../components/icons";
import {
  APP_NAME,
  EVENT,
  STORAGE_KEYS,
  TITLE_TIMEOUT,
  URL_HASH_KEYS,
  VERSION_TIMEOUT,
} from "../constants";
import { loadFromBlob } from "../data/blob";
import { restoreAppState, RestoredDataState } from "../data/restore";
import { ImportedDataState } from "../data/types";
import { ExcalidrawElement, FileId } from "../element/types";
import { useCallbackRefState } from "../hooks/useCallbackRefState";
import { Language, setLanguage, t } from "../i18n";
import Excalidraw, {
  defaultLang,
  languages,
} from "../packages/excalidraw/index";
import {
  AppState,
  BinaryFileData,
  BinaryFiles,
  ExcalidrawImperativeAPI,
  LibraryItem,
  LibraryItems,
} from "../types";
import {
  debounce,
  getVersion,
  ResolvablePromise,
  resolvablePromise,
} from "../utils";
import CustomStats from "./CustomStats";
import {
  FIREBASE_STORAGE_PREFIXES,
  SAVE_TO_LOCAL_STORAGE_TIMEOUT,
} from "./app_constants";
import CollabWrapper, {
  CollabAPI,
  CollabContext,
  CollabContextConsumer,
} from "./collab/CollabWrapper";
import { LanguageList } from "./components/LanguageList";
import { getCollaborationLinkData, loadScene } from "./data";
import {
  importFromLocalStorage,
  saveToLocalStorage,
} from "./data/localStorage";

import { BackToWorkspace, SignOutButton } from "../App/msal-auth/SignOutButton";
import "./index.scss";

import { createStore, del, getMany, keys, set } from "idb-keyval";
import { Avatar } from "../components/Avatar";
import { newElementWith } from "../element/mutateElement";
import { isInitializedAvatarImageElement, isInitializedImageElement } from "../element/typeChecks";
import { getLocalStateLibray, postLocalStateLibray } from "./api/collection";
import { getLessonId, getUserInfo } from "./api/getuserInfo";
import { FileManager, updateStaleImageStatuses } from "./data/FileManager";
import { loadFilesFromFirebase } from "./data/firebase";

import { TopErrorBoundary } from "../components/TopErrorBoundary";
import { StudentMessageContext } from "../components/contexts/StudentMessage.context";
import { groupType } from "../components/flexibleGroups/Types";
import { StoreAction } from "../store";
import { apiPut } from "./api";
import { API_URL, studentAccessRevokeMessage } from "./api/constant";
import {
  getSharedWithMeWorkspaceByWorkspaceID,
  updateEmailsInSharedWorkspace,
} from "./api/storeElementData";
import { STORAGE_KEYS as STORAGE_KEY } from "./data/localStorage";

const filesStore = createStore("files-db", "files-store");

const languageDetector = new LanguageDetector();
languageDetector.init({
  languageUtils: {
    formatLanguageCode: (langCode: Language["code"]) => langCode,
    isWhitelisted: () => true,
  },
  checkWhitelist: false,
});

const localFileStorage = new FileManager({
  getFiles(ids) {
    return getMany(ids, filesStore).then(
      (filesData: (BinaryFileData | undefined)[]) => {
        const loadedFiles: BinaryFileData[] = [];
        const erroredFiles = new Map<FileId, true>();
        filesData.forEach((data, index) => {
          const id = ids[index];
          if (data) {
            loadedFiles.push(data);
          } else {
            erroredFiles.set(id, true);
          }
        });

        return { loadedFiles, erroredFiles };
      },
    );
  },
  async saveFiles({ addedFiles }) {
    const savedFiles = new Map<FileId, true>();
    const erroredFiles = new Map<FileId, true>();

    await Promise.all(
      [...addedFiles].map(async ([id, fileData]) => {
        try {
          await set(id, fileData, filesStore);
          savedFiles.set(id, true);
        } catch (error) {
          console.error(error);
          erroredFiles.set(id, true);
        }
      }),
    );

    return { savedFiles, erroredFiles };
  },
});

const saveDebounced = debounce(
  async (
    elements: readonly ExcalidrawElement[],
    appState: AppState,
    files: BinaryFiles,
    onFilesSaved: () => void,
  ) => {
    const isMyWorkSpaceData = localStorage.getItem("isMyWorkSpace");
    if (isMyWorkSpaceData === "true") {
      localStorage.removeItem(STORAGE_KEY.LOCAL_STORAGE_WORKSPACE_ELEMENTS);
    } else {
      localStorage.removeItem(STORAGE_KEY.LOCAL_STORAGE_ELEMENTS);
    }
    elements.reduce((element, current: any) => {
      const exists = element.find((item: any) => {
        return item.id === current.id;
      });
      if (!exists) {
        element = element.concat(current);
      }
      return element;
    }, []);
    saveToLocalStorage(elements, appState);
    await localFileStorage.saveFiles({
      elements,
      files,
    });

    onFilesSaved();
  },
  SAVE_TO_LOCAL_STORAGE_TIMEOUT,
);

const onBlur = () => {
  saveDebounced.flush();
};

const clearObsoleteFilesFromIndexedDB = async (opts: {
  currentFileIds: FileId[];
}) => {
  const allIds = await keys(filesStore);
  for (const id of allIds) {
    if (!opts.currentFileIds.includes(id as FileId)) {
      del(id, filesStore);
    }
  }
};

const initializeScene = async (opts: {
  collabAPI: CollabAPI;
}): Promise<
  { scene: ImportedDataState | null } & (
    | { isExternalScene: true; id: string; key: string }
    | { isExternalScene: false; id?: null; key?: null }
  )
> => {
  const searchParams = new URLSearchParams(window.location.search);
  const id = searchParams.get("id");
  const jsonBackendMatch = window.location.hash.match(
    /^#json=([a-zA-Z0-9_-]+),([a-zA-Z0-9_-]+)$/,
  );
  const externalUrlMatch = window.location.hash.match(/^#url=(.*)$/);

  const localDataState = importFromLocalStorage();

  let scene: RestoredDataState & {
    scrollToContent?: boolean;
  } = await loadScene(null, null, localDataState);

  let roomLinkData = getCollaborationLinkData(window.location.href);
  const isExternalScene = !!(id || jsonBackendMatch || roomLinkData);
  if (isExternalScene) {
    if (
      // don't prompt if scene is empty
      !scene.elements.length ||
      // don't prompt for collab scenes because we don't override local storage
      roomLinkData ||
      // otherwise, prompt whether user wants to override current scene
      window.confirm(t("alerts.loadSceneOverridePrompt"))
    ) {
      if (jsonBackendMatch) {
        scene = await loadScene(
          jsonBackendMatch[1],
          jsonBackendMatch[2],
          localDataState,
        );
      }
      scene.scrollToContent = true;
      if (!roomLinkData) {
        window.history.replaceState({}, APP_NAME, window.location.origin);
      }
    } else {
      // https://github.com/excalidraw/excalidraw/issues/1919
      if (document.hidden) {
        return new Promise((resolve, reject) => {
          window.addEventListener(
            "focus",
            () => initializeScene(opts).then(resolve).catch(reject),
            {
              once: true,
            },
          );
        });
      }

      roomLinkData = null;
      window.history.replaceState({}, APP_NAME, window.location.origin);
    }
  } else if (externalUrlMatch) {
    window.history.replaceState({}, APP_NAME, window.location.origin);

    const url = externalUrlMatch[1];
    try {
      const request = await fetch(window.decodeURIComponent(url));
      const data = await loadFromBlob(await request.blob(), null, null);
      if (
        !scene.elements.length ||
        window.confirm(t("alerts.loadSceneOverridePrompt"))
      ) {
        return { scene: data, isExternalScene };
      }
    } catch (error) {
      return {
        scene: {
          appState: {
            errorMessage: t("alerts.invalidSceneUrl"),
          },
        },
        isExternalScene,
      };
    }
  }

  // const collabrating = Boolean(localStorage.getItem("isCollaborating"));

  // if (collabrating) {
  //   const data = getCollaborationLinkData(window.location.href);
  //   await opts.collabAPI.initializeSocketClient(data);
  // }

  if (roomLinkData) {
    return {
      scene: await opts.collabAPI.initializeSocketClient(roomLinkData),
      isExternalScene: true,
      id: roomLinkData.roomId,
      key: roomLinkData.roomKey,
    };
  } else if (scene) {
    return isExternalScene && jsonBackendMatch
      ? {
          scene,
          isExternalScene,
          id: jsonBackendMatch[1],
          key: jsonBackendMatch[2],
        }
      : { scene, isExternalScene: false };
  }
  return { scene: null, isExternalScene: false };
};

const ExcalidrawWrapper = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const currentLangCode = languageDetector.detect() || defaultLang.code;
  const [langCode, setLangCode] = useState(currentLangCode);
  const [isAssignedPage, setIsAssignedPage] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState("");
  const [isFlexibleGroup, setIsFlexibleGroup] = useState<boolean>(false);
  const [groups, setGroups] = useState<groupType[]>([]);
  const isMyWorkSpaceData = localStorage.getItem("isMyWorkSpace");
  const [worspaceUsersWithShared, setWorkspaceUsersWithShared] = useState([]);
  const [searchWorkspaceMembers, setSearchWorkspaceMembers] = useState("");
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  
  const data = JSON.parse(
    localStorage.getItem("worspaceUsersWithShared") || "[]",
  );
  // Filter the displayed users based on search value
  const filteredUsers = worspaceUsersWithShared?.filter((user: any) =>
    user?.email.toLowerCase().includes(searchWorkspaceMembers.toLowerCase()),
  );

  useEffect(() => {
    if (data.length) {
      data.forEach((value: any) => {
        setWorkspaceUsersWithShared(value.users);
      });
    } else {
      const getUsers = async () => {
        const selectedWorkspaceCardID = localStorage.getItem(
          "selectedWorkspaceCard",
        );
        const res = await getSharedWithMeWorkspaceByWorkspaceID(
          selectedWorkspaceCardID as string,
        );
        const user = JSON.parse(localStorage.getItem("user") || "{}");
        const arr = [] as any;
        res?.data?.length &&
          res?.data[0]?.userEmail?.forEach((user_: any) => {
            if (user_.email !== user.mail) {
              arr.push(user_);
            }
          });
        setWorkspaceUsersWithShared(arr);
      };
      isMyWorkSpaceData === "true" && getUsers();
    }
  }, [isMyWorkSpaceData]);

  const { setStudentMessage, studentMessage } = useContext(
    StudentMessageContext,
  );

  const results: any[] = [];
  if (groups.length > 0) {
    groups.forEach((item) => {
      const foundStudent =
        item.students.length > 0
          ? item.students.find((student) =>
              student?.studentName
                ?.toLowerCase()
                ?.includes(searchValue?.toLowerCase()),
            )
          : [];
      if (foundStudent) {
        results?.push({ ...item, students: [foundStudent] });
      }
    });
  }

  const newGroups = window.localStorage.getItem("groups")
    ? JSON.parse(window.localStorage.getItem("groups") || "")
    : [];

  const updateStudent = async (updatedGroups: any, existGroup: any) => {
    const updatedGroup = await apiPut(`${API_URL}/flexible-group`, existGroup);
    if (updatedGroup.result) {
      setGroups(updatedGroups);
    }
  };

  useEffect(() => {
    setGroups(newGroups);
  }, [JSON.stringify({ newGroups })]);

  useEffect(() => {
    const isCollaboratingWithFlexibleGroups_ = JSON.parse(
      localStorage.getItem("isCollaboratingWithFlexibleGroups") || "false",
    );

    setIsFlexibleGroup(isCollaboratingWithFlexibleGroups_);
  }, [localStorage.getItem("isCollaboratingWithFlexibleGroups")]);

  // initial state
  // ---------------------------------------------------------------------------

  const initialStatePromiseRef = useRef<{
    promise: ResolvablePromise<ImportedDataState | null>;
  }>({ promise: null! });
  if (!initialStatePromiseRef.current.promise) {
    initialStatePromiseRef.current.promise = resolvablePromise<ImportedDataState | null>();
  }

  useEffect(() => {
    setIsAssignedPage(
      JSON.parse(localStorage.getItem("isShowNoPagesModel") || "false"),
    );
  }, [localStorage.getItem("isShowNoPagesModel")]);

  useEffect(() => {
    if (langCode === "es") setLangCode("es-ES");
    else if (langCode === "pt") setLangCode("pt-PT");
    else if (langCode === "fr") setLangCode("fr-FR");
    else if (langCode === "de") setLangCode("de-DE");
    else if (langCode === "it") setLangCode("it-IT");
    else if (langCode === "ja") setLangCode("ja-JA");
    else if (langCode === "hi") {
      setLangCode("hi-IN");
      // TODO: remove this once we find the proper solution
      // added this coz hindi lan. is not being rendered
      setLanguage({ code: "hi-IN", label: "हिन्दी" });
    }
  }, [currentLangCode]);

  useEffect(() => {
    // Delayed so that the app has a time to load the latest SW
    setTimeout(() => {
      trackEvent("load", "version", getVersion());
    }, VERSION_TIMEOUT);
    validatePagesWithLessonId();
  }, []);

  const [
    excalidrawAPI,
    excalidrawRefCallback,
  ] = useCallbackRefState<ExcalidrawImperativeAPI>();

  const collabAPI = useContext(CollabContext)?.api;

  useEffect(() => {
    if (!collabAPI || !excalidrawAPI) {
      return;
    }

    initializeScene({ collabAPI }).then(async ({ scene }) => {
      if (scene) {
        try {
          if (collabAPI?.isCollaborating()) {
            const element = scene.elements ? scene.elements : [];
            collabAPI.broadcastElements(element);
          }

          const user = await getUserInfo();
          const lessonId =
            new URLSearchParams(window.location.search)
              .get("lessonId")
              ?.replace(/\//g, "") || "";
          const apiBody = {
            userId: user.mail,
            lessonId,
          };
          const localStateLibray = await getLocalStateLibray(apiBody);

          const dataArray = localStateLibray.results
            .map((item: { data: LibraryItem }) => item.data)
            .flat();

          // Remove duplicates using Map by 'id'
          const uniqueDataArray = [
            ...new Map(
              dataArray.map((item: LibraryItem) => [item.id, item]),
            ).values(),
          ];

          const serializedItems = JSON.stringify(uniqueDataArray);

          localStorage.setItem(
            STORAGE_KEYS.LOCAL_STORAGE_LIBRARY,
            serializedItems,
          );

          scene.libraryItems = uniqueDataArray as LibraryItem[];
        } catch (e) {
          console.error(e);
        }
      }
      initialStatePromiseRef.current.promise.resolve(scene);
    });

    const onHashChange = (event: HashChangeEvent) => {
      event.preventDefault();
      const hash = new URLSearchParams(window.location.hash.slice(1));
      const libraryUrl = hash.get(URL_HASH_KEYS.addLibrary);
      if (libraryUrl) {
        // If hash changed and it contains library url, import it and replace
        // the url to its previous state (important in case of collaboration
        // and similar).
        // Using history API won't trigger another hashchange.
        window.history.replaceState({}, "", event.oldURL);
        excalidrawAPI.importLibrary(libraryUrl, hash.get("token"));
      } else {
        initializeScene({ collabAPI }).then(({ scene }) => {
          if (scene) {
            excalidrawAPI.updateScene({
              ...scene,
              appState: restoreAppState(scene.appState, null),
              storeAction: StoreAction.CAPTURE,
            });
          }
        });
      }
    };

    const titleTimeout = setTimeout(
      () => (document.title = APP_NAME),
      TITLE_TIMEOUT,
    );
    window.addEventListener(EVENT.HASHCHANGE, onHashChange, false);
    window.addEventListener(EVENT.UNLOAD, onBlur, false);
    window.addEventListener(EVENT.BLUR, onBlur, false);
    return () => {
      window.removeEventListener(EVENT.HASHCHANGE, onHashChange, false);
      window.removeEventListener(EVENT.UNLOAD, onBlur, false);
      window.removeEventListener(EVENT.BLUR, onBlur, false);
      clearTimeout(titleTimeout);
    };
  }, [collabAPI, excalidrawAPI]);

  useEffect(() => {
    languageDetector.cacheUserLanguage(langCode);
  }, [langCode]);

  useEffect(() => {
    if (!collabAPI || !excalidrawAPI) {
      return;
    }

    const loadImages = async (
      data: ResolutionType<typeof initializeScene>,
      isInitialLoad = false,
    ) => {
      if (!data.scene) {
        return;
      }
      if (collabAPI.isCollaborating()) {
        if (data.scene.elements) {
          collabAPI
            .fetchImageFilesFromFirebase({
              elements: data.scene.elements,
            })
            .then(({ loadedFiles, erroredFiles }) => {
              excalidrawAPI.addFiles(loadedFiles);
              updateStaleImageStatuses({
                excalidrawAPI,
                erroredFiles,
                elements: excalidrawAPI.getSceneElementsIncludingDeleted(),
              });
            });
        }
      } else {
        const fileIds =
          data.scene.elements?.reduce((acc, element) => {
            if (
              isInitializedAvatarImageElement(element) ||
              isInitializedImageElement(element)
            ) {
              return acc.concat(element.fileId);
            }
            return acc;
          }, [] as FileId[]) || [];

        if (data.isExternalScene) {
          loadFilesFromFirebase(
            `${FIREBASE_STORAGE_PREFIXES.shareLinkFiles}/${data.id}`,
            data.key,
            fileIds,
          ).then(({ loadedFiles, erroredFiles }) => {
            excalidrawAPI.addFiles(loadedFiles);
            updateStaleImageStatuses({
              excalidrawAPI,
              erroredFiles,
              elements: excalidrawAPI.getSceneElementsIncludingDeleted(),
            });
          });
        } else if (isInitialLoad) {
          if (fileIds.length) {
            localFileStorage
              .getFiles(fileIds)
              .then(({ loadedFiles, erroredFiles }) => {
                if (loadedFiles.length) {
                  excalidrawAPI.addFiles(loadedFiles);
                }
                updateStaleImageStatuses({
                  excalidrawAPI,
                  erroredFiles,
                  elements: excalidrawAPI.getSceneElementsIncludingDeleted(),
                });
              });
          }
          // on fresh load, clear unused files from IDB (from previous
          // session)
          clearObsoleteFilesFromIndexedDB({ currentFileIds: fileIds });
        }
      }

      try {
        if (collabAPI.isCollaborating()) {
          const element = data.scene.elements ? data.scene.elements : [];
          collabAPI.broadcastElements(element);
        }
        const user = await getUserInfo();
        const lessonId = await getLessonId();
        const apiBody = {
          userId: user.mail,
          lessonId,
        };
        const localStateLibray = await getLocalStateLibray(apiBody);
        const dataArray = localStateLibray.results
          .map((item: { data: LibraryItem }) => item.data)
          .flat();

        // Remove duplicates using Map by 'id'
        const uniqueDataArray = [
          ...new Map(
            dataArray.map((item: LibraryItem) => [item.id, item]),
          ).values(),
        ];
        const serializedItems = JSON.stringify(uniqueDataArray);

        localStorage.setItem(
          STORAGE_KEYS.LOCAL_STORAGE_LIBRARY,
          serializedItems,
        );

        data.scene.libraryItems = uniqueDataArray as LibraryItem[];
      } catch (error) {
        console.error(error);
      }
    };

    initializeScene({ collabAPI }).then((data) => {
      data && loadImages(data, /* isInitialLoad */ true);
      initialStatePromiseRef.current.promise.resolve(data.scene);
    });

    const onHashChange = (event: HashChangeEvent) => {
      event.preventDefault();
      const hash = new URLSearchParams(window.location.hash.slice(1));
      const libraryUrl = hash.get(URL_HASH_KEYS.addLibrary);
      if (libraryUrl) {
        // If hash changed and it contains library url, import it and replace
        // the url to its previous state (important in case of collaboration
        // and similar).
        // Using history API won't trigger another hashchange.
        window.history.replaceState({}, "", event.oldURL);
        excalidrawAPI.importLibrary(libraryUrl, hash.get("token"));
      } else {
        initializeScene({ collabAPI }).then((data) => {
          loadImages(data);
          if (data.scene) {
            excalidrawAPI.updateScene({
              ...data.scene,
              appState: restoreAppState(data.scene.appState, null),
              storeAction: StoreAction.CAPTURE,
            });
          }
        });
      }
    };

    const titleTimeout = setTimeout(
      () => (document.title = APP_NAME),
      TITLE_TIMEOUT,
    );
    window.addEventListener(EVENT.HASHCHANGE, onHashChange, false);
    window.addEventListener(EVENT.UNLOAD, onBlur, false);
    window.addEventListener(EVENT.BLUR, onBlur, false);
    return () => {
      window.removeEventListener(EVENT.HASHCHANGE, onHashChange, false);
      window.removeEventListener(EVENT.UNLOAD, onBlur, false);
      window.removeEventListener(EVENT.BLUR, onBlur, false);
      clearTimeout(titleTimeout);
    };
  }, [collabAPI, excalidrawAPI]);

  const validatePagesWithLessonId = async () => {
    const pages = JSON.parse(localStorage.getItem("pages") || "[]");
    const lessonId = await getLessonId();
    let clonedPages = [...pages];
    clonedPages = clonedPages.filter((page: any) => page.lessonId === lessonId);
    localStorage.setItem("pages", JSON.stringify(clonedPages));
  };

  const updatedPagesInLocal = async (
    elements: ExcalidrawElement[],
    lessonId: string,
  ) => {
    let allPages = JSON.parse(localStorage.getItem("pages") || "[]");
    let appState = JSON.parse(localStorage.getItem("acv-state") || "{}");
    if (allPages.length > 0 && appState) {
      const currentPageIndex = allPages.findIndex(
        (page: any) =>
          String(page.page) === String(appState.currentPage) &&
          page.lessonId === lessonId,
      );
      if (currentPageIndex !== -1) {
        allPages[currentPageIndex].data = elements;
      }
      localStorage.setItem("pages", JSON.stringify(allPages));
    }
  };

  const onChange = async (
    elements: readonly ExcalidrawElement[],
    appState: AppState,
    files: BinaryFiles,
  ) => {
    if (collabAPI?.isCollaborating()) {
      collabAPI.broadcastElements(elements);
    }

    const lessonId = await getLessonId();
    const notDeletedElements: ExcalidrawElement[] = [];

    for (let index = 0; index < elements.length; index++) {
      const element = elements[index];

      if (!element.isDeleted && element.lessonId === lessonId) {
        notDeletedElements.push(element);
      }
    }

    updatedPagesInLocal(notDeletedElements, lessonId);

    // localStorage.setItem("pages", JSON.stringify(notDeletedElements));
    saveDebounced(elements, appState, files, () => {
      if (excalidrawAPI) {
        let didChange = false;

        let pendingImageElement = appState.pendingImageElement;
        const elements = excalidrawAPI
          .getSceneElementsIncludingDeleted()
          .map((element) => {
            if (localFileStorage.shouldUpdateImageElementStatus(element)) {
              didChange = true;
              const newEl = newElementWith(element, { status: "saved" });
              if (pendingImageElement === element) {
                pendingImageElement = newEl;
              }
              return newEl;
            }
            return element;
          });

        if (didChange) {
          excalidrawAPI.updateScene({
            elements,
            appState: {
              pendingImageElement,
            },
            storeAction: StoreAction.UPDATE,
          });
        }
      }
    });
  };

  const renderTopRightUI = useCallback(
    (isMobile: boolean, appState: AppState) => {
      return null;
    },
    [],
  );

  const renderFooter = useCallback(
    (isMobile: boolean) => {
      const renderEncryptedIcon = () => (
        <a
          className="encrypted-icon tooltip"
          href="#"
          target="_blank"
          rel="noopener noreferrer"
          aria-label={t("encrypted.link")}
        >
          <Tooltip label={t("encrypted.tooltip")} long={true}>
            {shield}
          </Tooltip>
        </a>
      );

      const renderLanguageList = () => (
        <LanguageList
          onChange={(langCode) => setLangCode(langCode)}
          languages={languages}
          currentLangCode={langCode}
        />
      );
      if (isMobile) {
        const isTinyDevice = window.innerWidth < 362;
        return (
          <div
            style={{
              display: "flex",
              flexDirection: isTinyDevice ? "column" : "row",
            }}
          >
            <fieldset>
              <legend>{t("labels.language")}</legend>
              {renderLanguageList()}
            </fieldset>
            {/* FIXME remove after 2021-05-20 */}
            {/* <div
              style={{
                width: "24ch",
                fontSize: "0.7em",
                textAlign: "center",
                marginTop: isTinyDevice ? 16 : undefined,
                marginLeft: "auto",
                marginRight: isTinyDevice ? "auto" : undefined,
                padding: "4px 2px",
                border: "1px dashed #aaa",
                borderRadius: 12,
              }}
            >
              {PlusLinkJSX}
            </div> */}
          </div>
        );
      }
      return (
        <>
          {renderEncryptedIcon()}
          {renderLanguageList()}
        </>
      );
    },
    [langCode],
  );

  const renderCustomStats = () => {
    return (
      <CustomStats
        setToastMessage={(message) => excalidrawAPI!.setToastMessage(message)}
      />
    );
  };

  const onLibraryChange = async (items: LibraryItems) => {
    if (!items.length) {
      localStorage.removeItem(STORAGE_KEYS.LOCAL_STORAGE_LIBRARY);
      return;
    }
    const serializedItems = JSON.stringify(items);

    const user = await getUserInfo();
    const lessonId = new URLSearchParams(window.location.search)
      .get("lessonId")
      ?.replace(/\//g, "");

    const apiBody = {
      lessonId,
      userId: user.mail,
      data: items,
    };
    await postLocalStateLibray(apiBody);

    localStorage.setItem(STORAGE_KEYS.LOCAL_STORAGE_LIBRARY, serializedItems);
  };

  const studentData = localStorage.getItem("students")
    ? JSON.parse(localStorage.getItem("students") || "")
    : [];

  const filteredStudentData =
    studentData.length > 0
      ? studentData.filter((student: any) => {
          return student?.studName
            ?.toLowerCase()
            ?.includes(searchValue?.toLowerCase());
        })
      : [];

  const parseData = studentMessage;
  const errtitle = parseData?.title ? parseData?.title : "Whiteboard mismatch";
  const errmessage = parseData?.message
    ? parseData?.message
    : `${
        JSON.parse(localStorage.getItem("user") || "{}").displayName || "Hi"
      }, you do not have permission to access this lesson.`;

  const errbutton = parseData?.button ? parseData?.button : "Sign Out";

  return (
    <>
      <div
        className="modal fade"
        id="collaboration"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="collaborationLable"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          {isFlexibleGroup ? (
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="collaborationLable">
                  Flexible Group
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="container" style={{ marginTop: "15px" }}>
                <div className="row height d-flex justify-content-center align-items-center">
                  <div className="col-md-6" style={{ width: "100%" }}>
                    <div className="form">
                      <input
                        type="text"
                        className="form-control form-input"
                        placeholder="Search anything..."
                        onChange={(e) => setSearchValue(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-body Karla">
                {Array.isArray(groups) && groups?.length > 0 ? (
                  <table className="table table-striped mb-0">
                    <tbody>
                      {searchValue ? (
                        results.length > 0 ? (
                          Array.isArray(groups) &&
                          results?.map((group: groupType, key: any) => {
                            return (
                              <>
                                <td>
                                  <h4 style={{ fontWeight: "700" }}>
                                    {`G-${key + 1}`}
                                    {group?.name}
                                  </h4>
                                </td>
                                {group?.students?.map(
                                  (student: any, index: number) => {
                                    return (
                                      <tr key={`stud-${key + 1}`}>
                                        <td>
                                          <div className="form-check">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              value=""
                                              id={`flexCheckDefault${key + 1}`}
                                              defaultChecked={
                                                student?.isEnabled
                                              }
                                              onChange={async (e) => {
                                                const updatedStudentIndex = group.students.findIndex(
                                                  (g) =>
                                                    g.studentEmail ===
                                                    student.studentEmail,
                                                );

                                                const existGroup = groups.find(
                                                  (g) => g.id === group.id,
                                                );

                                                if (
                                                  updatedStudentIndex === -1 ||
                                                  !existGroup
                                                ) {
                                                  return;
                                                }
                                                existGroup.students[
                                                  updatedStudentIndex
                                                ] = {
                                                  ...existGroup.students[
                                                    updatedStudentIndex
                                                  ],
                                                  isEnabled:
                                                    e.currentTarget.checked,
                                                };

                                                const updatedGroups = [
                                                  ...groups,
                                                ];

                                                window.localStorage.setItem(
                                                  "groups",
                                                  JSON.stringify(updatedGroups),
                                                );

                                                updateStudent(
                                                  updatedGroups,
                                                  existGroup,
                                                );
                                              }}
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor={`flexCheckDefault${
                                                key + 1
                                              }`}
                                            >
                                              Enabled
                                            </label>
                                          </div>
                                        </td>
                                        <td>
                                          <Avatar
                                            color={"transparent"}
                                            border={"transparent"}
                                            onClick={() => false}
                                          >
                                            {student.photo.substring(0, 4) !==
                                            "null" ? (
                                              <img
                                                key={key}
                                                src={student.photo}
                                                alt={student.studentName}
                                                className="user-profile"
                                              />
                                            ) : (
                                              <p className="user-profile">
                                                {student.studentName.substring(
                                                  0,
                                                  2,
                                                )}
                                              </p>
                                            )}
                                          </Avatar>
                                        </td>
                                        <td>
                                          {/* {group.students.map(
                                            (student: any, index: number) => {
                                              return ( */}
                                          <p>{student.studentName}</p>
                                          {/* );
                                            },
                                          )} */}
                                        </td>
                                      </tr>
                                    );
                                  },
                                )}
                              </>
                            );
                          })
                        ) : (
                          <p
                            style={{
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            No data found
                          </p>
                        )
                      ) : (
                        Array.isArray(groups) &&
                        groups?.map((group: groupType, key: any) => {
                          return (
                            <>
                              <td>
                                <h4 style={{ fontWeight: "700" }}>
                                  {`G-${key + 1}`}
                                  {group?.name}
                                </h4>
                              </td>
                              {group?.students?.map(
                                (student: any, index: number) => {
                                  return (
                                    <tr key={`stud-${key + 1}`}>
                                      <td>
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id={`flexCheckDefault${key + 1}`}
                                            defaultChecked={student?.isEnabled}
                                            onChange={async (e) => {
                                              const updatedStudentIndex = group.students.findIndex(
                                                (g) =>
                                                  g.studentEmail ===
                                                  student.studentEmail,
                                              );

                                              const existGroup = groups.find(
                                                (g) => g.id === group.id,
                                              );

                                              if (
                                                updatedStudentIndex === -1 ||
                                                !existGroup
                                              ) {
                                                return;
                                              }

                                              existGroup.students[
                                                updatedStudentIndex
                                              ] = {
                                                ...existGroup.students[
                                                  updatedStudentIndex
                                                ],
                                                isEnabled:
                                                  e.currentTarget.checked,
                                              };

                                              const updatedGroups = [...groups];

                                              window.localStorage.setItem(
                                                "groups",
                                                JSON.stringify(updatedGroups),
                                              );

                                              updateStudent(
                                                updatedGroups,
                                                existGroup,
                                              );
                                            }}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={`flexCheckDefault${
                                              key + 1
                                            }`}
                                          >
                                            Enabled
                                          </label>
                                        </div>
                                      </td>
                                      <td>
                                        <Avatar
                                          color={"transparent"}
                                          border={"transparent"}
                                          onClick={() => false}
                                        >
                                          {student.photo.substring(0, 4) !==
                                          "null" ? (
                                            <img
                                              key={key}
                                              src={student.photo}
                                              alt={student.studentName}
                                              className="user-profile"
                                            />
                                          ) : (
                                            <p className="user-profile">
                                              {student.studentName.substring(
                                                0,
                                                2,
                                              )}
                                            </p>
                                          )}
                                        </Avatar>
                                      </td>
                                      <td>
                                        {/* {group.students.map(
                                            (student: any, index: number) => {
                                              return ( */}
                                        <p>{student.studentName}</p>
                                        {/* );
                                            },
                                          )} */}
                                      </td>
                                    </tr>
                                  );
                                },
                              )}
                            </>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                ) : (
                  <div className="centered-loader" style={{ height: "100%" }}>
                    <div className="spinner-border" role="status"></div>
                  </div>
                )}
              </div>
            </div>
          ) : isMyWorkSpaceData === "true" ? (
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="collaborationLable">
                  Live collaboration - Users
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="container" style={{ marginTop: "15px" }}>
                <div className="row height d-flex justify-content-center align-items-center">
                  <div className="col-md-6" style={{ width: "100%" }}>
                    <div className="form">
                      <input
                        type="text"
                        className="form-control form-input"
                        placeholder="Search anything..."
                        value={searchWorkspaceMembers}
                        onChange={(e) =>
                          setSearchWorkspaceMembers(e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-body Karla">
                {filteredUsers.length > 0 ? (
                  <table className="table table-striped mb-0">
                    <tbody>
                      {filteredUsers.map((studentData: any, key: number) => (
                        <tr key={`stud-${key + 1}`}>
                          <td>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id={`flexCheckDefault${key + 1}`}
                                checked={studentData.isEnabled}
                                onChange={async (e) => {
                                  const user = JSON.parse(
                                    localStorage.getItem("user") || "{}",
                                  );
                                  const ownerUser = {
                                    email: user?.mail,
                                    isActive: true,
                                    isEnabled: true,
                                  };
                                  const selectedWorkspaceCardID = localStorage.getItem(
                                    "selectedWorkspaceCard",
                                  );
                                  const selectedUser = {
                                    ...studentData,
                                    isEnabled: e.currentTarget.checked,
                                    selectedWorkspaceCardID,
                                  };
                                  const isOwnerUserPresent = worspaceUsersWithShared.some(
                                    (existingUser: any) =>
                                      existingUser.email === ownerUser.email,
                                  );
                                  const updatedWorkspaceUsers = worspaceUsersWithShared.map(
                                    (user: any) =>
                                      user.email === studentData.email
                                        ? {
                                            ...user,
                                            isEnabled: e.currentTarget.checked,
                                          }
                                        : user,
                                  );

                                  collabAPI?.saveAndRetriveStudData(
                                    selectedUser,
                                    new URLSearchParams(window.location.search)
                                      .get("lessonId")
                                      ?.replace(/\//g, "")!,
                                  );
                                  const finalWorkspaceUsers = isOwnerUserPresent
                                    ? updatedWorkspaceUsers
                                    : [...updatedWorkspaceUsers, ownerUser];

                                  setWorkspaceUsersWithShared(
                                    updatedWorkspaceUsers as any,
                                  );

                                  await updateEmailsInSharedWorkspace(
                                    selectedWorkspaceCardID,
                                    finalWorkspaceUsers,
                                  );
                                }}
                              />

                              <label
                                className="form-check-label"
                                htmlFor={`flexCheckDefault${key + 1}`}
                              >
                                Enabled
                              </label>
                            </div>
                          </td>
                          <td></td>
                          <td title={studentData.email}>{studentData.email}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p style={{ justifyContent: "center", display: "flex" }}>
                    No data found
                  </p>
                )}
              </div>
            </div>
          ) : (
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="collaborationLable">
                  Live collaboration - Students
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="container" style={{ marginTop: "15px" }}>
                <div className="row height d-flex justify-content-center align-items-center">
                  <div className="col-md-6" style={{ width: "100%" }}>
                    <div className="form">
                      <input
                        type="text"
                        className="form-control form-input"
                        placeholder="Search anything..."
                        onChange={(e) => setSearchValue(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-body Karla">
                {Array.isArray(studentData) && studentData?.length > 0 ? (
                  <table className="table table-striped mb-0">
                    <tbody>
                      {filteredStudentData.length > 0 ? (
                        Array.isArray(studentData) &&
                        filteredStudentData?.map(
                          (studentData: any, key: any) => {
                            return (
                              <tr key={`stud-${key + 1}`}>
                                <td>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id={`flexCheckDefault${key + 1}`}
                                      defaultChecked={studentData.isWhiteboard}
                                      onChange={(e) => {
                                        const students = window.localStorage.getItem(
                                          "students",
                                        )
                                          ? JSON.parse(
                                              window.localStorage.getItem(
                                                "students",
                                              ) || "",
                                            )
                                          : [];
                                        if (Array.isArray(students)) {
                                          const updateStudents = students.map(
                                            (stud: any) => {
                                              if (
                                                stud.studEmail ===
                                                studentData.studEmail
                                              ) {
                                                stud.isWhiteboard =
                                                  e.currentTarget.checked;
                                                return stud;
                                              }
                                              return stud;
                                            },
                                          );

                                          localStorage.setItem(
                                            "students",
                                            JSON.stringify(updateStudents),
                                          );
                                          collabAPI?.saveAndRetriveStudData(
                                            updateStudents,
                                            new URLSearchParams(
                                              window.location.search,
                                            )
                                              .get("lessonId")
                                              ?.replace(/\//g, "")!,
                                          );
                                        }
                                        if (!e.currentTarget.checked) {
                                          setStudentMessage({
                                            ...studentAccessRevokeMessage,
                                          });
                                        }
                                        // document.getElementById("errModalBtn")?.click();
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`flexCheckDefault${key + 1}`}
                                    >
                                      Enabled
                                    </label>
                                  </div>
                                </td>
                                <td>
                                  {/* <img
                            src={studentData.photo}
                            className="user-profile"
                          /> */}

                                  <Avatar
                                    color={"transparent"}
                                    border={"transparent"}
                                    onClick={() => false}
                                  >
                                    {studentData?.photo?.substring(0, 4) !==
                                    "null" ? (
                                      <img
                                        key={key}
                                        src={studentData.photo}
                                        alt={studentData.studName}
                                        className="user-profile"
                                      />
                                    ) : (
                                      <p className="user-profile">
                                        {studentData?.studName?.substring(0, 2)}
                                      </p>
                                    )}
                                  </Avatar>
                                </td>
                                <td title={studentData.studEmail}>
                                  {studentData.studName}
                                </td>
                              </tr>
                            );
                          },
                        )
                      ) : (
                        <p
                          style={{
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          No data found
                        </p>
                      )}
                    </tbody>
                  </table>
                ) : (
                  <div className="centered-loader" style={{ height: "100%" }}>
                    <div className="spinner-border" role="status"></div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className="modal fade Karla"
        id="error"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="errorLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{errtitle}</h5>
              <button
                type="button"
                className="btn-close d-none"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="clsBtn"
              ></button>
            </div>
            <div className="modal-body">
              <p>{errmessage}</p>
            </div>
            <div className="modal-footer">
              {errbutton === "Sign Out" ? (
                isMyWorkSpaceData === "true" && user ? (
                  <BackToWorkspace />
                ) : (
                  <SignOutButton />
                )
              ) : errbutton === "Download" ? (
                <div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      document.getElementById("exportData1")?.click();
                    }}
                  >
                    {errbutton}
                  </button>
                  {isMyWorkSpaceData === "true" && user ? (
                    <BackToWorkspace />
                  ) : (
                    <SignOutButton />
                  )}
                </div>
              ) : (
                <button type="button" className="btn btn-primary">
                  {errbutton}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        className="btn btn-primary d-none"
        id="errModalBtn"
        data-bs-toggle="modal"
        data-bs-target="#error"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        Error Modal
      </button>
      <Excalidraw
        ref={excalidrawRefCallback}
        onChange={onChange}
        initialData={initialStatePromiseRef.current.promise}
        onCollabButtonClick={collabAPI?.onCollabButtonClick}
        onFlexibleGroupButtonClick={collabAPI?.onFlexibleGroupButtonClick}
        isCollaboratingWithFlexibleGroups={collabAPI?.isCollaboratingWithFlexibleGroups()}
        isCollaborating={collabAPI?.isCollaborating()}
        onPointerUpdate={collabAPI?.onPointerUpdate}
        UIOptions={{
          canvasActions: {},
        }}
        renderTopRightUI={renderTopRightUI}
        renderFooter={renderFooter}
        langCode={langCode}
        renderCustomStats={renderCustomStats}
        detectScroll={false}
        handleKeyboardGlobally={true}
        onLibraryChange={onLibraryChange}
        autoFocus={true}
      />
      {excalidrawAPI && (
        <CollabWrapper
          isAssignedPage={isAssignedPage}
          excalidrawAPI={excalidrawAPI}
          setStudentMessage={setStudentMessage}
        />
      )}
      {errorMessage && (
        <ErrorDialog
          message={errorMessage}
          onClose={() => setErrorMessage("")}
        />
      )}
    </>
  );
};

const ExcalidrawApp = () => {
  return (
    <TopErrorBoundary>
      <CollabContextConsumer>
        <ExcalidrawWrapper />
      </CollabContextConsumer>
    </TopErrorBoundary>
  );
};

export default ExcalidrawApp;
