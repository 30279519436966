import React, { useState } from "react";
import "./customColorPickerStyles.scss";
import pickerIcon from "./picker-icon.svg";

interface Color {
  hex: string;
}
const DEFAULT_COLORS: Color[] = [
  { hex: "#000000" },
  { hex: "#E67700" },
  { hex: "#D9480F" },
  { hex: "#BE4BDB" },
  { hex: "#7950F2" },
  { hex: "#4C6EF5" },
  { hex: "#228BE6" },
  { hex: "#E64980" },
  { hex: "#12B886" },
  { hex: "#40C057" },
  { hex: "#A61E4D" },
  { hex: "#FAB005" },
  { hex: "#364FC7" },
  { hex: "#0B7285" },
  { hex: "#087F5B" },
];

type ColorPickerProps = {
  toggleHighlight?: (color: string) => void;
  openTextColorPicker?: boolean;
  openColorPicker?: boolean;
  onCloseColorPicker?: () => void;
  handleFocus?: () => void;
  handleBlur?: () => void;
};

export const CustomColorPicker = (props: ColorPickerProps) => {
  const {
    toggleHighlight,
    onCloseColorPicker,
    handleBlur,
    handleFocus,
  } = props;

  const [color, setColor] = useState<string>(
    "",
    // props.openColorPicker ? "#ffffff" : "#000000",
  ); // Initial color
  const [isEyedropperActive, setIsEyedropperActive] = useState<boolean>(false);
  const [colorFormat, setColorFormat] = useState<"hex" | "rgb">("hex"); // Initially unknown

  const handleKeyDown = (event: React.KeyboardEvent | KeyboardEvent) => {
    if (event.keyCode === 13) {
      handleColor(color);
      onCloseColorPicker && onCloseColorPicker();
    }
    return null;
  };

  const handleColor = (color: string) => {
    console.log("----------------------")
    if (toggleHighlight) {
      toggleHighlight(
        isEyedropperActive || color.startsWith("#")
          ? color
          : colorFormat === "rgb"
          ? `rgb(${color})`
          : `#${color}`,
      );
    }
  };

  const handleColorChange: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    const inputValue = event.target.value.trim();

    // Clear state on empty input to prevent issues
    if (inputValue === "") {
      setColor("");
      setColorFormat("hex");
      return;
    }

    // Check for valid hex format (3 or 6 characters, with or without leading '#')
    if (inputValue.match(/^#[0-9A-Fa-f]{3}$|^#[0-9A-Fa-f]{6}$/)) {
      const colorWithoutHash = inputValue.startsWith("#")
        ? inputValue.slice(1)
        : inputValue;
      setColor(colorWithoutHash); // Set color without '#' or 'rgb'
      setColorFormat("hex");
      return;
    }

    // Check for valid rgb format (comma-separated values)
    const rgbValues = inputValue.split(",");
    if (rgbValues.length === 3) {
      const red = parseInt(rgbValues[0], 10);
      const green = parseInt(rgbValues[1], 10);
      const blue = parseInt(rgbValues[2], 10);

      // Validate each color value (0-255)
      if (
        !isNaN(red) &&
        !isNaN(green) &&
        !isNaN(blue) &&
        red >= 0 &&
        red <= 255 &&
        green >= 0 &&
        green <= 255 &&
        blue >= 0 &&
        blue <= 255
      ) {
        setColor(`${red}, ${green}, ${blue}`); // Set color as comma-separated values, no parentheses
        setColorFormat("rgb");
        return;
      }
    }

    // Check for custom format (#0,0,0)
    if (inputValue.startsWith("#") && inputValue.split(",").length === 3) {
      const rgbValues = inputValue.slice(1).split(","); // Remove leading '#' and split
      const red = parseInt(rgbValues[0], 10);
      const green = parseInt(rgbValues[1], 10);
      const blue = parseInt(rgbValues[2], 10);

      // Validate and convert to RGB format
      if (
        !isNaN(red) &&
        !isNaN(green) &&
        !isNaN(blue) &&
        red >= 0 &&
        red <= 255 &&
        green >= 0 &&
        green <= 255 &&
        blue >= 0 &&
        blue <= 255
      ) {
        setColor(`${red}, ${green}, ${blue}`); // Set color as comma-separated values, no parentheses
        setColorFormat("rgb");
        return;
      }
    }

    // Invalid input, display an error or ignore the change
    // console.error(
    //   "Invalid color format. Please use a valid hex (#RRGGBB) or rgb (r, g, b) format.",
    // );
    setColor(inputValue);
  };

  const handleEyedropperClick = async () => {
    setIsEyedropperActive(true);
    if (!("EyeDropper" in window)) {
      alert("Eye Dropper API not supported.");
      return;
    }

    try {
      // @ts-ignore
      const eyedropper = new EyeDropper();
      const selectedColor = await eyedropper.open();
      if (selectedColor) {
        setColor(selectedColor.sRGBHex);
        handleColor(selectedColor.sRGBHex);
      }
    } catch (error) {
      console.error("Eye Dropper API failed:", error);
      // Fallback mechanism (e.g., document click simulation)
    } finally {
      setIsEyedropperActive(false);
    }
  };

  return (
    <div
      className={
        props?.openColorPicker
          ? "main-div"
          : props?.openTextColorPicker
          ? "main_div"
          : "main-div"
      }
    >
      <div className="custom-color-picker">
        <span
          className="position-absolute border border-light"
          style={{
            borderRadius: "4px",
            padding: "0.6em",
            backgroundColor:
              isEyedropperActive || color.startsWith("#")
                ? color
                : colorFormat === "rgb"
                ? `rgb(${color})`
                : `#${color}`,
          }}
        />
        <label className="color-input-container">
          <input
            className="color-picker-input"
            type="text"
            value={color}
            onChange={handleColorChange}
            placeholder="Enter Hex or RGB"
            onKeyDown={handleKeyDown}
            id="fontSize-input"
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </label>
        <div
          onClick={handleEyedropperClick}
          style={{
            cursor: "pointer",
          }}
        >
          <img src={pickerIcon} alt="" className="image-color-picker" />
        </div>
      </div>
      <div
        className="color_picker"
        role="dialog"
        aria-modal="true"
        aria-label="colorPicker"
      >
        <div className="color-picker-triangle color-picker-triangle-shadow"></div>
        <div className="color-picker-triangle"></div>
        <div className="color-picker-content">
          {DEFAULT_COLORS.map((_color, i) => {
            return (
              <button
                className="color-picker-box"
                key={_color.hex}
                style={{ backgroundColor: _color.hex, opacity: 0.8 }}
                onClick={() => {
                  setColor(_color.hex);
                  handleColor(_color.hex);
                  onCloseColorPicker && onCloseColorPicker();
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
