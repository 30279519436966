import { ReactNode, useEffect, useRef, useState } from "react";
import { AppState } from "../../../../types";
import { moreIcon, watchIcon } from "../../../icons";
import { Popover } from "../../../Popover";
import { CardActions, CardData, PopoverActionsENUM } from "../../MyWorkspace";
import { MyWorkspaceSidebarItemsEnum } from "../sidebar";
import default_image from "./assets/default_image2.png";
import "./style.scss";
import { getWorkspaceUsers } from "../../../../excalidraw-app/api/storeElementData";
interface CardProps {
  cardData: CardData;
  actions: CardActions[];
  renderTitle?: () => ReactNode;
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
  initializeSceneWithWorkspace: (
    id: string,
    sharedUserProfileEmail: string,
    isActive?: boolean,
  ) => void;
  setOpenModal: (val: boolean) => void;
  showInput: boolean;
  setShowInput: (val: boolean) => void;
  onSaveDate: () => void;
  setSelectedDate: (val: string) => void;
  selectedDate: string;
  expirationLoader: boolean;
  setShareableWorkspaceOrNot: (val: boolean) => void;
  shareableWorkspaceOrNot: boolean;
  workspaceAllCards: CardData[];
}

export const Card = (props: CardProps) => {
  const { cardData, actions, renderTitle } = props;
  const [openActionPopover, setOpenActionPopover] = useState(false);
  const popoverRef = useRef(null);
  const isInvalidThumbnail =
    !cardData.elements.length || [null, "0", "[]"].includes(cardData.thumbnail);
  const inputRef = useRef<HTMLInputElement>(null); // Specify the type explicitly
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        openActionPopover &&
        popoverRef.current &&
        !(popoverRef.current as HTMLElement | null)?.contains(
          event.target as Node,
        )
      ) {
        setOpenActionPopover(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [openActionPopover, popoverRef]);

  // Get the current date and time in the format required for datetime-local input
  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };
  const roomDetailsLength =
    cardData?.roomDetails && Object.keys(cardData.roomDetails).length;
  return (
    <div
      className="workspace-card cursor-pointer"
      style={{
        border:
          cardData?.roomDetails && roomDetailsLength > 0 && cardData.sharable
            ? "2px solid #607d3b"
            : "2px solid #0F09A1",
      }}
    >
      <div className="col-xs-12 col-sm-6 col-md-4 w-100">
        <div className="card">
          <div className="title-wrap d-flex position-relative">
            {cardData?.roomDetails ? (
              roomDetailsLength > 0 &&
              cardData.sharable && (
                <div className="live-container">
                  <div className="live-pill">
                    <div
                      className="live-dot"
                      style={{
                        backgroundColor: "#607d3b",
                      }}
                    ></div>
                    <div className="live-text">LIVE</div>
                  </div>
                </div>
              )
            ) : (
              <div className="name-dot"></div>
            )}
            <div
              className="title"
              style={{
                marginLeft:
                  cardData?.roomDetails &&
                  roomDetailsLength > 0 &&
                  cardData.sharable
                    ? "10px"
                    : "0px",
              }}
            >
              {renderTitle?.() ?? cardData.title}
            </div>
            {props.appState.selectedWorkspaceTab !==
              MyWorkspaceSidebarItemsEnum.SHARED_WITH_ME && (
              <div
                className="Action"
                onClick={async (e) => {
                  if (
                    cardData?.roomDetails &&
                    roomDetailsLength > 0 &&
                    cardData.sharable
                  ) {
                    const date = new Date(cardData.expirationDate as string);
                    // Convert UTC to local time and format to 'YYYY-MM-DDTHH:MM'
                    const formattedDate = date
                      .toLocaleString("en-CA", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false, // Use 24-hour format
                      })
                      .replace(", ", "T"); // Replace the separator for ISO-like format

                    props.setSelectedDate(formattedDate);

                    props.setShareableWorkspaceOrNot(true);
                  } else {
                    props.setShareableWorkspaceOrNot(false);
                  }
                  localStorage.setItem("selectedWorkspaceCard", cardData.id);
                  e.stopPropagation();
                  setOpenActionPopover(!openActionPopover);
                  props.setShowInput(false);
                }}
              >
                {moreIcon()}
              </div>
            )}
            {openActionPopover && (
              <div ref={popoverRef}>
                <Popover
                  top={45}
                  fitInViewport={true}
                  style={{
                    width: "fit-content",
                    transform: "translateX(-20%)",
                  }}
                >
                  {actions
                    .filter(
                      (action) =>
                        !(
                          action.label ===
                            PopoverActionsENUM.SETEXPIRATIONTIME &&
                          !props.shareableWorkspaceOrNot
                        ),
                    )
                    .map((action) => (
                      <div
                        className="px-3 py-2 item position-relative"
                        onClick={() => {
                          if (action.onClick) {
                            action.onClick(); // Call only if `onClick` exists
                            action.isClosePopover &&
                              setOpenActionPopover(false);
                          }
                        }}
                      >
                        {action.label}
                        {action.label ===
                          PopoverActionsENUM.SETEXPIRATIONTIME &&
                          props.showInput && (
                            <Popover
                              left={-5} // Adjust left value to position side-by-side
                              fitInViewport={true}
                              style={{
                                transform: "translate(-100%, 0%)",
                              }}
                            >
                              <input
                                ref={inputRef}
                                className="px-3 py-2 item"
                                aria-label="Date and time"
                                type="datetime-local"
                                min={getCurrentDateTime()} // Set the minimum selectable datetime
                                value={props.selectedDate} // Bind the input's value to props.selectedDate
                                onChange={(e) => {
                                  props.setSelectedDate(e.target.value);
                                  inputRef.current?.blur();
                                }}
                              />
                              <div className="btn-save-date-div">
                                <button
                                  className="btn save-date"
                                  disabled={props.expirationLoader}
                                  onClick={async () => {
                                    props.onSaveDate();
                                    props.setShowInput(false); // Hide input on save
                                    const res = await getWorkspaceUsers(); // Fetch users data
                                    const updatedWorkspaceData = res.map(
                                      (workspace: any) =>
                                        workspace?.workspaceId === cardData.id
                                          ? {
                                              ...workspace,
                                              expirationDate:
                                                props.selectedDate,
                                            }
                                          : workspace,
                                    );
                                    props.setAppState({
                                      workspaceUsers: updatedWorkspaceData,
                                    });
                                    console.log(
                                      "Updated Workspace Data:",
                                      updatedWorkspaceData,
                                    );
                                  }}
                                >
                                  {props.expirationLoader && (
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                      style={{
                                        height: "10px",
                                        width: "10px",
                                      }}
                                    ></span>
                                  )}{" "}
                                  Save
                                </button>
                              </div>
                            </Popover>
                          )}
                      </div>
                    ))}
                  {/* Render input when "Set Expiration Time" is clicked */}
                </Popover>
              </div>
            )}
          </div>
          <div
            className="thumbnail"
            onClick={() => {
              if (
                props.appState.selectedWorkspaceTab ===
                MyWorkspaceSidebarItemsEnum.ARCHIVE
              ) {
                return;
              }
              localStorage.setItem("isClicked", "true");

              window.parent.postMessage(
                {
                  type: "RELOAD_WHITEBOARD",
                },
                `${`${process.env.REACT_APP_PARENT_APP}`}`,
              );
              props.initializeSceneWithWorkspace(
                cardData.id,
                cardData.sharedUserProfileEmail,
                true,
              );

              localStorage.setItem("selectedWorkspaceCard", cardData.id);
              const user = JSON.parse(localStorage.getItem("user") || "{}");
              localStorage.setItem(
                "sharedUserProfileEmail",
                cardData.sharedUserProfileEmail
                  ? cardData.sharedUserProfileEmail
                  : user?.mail,
              );
              window.parent.postMessage(
                {
                  type: "SELECTED_WORKSPACE",
                  selectedWorkspaceCardId: cardData.id,
                },
                `${`${process.env.REACT_APP_PARENT_APP}`}`,
              );
            }}
          >
            <img
              src={
                isInvalidThumbnail
                  ? default_image
                  : cardData.thumbnail +
                    process.env.REACT_APP_AZURE_STORAGE_SAS_TOKEN
              }
              style={{
                objectFit: "contain",
                height: "170px",
                width: "100%",
              }}
              alt=""
            />
          </div>
          <div className="footer-wrap d-flex justify-content-space">
            <div className="d-flex">
              <div className="profile">{cardData.profile}</div>
              {cardData?.sharedUserProfile && (
                <div className="profile" style={{ backgroundColor: "#767676" }}>
                  {cardData.sharedUserProfile}
                </div>
              )}
            </div>

            <div className="date d-flex gap-2">
              <div className="icon">{watchIcon}</div>
              <div>{cardData.date}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
