import axios from "axios";
import { createRef, useEffect, useMemo, useState } from "react";
import {
  ExcalidrawElement,
  NonDeleted,
  NonDeletedExcalidrawElement,
} from "../../../../element/types";
import { t } from "../../../../i18n";
import { CollocationType } from "../../../../types";
import { useIsMobile } from "../../../App";
import ConfirmDialog from "../../../ConfirmDialog";
import { sortAndFilterIcon } from "../../../icons";
import {
  LibraryItemsDialogProps,
  LibraryTabsEnum,
} from "../../LibraryItemsDialog";
import { AcvLibraryPreviewDialog } from "../../components/acvLibraryPreviewDialog";
import { CollectionSidebarItemsEnum } from "../../components/constant/SidebarItems";
import { ActionButtons } from "./components/actionButtons";
import { AudioCollection } from "./components/audioCollection";
import { BookmarkCollection } from "./components/bookmarkCollection";
import { ImageCollection } from "./components/imageCollection";
import { LikedCollection } from "./components/likedCollection";
import { MyCollection } from "./components/myCollection";
import {
  FilterCollection
} from "./components/sortAndFilter";
import { StockImage } from "./components/stokeImage";
import { TemplateCollection } from "./components/template";
import { UnderReviewCollection } from "./components/underReviewCollection";
import { VideoCollection } from "./components/videoCollection";

interface CollectionTabProps extends LibraryItemsDialogProps {
  selectedSideCollectionTab: CollectionSidebarItemsEnum;
  setFullScreenCollectionPreview: (value: boolean) => void;
  fullScreenCollectionPreview: boolean;
  previewTemplateData: CollocationType | null;
  setPreviewTemplateData: (data: CollocationType) => void;
  setSelectedSection: (val: LibraryTabsEnum) => void;
  importLibraryFromUrl: (urls: string[]) => void;
}

export interface MediaHandlersRef {
  onDelete: () => void;
  onUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface FilteredValueType {
  age: string;
  author: string[];
  tags: string[];
  search?: string;
  userId?: string;
  myPublished?: boolean;
  userAction?: string;
  offset?: number;
}
export interface FilterRef {
  onApply: (val: FilteredValueType) => void;
  onClear: (hasFilterApplied: boolean) => void;
  onSetIsFilteredAplied?: (val: boolean) => void;
  onSetSelectedFilteredValues?: (
    val: Pick<FilteredValueType, "age" | "author" | "tags">,
  ) => void;
}

export const CollectionTab = (props: CollectionTabProps) => {
  const {
    selectedSideCollectionTab,
    setFullScreenCollectionPreview,
    fullScreenCollectionPreview,
    previewTemplateData,
    setPreviewTemplateData,
    setAppState,
    insertImageOnCanvas,
    insertAudioOnCanvas,
    insertVideoOnCanvas,
    subscription,
    elements,
    onInsertShape,
    appState,
    editCollection,
    setSelectedSection,
    importLibraryFromUrl,
  } = props;

  const lesson = JSON.parse(localStorage.getItem("lesson") || "");
  const parts = lesson ? lesson?.ClassName?.split("-") : ["Nature"];
  const [search, setSearch] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState<string[]>([]);
  const [showRemoveLibAlert, setShowRemoveLibAlert] = useState(false);
  const [selectedSortBy, setSelectedSortBy] = useState<number | null>(null);
  const [filterApply, setFilterApply] = useState<boolean>(false);
  const [filterDataWithKeys, setFilterDataWithKeys] = useState<
    Pick<FilteredValueType, "age" | "author" | "tags">
  >({
    age: "",
    author: [],
    tags: [],
  });

  const mediaHandlersRef = createRef<MediaHandlersRef>();
  const filterRef = createRef<FilterRef>();
  const isMobile = useIsMobile();

  useEffect(() => {
    setSearch(
      selectedSideCollectionTab === CollectionSidebarItemsEnum.StockImage
        ? lesson
          ? parts[0]
          : "Nature"
        : "",
    );
    // eslint-disable-next-line
  }, [selectedSideCollectionTab]);

  useEffect(() => {
    filterRef?.current?.onSetSelectedFilteredValues?.(filterDataWithKeys);
    // eslint-disable-next-line
  }, [filterDataWithKeys]);

  // laod element from DB - azure blob storage link
  const loadElementsFromDB = async (url: string) => {
    axios
      .get(`${url}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        const elements: NonDeleted<ExcalidrawElement>[] = [];
        if (res.data?.library) {
          res.data.library.forEach((lib: any) => {
            lib.forEach((data: NonDeleted<ExcalidrawElement>) => {
              elements.push(data);
            });
          });
        }

        if (res.data?.libraryItems) {
          res.data.libraryItems.forEach((lib: any) => {
            lib.elements.forEach((data: NonDeleted<ExcalidrawElement>) => {
              elements.push(data);
            });
          });
        }
        const slug = new URLSearchParams(window.location.search).get("slug");
        const room = new URLSearchParams(window.location.search).get("room");
        const lessonId = new URLSearchParams(window.location.search).get(
          "lessonId",
        );

        if (room) {
          const finalURL = `/?slug=${slug}&lessonId=${lessonId}/#room=${room}/#addLibrary=${url}`;
          const libraryId = finalURL.split("#addLibrary=")[1];
          const urlArray = libraryId ? libraryId.split(",") : [];
          importLibraryFromUrl(urlArray);
        } else {
          const finalURL = `/?slug=${slug}&lessonId=${lessonId}/#addLibrary=${url}`;
          const libraryId = finalURL.split("#addLibrary=")[1];
          const urlArray = libraryId ? libraryId.split(",") : [];
          importLibraryFromUrl(urlArray);
        }

        setAppState({ isLibraryOpen: false });
        return res.data;
      });
  };

  const getTranslationForCollectionTab = (
    selectedSideCollectionTab: CollectionSidebarItemsEnum,
  ) => {
    switch (selectedSideCollectionTab) {
      case CollectionSidebarItemsEnum.Template:
        return t("collection.community.template.title");
      case CollectionSidebarItemsEnum.StockImage:
        return t("collection.community.stokeImage.title");
      case CollectionSidebarItemsEnum.MyCollections:
        return t("collection.personal.myCollections.title");
      case CollectionSidebarItemsEnum.Images:
        return t("collection.personal.images.title");
      case CollectionSidebarItemsEnum.Videos:
        return t("collection.personal.videos.title");
      case CollectionSidebarItemsEnum.Audios:
        return t("collection.personal.audios.title");
      case CollectionSidebarItemsEnum.UnderReview:
        return t("collection.personal.underReview.title");
      case CollectionSidebarItemsEnum.Liked:
        return t("collection.personal.liked.title");
      case CollectionSidebarItemsEnum.Bookmark:
        return t("collection.personal.bookmark.title");
      default:
        return selectedSideCollectionTab;
    }
  };

  const renderRemoveLibAlert = useMemo(() => {
    const text =
      selectedSideCollectionTab === CollectionSidebarItemsEnum.Audios
        ? "audio"
        : selectedSideCollectionTab === CollectionSidebarItemsEnum.Videos
          ? "video"
          : "image";
    const content = `Are you sure you want to delete the ${text}?`;
    const title = `Delete ${text}`;
    return (
      <ConfirmDialog
        onConfirm={() => {
          setSelectedTemplate([]);
          setShowRemoveLibAlert(false);
          mediaHandlersRef.current?.onDelete();
        }}
        onCancel={() => {
          setSelectedTemplate([]);
          setShowRemoveLibAlert(false);
        }}
        title={title}
        children={<p>{content}</p>}
        closeOnClickOutside={false}
        open={false}
        setOpen={() => false}
      />
    );
    // eslint-disable-next-line
  }, [selectedTemplate, showRemoveLibAlert, mediaHandlersRef]);

  const renderSelectedNavItemData = useMemo(() => {
    switch (selectedSideCollectionTab) {
      case CollectionSidebarItemsEnum.Template:
        return (
          <TemplateCollection
            searchVal={search}
            setFullScreenCollectionPreview={() => {
              setFullScreenCollectionPreview(!fullScreenCollectionPreview);
            }}
            setPreviewTemplateData={setPreviewTemplateData}
            loadElementsFromDB={loadElementsFromDB}
            setSelectedTemplate={setSelectedTemplate}
            selectedTemplate={selectedTemplate}
            setAppState={setAppState}
            appState={appState}
            editCollection={editCollection}
            setSelectedSection={setSelectedSection}
            selectedSortBy={selectedSortBy}
            ref={filterRef}
          />
        );
      case CollectionSidebarItemsEnum.StockImage:
        return (
          <StockImage
            insertImageOnCanvas={insertImageOnCanvas}
            setAppState={setAppState}
            searchVal={search}
          />
        );
      case CollectionSidebarItemsEnum.MyCollections:
        return (
          <MyCollection
            searchVal={search}
            setFullScreenCollectionPreview={() => {
              setFullScreenCollectionPreview(!fullScreenCollectionPreview);
            }}
            setPreviewTemplateData={setPreviewTemplateData}
            loadElementsFromDB={loadElementsFromDB}
            selectedSortBy={selectedSortBy}
            ref={filterRef}
            setAppState={setAppState}
            appState={appState}
            editCollection={editCollection}
            setSelectedSection={setSelectedSection}
          />
        );
      case CollectionSidebarItemsEnum.Images:
        return (
          <ImageCollection
            insertOnCanvas={insertImageOnCanvas}
            setAppState={setAppState}
            setDeleteElement={setSelectedTemplate}
            deleteElement={selectedTemplate}
            ref={mediaHandlersRef}
            searchVal={search}
          />
        );
      case CollectionSidebarItemsEnum.Videos:
        return (
          <VideoCollection
            insertOnCanvas={insertVideoOnCanvas}
            setAppState={setAppState}
            setDeleteElement={setSelectedTemplate}
            deleteElement={selectedTemplate}
            ref={mediaHandlersRef}
            subscription={subscription}
            elements={elements as NonDeletedExcalidrawElement[]}
            searchVal={search}
          />
        );
      case CollectionSidebarItemsEnum.Audios:
        return (
          <AudioCollection
            insertOnCanvas={insertAudioOnCanvas}
            setAppState={setAppState}
            setDeleteElement={setSelectedTemplate}
            deleteElement={selectedTemplate}
            ref={mediaHandlersRef}
            subscription={subscription}
            elements={elements as NonDeletedExcalidrawElement[]}
            searchVal={search}
          />
        );
      case CollectionSidebarItemsEnum.UnderReview:
        return (
          <UnderReviewCollection
            searchVal={search}
            setAppState={setAppState}
            appState={appState}
            editCollection={editCollection}
            setSelectedsection={setSelectedSection}
            selectedSortBy={selectedSortBy}
            ref={filterRef}
          />
        );
      case CollectionSidebarItemsEnum.Liked:
        return (
          <LikedCollection
            searchVal={search}
            setFullScreenCollectionPreview={() => {
              setFullScreenCollectionPreview(!fullScreenCollectionPreview);
            }}
            setPreviewTemplateData={setPreviewTemplateData}
            loadElementsFromDB={loadElementsFromDB}
            setSelectedTemplate={setSelectedTemplate}
            selectedTemplate={selectedTemplate}
            selectedSortBy={selectedSortBy}
            ref={filterRef}
            setAppState={setAppState}
            appState={appState}
            editCollection={editCollection}
            setSelectedSection={setSelectedSection}
          />
        );
      case CollectionSidebarItemsEnum.Bookmark:
        return (
          <BookmarkCollection
            searchVal={search}
            setFullScreenCollectionPreview={() => {
              setFullScreenCollectionPreview(!fullScreenCollectionPreview);
            }}
            setPreviewTemplateData={setPreviewTemplateData}
            loadElementsFromDB={loadElementsFromDB}
            setSelectedTemplate={setSelectedTemplate}
            selectedTemplate={selectedTemplate}
            selectedSortBy={selectedSortBy}
            ref={filterRef}
            setAppState={setAppState}
            appState={appState}
            editCollection={editCollection}
            setSelectedSection={setSelectedSection}
          />
        );
      default:
        return <h1>{selectedSideCollectionTab}</h1>;
    }
    // eslint-disable-next-line
  }, [
    selectedSideCollectionTab,
    search,
    setPreviewTemplateData,
    selectedTemplate,
    mediaHandlersRef,
  ]);

  const renderPreviewDialog = useMemo(() => {
    return (
      <AcvLibraryPreviewDialog
        onBack={() => {
          setFullScreenCollectionPreview(false);
        }}
        previewTemplateData={previewTemplateData}
        setPreviewTemplateData={setPreviewTemplateData}
        setAppState={setAppState}
        loadElementsFromDB={loadElementsFromDB}
        isShowTab={true}
        onInsertShape={onInsertShape}
        setFullScreenCollectionPreview={() => {
          setFullScreenCollectionPreview(true);
        }}
        check_={true}
      />
    );
    // eslint-disable-next-line
  }, [previewTemplateData, onInsertShape]);

  if (fullScreenCollectionPreview) {
    return renderPreviewDialog;
  }

  const translation = getTranslationForCollectionTab(selectedSideCollectionTab);

  return (
    <>
      <div className="pt-3">
        <div
          className={`${isMobile ? "d-grid" : "d-flex"
            } form-group has-search gap-2`}
        >
          <input
            type="search"
            className="form-control search-input"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          {(selectedSideCollectionTab === CollectionSidebarItemsEnum.Template ||
            selectedSideCollectionTab ===
            CollectionSidebarItemsEnum.MyCollections ||
            selectedSideCollectionTab ===
            CollectionSidebarItemsEnum.UnderReview ||
            selectedSideCollectionTab === CollectionSidebarItemsEnum.Liked ||
            selectedSideCollectionTab ===
            CollectionSidebarItemsEnum.Bookmark) && (
              <div className="position-relative d-flex align-items-center justify-content-center gap-2">
                {/* <ToolButton
                type={ToolButtonEnum.BUTTON}
                onClick={() => {
                  setOpenSortFilterPopover(!openSortFilterPopover);
                }}
                aria-label="Sort & Filter"
                icon={sortAndFilterIcon}
                label="Sort & Filter"
                className="sort-filter__button pe-3 py-0 "
                size="small"
              >
                Filter
              </ToolButton> */}

                <FilterCollection
                  setFilterDataWithKeys={setFilterDataWithKeys}
                  filterDataWithKeys={filterDataWithKeys}
                  onFilterApply={(filterDataWithKeys) => {
                    filterRef.current?.onApply?.(filterDataWithKeys);
                    filterRef?.current?.onSetIsFilteredAplied?.(true);
                  }}
                  filterApply={filterApply}
                  setFilterApply={setFilterApply}
                  onFilterClear={() => {
                    let hasFilterApplied = false;
                    setFilterDataWithKeys((prev) => {
                      // ex: if age, author and tags is selected but "Apply" button is not clicked then don't call API
                      // call API only if filter is applied
                      if (
                        prev.age !== "" ||
                        prev.author.length ||
                        prev.tags.length
                      ) {
                        hasFilterApplied = true;
                      } else {
                        hasFilterApplied = false;
                      }

                      return {
                        age: "",
                        author: [],
                        tags: [],
                      };
                    });
                    filterRef.current?.onClear?.(
                      filterApply ? hasFilterApplied : false,
                    );
                    filterRef?.current?.onSetIsFilteredAplied?.(false);
                  }}
                  setSelectedSortBy={setSelectedSortBy}
                  selectedSortBy={selectedSortBy ? selectedSortBy : 0}
                />
                <button
                  className="sort-filter__button px-3 py-0 "
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                >
                  {sortAndFilterIcon} Filter
                </button>
              </div>
            )}
        </div>
        <div
          className={
            isMobile
              ? "mt-3 mb-4 justify-content-between"
              : "mt-3 mb-4 d-flex justify-content-between"
          }
        >
          <h4>{translation}</h4>
          <div className="library-menu-items-container d-flex align-items-center gap-3">
            <div className="layer-ui__library-header" key="library-header">
              <ActionButtons
                selectedItems={selectedTemplate}
                setSelectedItems={setSelectedTemplate}
                selectedSideCollectionTab={selectedSideCollectionTab}
                setShowRemoveLibAlert={setShowRemoveLibAlert}
                deleteElelement={selectedTemplate}
                onUpload={(e) => {
                  mediaHandlersRef?.current?.onUpload(e);
                }}
                importLibraryFromUrl={importLibraryFromUrl}
              />
            </div>
          </div>
        </div>
      </div>
      {renderSelectedNavItemData}
      {showRemoveLibAlert && renderRemoveLibAlert}
    </>
  );
};
