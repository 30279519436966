import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Avatar } from "./Avatar";
import { Island } from "./Island";
import "./NamePickerDropDown.scss";
import { Section } from "./Section";

// Define the type for the props
interface NamePickerDropdownWithCheckboxProps {
  studentData: {
    StudentEmailId: string;
    StudentName: string;
    StudentPhoto: string; // Assuming StudentPhoto is a URL string or a path
  }[]; // This should be an array of student objects
  filteredPeople: any;
  zenModeEnabled: any;
  appState: any;
  setSearchQuery: (val: string) => void;
  searchQuery: string;
  isProfilePicker: boolean;
  draggedDataRef?: any;
  setIsOpenProfilePicker: (val: boolean) => void;
  isOpenNamePicker: boolean;
}

const ProfilePickerDropdownWithCheckbox = ({
  studentData,
  filteredPeople,
  zenModeEnabled,
  appState,
  setSearchQuery,
  searchQuery,
  isProfilePicker,
  draggedDataRef,
  setIsOpenProfilePicker,
  isOpenNamePicker,
}: NamePickerDropdownWithCheckboxProps) => {
  const [dragging, setDragging] = useState(false);
  const [avatarCache, setAvatarCache] = useState<Record<string, string>>({});

  // Generate and cache avatar URLs when component mounts or studentData changes
  useEffect(() => {
    const newCache: Record<string, string> = {};
    studentData.forEach((student) => {
      if (!student.StudentPhoto || student.StudentPhoto === "null") {
        newCache[student.StudentName] = generateInitialAvatar(
          student.StudentName,
        );
      }
    });
    setAvatarCache(newCache);
  }, [studentData]);

  // Add this new function at the top of your file
  const generateInitialAvatar = (name: string): string => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    // Set canvas size
    canvas.width = 100;
    canvas.height = 100;

    if (context) {
      // Fill background with a random color based on name
      context.fillStyle = `#c2c2c2`;
      context.fillRect(0, 0, canvas.width, canvas.height);

      // Add text
      const initial = name.substring(0, 1).toUpperCase();
      context.fillStyle = "#fff";
      context.font =
        '500 40px system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, sans-serif';
      context.textAlign = "center";
      context.textBaseline = "middle";
      context.fillText(initial, canvas.width / 2, canvas.height / 2);
    }
    draggedDataRef.current = canvas?.toDataURL("image/png");
    return canvas.toDataURL("image/png");
  };

  const handleDragStart = async (
    e: React.DragEvent<HTMLDivElement>,
    studentPhoto: string,
  ) => {
    try {
      setDragging(true);
      const imageSrc = `${studentPhoto}${process.env.REACT_APP_AZURE_STORAGE_SAS_TOKEN}`;

      // Create a new image element to get dimensions
      const img = new Image();
      img.src = imageSrc;
      await new Promise((resolve) => {
        img.onload = resolve;
      });

      // Create the file with proper dimensions
      const response = await fetch(imageSrc);
      const blob = await response.blob();
      const file = new File([blob], `studentProfile_${Date.now()}.jpg`, {
        type: "image/jpeg",
        lastModified: Date.now(),
      });

      if (draggedDataRef?.current !== undefined) {
        draggedDataRef.current = file;

        // Set the drag data with the image dimensions
        e.dataTransfer.setData(
          "text/plain",
          JSON.stringify({
            type: "image/jpeg",
            width: img.width,
            height: img.height,
          }),
        );
        e.dataTransfer.effectAllowed = "copy";
      }
    } catch (error) {
      console.error("Error during drag start:", error);
    }
  };

  const handleDragEnd = () => {
    setDragging(false);
  };

  return (
    <Section
      heading="selectedShapeActions"
      className={clsx("zen-mode-transition", {
        "transition-left": zenModeEnabled,
      })}
      style={{ width: "266px", marginTop: "20px" }}
    >
      <Island
        className={"App-menu__left shdw ml-6px"}
        padding={2}
        style={{
          // we want to make sure this doesn't overflow so substracting 200
          // which is approximately height of zoom footer and top left menu items with some buffer
          // if active file name is displayed, subtracting 248 to account for its height
          maxHeight: `${
            appState?.height - (appState?.fileHandle ? 248 : 200)
          }px`,
          translate: isProfilePicker && isOpenNamePicker ? "19rem" : "",
          left: appState?.sidebarWrapper ? "15rem" : 0,
          width: "266px",
          paddingBottom: "0px",
        }}
      >
        <div className="name_picker">
          <div className="d-flex align-items-center justify-content-between">
            <p className="name_widget">Profile Picker</p>
            <button
              className="close_button"
              onClick={() => {
                setIsOpenProfilePicker(false);
              }}
            >
              x
            </button>
          </div>

          <div className="search-wrapper" style={{ marginBottom: "9px" }}>
            <div className="search-container">
              <input
                style={{
                  boxShadow: "0 0 0 2px #efefef",
                  outline: "none !important",
                  textIndent: "0",
                }}
                type="text"
                className="form-control form-input search-input"
                placeholder="Search anything..."
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <div className="search-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <circle cx="11" cy="11" r="8"></circle>
                  <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
              </div>
            </div>
          </div>
          <div style={{ maxHeight: "300px", overflow: "auto" }}>
            <table className="table mb-0">
              <tbody>
                {(searchQuery ? filteredPeople : studentData).length > 0 ? (
                  (searchQuery ? filteredPeople : studentData).map(
                    (student: any, index: number) => {
                      const studentPhoto = student.StudentPhoto?.Foto;
                      const studentName = student.StudentName || "";
                      const studentEmail = student.StudentEmailId || "";

                      return (
                        <tr
                          key={`stud-${index}`}
                          style={{
                            cursor: "grab",
                          }}
                          draggable={isProfilePicker}
                          onDragStart={(e) => handleDragStart(e, studentPhoto)}
                          onDragEnd={(e) => handleDragEnd}
                        >
                          <td
                            style={{ paddingLeft: "0px", paddingRight: "0px" }}
                          >
                            <div>
                              <Avatar
                                color="transparent"
                                border="transparent"
                                onClick={() => false}
                              >
                                {studentPhoto && studentPhoto !== "null" ? (
                                  <img
                                    src={
                                      studentPhoto +
                                      process.env
                                        .REACT_APP_AZURE_STORAGE_SAS_TOKEN
                                    }
                                    alt={studentName}
                                    className="user_profile"
                                    style={{
                                      cursor: "grab",
                                      objectFit: "cover",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={
                                      avatarCache[studentName] ||
                                      generateInitialAvatar(studentName)
                                    }
                                    alt={studentName}
                                    className="user_profile"
                                    style={{
                                      cursor: "grab",
                                    }}
                                  />
                                )}
                              </Avatar>
                            </div>
                          </td>
                          <td>
                            <p
                              className={"profile_picker_student_data"}
                              style={{ marginBottom: "0px" }}
                            >
                              {studentName}
                            </p>
                            <p
                              className={"profile_picker_student_data"}
                              style={{
                                fontSize: "11px",
                                marginBottom: "0px",
                                color: "#a5a2a2",
                              }}
                            >
                              {studentEmail}
                            </p>
                          </td>
                        </tr>
                      );
                    },
                  )
                ) : (
                  <tr>
                    <td
                      colSpan={3}
                      style={{ textAlign: "center", padding: "10px" }}
                    >
                      No records available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Island>
    </Section>
  );
};

export default ProfilePickerDropdownWithCheckbox;
