import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { DndProvider, useDrag, useDragLayer, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Editable from "../components/Editable";
import { apiGet } from "../excalidraw-app/api";
import { getLessonId, getUserInfo } from "../excalidraw-app/api/getuserInfo";
import {
  getElemetDataFromDatabase,
  updateActivePage,
  updatePageName,
  updatePageOrder,
} from "../excalidraw-app/api/storeElementData";
import {
  onSaveElementInDB,
  pdfImportDocumentCounter,
} from "../excalidraw-app/api/userAPI";
import CollabWrapper from "../excalidraw-app/collab/CollabWrapper";
import {
  createNewCanvas,
  duplicateCanvas,
  getPages,
} from "../excalidraw-app/data";
import { STORAGE_KEYS } from "../excalidraw-app/data/localStorage";
import { t } from "../i18n";
import { urlToDataUrl } from "../utils/convertToDataURL";
import { uploadImageToAzureBlob } from "../utils/uploadImageToAzureBlob";
import { useIsMobile } from "./App";
import { Dialog } from "./Dialog";
import {
  chevronsLeft,
  copy,
  del,
  downArrow,
  dragIcon,
  exportFile,
  exportToFileIcon,
  moreIcon,
  moreTools,
} from "./icons";
import { MyWorkspaceSidebarItemsEnum } from "./myWorkSpace/views/sidebar";
import { Popover } from "./Popover";
import "./Sidebar.scss";
import { ToolButton, ToolButtonEnum } from "./ToolButton";

export const component = new CollabWrapper();

export const SideBar = ({
  appState,
  setAppState,
  onPageChange,
  onPageDelete,
  isStudent,
  setConfirmationModal,
  pageChange,
  setPageChange,
}) => {
  const lessonId =
    new URLSearchParams(window.location.search)
      .get("lessonId")
      ?.replace(/\//g, "") || "";

  const [pageDetails, setPageDetails] = useState([]);
  const [deletePageDetails, setDeletePageDetails] = useState(null);
  const inputRef = useRef();
  const [task, setTask] = useState({
    page: 1,
    text: "",
  });
  const [isOpen, setIsOpen] = useState(false);
  const [
    isCollaboratingWithFlexibleGroups,
    setIsCollaboratingWithFlexibleGroups,
  ] = useState(false);
  const [user, setUser] = useState(null);
  const [group, setGroup] = useState(null);
  const [mount, setMount] = useState(false);
  const [teacherActivePage, setTeacherActivePage] = useState(0);
  const [pageActions, setPageActions] = useState("");

  const currentPageInNumber = useMemo(() => {
    return Number(appState.currentPage);
  }, [appState.currentPage]);

  useEffect(() => {
    setTeacherActivePage(localStorage.getItem("ACTIVE_PAGE"));
    // eslint-disable-next-line
  }, [localStorage.getItem("ACTIVE_PAGE")]);
  useEffect(() => {
    setTeacherActivePage(localStorage.getItem("ACTIVE_PAGE"));
    // eslint-disable-next-line
  }, [localStorage.getItem("ACTIVE_PAGE_URL")]);

  const collaborating = Boolean(localStorage.getItem("isCollaborating"));

  const groupCondition =
    isStudent && isCollaboratingWithFlexibleGroups ? Boolean(group) : true;

  const [pageURL, setPageURL] = useState(null);
  const newPageButtonRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);

  useState(() => {
    setCurrentPage(appState.currentPage);
  }, [currentPageInNumber]);

  useEffect(() => {
    if (pageChange) {
      pageDetails?.forEach((page) => {
        changePage(page.page);
        setPageChange(false);
      });
    }
    // eslint-disable-next-line
  }, [pageChange]);

  useEffect(() => {
    // The getPageData function is called to address the page data replacement issue. Please do not remove this function.
    const fetchData = async () => {
      await getUser();
      await getpageData();
    };
    fetchData();
    setMount(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (appState.sidebarWrapper) {
      localStorage.setItem("isDragAndDrop", JSON.stringify(true));
    }
  }, [appState.sidebarWrapper]);

  useEffect(() => {
    const condition = isCollaboratingWithFlexibleGroups ? !isStudent : true;

    const fetchData = async () => {
      // The getPageData function is called to address the page data replacement issue. Please do not remove this function.
      await onPageChange(appState.currentPage);
    };

    if (condition) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [
    JSON.stringify({
      isCollaboratingWithFlexibleGroups,
      currentPageInNumber,
    }),
  ]);

  useEffect(() => {
    //add appState.currentPage to the dependency array to get the updated page data and also add condition for if same page is selected then no need to call the api (this is for dupkicate element issue after drag and drop)
    if (appState.currentPage === currentPage) return;
    onPageChange(appState.currentPage);
    // eslint-disable-next-line
  }, [currentPage, currentPageInNumber]);

  useEffect(() => {
    const lastPart = appState.updatedPageDataURL?.dataURL?.split("-").pop();
    if (Number(lastPart) === Number(appState.currentPage)) {
      setPageURL(appState.updatedPageDataURL);
    }
  }, [appState.updatedPageDataURL, currentPageInNumber]);

  useEffect(() => {
    setIsCollaboratingWithFlexibleGroups(
      JSON.parse(
        localStorage.getItem("isCollaboratingWithFlexibleGroups") || "false",
      ),
    );
    // eslint-disable-next-line
  }, [localStorage.getItem("isCollaboratingWithFlexibleGroups")]);
  const isMyWorkSpace = localStorage.getItem("isMyWorkSpace");
  useEffect(() => {
    const isMyWorkSpaceData = localStorage.getItem("isMyWorkSpace");
    let acvData = [];
    if (isMyWorkSpaceData === "true") {
      acvData = JSON.parse(
        localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_WORKSPACE_ELEMENTS) ||
          "[]",
      );
    } else {
      acvData = JSON.parse(
        localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS) || "[]",
      );
    }

    const storeData = async () => {
      if (acvData?.length) {
        window.parent.postMessage(
          { type: "STORE_ELEMENTS", isLoading: true },
          `${process.env.REACT_APP_PARENT_APP}`,
        );
        await onSaveElementInDB(
          appState.currentPage,
          lessonId || null,
          appState.DBElements,
          appState.viewBackgroundColor,
        );
        window.parent.postMessage(
          { type: "STORE_ELEMENTS", isLoading: false },
          `${process.env.REACT_APP_PARENT_APP}`,
        );
      }
    };

    const isCollaboration = JSON.parse(localStorage.getItem("isCollaborating"));

    if (mount && acvData && !isCollaboration) storeData();
  }, [
    // eslint-disable-next-line
    localStorage.getItem(
      isMyWorkSpace === "true"
        ? STORAGE_KEYS.LOCAL_STORAGE_WORKSPACE_ELEMENTS
        : STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS,
    ),
    localStorage.getItem("isCollaborating"),
  ]);

  useEffect(() => {
    isStudent && user && isCollaboratingWithFlexibleGroups && getGroup();
    // eslint-disable-next-line
  }, [
    isStudent,
    isCollaboratingWithFlexibleGroups,
    user,
    // eslint-disable-next-line
    localStorage.getItem("PAGE_UPDATE"),
  ]);

  const getGroup = async () => {
    const lessonId = await getLessonId();

    if (!!lessonId.trim() && !!user?.mail.trim()) {
      //&&
      const res = await apiGet(
        `${process.env.REACT_APP_EP_URL_WHITEBOARD}/flexible-group/get-group-by-student?lessonId=${lessonId}&mail=${user.mail}`,
      );
      if (res) {
        setGroup(res?.result);
        if (res?.result?.page?.isPage) {
          localStorage.setItem("isShowNoPagesModel", "false");
          setAppState({
            currentPage: res.result.page.no,
          });
          await onPageChange(res.result.page.no);
          await getpageData();
          localStorage.removeItem("PAGE_UPDATE");
        } else {
          localStorage.setItem("isShowNoPagesModel", "true");
          setAppState({ isShowWhiteboard: false });
        }
      }
    }
  };

  const getUser = async () => {
    try {
      const user = await getUserInfo();
      setUser(user);
    } catch (error) {
      console.error("error-from-getUser", error);
    }
  };

  const checkIsPageInGroup = (pageId) => {
    const isPageInGroup = group.page.id === pageId;
    return isPageInGroup;
  };

  const getpageData = async (page) => {
    const currentPage = page ? page : false;

    if (currentPage) {
      setAppState({
        currentPage: page,
      });
    }
    const isMyWorkSpace = localStorage.getItem("isMyWorkSpace");
    if (isMyWorkSpace === "true") {
      return;
    }

    await getPages(lessonId, currentPage).then(async (res) => {
      if (res.result.length === 1 || res.result.length === 0) {
        setAppState({
          currentPage: 1,
        });
      }

      const pageData = [...res.result];
      // const pageData = res.result.sort((a, b) =>
      //   a.page > b.page ? 1 : b.page > a.page ? -1 : 0,
      // );

      if (pageData.length > 0) {
        const currentPage = pageData[0].page;
        setAppState({
          currentPage,
          updatedPageDataURL: {
            lessonId,
            page: currentPage,
            dataURL: pageData[0].dataURL,
          },
        });
      }

      if (pageData?.length === 0) {
        newPageButtonRef?.current?.click();
      }
      setPageDetails([...pageData]);
    });
  };

  const changePage = async (page) => {
    if (appState.currentPage === page) {
      return;
    }
    await onPageChange(page, setPageDetails);
    setAppState({
      currentPage: page,
      sidebarWrapper: false,
    });

    const student = JSON.parse(window.localStorage.getItem("S") || "{}");
    if (!student) {
      await updateActivePage({
        link: window.location.href,
        activePage: page,
      });
    }
  };

  const sideWrapperToggler = () => {
    setAppState({
      sidebarWrapper: false,
    });
  };

  const handleClose = React.useCallback(() => {
    if (isOpen) {
      setIsOpen(false);

      localStorage.setItem("isDragAndDrop", JSON.stringify(false));
    } else {
      setIsOpen(true);

      localStorage.setItem("isDragAndDrop", JSON.stringify(true));
    }
  }, [isOpen]);

  const getUpdatedPageData = async () => {
    await getPages(lessonId).then(async (res) => {
      if (res.result.length === 1 || res.result.length === 0) {
        setAppState({
          currentPage: 1,
        });
      }
      const pageData = [...res.result].sort((a, b) => {
        if (a.order !== undefined && b.order !== undefined) {
          return a.order - b.order; // Sort by order if both have it
        }
        return a.id - b.id; // Fallback to sorting by id
      });
      setPageDetails([...pageData]);
    });
  };

  const onSavePageNameHandler = async (pageName, data) => {
    try {
      const apiBody = {
        pageName,
        data,
      };
      await updatePageName(apiBody);
      getUpdatedPageData();
    } catch (err) {
      console.error(err);
    }
  };

  const savePageNameOnEnter = async (e, data) => {
    if (e.keyCode === 13 || e.keyCode === 9) {
      const apiBody = {
        pageName: task.text,
        data,
      };
      await updatePageName(apiBody);
      getUpdatedPageData();
    }
  };

  const onPageDeleteHandler = async () => {
    setMount(false);
    if (deletePageDetails) {
      const isMyWorkSpace = localStorage.getItem("isMyWorkSpace");
      if (isMyWorkSpace === "true") {
        return;
      }
      const pageData = await getElemetDataFromDatabase(
        lessonId,
        Number(deletePageDetails.pageNo),
      );

      await onPageDelete(deletePageDetails.id);
      //delete imported pdf counter when delete the page
      if (
        pageData.result &&
        pageData.result[0].data &&
        pageData.result[0].data.length
      ) {
        const pageDetails = pageData.result[0].data;
        for (let i = 0; i < pageDetails.length; i++) {
          if (pageDetails[i].pdfId) {
            const slug =
              new URLSearchParams(window.location.search).get("slug") || "";
            const body = {
              userId: user?.mail,
              slug,
              lessonId: pageDetails[i].lessonId,
              pdfId: pageDetails[i].pdfId,
            };
            pdfImportDocumentCounter(body);
          }
        }
      }
      const filteredPages = pageDetails.filter(
        (page) => page.id !== deletePageDetails.id,
      );

      setPageDetails([...filteredPages]);
      handleClose();
      // await getpageData();
      if (filteredPages.length === 0) {
        newPageButtonRef.current.click();
        setPageURL({
          page: 1,
          dataURL: null,
          lessonId,
          order: 1,
        });
        setAppState({
          currentPage: 1,
        });
      }
    }
    setMount(true);
  };

  const getLatestUrl = useCallback(
    (page) => {
      const url = pageURL
        ? pageURL.lessonId === lessonId &&
          Number(pageURL.page) === Number(page.page)
          ? pageURL.dataURL
          : page.dataURL
        : page && page?.dataURL
        ? page.dataURL
        : "";
      return url;
    },
    [pageURL, lessonId],
  );

  const movePage = async (fromIndex, toIndex) => {
    // Create a copy of the page details array
    const updatedPages = [...pageDetails];

    // Remove the page from the original position and store it
    const [movedPage] = updatedPages.splice(fromIndex, 1);

    // Insert the page into the new position
    updatedPages.splice(toIndex, 0, movedPage);

    // Update the order field for each page
    const reorderedPages = updatedPages.map((page, index) => ({
      ...page,
      order: index + 1, // Assuming order starts from 1
    }));

    // Update the state with the reordered pages
    setPageDetails([...reorderedPages]);

    // Prepare the API payload
    const apiBody = {
      data: reorderedPages,
    };

    // Call the API to update the order
    await updatePageOrder(apiBody);
  };

  // Function to move a page up
  const movePageUp = async (index) => {
    // Prevent moving the first page up
    if (index <= 0) return;

    // Create a copy of the pages and swap with the one above
    const updatedPages = [...pageDetails];
    [updatedPages[index - 1], updatedPages[index]] = [
      updatedPages[index],
      updatedPages[index - 1],
    ];

    // Reorder the pages and update their order field
    await updatePageOrderAndState(updatedPages);
  };

  // Function to move a page down
  const movePageDown = async (index) => {
    if (index >= pageDetails.length - 1) return;

    const updatedPages = [...pageDetails];
    [updatedPages[index], updatedPages[index + 1]] = [
      updatedPages[index + 1],
      updatedPages[index],
    ];

    await updatePageOrderAndState(updatedPages);
  };

  // Common function to reorder and update pages
  const updatePageOrderAndState = async (updatedPages) => {
    // Update the order field for each page
    const reorderedPages = updatedPages.map((page, i) => ({
      ...page,
      order: i + 1,
    }));

    setPageDetails([...reorderedPages]);

    const apiBody = { data: reorderedPages };
    await updatePageOrder(apiBody);
  };

  return (
    <>
      {isOpen && (
        <Dialog
          title={t("alerts.confirmDelete")}
          small={true}
          onCloseRequest={handleClose}
          className={"delete-page-dialog"}
          autofocus={true}
        >
          <p>{t("alerts.deleteMsg")}</p>
          <div className="RoomDialog-sessionStartButtonContainer">
            <ToolButton
              className="cancel-page-dialogBtn me-2 px-2 py-1"
              type={ToolButtonEnum.BUTTON}
              // icon={trash}
              title={t("buttons.cancel")}
              aria-label={t("buttons.cancel")}
              showAriaLabel={true}
              onClick={handleClose}
            />
            <ToolButton
              className="Remove-page-dialogBtn px-2 py-1"
              type={ToolButtonEnum.BUTTON}
              // icon={del}
              title={t("labels.delete")}
              aria-label={t("labels.delete")}
              showAriaLabel={true}
              onClick={() => onPageDeleteHandler()}
            />
          </div>
        </Dialog>
      )}
      <div
        className={`${
          // appState.sidebarWrapper ? "sidebarWrapper " : "sidebarWrapper"
          appState.sidebarWrapper ? "sidebarWrapper" : "sidebarWrapper wnone"
        }${
          useIsMobile()
            ? " align-self-start  d-flex flex-column justify-content-between"
            : ""
        }`}
      >
        <div className="page-block">
          <ToolButton
            className="btn btn-light shadow-sm btn-compact"
            key="newPage"
            type={ToolButtonEnum.BUTTON}
            title={t("labels.compact")}
            aria-label={t("labels.compact")}
            icon={chevronsLeft}
            onClick={sideWrapperToggler}
            {...(useIsMobile() && { style: { maxHeight: "70px", flex: "1" } })}
          ></ToolButton>
        </div>
        <DndProvider backend={HTML5Backend}>
          <div
            {...(useIsMobile() && {
              style: { overflowY: "auto", overflowX: "hidden", flex: 2 },
            })}
          >
            {groupCondition &&
              pageDetails?.map((page, index) => {
                const condition =
                  isStudent && isCollaboratingWithFlexibleGroups
                    ? checkIsPageInGroup(page.id)
                    : true;

                const url = getLatestUrl(page);

                return (
                  condition && (
                    <div className="page flex-2" key={`page-${index}`}>
                      {/* <DragLayer /> */}
                      <DraggablePage
                        index={index}
                        movePage={movePage}
                        key={index}
                      >
                        <>
                          {isStudent ? (
                            <div
                              className={`rounded py-2 px-3 text-gray-700 leading-tight whitespace-pre-wrap hover:shadow-outline`}
                            >
                              <span
                                className={`${
                                  page.pageName ? "text-black" : "text-gray-500"
                                }`}
                              >
                                {page.pageName ||
                                  t("multipage.renamePlaceHolder") ||
                                  "Editable content"}
                              </span>
                            </div>
                          ) : (
                            <div className="d-flex justify-content-between">
                              <div className="d-flex">
                                <div
                                  style={{
                                    opacity: 0.8,
                                    cursor: "grabbing",
                                  }}
                                >
                                  {dragIcon(
                                    appState.theme === "light"
                                      ? "#2e3436"
                                      : "#fff",
                                  )}
                                </div>
                                <Editable
                                  text={page.pageName}
                                  // placeholder={t("multipage.renamePlaceHolder")}
                                  placeholder={`${t("multipage.pageNumber")}${
                                    page.page
                                  }`}
                                  childRef={inputRef}
                                  type="input"
                                >
                                  <input
                                    ref={inputRef}
                                    type="text"
                                    name={task.page}
                                    style={{ height: "30px" }}
                                    className="shadow appearance-none border rounded w-100 mb-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-300"
                                    placeholder={t(
                                      "multipage.renamePlaceHolder",
                                    )}
                                    defaultValue={page.pageName}
                                    onChange={(e) => {
                                      if (!isStudent) {
                                        setTask({
                                          page: page.page,
                                          text: e.target.value,
                                        });
                                        setAppState({
                                          pageName: e.target.value,
                                        });
                                      }
                                    }}
                                    onBlur={(e) => {
                                      if (!isStudent) {
                                        setTask({
                                          page: page.page,
                                          text: e.target.value,
                                        });
                                        onSavePageNameHandler(
                                          e.target.value,
                                          page,
                                        );
                                      }
                                    }}
                                    onKeyDown={(e) =>
                                      !isStudent && savePageNameOnEnter(e, page)
                                    }
                                  />
                                </Editable>
                              </div>
                              {!isStudent && (
                                <div
                                  className="position-relative cursor-pointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setPageActions(page.id);
                                  }}
                                >
                                  <div className="more_icon">
                                    {" "}
                                    {moreIcon(
                                      appState.theme === "light"
                                        ? "black"
                                        : "white",
                                    )}
                                  </div>
                                  {pageActions === page.id && (
                                    <Popover
                                      onCloseRequest={() => setPageActions("")}
                                      left={20}
                                      top={20}
                                      style={{
                                        transform: "translate(-100%, 0)",
                                        zIndex: 100,
                                      }}
                                    >
                                      <div
                                        className="w-100 d-flex gap-2 context-menu-option action_icon_wrapper mx-0"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          movePageUp(index);
                                          setPageActions("");
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: 15,
                                            transform: "rotate(180deg)",
                                          }}
                                        >
                                          {exportFile}
                                        </div>
                                        Move up
                                      </div>
                                      <div
                                        className="w-100 d-flex gap-2 context-menu-option action_icon_wrapper mx-0"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          movePageDown(index);
                                          setPageActions("");
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: 15,
                                          }}
                                        >
                                          {exportFile}
                                        </div>{" "}
                                        Move down
                                      </div>
                                      <div
                                        style={{ color: "#777" }}
                                        className="w-100 d-flex gap-2 context-menu-option action_icon_wrapper mx-0"
                                        onClick={async (e) => {
                                          e.stopPropagation();
                                          const pageNo =
                                            Math.max.apply(
                                              Math,
                                              pageDetails.length
                                                ? pageDetails.map((o) =>
                                                    o.page ? o.page : 0,
                                                  )
                                                : [0],
                                            ) + 1;
                                          if (collaborating) {
                                            await component.initializeSocketClient(
                                              null,
                                            );
                                          }
                                          const isMyWorkSpace = localStorage.getItem(
                                            "isMyWorkSpace",
                                          );
                                          if (isMyWorkSpace === "true") {
                                            return;
                                          }
                                          const pageData = await getElemetDataFromDatabase(
                                            lessonId,
                                            Number(page.page),
                                          );
                                          let url = null;
                                          if (pageData.result[0].dataURL) {
                                            const {
                                              dataUrl,
                                            } = await urlToDataUrl(
                                              pageData.result[0].dataURL +
                                                process.env
                                                  .REACT_APP_AZURE_STORAGE_SAS_TOKEN,
                                            );
                                            const blobName = `${lessonId}-${pageNo}`;
                                            url = await uploadImageToAzureBlob(
                                              dataUrl,
                                              blobName,
                                            );
                                          }

                                          const res = await duplicateCanvas(
                                            lessonId,
                                            pageNo,
                                            [
                                              ...pageData.result[0].data,
                                              { dataURL: url },
                                            ],
                                            appState.viewBackgroundColor,
                                          );

                                          setPageDetails([
                                            ...pageDetails,
                                            {
                                              id: res?.result?.id,
                                              page: pageNo,
                                              dataURL: url,
                                            },
                                          ]);

                                          setPageActions("");
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: 15,
                                          }}
                                        >
                                          {copy}
                                        </div>
                                        Copy
                                      </div>
                                      <div
                                        style={{
                                          color: "#d33228",
                                        }}
                                        className="w-100 d-flex gap-2 context-menu-option action_icon_wrapper mx-0"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleClose();
                                          setDeletePageDetails({
                                            id: page.id,
                                            pageNo: page.page,
                                          });
                                          setPageActions("");
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: 15,
                                          }}
                                        >
                                          {del}
                                        </div>{" "}
                                        Delete
                                      </div>
                                    </Popover>
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                          <div
                            className="pagePreview"
                            style={{
                              outline: collaborating
                                ? Number(teacherActivePage) ===
                                    Number(page.page) && "2px solid #607d3b"
                                : "",
                            }}
                            onClick={async () => {
                              if (
                                appState.textEditor.open &&
                                appState.textEditor.value
                              ) {
                                setConfirmationModal(true);
                              } else {
                                window.parent.postMessage(
                                  { type: "STORE_ELEMENTS", isLoading: true },
                                  `${process.env.REACT_APP_PARENT_APP}`,
                                );
                                setAppState({
                                  ...appState,
                                  isLoading: true,
                                });
                                const lessonId =
                                  new URLSearchParams(window.location.search)
                                    .get("lessonId")
                                    ?.replace(/\//g, "") || "";

                                await onSaveElementInDB(
                                  appState.currentPage,
                                  lessonId || null,
                                  appState.DBElements,
                                  appState.viewBackgroundColor,
                                );
                                window.parent.postMessage(
                                  { type: "STORE_ELEMENTS", isLoading: false },
                                  `${process.env.REACT_APP_PARENT_APP}`,
                                );
                                setAppState({
                                  ...appState,
                                  isLoading: false,
                                });
                                await changePage(page.page);
                              }
                            }}
                          >
                            {url ? (
                              <img
                                key={index}
                                src={`${url}${
                                  process.env.REACT_APP_AZURE_STORAGE_SAS_TOKEN
                                }&t=${Date.now()}`}
                                alt={index + 1}
                                width="186px"
                                height="80px"
                              />
                            ) : null}
                          </div>
                        </>
                      </DraggablePage>
                    </div>
                  )
                );
              })}
          </div>
        </DndProvider>
        <div
          className={
            useIsMobile() ? "text-center align-items-center mt-2" : "page-block"
          }
          {...(useIsMobile() && {
            style: { flex: "1", maxHeight: "120px" }, // combine the height of app-toolbar-content(mobile view) + version-info + newPageBtn
          })}
        >
          {!isStudent &&
            appState.selectedWorkspaceTab !==
              MyWorkspaceSidebarItemsEnum.NEW_WORKSPACE && (
              <ToolButton
                className="bg-brand text-white pr-2 btnNewPage"
                key="newPage"
                type={ToolButtonEnum.BUTTON}
                title={t("multipage.newPageBtn")}
                aria-label={t("multipage.newPageBtn")}
                icon={moreTools}
                ref={newPageButtonRef}
                onClick={async () => {
                  const pageNo = pageDetails
                    ? Math.max.apply(
                        Math,
                        pageDetails.length
                          ? pageDetails.map((o) => (o.page ? o.page : 0))
                          : [0],
                      ) + 1
                    : 1;
                  if (collaborating) {
                    await component.initializeSocketClient(null);
                  }

                  const res = await createNewCanvas(
                    lessonId,
                    pageNo,
                    appState.viewBackgroundColor,
                    pageDetails.length + 1,
                  );

                  if (!pageDetails) {
                    setPageDetails([
                      {
                        id: res?.result?.id,
                        page: pageNo,
                        dataURL: "",
                        order: pageDetails.length + 1,
                      },
                    ]);
                  } else {
                    setPageDetails([
                      ...pageDetails,
                      {
                        id: res?.result?.id,
                        page: pageNo,
                        dataURL: "",
                        order: pageDetails.length + 1,
                      },
                    ]);
                  }
                }}
              >
                <span>{t("multipage.newPageBtn")}</span>
              </ToolButton>
            )}
        </div>
      </div>
    </>
  );
};

const DraggablePage = ({ children, index, movePage }) => {
  const ref = useRef(null);

  const [, drag, preview] = useDrag({
    type: "PAGE",
    item: { index },
  });

  const [, drop] = useDrop({
    accept: "PAGE",
    drop: (draggedItem) => {
      if (draggedItem.index !== index) {
        movePage(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  drag(drop(ref));

  return (
    <div ref={ref} className="page-container" style={{ cursor: "move" }}>
      {children}
    </div>
  );
};

const DragLayer = () => {
  const { isDragging, item, currentOffset } = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging(),
    item: monitor.getItem(),
    currentOffset: monitor.getSourceClientOffset(),
  }));

  const [draggedElementSize, setDraggedElementSize] = useState({
    width: 0,
    height: 0,
  });

  // Capture the size of the dragged element using the `item` data
  useEffect(() => {
    if (isDragging && item) {
      const draggedElement = document.querySelector(
        `.page-container[data-index="${item.index}"]`,
      );
      if (draggedElement) {
        const rect = draggedElement.getBoundingClientRect();
        setDraggedElementSize({ width: rect.width, height: rect.height });
      }
    }
  }, [isDragging, item]);

  if (!isDragging || !currentOffset) {
    return null;
  }

  const layerStyle = {
    position: "fixed",
    pointerEvents: "none",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 100,
  };

  // Set the height and width explicitly to 186px and 112px
  const itemStyle = {
    transform: `translate(${currentOffset.x}px, ${currentOffset.y}px)`,
    WebkitTransform: `translate(${currentOffset.x}px, ${currentOffset.y}px)`,
    opacity: 0.6,
    backgroundColor: "rgb(233, 236, 239)",
    padding: "8px",
    width: "186px",
    height: "112px",
    color: "black",
  };

  // Adjust the layer to match the drag preview size
  const adjustedLayerStyle = {
    ...layerStyle,
    height: "112px",
    width: "186px",
  };

  return (
    <div style={adjustedLayerStyle}>
      <div style={itemStyle}>
        <div></div>
      </div>
    </div>
  );
};
