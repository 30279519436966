import clsx from "clsx";
import { useEffect, useState } from "react";
import { Avatar } from "./Avatar";
import { Island } from "./Island";
import "./NamePickerDropDown.scss";
import { Section } from "./Section";

// Define the type for the props
interface NamePickerDropdownWithCheckboxProps {
  studentData: {
    StudentEmailId: string;
    StudentName: string;
    StudentPhoto: string; // Assuming StudentPhoto is a URL string or a path
  }[]; // This should be an array of student objects
  setSelectedNames: (val: any) => void;
  filteredPeople: any;
  zenModeEnabled: any;
  appState: any;
  setSearchQuery: (val: string) => void;
  searchQuery: string;
  handleNamePicker: any;
  draggedDataRef?: any;
  isOpenNamePicker?: boolean;
  setIsOpenNamePicker: (val: boolean) => void;
}

const NamePickerDropdownWithCheckbox = ({
  studentData,
  setSelectedNames,
  filteredPeople,
  zenModeEnabled,
  appState,
  setSearchQuery,
  searchQuery,
  handleNamePicker,
  draggedDataRef,
  isOpenNamePicker,
  setIsOpenNamePicker,
}: NamePickerDropdownWithCheckboxProps) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]); // Track selected checkboxes
  const [avatarCache, setAvatarCache] = useState<Record<string, string>>({});

  // Generate and cache avatar URLs when component mounts or studentData changes
  useEffect(() => {
    const newCache: Record<string, string> = {};
    studentData.forEach((student) => {
      if (!student.StudentPhoto || student.StudentPhoto === "null") {
        newCache[student.StudentName] = generateInitialAvatar(
          student.StudentName,
        );
      }
    });
    setAvatarCache(newCache);
  }, [studentData]);

  // Add this new function at the top of your file
  const generateInitialAvatar = (name: string): string => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    // Set canvas size
    canvas.width = 100;
    canvas.height = 100;

    if (context) {
      // Fill background with a random color based on name
      context.fillStyle = `#c2c2c2`;
      context.fillRect(0, 0, canvas.width, canvas.height);

      // Add text
      const initial = name.substring(0, 1).toUpperCase();
      context.fillStyle = "#fff";
      context.font =
        '500 40px system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, sans-serif';
      context.textAlign = "center";
      context.textBaseline = "middle";
      context.fillText(initial, canvas.width / 2, canvas.height / 2);
    }
    draggedDataRef.current = canvas?.toDataURL("image/png");
    return canvas.toDataURL("image/png");
  };

  useEffect(() => {
    setSelectedNames(selectedItems);
  }, [selectedItems]);

  // Handle checkbox selection/deselection for individual items
  const handleCheckboxChange = (name: string) => {
    setSelectedItems((prevSelected) =>
      prevSelected.includes(name)
        ? prevSelected.filter((item) => item !== name)
        : [...prevSelected, name],
    );
  };

  // Handle the "Select All" checkbox change
  const handleSelectAllChange = () => {
    if (selectedItems.length === studentData.length) {
      // If all items are selected, deselect all
      setSelectedItems([]);
    } else {
      // Select all items
      setSelectedItems(studentData.map((item) => item.StudentName));
    }
  };

  // Check if all items are selected
  const isAllSelected = selectedItems.length === studentData.length;

  return (
    <Section
      heading="selectedShapeActions"
      className={clsx("zen-mode-transition", {
        "transition-left": zenModeEnabled,
      })}
      style={{ width: "300px", marginTop: "20px" }}
    >
      <Island
        className={"App-menu__left shdw ml-6px"}
        padding={2}
        style={{
          // we want to make sure this doesn't overflow so substracting 200
          // which is approximately height of zoom footer and top left menu items with some buffer
          // if active file name is displayed, subtracting 248 to account for its height
          maxHeight: `${
            appState?.height - (appState?.fileHandle ? 248 : 200)
          }px`,
          left: appState?.sidebarWrapper ? "15rem" : 0,
          width: "300px",
          paddingBottom: "0px",
        }}
      >
        <div className="name_picker">
          <div className="d-flex align-items-center justify-content-between">
            <p className="name_widget">Random Name Picker</p>
            <button
              className="close_button"
              onClick={() => {
                isOpenNamePicker && setIsOpenNamePicker(false);
              }}
            >
              x
            </button>
          </div>

          <div className="search-wrapper" style={{ marginBottom: "1.5rem" }}>
            <div className="search-container">
              <input
                style={{
                  boxShadow: "0 0 0 2px #efefef",
                  outline: "none !important",
                  textIndent: "0",
                }}
                type="text"
                className="form-control form-input search-input"
                placeholder="Search anything..."
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <div className="search-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <circle cx="11" cy="11" r="8"></circle>
                  <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
              </div>
            </div>
          </div>
          {(searchQuery ? filteredPeople : studentData).length !== 0 && (
            <div className="header-section">
              <label className="checkbox-wrapper select-all">
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={isAllSelected}
                  onChange={handleSelectAllChange}
                />
                <span className="select-all-text">Select All</span>
              </label>
              <div className="selected-count">
                {selectedItems.length} Selected
              </div>
            </div>
          )}
          <div style={{ maxHeight: "300px", overflow: "auto" }}>
            <table className="table mb-0">
              <tbody>
                {(searchQuery ? filteredPeople : studentData).length > 0 ? (
                  (searchQuery ? filteredPeople : studentData).map(
                    (student: any, index: number) => {
                      const studentPhoto = student.StudentPhoto?.Foto;
                      const studentName = student.StudentName || "";
                      const studentEmail = student.StudentEmailId || "";

                      return (
                        <tr
                          key={`stud-${index}`}
                          onClick={() =>
                            handleCheckboxChange(student.StudentName)
                          } // Trigger checkbox change on row click
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <td>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={`flexCheckDefault${index + 1}`}
                              style={{ marginTop: "13px" }}
                              checked={selectedItems.includes(
                                student.StudentName,
                              )}
                              onClick={(e) => e.stopPropagation()} // Prevent row click when checkbox is clicked
                              onChange={() =>
                                handleCheckboxChange(student.StudentName)
                              }
                            />
                          </td>

                          <td
                            style={{ paddingLeft: "0px", paddingRight: "0px" }}
                          >
                            <div>
                              <Avatar
                                color="transparent"
                                border="transparent"
                                onClick={() => false}
                              >
                                {studentPhoto && studentPhoto !== "null" ? (
                                  <img
                                    src={
                                      studentPhoto +
                                      process.env
                                        .REACT_APP_AZURE_STORAGE_SAS_TOKEN
                                    }
                                    alt={studentName}
                                    className="user_profile"
                                    style={{
                                      objectFit: "cover",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={
                                      avatarCache[studentName] ||
                                      generateInitialAvatar(studentName)
                                    }
                                    alt={studentName}
                                    className="user_profile"
                                  />
                                )}
                              </Avatar>
                            </div>
                          </td>
                          <td>
                            <p
                              className={"student_data"}
                              style={{ marginBottom: "0px" }}
                            >
                              {studentName}
                            </p>
                            <p
                              className={"student_data"}
                              style={{
                                fontSize: "11px",
                                marginBottom: "0px",
                                color: "#a5a2a2",
                              }}
                            >
                              {studentEmail}
                            </p>
                          </td>
                        </tr>
                      );
                    },
                  )
                ) : (
                  <tr>
                    <td
                      colSpan={3}
                      style={{ textAlign: "center", padding: "10px" }}
                    >
                      No records available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {(searchQuery ? filteredPeople : studentData).length !== 0 && (
            <div className="form mb-1 p-1">
              <button
                disabled={selectedItems.length === 0}
                className="generate-btn"
                onClick={handleNamePicker}
              >
                Pick a Random Name
              </button>
            </div>
          )}
        </div>
      </Island>
    </Section>
  );
};

export default NamePickerDropdownWithCheckbox;
