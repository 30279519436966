import throttle from "lodash.throttle";
import { PureComponent, ReactNode } from "react";
import { ExcalidrawImperativeAPI } from "../../types";
import { ErrorDialog } from "../../components/ErrorDialog";
import { APP_NAME, ENV, EVENT } from "../../constants";
import { ImportedDataState } from "../../data/types";
import {
  ExcalidrawElement,
  InitializedExcalidrawAudioElement,
  InitializedExcalidrawAvatarImageElement,
  InitializedExcalidrawImageElement,
  InitializedExcalidrawMermaidDiagramElement,
  InitializedExcalidrawTextWithStyleElement,
  InitializedExcalidrawVideoElement,
} from "../../element/types";
import {
  getElementMap,
  getSceneVersion,
} from "../../packages/excalidraw/index";
import { Collaborator, Gesture } from "../../types";
import { resolvablePromise, withBatchedUpdates } from "../../utils";
import {
  FILE_UPLOAD_MAX_BYTES,
  FIREBASE_STORAGE_PREFIXES,
  INITIAL_SCENE_UPDATE_TIMEOUT,
  LOAD_IMAGES_TIMEOUT,
  SCENE,
  SYNC_FULL_SCENE_INTERVAL_MS,
} from "../app_constants";
import {
  decryptAESGEM,
  generateCollaborationLinkData,
  getCollaborationLink,
  SocketUpdateDataSource,
  SOCKET_SERVER,
  getCollaborationLinkData,
} from "../data";
import {
  isSavedToFirebase,
  loadFilesFromFirebase,
  saveFilesToFirebase,
  saveToFirebase,
} from "../data/firebase";
import {
  importUsernameFromLocalStorage,
  saveUsernameToLocalStorage,
  STORAGE_KEYS,
} from "../data/localStorage";
import Portal from "./Portal";
import RoomDialog from "./RoomDialog";
import { createInverseContext } from "../../createInverseContext";
import { t } from "../../i18n";
import { UserIdleState } from "../../types";
import { IDLE_THRESHOLD, ACTIVE_THRESHOLD } from "../../constants";
import { trackEvent } from "../../analytics";
import { isInvisiblySmallElement } from "../../element";
import { apiPost, getStudents } from "../api";
import {
  ACV_API_URL,
  studentAccessRevokeMessage,
  studentSessionEndMessage,
} from "../api/constant";
import {
  isInitializedAudioElement,
  isInitializedAvatarImageElement,
  isInitializedFormulaElement,
  isInitializedImageElement,
  isInitializedMermaidDiagramElement,
  isInitializedTextWithStylesElement,
  isInitializedVideoElement,
} from "../../element/typeChecks";
import {
  encodeFilesForUpload,
  FileManager,
  updateStaleAudioStatuses,
  updateStaleFormulaStatuses,
  updateStaleImageStatuses,
  updateStaleTextWithStylesStatuses,
  updateStaleVideoStatuses,
} from "../data/FileManager";
import { AbortError } from "../../errors";
import { sendMessage } from "../api/graph-api";
import { addCollaborationLink, deleteCollaborationLink } from "../api/userAPI";
import axios from "axios";
import { getUserInfo } from "../api/getuserInfo";
import FlexibleGroupDialog from "./FlexibleGroupDialog";
import { getCollaborationDetails } from "../../utils/collaboration";
import {
  getOrganizationUsers,
  getSharedWithMeWorkspace,
  getSharedWithMeWorkspaceByWorkspaceID,
  updateActivePage,
  updateEmailsInSharedWorkspace,
  updateSharedWorkspace,
} from "../api/storeElementData";
import ConfirmDialog from "../../components/ConfirmDialog";
import { StudentMessageType } from "../../components/contexts/StudentMessage.context";
import captchScreenImage from "../../data/getImage";

interface CollabState {
  modalIsShown: boolean;
  errorMessage: string;
  username: string;
  userState: UserIdleState;
  activeRoomLink: string;
  isCollaborating: boolean;
  isCollaboratingWithFlexibleGroups: boolean;
  isShowFlexibleGroupModel: boolean;
  isOpenModal: boolean;
  organizationUsers: User[];
  show: boolean;
  filteredEmails: string[];
  isPublic: boolean;
}

export interface User {
  includes(email: string): boolean | undefined;
  userID: string; // or number, depending on your data type
  email: string;
  firstName: string;
  photo: string;
  lastName: string;
  role: string;
}

type CollabInstance = InstanceType<typeof CollabWrapper>;

export interface CollabAPI {
  /** function so that we can access the latest value from stale callbacks */
  isCollaborating: () => boolean;
  isCollaboratingWithFlexibleGroups: () => boolean;
  username: CollabState["username"];
  userState: CollabState["userState"];
  onPointerUpdate: CollabInstance["onPointerUpdate"];
  initializeSocketClient: CollabInstance["initializeSocketClient"];
  onCollabButtonClick: CollabInstance["onCollabButtonClick"];
  onFlexibleGroupButtonClick: CollabInstance["onFlexibleGroupButtonClick"];
  onStartFlexibleGroupCollaboration: CollabInstance["onStartFlexibleGroupCollaboration"];
  broadcastElements: CollabInstance["broadcastElements"];
  fetchImageFilesFromFirebase: CollabInstance["fetchImageFilesFromFirebase"];
  fetchVideoFilesFromFirebase: CollabInstance["fetchVideoFilesFromFirebase"];
  fetchAudioFilesFromFirebase: CollabInstance["fetchAudioFilesFromFirebase"];
  fetchFormulaFilesFromFirebase: CollabInstance["fetchFormulaFilesFromFirebase"];
  fetchTextwithStylesFilesFromFirebase: CollabInstance["fetchTextwithStylesFilesFromFirebase"];
  saveAndRetriveStudData: CollabInstance["saveAndRetriveStudData"];
}

type ReconciledElements = readonly ExcalidrawElement[] & {
  _brand: "reconciledElements";
};

interface Props {
  excalidrawAPI: ExcalidrawImperativeAPI;
  isAssignedPage: boolean;
  setStudentMessage?: (val: StudentMessageType) => void;
}

const {
  Context: CollabContext,
  Consumer: CollabContextConsumer,
  Provider: CollabContextProvider,
} = createInverseContext<{ api: CollabAPI | null }>({
  api: null,
});

export { CollabContext, CollabContextConsumer };

class CollabWrapper extends PureComponent<Props, CollabState> {
  portal: Portal;
  fileManager: FileManager;
  excalidrawAPI: Props["excalidrawAPI"];
  // isCollaborating: boolean = false;
  activeIntervalId: number | null;
  idleTimeoutId: number | null;

  private socketInitializationTimer?: NodeJS.Timeout;
  private lastBroadcastedOrReceivedSceneVersion: number = -1;
  private collaborators = new Map<string, Collaborator>();

  constructor(props: Props) {
    super(props);
    this.state = {
      modalIsShown: false,
      errorMessage: "",
      username: importUsernameFromLocalStorage() || "",
      userState: UserIdleState.ACTIVE,
      activeRoomLink: "",
      isCollaborating: false,
      isCollaboratingWithFlexibleGroups: false,
      isShowFlexibleGroupModel: false,
      isOpenModal: false,
      organizationUsers: [],
      show: false,
      filteredEmails: [],
      isPublic: false,
    };
    this.portal = new Portal(this);
    this.fileManager = new FileManager({
      getFiles: async (fileIds) => {
        const { roomId, roomKey } = this.portal;
        if (!roomId || !roomKey) {
          throw new AbortError();
        }

        return loadFilesFromFirebase(`files/rooms/${roomId}`, roomKey, fileIds);
      },
      saveFiles: async ({ addedFiles }) => {
        const { roomId, roomKey } = this.portal;
        if (!roomId || !roomKey) {
          throw new AbortError();
        }

        return saveFilesToFirebase({
          prefix: `${FIREBASE_STORAGE_PREFIXES.collabFiles}/${roomId}`,
          files: await encodeFilesForUpload({
            files: addedFiles,
            encryptionKey: roomKey,
            maxBytes: FILE_UPLOAD_MAX_BYTES,
          }),
        });
      },
    });
    this.excalidrawAPI = props?.excalidrawAPI;
    this.activeIntervalId = null;
    this.idleTimeoutId = null;
  }

  getCollaborationDetails_ = async () => {
    try {
      const isFlexibleGroup = await getCollaborationDetails();
      if (isFlexibleGroup) {
        this.setState({
          isCollaboratingWithFlexibleGroups: isFlexibleGroup,
        });
      }
    } catch (error) {
      console.log("error-from-getCollaborationDetails", error);
    }
  };

  componentDidMount() {
    // window.addEventListener(EVENT.BEFORE_UNLOAD, this.beforeUnload);
    window.addEventListener(EVENT.UNLOAD, this.onUnload);

    if (
      process.env.NODE_ENV === ENV.TEST ||
      process.env.NODE_ENV === ENV.DEVELOPMENT
    ) {
      window.collab = window.collab || ({} as Window["collab"]);
      Object.defineProperties(window, {
        collab: {
          configurable: true,
          value: this,
        },
      });
    }
    this.getActiveCollabLinks();
    const isMyWorkSpaceData = localStorage.getItem("isMyWorkSpace");
    const url = new URL(window.location.href);
    const searchParamsLessonId = url.searchParams.get("lessonId");
    // Get hash (fragment identifier)
    const room = url.hash.replace("#room=", ""); // Removes "#room=" if needed
    if (searchParamsLessonId === "MyWorkSpace" && room) {
      localStorage.setItem("isMyWorkSpace", "true");
      this.openPortal(false);
    }
    window.Portal = this.Portal.bind(this);
    if (isMyWorkSpaceData === "true") {
      this.getOrganizationStudentAndTeachers();
      window.publicOpenPortal = this.publicOpenPortal.bind(this);
      window.publicClosePortal = this.publicClosePortal.bind(this);
      window.saveAndRetriveStudData = this.saveAndRetriveStudData.bind(this);
    }
  }

  componentWillUnmount() {
    // window.removeEventListener(EVENT.BEFORE_UNLOAD, this.beforeUnload);
    window.removeEventListener(EVENT.UNLOAD, this.onUnload);
    window.removeEventListener(EVENT.POINTER_MOVE, this.onPointerMove);
    window.removeEventListener(
      EVENT.VISIBILITY_CHANGE,
      this.onVisibilityChange,
    );
    if (this.activeIntervalId) {
      window.clearInterval(this.activeIntervalId);
      this.activeIntervalId = null;
    }
    if (this.idleTimeoutId) {
      window.clearTimeout(this.idleTimeoutId);
      this.idleTimeoutId = null;
    }
  }

  getActiveCollabLinks = () => {
    axios
      .get(
        `${process.env.REACT_APP_EP_URL_WHITEBOARD}/get-all-collaboration-link`,
      )
      .then(async (res) => {
        const lessons = res.data.result;
        const currentLessonId =
          new URLSearchParams(window.location.search)
            .get("lessonId")
            ?.replace(/\//g, "") || "";
        const index = lessons.findIndex(
          (data: { id: string }) => data.id === currentLessonId,
        );

        if (!this.contextValue) {
          this.contextValue = {} as CollabAPI;
        }

        if (index === -1) {
          this.setState({
            activeRoomLink: "",
            isCollaborating: false,
          });
          localStorage.removeItem("isCollaborating");
          localStorage.removeItem("isCollaboratingWithFlexibleGroups");
        } else {
          const currentLesson = lessons[index];
          const roomLinkData = getCollaborationLinkData(
            currentLesson.collaborationLink,
          );
          const isMyWorkSpaceData = localStorage.getItem("isMyWorkSpace");

          this.setState({
            activeRoomLink:
              isMyWorkSpaceData !== "true" && currentLesson.collaborationLink,
            isCollaborating: true,
            isCollaboratingWithFlexibleGroups: currentLesson.isFlexibleGroup,
          });
          localStorage.setItem("isCollaborating", "true");
          localStorage.setItem(
            "isCollaboratingWithFlexibleGroups",
            currentLesson.isFlexibleGroup,
          );
          const isMyWorkSpace = localStorage.getItem("isMyWorkSpace");
          if (isMyWorkSpace === "true") {
            const selectedWorkspaceCardID = localStorage.getItem(
              "selectedWorkspaceCard",
            );
            const user = await getUserInfo();
            const res = await getSharedWithMeWorkspace(user.mail);
            const inviteWorkspaceMembers = res.data.map((workspace: any) => {
              return workspace.InviteWorkspaceMembers[0]?.roomDetails || null;
            });

            const matchingWorkspaces = inviteWorkspaceMembers?.filter(
              (item: { workspaceId: string }) =>
                item?.workspaceId === selectedWorkspaceCardID,
            );
            if (matchingWorkspaces[0]?.isCollaboration) {
              return await this.initializeSocketClient(
                {
                  roomId: matchingWorkspaces[0].roomId,
                  roomKey: matchingWorkspaces[0].roomKey,
                },
                true,
              );
            }
          } else {
            return await this.initializeSocketClient(roomLinkData);
          }
        }
      })
      .catch((err) => {
        console.log(err, err.response);
      });
  };

  getOrganizationStudentAndTeachers = async () => {
    const slug = new URLSearchParams(window.location.search).get("slug");
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const users = await getOrganizationUsers(slug, user?.mail); // Ensure this function returns the expected data
    this.setState({ organizationUsers: users.data });
  };

  private onUnload = () => {
    const lessonId =
      new URLSearchParams(window.location.search)
        .get("lessonId")
        ?.replace(/\//g, "") || "";
    // this.portal.socket!.emit("onUnload", lessonId);

    //this is from beforeunload because we don't need leave site popup
    //and we remove that then it take a time to save data in firebase
    // const syncableElements = this.getSyncableElements(
    //   this.getSceneElementsIncludingDeleted(),
    // );
    // if (
    //   this.state.isCollaborating &&
    //   !isSavedToFirebase(this.portal, syncableElements)
    // ) {
    //   // this won't run in time if user decides to leave the site, but
    //   //  the purpose is to run in immediately after user decides to stay
    //   this.saveCollabRoomToFirebase(syncableElements);

    //   localStorage.removeItem("collaborating");
    //   localStorage.removeItem("roomLinkData");
    //   localStorage.removeItem("isCollaborating");
    // }

    // if (this.state.isCollaborating || this.portal.roomId) {
    //   try {
    //     localStorage?.setItem(
    //       STORAGE_KEYS.LOCAL_STORAGE_KEY_COLLAB_FORCE_FLAG,
    //       JSON.stringify({
    //         timestamp: Date.now(),
    //         room: this.portal.roomId,
    //       }),
    //     );
    //   } catch {}
    // }
  };

  // Function to extract value before comma from the "room" parameter
  private extractRoomValue = (url: string) => {
    const params = new URLSearchParams(url.split("#")[1]);
    const room = params.get("room");
    if (room) {
      const roomValue = room.split(",")[0];
      return roomValue;
    }

    return null; // If "room" parameter is not found
  };

  // private beforeUnload = withBatchedUpdates((event: BeforeUnloadEvent) => {
  //   const syncableElements = this.getSyncableElements(
  //     this.getSceneElementsIncludingDeleted(),
  //   );
  //   if (
  //     this.state.isCollaborating &&
  //     !isSavedToFirebase(this.portal, syncableElements)
  //   ) {
  //     // this won't run in time if user decides to leave the site, but
  //     //  the purpose is to run in immediately after user decides to stay
  //     this.saveCollabRoomToFirebase(syncableElements);

  //     // event.preventDefault();
  //     // NOTE: modern browsers no longer allow showing a custom message here
  //     event.returnValue = "";
  //     localStorage.removeItem("collaborating");
  //     localStorage.removeItem("roomLinkData");
  //     localStorage.removeItem("isCollaborating");
  //   }

  //   if (this.state.isCollaborating || this.portal.roomId) {
  //     try {
  //       localStorage?.setItem(
  //         STORAGE_KEYS.LOCAL_STORAGE_KEY_COLLAB_FORCE_FLAG,
  //         JSON.stringify({
  //           timestamp: Date.now(),
  //           room: this.portal.roomId,
  //         }),
  //       );
  //     } catch {}
  //   }
  // });

  saveCollabRoomToFirebase = async (
    syncableElements: ExcalidrawElement[] = this.getSyncableElements(
      this.excalidrawAPI.getSceneElementsIncludingDeleted(),
    ),
  ) => {
    try {
      await saveToFirebase(this.portal, syncableElements);
    } catch (error) {
      console.error(error);
    }
  };

  public Portal() {
    this.destroySocketClient();
  }

  public publicOpenPortal(isGroup: boolean) {
    return this.openPortal(isGroup);
  }

  private storeWorkspaceSharedUsers = async () => {
    const selectedWorkspaceCardID = localStorage.getItem(
      "selectedWorkspaceCard",
    );
    const res = await getSharedWithMeWorkspaceByWorkspaceID(
      selectedWorkspaceCardID as string,
    );
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    let arr = [] as any;
    res?.data?.length &&
      res.data[0]?.userEmail.filter((user_: any) => {
        if (user_.email !== user.mail) {
          arr.push(user_);
        }
      });
    const newUsers =
      (res?.data?.length &&
        res.data[0]?.userEmail.filter(
          (user_: any) => user_.email !== user.mail,
        )) ||
      [];

    // Retrieve existing data from localStorage
    const existingData = JSON.parse(
      localStorage.getItem("worspaceUsersWithShared") || "[]",
    );

    // Find the entry for the current workspace
    const existingWorkspaceData = existingData.find(
      (entry: any) => entry.selectedWorkspaceCardID === selectedWorkspaceCardID,
    );

    if (existingWorkspaceData) {
      // Merge emails, ensuring no duplicates
      existingWorkspaceData.users = [
        ...existingWorkspaceData.users.filter(
          (user: any) =>
            !newUsers.some((newUser: any) => newUser.email === user.email),
        ),
        ...newUsers,
      ];
    } else {
      // Add a new workspace entry if it doesn't exist
      existingData.push({
        selectedWorkspaceCardID,
        users: newUsers,
      });
    }

    // Save updated data to localStorage
    localStorage.setItem(
      "worspaceUsersWithShared",
      JSON.stringify(existingData),
    );
  };

  private openPortal = async (isGroup: boolean, addParticipants?: boolean) => {
    const appState = this.excalidrawAPI.getAppState();
    const lessonId =
      new URLSearchParams(window.location.search)
        .get("lessonId")
        ?.replace(/\//g, "") || "";

    const selectedWorkspaceCardID = localStorage.getItem(
      "selectedWorkspaceCard",
    );
    const isMyWorkSpace = localStorage.getItem("isMyWorkSpace");
    if (isMyWorkSpace === "true") {
      const data = JSON.parse(
        localStorage.getItem("worspaceUsersWithShared") || "[]",
      );
      // const slug = new URLSearchParams(window.location.search).get("slug");

      window.localStorage.setItem("startCollabLessonId", lessonId);
      await updateActivePage({
        link: window.location.href,
        activePage: appState.currentPage,
      });
      localStorage.setItem("ACTIVE_PAGE", String(appState.currentPage));

      const user = await getUserInfo();

      trackEvent("share", "room creation");
      window.parent.postMessage(
        { type: "IS_START_COLLABORATION", session: true, lessonId: lessonId },
        `${process.env.REACT_APP_PARENT_APP}`,
      );
      if (data.length) {
        data.map((value: any) => {
          this.saveAndRetriveStudData(
            Object.values({
              ...value.users,
              selectedWorkspaceCardID: selectedWorkspaceCardID,
            }),
            lessonId,
          );
        });
      } else {
        const res = await getSharedWithMeWorkspaceByWorkspaceID(
          selectedWorkspaceCardID as string,
        );
        const user = JSON.parse(localStorage.getItem("user") || "{}");
        const students =
          res?.data?.length &&
          res?.data[0]?.userEmail.filter((student_: any) => {
            if (student_.email === user.mail) {
              return {
                ...student_[0],
                selectedWorkspaceCardID: selectedWorkspaceCardID,
              };
            }
          });
        this.saveAndRetriveStudData(students, lessonId);
      }
      const res = await getSharedWithMeWorkspace(user.mail);
      const inviteWorkspaceMembers = res?.data?.map((workspace: any) => {
        return workspace.InviteWorkspaceMembers[0]?.roomDetails || null;
      });

      const matchingWorkspaces = inviteWorkspaceMembers?.filter(
        (item: { workspaceId: string }) =>
          item?.workspaceId === selectedWorkspaceCardID,
      );
      // window.handleWorkspaceData();
      if (
        matchingWorkspaces?.length &&
        matchingWorkspaces[0]?.isCollaboration
      ) {
        this.storeWorkspaceSharedUsers();
        return await this.initializeSocketClient(
          {
            roomId: matchingWorkspaces[0].roomId,
            roomKey: matchingWorkspaces[0].roomKey,
          },
          addParticipants,
        );
      } else {
        this.storeWorkspaceSharedUsers();
        return await this.initializeSocketClient(null, addParticipants);
      }
    } else {
      const appState = this.excalidrawAPI.getAppState();
      const lessonId =
        new URLSearchParams(window.location.search)
          .get("lessonId")
          ?.replace(/\//g, "") || "";

      const slug = new URLSearchParams(window.location.search).get("slug");

      window.localStorage.setItem("startCollabLessonId", lessonId);
      await updateActivePage({
        link: window.location.href,
        activePage: appState.currentPage,
      });
      localStorage.setItem("ACTIVE_PAGE", String(appState.currentPage));

      const user = await getUserInfo();

      trackEvent("share", "room creation");
      getStudents(
        `${ACV_API_URL}/api/record/get-student-record-for-whiteBaord/${lessonId}?slug=${slug}`,
      )
        .then(async (res) => {
          const studList = res?.result;
          const studentsList = localStorage.getItem("students")
            ? JSON.parse(localStorage.getItem("students") || "")
            : [];
          let studs: any = [];
          if (studentsList) {
            studs = studentsList ? studentsList : [];
          }
          const students =
            studList.length > 0 &&
            (await studList.map((stud: any) => {
              const student =
                Array.isArray(studs) &&
                studs.find(
                  (data: { studEmail: string }) =>
                    data.studEmail === stud.StudentEmailId,
                );
              return {
                studEmail: stud.StudentEmailId,
                studName: stud.StudentName,
                photo: `${stud.StudentPhoto.Foto}?${res?.SASToken}`,
                isWhiteboard: student
                  ? student.isWhiteboard !== 2
                    ? student.isWhiteboard
                    : true
                  : true,
                idle: false,
              };
            }));
          // Get the value before comma from the "room" parameter
          const roomId = this.extractRoomValue(window.location.href);
          const slug = new URLSearchParams(window.location.search).get("slug");
          // students?.length > 0 &&
          addCollaborationLink({
            lessonId: lessonId,
            activeRoomLink: window.location.href,
            collaboratorId: user?.mail,
            roomId: roomId ? roomId : "",
            isFlexibleGroup: isGroup,
            slug: slug,
            activePage: appState.currentPage,
          });
          this.saveAndRetriveStudData(students, lessonId);
          window.localStorage.setItem("S", "false");
          window.localStorage.setItem("SAS", res?.SASToken);
          window.localStorage.setItem("students", JSON.stringify(students));
          // this is for add green line in lesson (ACV) at the time of start the whiteboard session
          window.parent.postMessage(
            {
              type: "IS_START_COLLABORATION",
              session: true,
              lessonId: lessonId,
            },
            `${process.env.REACT_APP_PARENT_APP}`,
          );
        })
        .catch((err) => {
          console.log("err-------------", err);
        });
      return await this.initializeSocketClient(null);
    }
  };

  getStudentFromLocal = () => {
    return localStorage.getItem("students")
      ? JSON.parse(localStorage.getItem("students") || "")
      : [];
  };

  updateStudentFromLocal = () => {
    const studData = this.getStudentFromLocal();
    if (studData.length > 0) {
      const students = studData.map((stud: any) => {
        stud.isWhiteboard = 2;
        return stud;
      });
      localStorage.setItem("students", JSON.stringify(students));
    }
  };

  public publicClosePortal(workspaceID?: string) {
    return this.closePortal(workspaceID);
  }

  private closePortal: (workspaceID?: string) => void = async (
    workspaceID?: string,
  ) => {
    this.loadImageFiles.cancel();
    this.updateStudentFromLocal();
    this.setState({ isCollaborating: false });
    localStorage.removeItem("collaborating");
    localStorage.removeItem("roomLinkData");
    localStorage.removeItem("isCollaborating");
    localStorage.removeItem("isCollaboratingWithFlexibleGroups");
    localStorage.removeItem("startCollabLessonId");
    localStorage.removeItem("activeRoomLink");

    this.saveCollabRoomToFirebase();
    const isMyWorkSpaceData = localStorage.getItem("isMyWorkSpace");
    if (isMyWorkSpaceData === "true") {
      // Fetching data from localStorage and parsing it
      let data = JSON.parse(
        localStorage.getItem("worspaceUsersWithShared") || "[]",
      );
      const lessonId =
        new URLSearchParams(window.location.search)
          .get("lessonId")
          ?.replace(/\//g, "") || "";
      const selectedWorkspaceCardID = localStorage.getItem(
        "selectedWorkspaceCard",
      );
      const res = await getSharedWithMeWorkspaceByWorkspaceID(
        selectedWorkspaceCardID as string,
      );
      if (res?.data?.length) {
        const user = JSON.parse(localStorage.getItem("user") || "{}");
        if (res.data[0].ownerUserEmail === user.mail) {
          // Set `isEnabled` to false for all users
          data.forEach((workspace: any) => {
            workspace.users.forEach((user: any) => {
              user.isEnabled = false;
              user.selectedWorkspaceCardID = selectedWorkspaceCardID;
            });
          });
          if (data.length) {
            data.map((value: any) => {
              this.saveAndRetriveStudData(
                Object.values({
                  ...value.users,
                }),
                lessonId,
              );
            });
          }
          const data_ = await getSharedWithMeWorkspaceByWorkspaceID(
            selectedWorkspaceCardID as string,
          );
          const workspaceData = data_?.data[0];
          if (workspaceData.status === "Private") {
            await updateEmailsInSharedWorkspace(
              selectedWorkspaceCardID,
              data[0]?.users,
            );
          }
          !workspaceID &&
            (await updateSharedWorkspace(selectedWorkspaceCardID, {}));
        }
      }
      if (workspaceID) {
        await updateSharedWorkspace(workspaceID, {});
      } else {
        localStorage.removeItem("worspaceUsersWithShared");
        window.parent.postMessage(
          {
            type: "IS_START_COLLABORATION",
            session: false,
            lessonId: lessonId,
          },
          `${process.env.REACT_APP_PARENT_APP}`,
        );
        this.props.setStudentMessage?.({ ...studentSessionEndMessage });

        window.history.pushState(
          {},
          APP_NAME,
          `${window.location.origin}/?slug=${new URLSearchParams(
            window.location.search,
          ).get("slug")}&lessonId=${lessonId}`,
        );
        this.destroySocketClient();
        deleteCollaborationLink(lessonId);
        trackEvent("share", "room closed");
      }
    } else {
      this.setState({ isOpenModal: true });
    }
  };

  private destroySocketClient = (opts?: { isUnload: boolean }) => {
    if (!opts?.isUnload) {
      this.collaborators = new Map();
      this.excalidrawAPI.updateScene({
        collaborators: this.collaborators,
      });
      this.setState({
        activeRoomLink: "",
      });
    }

    this.portal.close();
  };

  initializeSocketClient = async (
    existingRoomLinkData: null | { roomId: string; roomKey: string },
    addParticipants?: boolean,
  ): Promise<ImportedDataState | null> => {
    const isMyWorkSpaceData = localStorage.getItem("isMyWorkSpace");
    if (this.portal.socket) {
      return null;
    }

    let roomId;
    let roomKey;
    let isNewTab = false;
    const selectedWorkspaceCardID = localStorage.getItem(
      "selectedWorkspaceCard",
    );
    const currentUrl = window.location.href;

    // Check if the URL contains "/#room="
    const roomIndex = currentUrl.indexOf("/#room=");
    if (roomIndex !== -1) {
      // Extract the part starting from "/#room=" to the end
      const part1 = currentUrl.slice(0, roomIndex);

      const queryParams = new URLSearchParams(part1);
      isNewTab = queryParams.has("W");
    } else {
      const queryParams = new URLSearchParams(currentUrl);
      isNewTab = queryParams.has("W");
    }
    // Get the URL hash
    const urlHash = window.location.hash; // "#room=df2d98dbd05560c7c5ec,EG5eMfMrwRidzzHO08HoiA&selectedWorkspaceCardID=2b06c4fb-fd99-4973-a963-d6c3cfc07aa6"

    // Extract the room parameter from the URL hash
    const roomParam = new URLSearchParams(urlHash.replace("#", "")).get("room");

    let roomIdFromUrl = "";
    let roomKeyFromUrl = "";

    if (roomParam) {
      // Split the room value into roomId and roomKey
      const roomValues = roomParam.split(",");
      roomIdFromUrl = roomValues[0] || ""; // Default to empty if no value is present
      roomKeyFromUrl = roomValues[1] || ""; // Default to empty if no value is present
    }
    if (existingRoomLinkData) {
      console.log("ROOM LINK DATA IS EXIST");
      ({ roomId, roomKey } = existingRoomLinkData);

      // Store in localStorage
      localStorage.setItem(
        "roomLinkData",
        JSON.stringify({
          roomId: roomIdFromUrl ? roomIdFromUrl : roomId,
          roomKey: roomKeyFromUrl ? roomKeyFromUrl : roomKey,
        }),
      );
    } else {
      console.log("ROOM LINK DATA IS NOT EXIST");
      if (isNewTab) return null;

      ({ roomId, roomKey } = await generateCollaborationLinkData());
      window.history.pushState(
        {},
        APP_NAME,
        getCollaborationLink({ roomId, roomKey }),
      );
      // Get the URL hash
      const urlHash = window.location.hash; // "#room=df2d98dbd05560c7c5ec,EG5eMfMrwRidzzHO08HoiA&selectedWorkspaceCardID=2b06c4fb-fd99-4973-a963-d6c3cfc07aa6"

      // Extract the room parameter from the URL hash
      const roomParam = new URLSearchParams(urlHash.replace("#", "")).get(
        "room",
      );

      let roomIdFromUrl = "";
      let roomKeyFromUrl = "";

      if (roomParam) {
        // Split the room value into roomId and roomKey
        const roomValues = roomParam.split(",");
        roomIdFromUrl = roomValues[0] || ""; // Default to empty if no value is present
        roomKeyFromUrl = roomValues[1] || ""; // Default to empty if no value is present
      }
      localStorage.setItem(
        "roomLinkData",
        JSON.stringify({
          roomId: roomIdFromUrl ? roomIdFromUrl : roomId,
          roomKey: roomKeyFromUrl ? roomKeyFromUrl : roomKey,
        }),
      );
      const isCollaboration = true;
      if (addParticipants) {
        const selectedWorkspaceCardID = localStorage.getItem(
          "selectedWorkspaceCard",
        );
        await updateSharedWorkspace(selectedWorkspaceCardID, {
          roomId,
          roomKey,
          isCollaboration,
          workspaceId: selectedWorkspaceCardID,
        });
      }
    }

    const scenePromise = resolvablePromise<ImportedDataState | null>();

    this.setState({ isCollaborating: true });
    // this.state.isCollaborating = true;
    localStorage.setItem("isCollaborating", "true");

    const { default: socketIOClient }: any = await import(
      /* webpackChunkName: "socketIoClient" */ "socket.io-client"
    );

    this.portal.open(
      socketIOClient(SOCKET_SERVER),
      roomIdFromUrl ? roomIdFromUrl : roomId,
      roomKeyFromUrl ? roomKeyFromUrl : roomKey,
    );

    if (existingRoomLinkData) {
      if (isMyWorkSpaceData !== "true" && this.excalidrawAPI)
        this.excalidrawAPI.resetScene();

      try {
        const elements: any = [];
        if (elements) {
          scenePromise.resolve({
            elements,
            scrollToContent: true,
          });
        }
      } catch (error) {
        // log the error and move on. other peers will sync us the scene.
        console.error(error);
      }
    } else {
      if (this.excalidrawAPI) {
        const elements = this.excalidrawAPI.getSceneElements();
        // remove deleted elements from elements array & history to ensure we don't
        // expose potentially sensitive user data in case user manually deletes
        // existing elements (or clears scene), which would otherwise be persisted
        // to database even if deleted before creating the room.
        this.excalidrawAPI.history.clear();
        this.excalidrawAPI.updateScene({
          elements,
          commitToHistory: true,
        });
      }
    }

    // fallback in case you're not alone in the room but still don't receive
    // initial SCENE_UPDATE message
    this.socketInitializationTimer = setTimeout(() => {
      this.initializeSocket();
      scenePromise.resolve(null);
    }, INITIAL_SCENE_UPDATE_TIMEOUT);

    this.portal.socket!.on("store-data", async (data: any) => {
      const user = JSON.parse(localStorage.getItem("user") || "{}");

      if (data && Array.isArray(data.studentData)) {
        const studData = data?.studentData?.find((stud: any) => {
          return stud.studEmail === user.mail;
        });

        window.localStorage.setItem(
          "students",
          JSON.stringify(data?.studentData),
        );
        if (typeof studData === "object" && studData?.isWhiteboard === false) {
          window.toggleZoomInZoomOut(false);
          this.props.setStudentMessage?.({ ...studentAccessRevokeMessage });

          document.getElementById("rerender")?.click();
          const isModalOpen = document
            .getElementsByClassName("modal fade Karla")[0]
            .classList.contains("show");

          if (!isModalOpen) {
            Array.from(
              document.getElementsByClassName(
                "acv",
              ) as HTMLCollectionOf<HTMLElement>,
            )[0].style.opacity = "0";
            document.getElementById("errModalBtn")?.click();
          } else {
            Array.from(
              document.getElementsByClassName(
                "acv",
              ) as HTMLCollectionOf<HTMLElement>,
            )[0].style.opacity = "1";
          }
        } else if (
          typeof studData === "object" &&
          studData?.isWhiteboard === 2
        ) {
          window.parent.postMessage(
            {
              type: "IS_START_COLLABORATION",
              session: false,
            },
            `${process.env.REACT_APP_PARENT_APP}`,
          );
          this.props.setStudentMessage?.({ ...studentSessionEndMessage });

          document.getElementById("rerender")?.click();
          const isModalOpen = document
            .getElementsByClassName("modal fade Karla")[0]
            .classList.contains("show");

          if (!isModalOpen) {
            Array.from(
              document.getElementsByClassName(
                "acv",
              ) as HTMLCollectionOf<HTMLElement>,
            )[0].style.opacity = "0";
            document.getElementById("errModalBtn")?.click();
          } else {
            Array.from(
              document.getElementsByClassName(
                "acv",
              ) as HTMLCollectionOf<HTMLElement>,
            )[0].style.opacity = "1";
          }
        } else if (
          typeof studData === "object" &&
          studData?.isWhiteboard === true
        ) {
          window.toggleZoomInZoomOut(true);
          const isModalOpen = document
            .getElementsByClassName("modal fade Karla")[0]
            .classList.contains("show");

          Array.from(
            document.getElementsByClassName(
              "acv",
            ) as HTMLCollectionOf<HTMLElement>,
          )[0].style.opacity = "1";

          if (isModalOpen) {
            document.getElementById("errModalBtn")?.click();
          }
        }
      }
    });

    // All socket listeners are moving to Portal
    this.portal.socket!.on(
      "client-broadcast",
      async (encryptedData: ArrayBuffer, iv: Uint8Array) => {
        if (!this.portal.roomKey) {
          return;
        }
        const decryptedData = await decryptAESGEM(
          encryptedData,
          this.portal.roomKey,
          iv,
        );

        switch (decryptedData.type) {
          case "INVALID_RESPONSE":
            return;
          case SCENE.INIT: {
            if (!this.portal.socketInitialized) {
              this.initializeSocket();
              const remoteElements = decryptedData.payload.elements;
              const currentPage = localStorage.getItem("USER_CURRENT_PAGE");
              const element = JSON.parse(
                JSON.stringify(decryptedData.payload.elements),
              );
              const currentPageElement = element.filter(
                (data: { page: string }) =>
                  Number(data.page) === Number(currentPage),
              );
              const reconciledElements = this.reconcileElements(
                currentPageElement,
                this.excalidrawAPI,
              );
              this.handleRemoteSceneUpdate(reconciledElements, {
                init: true,
              });
              // noop if already resolved via init from firebase
              scenePromise.resolve({
                elements: reconciledElements,
                scrollToContent: true,
              });
              const isMyWorkSpaceData = localStorage.getItem("isMyWorkSpace");
              if (isMyWorkSpaceData === "true") {
                localStorage.setItem(
                  STORAGE_KEYS.LOCAL_STORAGE_WORKSPACE_ELEMENTS,
                  JSON.stringify(
                    currentPageElement ? currentPageElement : element,
                  ),
                );
                window.parent.postMessage(
                  {
                    type: "WORKSPACE_ELEMENTS",
                    personalWorkSpaceElements: JSON.stringify(
                      currentPageElement ? currentPageElement : element,
                    ),
                  },
                  `${`${process.env.REACT_APP_PARENT_APP}`}`,
                );
                const appState = JSON.parse(
                  localStorage.getItem("acv-state") || "{}",
                );
              } else {
                localStorage.setItem(
                  STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS,
                  JSON.stringify(
                    currentPageElement ? currentPageElement : element,
                  ),
                );
              }
            } else {
              const isMyWorkSpaceData = localStorage.getItem("isMyWorkSpace");
              if (isMyWorkSpaceData === "true") {
                localStorage.setItem(
                  STORAGE_KEYS.LOCAL_STORAGE_WORKSPACE_ELEMENTS,
                  JSON.stringify(decryptedData.payload.elements),
                );
                window.parent.postMessage(
                  {
                    type: "WORKSPACE_ELEMENTS",
                    personalWorkSpaceElements: JSON.stringify(
                      decryptedData.payload.elements,
                    ),
                  },
                  `${`${process.env.REACT_APP_PARENT_APP}`}`,
                );
                const appState = JSON.parse(
                  localStorage.getItem("acv-state") || "{}",
                );
              } else {
                localStorage.setItem(
                  STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS,
                  JSON.stringify(decryptedData.payload.elements),
                );
              }
            }
            break;
          }
          case SCENE.UPDATE:
            const element = JSON.parse(
              JSON.stringify(decryptedData.payload.elements),
            );
            if (element) {
              const currentPage = localStorage.getItem("USER_CURRENT_PAGE");
              const currentPageElement = element.filter(
                (data: { page: string }) =>
                  Number(data.page) === Number(currentPage),
              );
              this.handleRemoteSceneUpdate(
                this.reconcileElements(
                  currentPageElement
                    ? currentPageElement
                    : decryptedData.payload.elements,
                  this.excalidrawAPI,
                ),
              );
              const isMyWorkSpaceData = localStorage.getItem("isMyWorkSpace");
              if (isMyWorkSpaceData === "true") {
                localStorage.setItem(
                  STORAGE_KEYS.LOCAL_STORAGE_WORKSPACE_ELEMENTS,
                  JSON.stringify(
                    currentPageElement ? currentPageElement : element,
                  ),
                );
                window.parent.postMessage(
                  {
                    type: "WORKSPACE_ELEMENTS",
                    personalWorkSpaceElements: JSON.stringify(
                      currentPageElement ? currentPageElement : element,
                    ),
                  },
                  `${`${process.env.REACT_APP_PARENT_APP}`}`,
                );
                const appState = JSON.parse(
                  localStorage.getItem("acv-state") || "{}",
                );
              } else {
                localStorage.setItem(
                  STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS,
                  JSON.stringify(
                    currentPageElement ? currentPageElement : element,
                  ),
                );
              }
            }

            break;
          case "MOUSE_LOCATION": {
            const {
              pointer,
              button,
              username,
              selectedElementIds,
            } = decryptedData.payload;
            const socketId: SocketUpdateDataSource["MOUSE_LOCATION"]["payload"]["socketId"] =
              decryptedData.payload.socketId ||
              // @ts-ignore legacy, see #2094 (#2097)
              decryptedData.payload.socketID;

            const collaborators = new Map(this.collaborators);
            const user = collaborators.get(socketId) || {}!;
            user.pointer = pointer;
            user.button = button;
            user.selectedElementIds = selectedElementIds;
            user.username = username;
            collaborators.set(socketId, user);

            this.excalidrawAPI.updateScene({
              collaborators,
            });

            break;
          }
          case "IDLE_STATUS": {
            const { userState, socketId, username } = decryptedData.payload;
            const collaborators = new Map(this.collaborators);
            const user = collaborators.get(socketId) || {}!;
            user.userState = userState;
            user.username = username;

            this.excalidrawAPI.updateScene({
              collaborators,
            });

            break;
          }
          case "PAGE_UPDATE": {
            localStorage.setItem("PAGE_UPDATE", "true");
            return;
          }
        }
      },
    );

    this.portal.socket!.on("first-in-room", () => {
      if (this.portal.socket) {
        this.portal.socket.off("first-in-room");
      }
      this.initializeSocket();
      scenePromise.resolve(null);
    });

    this.initializeIdleDetector();
    localStorage.setItem(
      "activeRoomLink",
      isMyWorkSpaceData !== "true"
        ? window.location.href
        : window.location.href +
            "#room=" +
            roomId +
            "," +
            roomKey +
            "&selectedWorkspaceCardID=" +
            selectedWorkspaceCardID,
    );
    this.setState({
      activeRoomLink:
        isMyWorkSpaceData !== "true"
          ? window.location.href
          : window.location.href +
            "#room=" +
            roomId +
            "," +
            roomKey +
            "&selectedWorkspaceCardID=" +
            selectedWorkspaceCardID,
    });

    const context = JSON.parse(localStorage.getItem("user") || "{}");

    const body = {
      body: {
        contentType: "html",
        content: `<div>Here is your Collaboration Link...<br /><a href="${this.state.activeRoomLink}">${this.state.activeRoomLink}</a></div>`,
      },
    };

    await sendMessage(body, context.groupId, context.channelId);
    return scenePromise;
  };

  private initializeSocket = () => {
    this.portal.socketInitialized = true;
    clearTimeout(this.socketInitializationTimer!);
  };

  private reconcileElements = (
    elements: readonly ExcalidrawElement[],
    excalidrawAPI: ExcalidrawImperativeAPI,
  ): ReconciledElements => {
    const currentElements = this.getSceneElementsIncludingDeleted(
      excalidrawAPI,
    );
    // create a map of ids so we don't have to iterate
    // over the array more than once.
    const localElementMap = getElementMap(currentElements);

    const appState = excalidrawAPI.getAppState();

    // Reconcile
    const newElements: readonly ExcalidrawElement[] = elements
      .reduce((elements, element) => {
        // if the remote element references one that's currently
        // edited on local, skip it (it'll be added in the next step)
        if (
          element.id === appState.editingElement?.id ||
          element.id === appState.resizingElement?.id ||
          element.id === appState.draggingElement?.id
        ) {
          return elements;
        }

        if (
          localElementMap.hasOwnProperty(element.id) &&
          localElementMap[element.id].version > element.version
        ) {
          elements.push(localElementMap[element.id]);
          delete localElementMap[element.id];
        } else if (
          localElementMap.hasOwnProperty(element.id) &&
          localElementMap[element.id].version === element.version &&
          localElementMap[element.id].versionNonce !== element.versionNonce
        ) {
          // resolve conflicting edits deterministically by taking the one with the lowest versionNonce
          if (localElementMap[element.id].versionNonce < element.versionNonce) {
            if (element.page === appState.currentPage) {
              elements.push(localElementMap[element.id]);
            }
          } else {
            // it should be highly unlikely that the two versionNonces are the same. if we are
            // really worried about this, we can replace the versionNonce with the socket id.

            elements.push(element);
          }
          delete localElementMap[element.id];
        } else {
          elements.push(element);

          delete localElementMap[element.id];
        }

        return elements;
      }, [] as Mutable<typeof elements>)
      // add local elements that weren't deleted or on remote
      .concat(...Object.values(localElementMap));

    // Avoid broadcasting to the rest of the collaborators the scene
    // we just received!
    // Note: this needs to be set before updating the scene as it
    // synchronously calls render.
    this.setLastBroadcastedOrReceivedSceneVersion(getSceneVersion(newElements));

    return newElements as ReconciledElements;
  };

  private handleRemoteSceneUpdate = (
    elements: ReconciledElements,
    { init = false }: { init?: boolean } = {},
  ) => {
    this.excalidrawAPI.updateScene({
      elements,
      commitToHistory: !!init,
    });

    // We haven't yet implemented multiplayer undo functionality, so we clear the undo stack
    // when we receive any messages from another peer. This UX can be pretty rough -- if you
    // undo, a user makes a change, and then try to redo, your element(s) will be lost. However,
    // right now we think this is the right tradeoff.
    this.excalidrawAPI.history.clear();

    this.loadImageFiles();
    this.loadAudiosFiles();
    this.loadVideosFiles();
    this.loadFormulaFiles();
    this.loadTextWithStylesFiles();
  };

  private loadImageFiles = throttle(async () => {
    const {
      loadedFiles,
      erroredFiles,
    } = await this.fetchImageFilesFromFirebase({
      elements: this.excalidrawAPI.getSceneElementsIncludingDeleted(),
    });
    this.excalidrawAPI.addFiles(loadedFiles);

    updateStaleImageStatuses({
      excalidrawAPI: this.excalidrawAPI,
      erroredFiles,
      elements: this.excalidrawAPI.getSceneElementsIncludingDeleted(),
    });
  }, LOAD_IMAGES_TIMEOUT);
  private loadVideosFiles = throttle(async () => {
    const {
      loadedFiles,
      erroredFiles,
    } = await this.fetchVideoFilesFromFirebase({
      elements: this.excalidrawAPI.getSceneElementsIncludingDeleted(),
    });
    this.excalidrawAPI.addFiles(loadedFiles);

    updateStaleVideoStatuses({
      excalidrawAPI: this.excalidrawAPI,
      erroredFiles,
      elements: this.excalidrawAPI.getSceneElementsIncludingDeleted(),
    });
  }, LOAD_IMAGES_TIMEOUT);
  private loadAudiosFiles = throttle(async () => {
    const {
      loadedFiles,
      erroredFiles,
    } = await this.fetchAudioFilesFromFirebase({
      elements: this.excalidrawAPI.getSceneElementsIncludingDeleted(),
    });
    this.excalidrawAPI.addFiles(loadedFiles);

    updateStaleAudioStatuses({
      excalidrawAPI: this.excalidrawAPI,
      erroredFiles,
      elements: this.excalidrawAPI.getSceneElementsIncludingDeleted(),
    });
  }, LOAD_IMAGES_TIMEOUT);
  private loadFormulaFiles = throttle(async () => {
    const {
      loadedFiles,
      erroredFiles,
    } = await this.fetchFormulaFilesFromFirebase({
      elements: this.excalidrawAPI.getSceneElementsIncludingDeleted(),
    });
    this.excalidrawAPI.addFiles(loadedFiles);

    updateStaleFormulaStatuses({
      excalidrawAPI: this.excalidrawAPI,
      erroredFiles,
      elements: this.excalidrawAPI.getSceneElementsIncludingDeleted(),
    });
  }, LOAD_IMAGES_TIMEOUT);

  private loadTextWithStylesFiles = throttle(async () => {
    const {
      loadedFiles,
      erroredFiles,
    } = await this.fetchTextwithStylesFilesFromFirebase({
      elements: this.excalidrawAPI.getSceneElementsIncludingDeleted(),
    });
    this.excalidrawAPI.addFiles(loadedFiles);

    updateStaleTextWithStylesStatuses({
      excalidrawAPI: this.excalidrawAPI,
      erroredFiles,
      elements: this.excalidrawAPI.getSceneElementsIncludingDeleted(),
    });
  }, LOAD_IMAGES_TIMEOUT);

  private onPointerMove = () => {
    if (this.idleTimeoutId) {
      window.clearTimeout(this.idleTimeoutId);
      this.idleTimeoutId = null;
    }
    this.idleTimeoutId = window.setTimeout(this.reportIdle, IDLE_THRESHOLD);
    if (!this.activeIntervalId) {
      this.activeIntervalId = window.setInterval(
        this.reportActive,
        ACTIVE_THRESHOLD,
      );
    }
  };

  private onVisibilityChange = () => {
    if (document.hidden) {
      if (this.idleTimeoutId) {
        window.clearTimeout(this.idleTimeoutId);
        this.idleTimeoutId = null;
      }
      if (this.activeIntervalId) {
        window.clearInterval(this.activeIntervalId);
        this.activeIntervalId = null;
      }

      this.onIdleStateChange(UserIdleState.AWAY);
    } else {
      this.idleTimeoutId = window.setTimeout(this.reportIdle, IDLE_THRESHOLD);
      this.activeIntervalId = window.setInterval(
        this.reportActive,
        ACTIVE_THRESHOLD,
      );
      this.onIdleStateChange(UserIdleState.ACTIVE);
    }
  };

  private reportIdle = () => {
    this.onIdleStateChange(UserIdleState.IDLE);
    if (this.activeIntervalId) {
      window.clearInterval(this.activeIntervalId);
      this.activeIntervalId = null;
    }
  };

  private reportActive = () => {
    this.onIdleStateChange(UserIdleState.ACTIVE);
  };

  private initializeIdleDetector = () => {
    document.addEventListener(EVENT.POINTER_MOVE, this.onPointerMove);
    document.addEventListener(EVENT.VISIBILITY_CHANGE, this.onVisibilityChange);
  };

  setCollaborators(sockets: string[]) {
    this.setState((state) => {
      const collaborators: InstanceType<
        typeof CollabWrapper
      >["collaborators"] = new Map();
      for (const socketId of sockets) {
        if (this.collaborators.has(socketId)) {
          collaborators.set(socketId, this.collaborators.get(socketId)!);
        } else {
          collaborators.set(socketId, {});
        }
      }
      this.collaborators = collaborators;
      this.excalidrawAPI.updateScene({ collaborators });
    });
  }

  public setLastBroadcastedOrReceivedSceneVersion = (version: number) => {
    this.lastBroadcastedOrReceivedSceneVersion = version;
  };

  public getLastBroadcastedOrReceivedSceneVersion = () => {
    return this.lastBroadcastedOrReceivedSceneVersion;
  };

  public getSceneElementsIncludingDeleted = (
    excalidrawAPI?: ExcalidrawImperativeAPI,
  ) => {
    return excalidrawAPI
      ? excalidrawAPI.getSceneElementsIncludingDeleted()
      : this.excalidrawAPI?.getSceneElementsIncludingDeleted();
  };

  onPointerUpdate = (payload: {
    pointer: SocketUpdateDataSource["MOUSE_LOCATION"]["payload"]["pointer"];
    button: SocketUpdateDataSource["MOUSE_LOCATION"]["payload"]["button"];
    pointersMap: Gesture["pointers"];
  }) => {
    payload.pointersMap.size < 2 &&
      this.portal.socket &&
      this.portal.broadcastMouseLocation(payload);
    // this.portal.sendAndRetriveData();
  };

  onIdleStateChange = (userState: UserIdleState) => {
    this.setState({ userState });
    this.portal.broadcastIdleChange(userState);
  };

  public saveAndRetriveStudData = (studData: Object, lessonId: String) => {
    this.portal.sendAndRetriveData(studData, lessonId);
  };

  private fetchImageFilesFromFirebase = async (scene: {
    elements: readonly ExcalidrawElement[];
  }) => {
    const unfetchedImages = scene.elements
      .filter((element) => {
        return (
          (isInitializedAvatarImageElement(element) ||
            isInitializedImageElement(element)) &&
          !this.fileManager.isFileHandled(element.fileId) &&
          !element.isDeleted &&
          element.status === "saved"
        );
      })
      .map(
        (element) =>
          (
            (element as InitializedExcalidrawImageElement) ||
            (element as InitializedExcalidrawAvatarImageElement)
          ).fileId,
      );

    return await this.fileManager.getFiles(unfetchedImages);
  };
  private fetchFormulaFilesFromFirebase = async (scene: {
    elements: readonly ExcalidrawElement[];
  }) => {
    const unFetchedVideos = scene.elements
      .filter((element) => {
        return (
          isInitializedFormulaElement(element) &&
          !this.fileManager.isFileHandled(element.fileId) &&
          !element.isDeleted &&
          element.status === "saved"
        );
      })
      .map(
        (element) =>
          (
            (element as InitializedExcalidrawImageElement) ||
            (element as InitializedExcalidrawAvatarImageElement)
          ).fileId,
      );

    return await this.fileManager.getFiles(unFetchedVideos);
  };
  private fetchTextwithStylesFilesFromFirebase = async (scene: {
    elements: readonly ExcalidrawElement[];
  }) => {
    const unFetchedVideos = scene.elements
      .filter((element) => {
        const condition =
          isInitializedTextWithStylesElement(element) &&
          !this.fileManager.isFileHandled(element.fileId) &&
          !element.isDeleted &&
          element.status === "saved";
        return (
          isInitializedTextWithStylesElement(element) &&
          !this.fileManager.isFileHandled(element.fileId) &&
          !element.isDeleted &&
          element.status === "saved"
        );
      })
      .map(
        (element) =>
          (element as InitializedExcalidrawTextWithStyleElement).fileId,
      );
    return await this.fileManager.getFiles(unFetchedVideos);
  };
  private fetchMermaidDiagramFilesFromFirebase = async (scene: {
    elements: readonly ExcalidrawElement[];
  }) => {
    const unFetchedVideos = scene.elements
      .filter((element) => {
        return (
          isInitializedMermaidDiagramElement(element) &&
          !this.fileManager.isFileHandled(element.fileId) &&
          !element.isDeleted &&
          element.status === "saved"
        );
      })
      .map(
        (element) =>
          (element as InitializedExcalidrawMermaidDiagramElement).fileId,
      );

    return await this.fileManager.getFiles(unFetchedVideos);
  };
  private fetchVideoFilesFromFirebase = async (scene: {
    elements: readonly ExcalidrawElement[];
  }) => {
    const unfetchedVideos = scene.elements
      .filter((element) => {
        return (
          isInitializedVideoElement(element) &&
          !this.fileManager.isFileHandled(element.fileId) &&
          !element.isDeleted
        );
      })
      .map((element) => (element as InitializedExcalidrawVideoElement).fileId);

    return await this.fileManager.getFiles(unfetchedVideos);
  };
  private fetchAudioFilesFromFirebase = async (scene: {
    elements: readonly ExcalidrawElement[];
  }) => {
    const unfetchedAudios = scene.elements
      .filter((element) => {
        return (
          isInitializedAudioElement(element) &&
          !this.fileManager.isFileHandled(element.fileId) &&
          !element.isDeleted
        );
      })
      .map((element) => (element as InitializedExcalidrawAudioElement).fileId);

    return await this.fileManager.getFiles(unfetchedAudios);
  };

  broadcastElements = (elements: readonly ExcalidrawElement[]) => {
    if (
      getSceneVersion(elements) >
      this.getLastBroadcastedOrReceivedSceneVersion()
    ) {
      this.portal.broadcastScene(SCENE.UPDATE, elements, false);
      this.lastBroadcastedOrReceivedSceneVersion = getSceneVersion(elements);
      this.queueBroadcastAllElements();
    }
  };

  queueBroadcastAllElements = throttle(() => {
    this.portal.broadcastScene(
      SCENE.UPDATE,
      this.getSyncableElements(
        this.excalidrawAPI.getSceneElementsIncludingDeleted(),
      ),
      true,
    );
    const currentVersion = this.getLastBroadcastedOrReceivedSceneVersion();
    const newVersion = Math.max(
      currentVersion,
      getSceneVersion(this.getSceneElementsIncludingDeleted()),
    );
    this.setLastBroadcastedOrReceivedSceneVersion(newVersion);
  }, SYNC_FULL_SCENE_INTERVAL_MS);

  handleClose = () => {
    this.setState({ modalIsShown: false });
  };

  handleGroupModelClose = () => {
    this.setState({ isShowFlexibleGroupModel: false });
  };

  onUsernameChange = (username: string) => {
    this.setState({ username });
    saveUsernameToLocalStorage(username);
  };

  onCollabButtonClick = () => {
    this.setState({
      modalIsShown: true,
    });
  };

  onStartFlexibleGroupCollaboration = () => {
    this.setState({
      isCollaboratingWithFlexibleGroups: true,
    });
    localStorage.setItem("isCollaboratingWithFlexibleGroups", "true");
  };

  onFlexibleGroupButtonClick = () => {
    this.setState({
      isShowFlexibleGroupModel: true,
    });
  };

  isSyncableElement = (element: ExcalidrawElement) => {
    return element.isDeleted || !isInvisiblySmallElement(element);
  };

  getSyncableElements = (elements: readonly ExcalidrawElement[]) =>
    elements.filter((el) => el.isDeleted || !isInvisiblySmallElement(el));

  /** PRIVATE. Use `this.getContextValue()` instead. */
  private contextValue: CollabAPI | null = null;

  /** Getter of context value. Returned object is stable. */
  getContextValue = (): CollabAPI => {
    if (!this.contextValue) {
      this.contextValue = {} as CollabAPI;
    }
    // this.getActiveCollabLinks();

    this.contextValue.isCollaborating = () => this.state.isCollaborating;
    this.contextValue.isCollaboratingWithFlexibleGroups = () =>
      this.state.isCollaboratingWithFlexibleGroups;
    this.contextValue.username = this.state.username;
    this.contextValue.onPointerUpdate = this.onPointerUpdate;
    this.contextValue.initializeSocketClient = this.initializeSocketClient;
    this.contextValue.onCollabButtonClick = this.onCollabButtonClick;
    this.contextValue.onFlexibleGroupButtonClick = this.onFlexibleGroupButtonClick;
    this.contextValue.broadcastElements = this.broadcastElements;
    this.contextValue.saveAndRetriveStudData = this.saveAndRetriveStudData;
    this.contextValue.fetchImageFilesFromFirebase = this.fetchImageFilesFromFirebase;
    this.contextValue.fetchVideoFilesFromFirebase = this.fetchVideoFilesFromFirebase;
    this.contextValue.fetchAudioFilesFromFirebase = this.fetchAudioFilesFromFirebase;
    this.contextValue.fetchFormulaFilesFromFirebase = this.fetchFormulaFilesFromFirebase;
    return this.contextValue;
  };

  render() {
    const {
      modalIsShown,
      username,
      errorMessage,
      activeRoomLink,
      isShowFlexibleGroupModel,
    } = this.state;
    return (
      <>
        {this.state.isOpenModal && (
          <ConfirmDialog
            onCancel={() => {
              this.setState({ isOpenModal: false });
            }}
            onConfirm={() => {
              const studData = this.getStudentFromLocal();

              const lessonId =
                new URLSearchParams(window.location.search)
                  .get("lessonId")
                  ?.replace(/\//g, "") || "";
              this.saveAndRetriveStudData(studData, lessonId);
              window.parent.postMessage(
                {
                  type: "IS_START_COLLABORATION",
                  session: false,
                  lessonId: lessonId,
                },
                `${process.env.REACT_APP_PARENT_APP}`,
              );
              this.props.setStudentMessage?.({ ...studentSessionEndMessage });

              window.history.pushState(
                {},
                APP_NAME,
                `${window.location.origin}/?slug=${new URLSearchParams(
                  window.location.search,
                ).get("slug")}&lessonId=${lessonId}`,
              );
              this.destroySocketClient();
              deleteCollaborationLink(lessonId);
              trackEvent("share", "room closed");

              this.setState({ isOpenModal: false });
              //this is for add green line in lesson(ACV) at the time of end the whiteboard session
            }}
            title={`Are you sure?`}
            children={
              <>
                <p className="mb-0">
                  Ending the session will replace your previous drawing saved
                  locally. Are you certain you want to proceed?
                </p>
              </>
            }
            open={this.state.isOpenModal}
            setOpen={this.setState}
            isEditable={false}
            isShowInput={false}
            closeOnClickOutside={false}
          />
        )}

        {modalIsShown && (
          <>
            <RoomDialog
              handleClose={this.handleClose}
              activeRoomLink={activeRoomLink}
              username={username}
              onUsernameChange={this.onUsernameChange}
              onRoomCreate={this.openPortal}
              onRoomDestroy={this.closePortal}
              openFlexibleGroupsDialog={this.onFlexibleGroupButtonClick}
              setErrorMessage={(errorMessage) => {
                this.setState({ errorMessage });
              }}
              theme={this.excalidrawAPI.getAppState().theme}
              onStartFlexibleGroupCollaboration={
                this.onStartFlexibleGroupCollaboration
              }
              organizationUsers={this.state.organizationUsers}
              appState={this.excalidrawAPI.getAppState()}
              openAlert={(value) => {
                this.setState({ show: value });
              }}
              show={this.state.show}
              isPublic={(value) => {
                this.setState({ isPublic: value });
              }}
              filteredEmails={(emails) => {
                this.setState({ filteredEmails: emails });
              }}
              filteredEmailsData={this.state.filteredEmails}
              isPublicValue={this.state.isPublic}
            />
          </>
        )}
        {isShowFlexibleGroupModel && (
          <FlexibleGroupDialog
            theme={this.excalidrawAPI.getAppState().theme}
            handleClose={this.handleGroupModelClose}
            appState={this.excalidrawAPI.getAppState()}
            onRoomCreate={this.openPortal}
            onStartFlexibleGroupCollaboration={
              this.onStartFlexibleGroupCollaboration
            }
            portal={this.portal}
          />
        )}

        {errorMessage && (
          <ErrorDialog
            message={errorMessage}
            onClose={() => this.setState({ errorMessage: "" })}
          />
        )}
        {this.props.isAssignedPage && (
          <ErrorDialog
            title={t("labels.flexibleGroup")}
            message={t("labels.noPagesModelMsg")}
            onClose={() => {
              localStorage.setItem("isShowNoPagesModel", "false");
            }}
          />
        )}
        <CollabContextProvider
          value={{
            api: this.getContextValue(),
          }}
        />
      </>
    );
  }
}

declare global {
  interface Window {
    collab: InstanceType<typeof CollabWrapper>;
    publicOpenPortal: (val: boolean) => void;
    publicClosePortal: (workspaceID?: string) => void;
    saveAndRetriveStudData: (studData: Object, lessonId: String) => void;
    Portal: () => void;
  }
}

if (
  process.env.NODE_ENV === ENV.TEST ||
  process.env.NODE_ENV === ENV.DEVELOPMENT
) {
  window.collab = window.collab || ({} as Window["collab"]);
}

export default CollabWrapper;
