import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { useContext, useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import LoginPage from "../components/LoginPage";
import Whiteboard from "../components/Whiteboard";
// import ClosePopup from "../msal-auth/components/ClosePopup";
// import ConsentPopup from "../msal-auth/components/ConsentPopup";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { StudentMessageContext } from "../../components/contexts/StudentMessage.context";
import { UserProfileContext } from "../../components/contexts/UserProfile.context";
import {
  studentAccessRevokeMessage,
  studentSessionEndMessage,
  studentUnAuthorizedMessage,
} from "../../excalidraw-app/api/constant";
import {
  checkUserRole,
  getWhiteboardStudent,
} from "../../excalidraw-app/api/userAPI";
import { saveUsernameToLocalStorage } from "../../excalidraw-app/data/localStorage";
import { callMsGraph } from "../msal-auth/graph";
import { loginRequest } from "../msal-auth/msalAuth";
import Loader from "../shareComponent/Loader";
require("dotenv").config();

const App = () => {
  const { instance, accounts, inProgress } = useMsal();
  const [flag, setFlag] = useState(false);
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [studentMessage, setStudentMessage] = useState(null);
  const [gptCredit, setGptCredit] = useState(0);

  const callGraphFromClient = async (acctoken) => {
    const graphPhotoEndpoint = `https://graph.microsoft.com/v1.0/me/photo/$value`;
    const graphRequestParams = {
      method: "GET",
      headers: {
        "Content-Type": "image/jpg",
        Authorization: `Bearer ${acctoken}`,
      },
    };

    try {
      const response = await fetch(graphPhotoEndpoint, graphRequestParams);

      if (!response?.ok) {
        console.error("ERROR: ", response);
        window.localStorage.removeItem("profile-blob");
        return; // Exit the function if there was an error.
      }

      const imageBlog = await response.blob();
      const reader = new FileReader();

      reader.onloadend = function () {
        const base64data = reader.result;
        window.localStorage.setItem("profileBase64", base64data);
      };

      reader.readAsDataURL(imageBlog);
      window.localStorage.setItem(
        "profile-blob",
        URL.createObjectURL(imageBlog),
      );
    } catch (err) {
      console.error("Unhandled fetch error: ", err);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const userLS = JSON.parse(localStorage.getItem("user"));
      const accessToken = localStorage.getItem("access_token");
      if (userLS && userLS !== "undefined") {
        setUser(userLS);
        if (userLS?.accessToken) {
          setAccessToken(userLS?.accessToken);
        } else {
          setAccessToken(accessToken);
        }
      } else {
        window.parent.postMessage(
          {
            source: "whiteboard",
            message: "Resent the data!",
          },
          // process.env.REACT_APP_ACV_PARENT_APP,
          `${process.env.REACT_APP_PARENT_APP}`,
        );
      }
    }, 1000);

    setTimeout(() => {
      setFlag(true);
    }, 1500);
  }, []);

  const handleLogin = () => {
    if (window.parent !== window) {
      instance.loginPopup(loginRequest).catch((e) => {
        console.error(e);
      });
    } else {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.error(e);
      });
    }
  };

  useEffect(() => {
    const getUserdata = () => {
      if (accounts && accounts.length) {
        const accessTokenRequest = {
          ...loginRequest,
          account: accounts[0],
        };
        instance
          .acquireTokenSilent(accessTokenRequest)
          .then((response) => {
            callGraphFromClient(response.accessToken);
            callMsGraph(response.accessToken).then((res) => {
              setUser(res);
              setAccessToken(response.accessToken);
              window.localStorage.setItem("access_token", response.accessToken);
              window.localStorage.setItem("user", JSON.stringify(res));
            });
          })
          .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              instance
                .acquireTokenPopup(accessTokenRequest)
                .then((response) => {
                  callGraphFromClient(response.accessToken);
                  callMsGraph(response.accessToken).then((res) => {
                    setUser(res);
                    setAccessToken(response.accessToken);
                    window.localStorage.setItem(
                      "access_token",
                      response.accessToken,
                    );
                    window.localStorage.setItem("user", JSON.stringify(res));
                  });
                })
                .catch((error) => {
                  console.error(error);
                });
            }
          });
      }
    };
    if (inProgress === InteractionStatus.None) {
      getUserdata();
    }
  }, [instance, accounts, inProgress]);

  useEffect(() => {
    async function fetchData() {
      const slug = new URLSearchParams(window.location.search).get("slug");
      try {
        const res = await checkUserRole(slug);
        if (res) setGptCredit(res.credit ? res.credit : 0);
        if (res?.image) {
          setUserProfile(res?.image);
        }
        localStorage.setItem("Role", res?.role);
        if (["SuperAdmin", "Admin", "Teacher"].includes(res?.actualRole)) {
          window.localStorage.setItem("S", false);
        } else {
          window.localStorage.setItem("S", true);
        }
        if (accessToken && user.role) {
          const lessonId = new URLSearchParams(window.location.search)
            .get("lessonId")
            ?.replace(/\//g, "");
          localStorage.setItem("Role", user.role);
          if (["SuperAdmin", "Admin", "Teacher"].includes(user.role)) {
            window.localStorage.setItem("S", false);
          } else {
            const res = await getWhiteboardStudent(lessonId);
            const data = res.result?.data;
            const studData = data?.find((stud) => stud.studEmail === user.mail);
            if (data && data.length && studData) {
              window.localStorage.setItem("S", true);
              saveUsernameToLocalStorage(user?.displayName || "");
              if (
                typeof studData === "object" &&
                studData?.isWhiteboard === false
              ) {
                setStudentMessage({ ...studentAccessRevokeMessage });
                const isModalOpen = document
                  .getElementsByClassName("modal fade Karla")[0]
                  ?.classList.contains("show");
                if (!isModalOpen) {
                  if (document.getElementsByClassName("acv")[0]) {
                    document.getElementsByClassName("acv")[0].style.opacity =
                      "0";
                  }
                  document.getElementById("errModalBtn")?.click();
                } else {
                  const acvElement = document.getElementsByClassName("acv")[0];
                  if (acvElement) {
                    acvElement.style.opacity = "1";
                  }
                }
              } else if (
                typeof studData === "object" &&
                studData?.isWhiteboard === 2
              ) {
                window.parent.postMessage(
                  {
                    type: "IS_START_COLLABORATION",
                    session: false,
                    lessonId,
                  },
                  `${process.env.REACT_APP_PARENT_APP}`,
                );
                setStudentMessage({ ...studentSessionEndMessage });
              } else if (
                typeof studData === "object" &&
                studData?.isWhiteboard === true
              ) {
                const isModalOpen = document
                  .getElementsByClassName("modal fade Karla")[0]
                  ?.classList?.contains("show");
                if (document.getElementsByClassName("acv")[0]) {
                  document.getElementsByClassName("acv")[0].style.opacity = "1";
                }
                if (isModalOpen) {
                  document.getElementById("errModalBtn")?.click();
                }
              }
            } else {
              const isSetACVLibrary = Boolean(
                localStorage.getItem("SET-ACV-LIBRARY"),
              );
              if (!isSetACVLibrary) {
                setStudentMessage({ ...studentUnAuthorizedMessage });
              }
            }
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, [accessToken, user?.displayName, user?.mail, user?.role]); // Add other dependencies if needed

  return (
    <Router>
      <>
        {flag ? (
          user || accessToken ? (
            user && accessToken ? (
              <UserProfileContext.Provider
                value={{
                  image: userProfile,
                  gptCredit: gptCredit,
                  setGptCredit: setGptCredit,
                }}
              >
                <StudentMessageContext.Provider
                  value={{
                    studentMessage,
                    setStudentMessage,
                  }}
                >
                  <Whiteboard />
                </StudentMessageContext.Provider>
              </UserProfileContext.Provider>
            ) : (
              <Loader />
            )
          ) : (
            <>
              <AuthenticatedTemplate>
                <Loader />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                {/* <Route exact path="/auth-start" component={ConsentPopup} />
                <Route exact path="/auth-end" component={ClosePopup} /> */}
                <Routes>
                  <Route
                    exact
                    path="/"
                    element={<LoginPage login={() => handleLogin()} />}
                  />
                </Routes>
              </UnauthenticatedTemplate>
            </>
          )
        ) : (
          <Loader />
        )}
      </>
    </Router>
  );
};
export default App;
