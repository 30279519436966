import { NonDeletedExcalidrawElement } from "../element/types";
import { t } from "../i18n";
import { getSelectedElements } from "../scene";

import { useCallback, useState } from "react";
import Library from "../data/library";
import {
  isAvatarImageElement,
  isImageElement,
  isLinearElement,
  isTextElement,
} from "../element/typeChecks";
import { randomId } from "../random";
import { AppState, LibraryItems } from "../types";
import { getShortcutKey } from "../utils";
import ConfirmDialog from "./ConfirmDialog";
import "./HintViewer.scss";
import { ToolButton, ToolButtonEnum } from "./ToolButton";
import { refresh, save, trash } from "./icons";

interface HintViewerProps {
  appState: AppState;
  elements: readonly NonDeletedExcalidrawElement[];
  isMobile: boolean;
  library: Library;
  focusContainer: () => void;
  setLibraryItems: (val: LibraryItems) => void;
  resetLibrary?: () => void;
  setAddToCollection: (val: number[]) => void;
  setDeleteElement: (val: number[]) => void;
  setAppState: React.Component<any, AppState>["setState"];
  resetScene: (opts?: { resetLoadingState: boolean }) => void;
  setShowRemoveLibAlert?: (val: boolean) => void;
  showRemoveLibAlert?: boolean;
  setConfirmationDuringResetItem?: (val: boolean) => void;
  confirmationDuringResetItem?: boolean;
}

const getHints = ({
  appState,
  elements,
  isMobile,
  library,
  setLibraryItems,
  focusContainer,
  resetLibrary,
  setAddToCollection,
  setDeleteElement,
  setAppState,
  resetScene,
  setShowRemoveLibAlert,
  showRemoveLibAlert,
  setConfirmationDuringResetItem,
  confirmationDuringResetItem,
}: HintViewerProps) => {
  const { activeTool, isResizing, isRotating, lastPointerDownWith } = appState;
  const multiMode = appState.multiElement !== null;

  if (appState.editingLibrary.isEditing) {
    return (
      <>
        <div className="d-flex">
          <ToolButton
            key="reset"
            type={ToolButtonEnum.BUTTON}
            title={t("buttons.resetLibrary")}
            aria-label={t("buttons.resetLibrary")}
            icon={trash}
            className="library-actions--cancel text-white pe-3"
            onClick={() => setShowRemoveLibAlert && setShowRemoveLibAlert(true)}
          >
            Cancel editing process
          </ToolButton>
          <div className="w-100">
            <h6 className="mb-0 fw-bold text-dark mt-2">
              {t("hints.editLibrary.header")}
            </h6>
            <p className="mb-0">{t("hints.editLibrary.message")}</p>
          </div>
          <div className="w-100 d-flex justify-content-between">
            <ToolButton
              key="Save Item"
              type={ToolButtonEnum.BUTTON}
              title={"Save Item"}
              aria-label={"Save Item"}
              icon={save}
              className="save-item text-white pe-3"
              style={{ height: "34px", backgroundColor: "#494B83" }}
              onClick={async () => {
                setAppState({ isLibraryOpen: true });
                const items = await library.loadLibrary();
                if (items.length && elements.length) {
                  const selectedItems = JSON.parse(
                    localStorage.getItem("selectedElement") || "[]",
                  );
                  const filteredItems = items.filter(
                    (item) => item.id !== selectedItems.id,
                  );

                  const newItem = {
                    status: "unpublished",
                    elements,
                    id: selectedItems?.id || randomId(),
                    created: Date.now(),
                  };

                  const nextItems: LibraryItems = [
                    newItem as any,
                    ...filteredItems,
                  ];

                  try {
                    await library.saveLibrary(nextItems);
                  } catch (error) {
                    console.error("Error saving item:", error);
                    setLibraryItems(nextItems);
                    setAppState({
                      errorMessage: t("alerts.errorAddingToLibrary"),
                    });
                  }

                  setAppState({
                    editingLibrary: {
                      ...appState.editingLibrary,
                      libraryItems: nextItems,
                    },
                  });
                }
              }}
            >
              Save Item
            </ToolButton>
            <ToolButton
              key="Reset Item"
              type={ToolButtonEnum.BUTTON}
              title={"Reset Item"}
              aria-label={"Reset Item"}
              icon={refresh}
              className="reset-item pe-3"
              style={{ height: "34px", backgroundColor: "lightgray" }}
              onClick={() => {
                setConfirmationDuringResetItem &&
                  setConfirmationDuringResetItem(true);
              }}
            >
              Reset Item
            </ToolButton>
          </div>
          {showRemoveLibAlert && (
            <ConfirmDialog
              onConfirm={() => {
                resetLibrary && resetLibrary();
                setDeleteElement([]);
                setAddToCollection([]);
                setShowRemoveLibAlert && setShowRemoveLibAlert(false);
                setAppState({
                  editingLibrary: {
                    libraryId: "",
                    isEditing: false,
                    //@ts-ignore
                    libraryInfo: {},
                    libraryItems: [],
                    oldElements: [],
                  },
                  selectedElementIds: {},
                  selectedLinearElement: null,
                });
              }}
              onCancel={() => {
                setShowRemoveLibAlert && setShowRemoveLibAlert(false);
              }}
              title={t("confirmation.editCollection")}
              children={<p>{t("confirmation.areYousure")}</p>}
              closeOnClickOutside={false}
              open={false}
              setOpen={() => false}
            ></ConfirmDialog>
          )}
          {confirmationDuringResetItem && (
            <ConfirmDialog
              onConfirm={() => {
                setConfirmationDuringResetItem &&
                  setConfirmationDuringResetItem(false);
                resetScene();
                setAppState({
                  isLibraryOpen: true,
                  editingLibrary: {
                    ...appState.editingLibrary,
                    isEditing: true,
                  },
                });
              }}
              onCancel={() => {
                setConfirmationDuringResetItem &&
                  setConfirmationDuringResetItem(false);
              }}
              title={`Are you sure?`}
              children={
                <p>{`Are you sure you want to reset this item? If you reset it, you will lose your changes.`}</p>
              }
              confirmText="Yes"
              cancelText="No"
              closeOnClickOutside={false}
              open={false}
              setOpen={() => false}
            ></ConfirmDialog>
          )}
        </div>
      </>
    );
  }

  if (activeTool.type === "arrow" || activeTool.type === "line") {
    if (!multiMode) {
      return t("hints.linearElement");
    }
    return t("hints.linearElementMulti");
  }

  if (activeTool.type === "freedraw") {
    return t("hints.freeDraw");
  }

  if (activeTool.type === "text") {
    return t("hints.text");
  }

  if (
    (appState.activeTool.type === "image" ||
      appState.activeTool.type === "avatar") &&
    appState.pendingImageElement
  ) {
    return t("hints.placeImage");
  }

  const selectedElements = getSelectedElements(elements, appState);

  if (
    isResizing &&
    lastPointerDownWith === "mouse" &&
    selectedElements?.length === 1
  ) {
    const targetElement = selectedElements[0];
    if (isLinearElement(targetElement) && targetElement.points.length === 2) {
      return t("hints.lockAngle");
    }
    return isAvatarImageElement(targetElement) || isImageElement(targetElement)
      ? t("hints.resizeImage")
      : t("hints.resize");
  }

  if (isRotating && lastPointerDownWith === "mouse") {
    return t("hints.rotate");
  }

  if (selectedElements?.length === 1 && isLinearElement(selectedElements[0])) {
    if (appState.editingLinearElement) {
      return appState.editingLinearElement.activePointIndex
        ? t("hints.lineEditor_pointSelected")
        : t("hints.lineEditor_nothingSelected");
    }
    return t("hints.lineEditor_info");
  }

  if (selectedElements?.length === 1 && isTextElement(selectedElements[0])) {
    return t("hints.text_selected");
  }

  if (appState.editingElement && isTextElement(appState.editingElement)) {
    return t("hints.text_editing");
  }

  if (
    activeTool.type === "selection" &&
    !selectedElements?.length &&
    !isMobile
  ) {
    return t("hints.canvasPanning");
  }

  return null;
};

export const HintViewer = ({
  appState,
  elements,
  isMobile,
  library,
  setLibraryItems,
  focusContainer,
  setAddToCollection,
  setDeleteElement,
  setAppState,
  resetScene,
}: HintViewerProps) => {
  const [showRemoveLibAlert, setShowRemoveLibAlert] = useState(false);
  const [
    confirmationDuringResetItem,
    setConfirmationDuringResetItem,
  ] = useState(false);

  const resetLibrary = useCallback(() => {
    library.resetLibrary();
    setLibraryItems([]);
    focusContainer();
    // eslint-disable-next-line
  }, [library, focusContainer]);

  let hint = getHints({
    appState,
    elements,
    isMobile,
    library,
    setLibraryItems,
    focusContainer,
    resetLibrary,
    setAddToCollection,
    setDeleteElement,
    setAppState,
    resetScene,
    setShowRemoveLibAlert,
    showRemoveLibAlert,
    setConfirmationDuringResetItem,
    confirmationDuringResetItem,
  });
  if (!hint) {
    return null;
  }
  if (!appState.editingLibrary.isEditing) {
    hint = getShortcutKey(hint as string);
  }

  return (
    <div className="HintViewer">
      <span className="w-100">{hint}</span>
    </div>
  );
};
