import React from "react";
import { t } from "../i18n";
import { AppState } from "../types";
import { capitalizeString } from "../utils";
import { useIsMobile } from "./App";
import { ToolButton, ToolButtonEnum, ToolButtonSize } from "./ToolButton";

const LIBRARY_ICON = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8333 2.16673V3.83339L10.5 3.83339V2.16673L13.8333 2.16673ZM5.50002 2.16673V7.16673H2.16668L2.16668 2.16673H5.50002ZM13.8333 8.83339V13.8334H10.5V8.83339H13.8333ZM5.50002 12.1667V13.8334H2.16668V12.1667H5.50002ZM15.5 0.500061L8.83335 0.500061V5.50006H15.5V0.500061ZM7.16668 0.500061L0.500015 0.500061L0.500015 8.83339H7.16668L7.16668 0.500061ZM15.5 7.16673H8.83335V15.5001H15.5V7.16673ZM7.16668 10.5001H0.500015L0.500015 15.5001H7.16668V10.5001Z"
      fill="currentColor"
    />
  </svg>
);
const DEFAULT_SIZE: ToolButtonSize = "medium";
export const LibraryButton: React.FC<{
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
}> = ({ appState, setAppState }) => {
  const isMobile = useIsMobile();
  return (
    <label
      style={{ position: isMobile ? "absolute" : "relative" }}
      className={`${
        isMobile ? "library_icon" : ""
      } d-flex align-items-center justify-content-center cursor-pointer`}
    >
      <ToolButton
        onClick={() => {
          setAppState({ isLibraryOpen: true });
        }}
        icon={LIBRARY_ICON}
        type={ToolButtonEnum.BUTTON}
        aria-label={capitalizeString(t("toolBar.library"))}
        showAriaLabel={useIsMobile()}
        title={capitalizeString(t("toolBar.library"))}
      />
    </label>
  );
};
