import axios from "axios";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { ExcalidrawElement, NonDeleted } from "../../../../element/types";
import { removeUndpublishedCollectionRequestAPI } from "../../../../excalidraw-app/api/collection";
import { getUserInfo } from "../../../../excalidraw-app/api/getuserInfo";
import { STORAGE_KEYS } from "../../../../excalidraw-app/data/localStorage";
import { t } from "../../../../i18n";
import {
  Category,
  CollocationType,
  CustomCategies,
  LibraryData,
  LibraryItem,
  LibraryItems,
} from "../../../../types";
import { useIsMobile } from "../../../App";
import ConfirmDialog from "../../../ConfirmDialog";
import { SegmentType, timerDuration } from "../../../spinner/types";
import { LibraryItemsDialogProps } from "../../LibraryItemsDialog";
import { AcvLibraryPreviewDialog } from "../../components/acvLibraryPreviewDialog";
import { SidebarItemsEnum } from "../../components/constant/SidebarItems";
import { PreviewDialog } from "../../components/previewDialog";
import { SpinWheelPreviewDialog } from "../../components/spinWheelPreviewDialog";
import { ActionButtons } from "./components/actionButtons";
import { AddedFromCollectionsTab } from "./components/addedFromCollection";
import { CustomCategory } from "./components/customCategory";
import { PublicSpinWheel } from "./components/publicSpinWheel";
import { WorkInProgressLibraryTab } from "./components/workInProgress";
import "./sidebar/LibrarySidebar.scss";

interface LibraryTabProps extends LibraryItemsDialogProps {
  selectedSideLibraryTab: SidebarItemsEnum;
  selectedCategoryId: string;
  previewTemplateData: CollocationType | null;
  setPreviewTemplateData: (data: CollocationType) => void;
  fullScreenCollectionPreview: boolean;
  setFullScreenCollectionPreview: (value: boolean) => void;
  setSelectedSideLibraryTab: (val: SidebarItemsEnum) => void;
  setSelectedCatagoryForExportCanvas?: Dispatch<
    SetStateAction<CustomCategies[]>
  >;
  selectedCatagoryForExportCanvas?: CustomCategies[];
  addToCollection: number[];
  setAddToCollection: (val: number[]) => void;
  deleteElelement: number[];
  setDeleteElement: (val: number[]) => void;
  resetScene: (opts?: { resetLoadingState: boolean }) => void;
  getSelectedItems: (
    libraryItems: LibraryItems,
    selectedItems: LibraryItem["id"][],
  ) => void;
  onPublishLibSuccess: (data: LibraryData) => void;
  isTrusted: number;
  importLibraryFromUrl: (urls: string[]) => void;
}

export const LibraryTab = (props: LibraryTabProps) => {
  const {
    selectedSideLibraryTab,
    files,
    setSelectedItems,
    onInsertShape,
    selectedItems,
    onPublish,
    library,
    setAppState,
    libraryItems,
    setLibraryItems,
    pendingElements,
    onAddToLibrary,
    onRemoveFromLibrary,
    resetLibrary,
    handleSpinnerDialog,
    appState,
    selectedCategoryId,
    editCollection,
    previewTemplateData,
    setPreviewTemplateData,
    setFullScreenCollectionPreview,
    fullScreenCollectionPreview,
    setSelectedSideLibraryTab,
    selectedCatagoryForExportCanvas,
    setSelectedCatagoryForExportCanvas,
    importCustomCategory,
    elements,
    addToCollection,
    setAddToCollection,
    deleteElelement,
    setDeleteElement,
    resetScene,
    getSelectedItems,
    onPublishLibSuccess,
    isTrusted,
    importLibraryFromUrl,
  } = props;

  const allTemplateIds = useMemo(() => {
    let templateIds: any[] = [];
    selectedCatagoryForExportCanvas?.forEach((item) => {
      templateIds = [...templateIds, ...item.templateIds];
    });
    return templateIds;
  }, [selectedCatagoryForExportCanvas]);

  // Compute unpublishedItems using useMemo
  const unpublishedItems = useMemo(() => {
    return [
      ...(pendingElements.length > 0
        ? [{ id: null, elements: pendingElements }]
        : []),
      ...libraryItems.filter((item) => item.status !== "published"),
    ];
  }, [libraryItems, pendingElements]);

  const filterUnpublishedItems = useMemo(() => {
    const uniqueItems: any[] = [];
    const isMyWorkSpace = localStorage.getItem("isMyWorkSpace");

    const currentData = JSON.parse(
      localStorage.getItem(
        isMyWorkSpace === "true"
          ? STORAGE_KEYS.LOCAL_STORAGE_WORKSPACE_ELEMENTS
          : STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS,
      ) || "[]",
    );
    libraryItems.forEach((item) => {
      if (item.status !== "published" && item.status !== "Under Review") {
        const isDuplicate = uniqueItems.some((uniqueItem) => {
          if (uniqueItem.elements?.length === item.elements?.length) {
            return uniqueItem.elements?.every(
              (uniqueElement: { id: string }, index: number) => {
                return uniqueElement.id === item.elements[index].id;
              },
            );
          }
          return false;
        });

        // The below code shows unnecessary elements on the Working Progress tab, so check if the element is in the scene. If it is, display it; otherwise, do not.
        // Check if the item exists in `currentData`
        const isInCurrentData = currentData.some(
          (currentItem: { id: string }) => {
            // Check if any element in `item.elements` matches the `currentItem`
            return (
              item.elements?.some((element) => element.id === currentItem.id) ||
              false
            );
          },
        );

        if (appState.editingLibrary.isEditing) {
          if (!isDuplicate) {
            uniqueItems.push(item);
          }
        }
        // Add the item to `uniqueItems` if it is not a duplicate and exists in `currentData`
        else if (!isDuplicate && isInCurrentData) {
          uniqueItems.push(item);
        }

        // if (!isDuplicate) {
        //   uniqueItems.push(item);
        // }
      }
    });

    return uniqueItems;
    // eslint-disable-next-line
  }, [libraryItems, pendingElements]);

  const publishedItems = useMemo(
    () => libraryItems.filter((item) => item.status === "published"),
    [libraryItems],
  );

  const storeElement = (selectedCatagoryForExportCanvas: Category[]) => {
    const storedElements: {
      categoryId: string;
      templateId: string;
      elements: any[];
    }[] = [];

    selectedCatagoryForExportCanvas.forEach((category) => {
      category.elements.forEach((element) => {
        if (element.categoryId === category.categoryId) {
          const existingEntryIndex = storedElements.findIndex(
            (entry) =>
              entry.templateId === element.templateId &&
              entry.categoryId === element.categoryId,
          );

          if (existingEntryIndex !== -1) {
            storedElements[existingEntryIndex].elements.push(element);
          } else {
            storedElements.push({
              categoryId: element.categoryId,
              templateId: element.templateId,
              elements: [element],
            });
          }
        }
      });
    });
    return storedElements;
  };

  const modifyCoordinates = (selectedCatagoryForExportCanvas: Category[]) => {
    if (selectedCatagoryForExportCanvas.length === 0) {
      return [];
    }

    const elements_ = storeElement(selectedCatagoryForExportCanvas);

    const spacing = 60;
    let previousGroupEndX = 0;

    const updatedElements = elements_.flatMap((category, index) => {
      const groupWidth = category.elements.reduce((maxX, element) => {
        return Math.max(maxX, element.x + element.width);
      }, 0);

      const updatedCategoryElements = category.elements.map((element) => {
        const alreadyElementonCanvas = elements;

        let newX = element.x + previousGroupEndX + (index === 0 ? 0 : spacing);

        if (alreadyElementonCanvas.length) {
          const maxCanvasX = alreadyElementonCanvas.reduce((maxX, element) => {
            return Math.max(maxX, element.x + element.width);
          }, 0);
          newX += maxCanvasX;
        }

        return {
          ...element,
          x: newX,
        };
      });

      // previousGroupEndX += groupWidth + spacing;
      previousGroupEndX += groupWidth - 300;

      return updatedCategoryElements;
    });

    return updatedElements;
  };

  const isMobile = useIsMobile();

  useEffect(() => {
    const handleAddToLibrary = async () => {
      for (const item of unpublishedItems) {
        if (item.id === null) {
          await onAddToLibrary(item.elements);
        }
      }
    };
    const selectedItems = JSON.parse(
      localStorage.getItem("selectedElement") || "[]",
    );
    if (!appState.editingLibrary.isEditing) {
      handleAddToLibrary();
    } else if (
      selectedItems.length === 0 &&
      !appState.editingLibrary.isEditing
    ) {
      handleAddToLibrary();
    }
    // eslint-disable-next-line
  }, [unpublishedItems, onAddToLibrary]);

  const [search, setSearch] = useState<string>("");
  const [showRemoveLibAlert, setShowRemoveLibAlert] = useState(false);
  const [previewDialog, setPreviewDialog] = useState<
    LibraryItem["elements"] | null
  >();
  const [spinWheelPreviewDialog, setSpinWheelPreviewDialog] = useState<{
    id: string;
    title: string;
    description: string;
    isPublic: boolean;
    isTimer: boolean;
    timerDuration: {
      [timerDuration.MINUTES]: number;
      [timerDuration.SECONDS]: number;
    };
    wheels: SegmentType[];
  } | null>();
  const [saveLib, setSaveLib] = useState<boolean>(false);
  const [isSaveItemConfirmed, setIsSaveItemConfirmed] = useState<boolean>(
    false,
  );

  useEffect(() => {
    setDeleteElement([]);
    setAddToCollection([]);
    // eslint-disable-next-line
  }, [selectedSideLibraryTab]);

  const ExportMultipulCategories = () => {
    if (selectedCatagoryForExportCanvas?.length) {
      const modifiedElements = modifyCoordinates(
        selectedCatagoryForExportCanvas as any,
      );
      if (modifiedElements) {
        return importCustomCategory(modifiedElements as any);
      }
    }
  };

  const loadElementsFromDB = async (
    url: string,
  ): Promise<NonDeleted<ExcalidrawElement>[]> => {
    try {
      const res = await axios.get(`${url}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const elements: NonDeleted<ExcalidrawElement>[] = [];

      if (res.data?.library) {
        res.data.library.forEach((lib: any) => {
          lib.forEach((data: NonDeleted<ExcalidrawElement>) => {
            elements.push(data);
          });
        });
      }
      if (res.data?.libraryItems) {
        res.data.libraryItems.forEach((lib: any) => {
          lib.elements.forEach((data: NonDeleted<ExcalidrawElement>) => {
            elements.push(data);
          });
        });
      }
      return elements;
    } catch (error) {
      console.error("Error loading elements from DB", error);
      return [];
    }
  };

  const loadElementsFromDBforPreview = async (url: string) => {
    axios
      .get(`${url}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        const elements: NonDeleted<ExcalidrawElement>[] = [];

        if (res.data?.library) {
          res.data.library.forEach((lib: any) => {
            lib.forEach((data: NonDeleted<ExcalidrawElement>) => {
              elements.push(data);
            });
          });
        }

        if (res.data?.libraryItems) {
          res.data.libraryItems.forEach((lib: any) => {
            lib.elements.forEach((data: NonDeleted<ExcalidrawElement>) => {
              elements.push(data);
            });
          });
        }

        const slug = new URLSearchParams(window.location.search).get("slug");
        const room = new URLSearchParams(window.location.search).get("room");
        const lessonId = new URLSearchParams(window.location.search).get(
          "lessonId",
        );

        if (room) {
          const finalURL = `/?slug=${slug}&lessonId=${lessonId}/#room=${room}/#addLibrary=${url}`;
          const libraryId = finalURL.split("#addLibrary=")[1];
          const urlArray = libraryId ? libraryId.split(",") : [];
          importLibraryFromUrl(urlArray);
        } else {
          const finalURL = `/?slug=${slug}&lessonId=${lessonId}/#addLibrary=${url}`;
          const libraryId = finalURL.split("#addLibrary=")[1];
          const urlArray = libraryId ? libraryId.split(",") : [];
          importLibraryFromUrl(urlArray);
        }

        setAppState({ isLibraryOpen: false });
        return res.data;
      });
  };

  const renderRemoveLibAlert = useMemo(() => {
    const unpublishedItemsIds = libraryItems
      .filter((item) => item.status === "unpublished")
      .map((item) => item.id);
    const lesson = JSON.parse(localStorage.getItem("lesson") || "{}");
    const lessonId = lesson.Title ? lesson.Title : lesson?.LessonId;

    const content = deleteElelement.length
      ? t("alerts.removeItemsFromsLibrary", { count: deleteElelement.length })
      : appState.editingLibrary.isEditing
      ? t("confirmation.areYousure")
      : t("alerts.resetLibrary");
    const title = deleteElelement.length
      ? t("confirmDialog.removeItemsFromLib")
      : appState.editingLibrary.isEditing
      ? t("confirmation.editCollection")
      : t("confirmDialog.resetLibrary");

    return (
      <ConfirmDialog
        onConfirm={async () => {
          if (deleteElelement.length) {
            onRemoveFromLibrary();
            setDeleteElement([]);
            setAddToCollection([]);
            const user = await getUserInfo();
            libraryItems.length &&
              (await removeUndpublishedCollectionRequestAPI(
                selectedItems,
                user.mail,
                lessonId,
              ));
          } else {
            resetLibrary();
            setDeleteElement([]);
            setAddToCollection([]);
            setAppState({
              editingLibrary: {
                libraryId: "",
                isEditing: false,
                //@ts-ignore
                libraryInfo: {},
                libraryItems: [],
                oldElements: [],
              },
              selectedElementIds: {},
              selectedLinearElement: null,
            });
            const user = await getUserInfo();
            libraryItems.length &&
              (await removeUndpublishedCollectionRequestAPI(
                unpublishedItemsIds,
                user.mail,
                lessonId,
              ));
          }
          setShowRemoveLibAlert(false);
        }}
        onCancel={() => {
          setShowRemoveLibAlert(false);
          setDeleteElement([]);
          setSelectedItems([]);
        }}
        title={title}
        children={<p>{content}</p>}
        closeOnClickOutside={false}
        open={false}
        setOpen={() => false}
      ></ConfirmDialog>
    );
    // eslint-disable-next-line
  }, [
    deleteElelement,
    onRemoveFromLibrary,
    resetLibrary,
    selectedItems,
    libraryItems,
  ]);

  const confirmationDialog = useMemo(() => {
    return (
      <ConfirmDialog
        onConfirm={() => {
          let isCloseModal = false;
          window.parent.postMessage(
            { type: "STORE_ELEMENT", isLoading: true },
            `${process.env.REACT_APP_PARENT_APP}`,
          );
          const lesson = JSON.parse(localStorage.getItem("lesson") || "{}");
          const uploadedLibraryItems = JSON.parse(
            localStorage.getItem("uploadedLibraryItems") || "[]",
          );
          const combinedElements = uploadedLibraryItems.flatMap(
            (item: LibraryItem) => item.elements,
          );
          const lessonId = lesson.Title ? lesson.Title : lesson?.LessonId;
          combinedElements &&
            combinedElements.map(
              (element: any) =>
                (element.lessonId = lessonId ? lessonId : element.lessonId),
            );

          setAppState({
            showElementOnCanvasOrNot: false,
          });

          localStorage.removeItem("uploadedLibraryItems");
          if (combinedElements.length) {
            isCloseModal = true;
            return onInsertShape(combinedElements, isCloseModal);
          }
        }}
        onCancel={() => {
          setAppState({ showElementOnCanvasOrNot: false });
          localStorage.removeItem("uploadedLibraryItems");
        }}
        title={`Are you sure?`}
        children={
          <>
            <p>{`Are you sure you want to add this element to the canvas?`}</p>
          </>
        }
        confirmText="Yes"
        cancelText="No"
        closeOnClickOutside={false}
        open={false}
        setOpen={() => false}
      ></ConfirmDialog>
    );
    // eslint-disable-next-line
  }, [appState.showElementOnCanvasOrNot]);

  const isSaveItemConfirmedDialog = useMemo(() => {
    return (
      <ConfirmDialog
        onConfirm={() => setSaveLib(true)}
        onCancel={() => {
          setIsSaveItemConfirmed(false);
        }}
        title={`Are you sure?`}
        children={
          <>
            <p>{`You have not selected all the items in the template, only the selected ${
              selectedItems.length && selectedItems.length
            } will be saved and you will lose the others. Are you sure?`}</p>
          </>
        }
        confirmText="Yes"
        cancelText="No"
        closeOnClickOutside={false}
        open={false}
        setOpen={() => false}
      ></ConfirmDialog>
    );
    // eslint-disable-next-line
  }, [isSaveItemConfirmed]);

  const addCollectionIdToUnPublishedItems = (collectionId: string | null) => {
    const nextLibItems = [...libraryItems];
    nextLibItems.forEach((libItem) => {
      libItem.collectionId = collectionId;
    });
    library.saveLibrary(nextLibItems);
    setLibraryItems(nextLibItems);
  };

  const renderSelectedNavItemData = useMemo(() => {
    switch (selectedSideLibraryTab) {
      case SidebarItemsEnum.WorkInProgress:
        return (
          <>
            <WorkInProgressLibraryTab
              files={files}
              setSelectedItems={setSelectedItems}
              onInsertShape={onInsertShape}
              selectedItems={selectedItems}
              selectedTab={selectedSideLibraryTab}
              unpublishedItems={filterUnpublishedItems}
              setDeleteElement={setDeleteElement}
              deleteElelement={deleteElelement}
              addToCollection={addToCollection}
              setAddToCollection={setAddToCollection}
              setPreviewDialog={setPreviewDialog}
              appState={appState}
              resetScene={resetScene}
              setAppState={setAppState}
              saveLib={saveLib}
              getSelectedItems={getSelectedItems}
              onPublishLibSuccess={onPublishLibSuccess}
              isTrusted={isTrusted}
              library={library}
              libraryItems={libraryItems}
              setLibraryItems={setLibraryItems}
              setSaveLib={setSaveLib}
              addCollectionIdToUnPublishedItems={
                addCollectionIdToUnPublishedItems
              }
            />
          </>
        );
      case SidebarItemsEnum.AddedFromCollections:
        return (
          <>
            <AddedFromCollectionsTab
              files={files}
              setSelectedItems={setSelectedItems}
              onInsertShape={onInsertShape}
              selectedItems={selectedItems}
              selectedTab={selectedSideLibraryTab}
              publishedItems={publishedItems}
              setDeleteElement={setDeleteElement}
              deleteElelement={deleteElelement}
              addToCollection={addToCollection}
              setAddToCollection={setAddToCollection}
              setPreviewDialog={setPreviewDialog}
              appState={appState}
              resetScene={resetScene}
              setAppState={setAppState}
            />
          </>
        );

      case SidebarItemsEnum.PublicSpinWheel:
        return (
          <PublicSpinWheel
            setPreviewDialog={setSpinWheelPreviewDialog}
            handleSpinnerDialog={handleSpinnerDialog}
            search={search}
          />
        );

      default:
        return (
          <CustomCategory
            categoryId={selectedCategoryId}
            setAppState={setAppState}
            appState={appState}
            editCollection={editCollection}
            setSelectedSideLibraryTab={setSelectedSideLibraryTab}
            setPreviewTemplateData={setPreviewTemplateData}
            loadElementsFromDB={async (source) =>
              await loadElementsFromDB(source)
            }
            setFullScreenCollectionPreview={() => {
              setFullScreenCollectionPreview(!fullScreenCollectionPreview);
            }}
            search={search}
            setSearch={setSearch}
            selectedCatagoryForExportCanvas={selectedCatagoryForExportCanvas}
            setSelectedCatagoryForExportCanvas={
              setSelectedCatagoryForExportCanvas
            }
            addCollectionIdToUnPublishedItems={
              addCollectionIdToUnPublishedItems
            }
          />
        );
    }
    // eslint-disable-next-line
  }, [
    files,
    selectedItems,
    selectedSideLibraryTab,
    unpublishedItems,
    deleteElelement,
    addToCollection,
    publishedItems,
    setAddToCollection,
    setDeleteElement,
    onInsertShape,
    setSelectedItems,
    setPreviewDialog,
    appState,
    search,
    selectedCatagoryForExportCanvas,
    saveLib
  ]);

  const renderPreviewDialog = useMemo(() => {
    return (
      <AcvLibraryPreviewDialog
        onBack={() => {
          setFullScreenCollectionPreview(false);
        }}
        previewTemplateData={previewTemplateData}
        setPreviewTemplateData={setPreviewTemplateData}
        setAppState={setAppState}
        loadElementsFromDB={loadElementsFromDBforPreview}
        isShowTab={false}
        onInsertShape={onInsertShape}
        setFullScreenCollectionPreview={() => {
          setFullScreenCollectionPreview(true);
        }}
        check_={true}
      />
    );
    // eslint-disable-next-line
  }, [previewTemplateData, onInsertShape]);

  if (fullScreenCollectionPreview) {
    return renderPreviewDialog;
  }

  const getTranslationForSidebarItem = (
    selectedSideLibraryTab: SidebarItemsEnum,
  ) => {
    switch (selectedSideLibraryTab) {
      case SidebarItemsEnum.WorkInProgress:
        return t("myLibrary.WorkInProgress");
      case SidebarItemsEnum.AddedFromCollections:
        return t("myLibrary.AddedFromCollections");
      case SidebarItemsEnum.PublicSpinWheel:
        return t("myLibrary.PublicSpinWheel");
      default:
        return selectedSideLibraryTab;
    }
  };

  const selectedStaticCategoryIds = ["", "1", "2", "3"];
  const translation = getTranslationForSidebarItem(selectedSideLibraryTab);

  return (
    <>
      {/* sidebard */}
      <div
        className="pt-3"
        style={{
          height:
            isMobile || selectedStaticCategoryIds.includes(selectedCategoryId)
              ? "13%"
              : "19%",
        }}
      >
        <div className="d-flex form-group has-search">
          {![
            SidebarItemsEnum.WorkInProgress,
            SidebarItemsEnum.AddedFromCollections,
            SidebarItemsEnum.PublicSpinWheel,
          ].includes(selectedSideLibraryTab) && (
            <input
              type="search"
              className="form-control search-input"
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
          )}
        </div>
        <div
          className={
            isMobile
              ? "my-3 justify-content-between"
              : "my-3 d-flex justify-content-between"
          }
        >
          <h4>
            {appState.editingLibrary.isEditing
              ? selectedSideLibraryTab === SidebarItemsEnum.WorkInProgress
                ? t("collection.editCollection")
                : selectedSideLibraryTab
              : translation}
          </h4>
          {!selectedStaticCategoryIds.includes(selectedCategoryId) &&
          selectedCatagoryForExportCanvas?.length ? (
            allTemplateIds.length ? (
              <div className="d-flex align-items-center gap-1 position-relative">
                <button
                  type="button"
                  className="export position-relative"
                  style={{
                    color: "white",
                    background: "#585A96",
                    padding: "6px",
                  }}
                  onClick={ExportMultipulCategories}
                >
                  Export Collection
                </button>

                {allTemplateIds.length ? (
                  <span
                    className="p-2 border border-light rounded-circle selectedCatagoryLibraryatab position-absolute"
                    style={{ backgroundColor: "green" }}
                  >
                    {allTemplateIds.length ? allTemplateIds.length : null}
                  </span>
                ) : null}
              </div>
            ) : null
          ) : null}

          {(selectedSideLibraryTab === SidebarItemsEnum.WorkInProgress ||
            selectedSideLibraryTab ===
              SidebarItemsEnum.AddedFromCollections) && (
            <div className="library-menu-items-container d-flex align-items-center gap-3">
              <div className="layer-ui__library-header" key="library-header">
                <ActionButtons
                  selectedItems={selectedItems}
                  libraryItems={libraryItems}
                  deleteElelement={deleteElelement}
                  addToCollection={addToCollection}
                  setAddToCollection={setAddToCollection}
                  setDeleteElement={setDeleteElement}
                  library={library}
                  setAppState={setAppState}
                  setShowRemoveLibAlert={setShowRemoveLibAlert}
                  onPublish={onPublish}
                  isCollectionEdit={appState.editingLibrary.isEditing}
                  setSaveLib={setSaveLib}
                  saveLib={saveLib}
                  setIsSaveItemConfirmed={setIsSaveItemConfirmed}
                  selectedSideLibraryTab={selectedSideLibraryTab}
                  setSelectedItems={setSelectedItems}
                />
              </div>
              {/* <div className="d-flex align-items-center gap-1">
                <span className="fs-5 fw-normal text-nowrap">Assign to :</span>
                <button type="button" className="assignBtn">
                  Brainstorming
                </button>
              </div> */}
            </div>
          )}
        </div>
      </div>

      {renderSelectedNavItemData}
      {showRemoveLibAlert && renderRemoveLibAlert}
      {appState.showElementOnCanvasOrNot && confirmationDialog}
      {isSaveItemConfirmed && isSaveItemConfirmedDialog}
      {previewDialog && (
        <PreviewDialog
          onClose={() => setPreviewDialog(null)}
          elements={previewDialog}
          files={files}
          onInsertShape={onInsertShape}
        />
      )}
      {spinWheelPreviewDialog && (
        <SpinWheelPreviewDialog
          onClose={() => setSpinWheelPreviewDialog(null)}
          elements={spinWheelPreviewDialog}
          handleSpinnerDialog={handleSpinnerDialog}
        />
      )}
    </>
  );
};
