import React, { useEffect, useRef, useState } from "react";
import { AppState } from "../../types";

interface CelebrationProps {
  trigger: boolean;
  setAppState: React.Component<any, AppState>["setState"];
  selectedNameOrTopic: string | null;
}

export const Celebration: React.FC<CelebrationProps> = ({
  trigger,
  setAppState,
  selectedNameOrTopic,
}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (!trigger) {
      return;
    }

    setIsAnimating(trigger); // Start animation when trigger is true
  }, [trigger]);

  useEffect(() => {
    if (!isAnimating) {
      return;
    }

    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }

    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return;
    }

    const confettiPieces: Confetti[] = [];

    class Confetti {
      x: number;
      y: number;
      color: string;
      size: number;
      speedX: number;
      speedY: number;
      rotation: number;
      rotationSpeed: number;
      active: boolean;

      constructor(x: number, y: number, color: string) {
        this.x = x;
        this.y = y;
        this.color = color;
        this.size = Math.random() * 8 + 4;
        this.speedX = Math.random() * 2 - 1;
        this.speedY = Math.random() * 3 + 3;
        this.rotation = Math.random() * 360;
        this.rotationSpeed = Math.random() * 6 - 3;
        this.active = true;
      }

      update() {
        this.y += this.speedY;
        this.x += this.speedX;
        this.rotation += this.rotationSpeed;
        if (!canvas) {
          return;
        }
        if (
          this.y > canvas.height ||
          this.x > canvas.width ||
          this.x < -this.size
        ) {
          this.active = false;
        }
      }

      draw() {
        if (!ctx || !this.active) {
          return;
        }
        ctx.save();
        ctx.translate(this.x, this.y);
        ctx.rotate((this.rotation * Math.PI) / 180);
        ctx.fillStyle = this.color;
        ctx.fillRect(-this.size / 2, -this.size / 2, this.size, this.size);
        ctx.restore();
      }
    }

    const createConfetti = () => {
      const confettiCount = 300;
      for (let i = 0; i < confettiCount; i++) {
        const x = Math.random() * canvas.width;
        const y = Math.random() * canvas.height * 0.9;
        const color = `hsl(${Math.random() * 360}, 100%, 50%)`;
        confettiPieces.push(new Confetti(x, y, color));
      }
    };

    const drawName = () => {
      if (!ctx) return;

      // Set text properties
      ctx.font = "bold 48px Arial";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";

      // Create gradient for text
      const gradient = ctx.createLinearGradient(
        canvas.width / 2 - 100,
        canvas.height / 2,
        canvas.width / 2 + 100,
        canvas.height / 2,
      );
      gradient.addColorStop(0, "#FF4D4D");
      gradient.addColorStop(0.5, "#FFD700");
      gradient.addColorStop(1, "#FF4D4D");

      ctx.fillStyle = gradient;

      // Add slight shadow for better visibility
      ctx.shadowColor = "rgba(0, 0, 0, 0.3)";
      ctx.shadowBlur = 4;
      ctx.shadowOffsetX = 2;
      ctx.shadowOffsetY = 2;

      // Draw the name
      ctx.fillText(
        selectedNameOrTopic as string,
        canvas.width / 2,
        canvas.height / 2,
      );

      // Reset shadow
      ctx.shadowColor = "transparent";
    };

    let animationFrameId: number;

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Draw name first so confetti appears on top
      selectedNameOrTopic && drawName();

      confettiPieces.forEach((piece) => {
        piece.update();
        piece.draw();
      });

      const activeConfetti = confettiPieces.filter((piece) => piece.active);

      if (activeConfetti.length > 0) {
        animationFrameId = requestAnimationFrame(animate);
      } else {
        setAppState({ celebration: false });
        setIsAnimating(false);
      }
    };

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    createConfetti();
    animate();

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [isAnimating]);

  if (!isAnimating) {
    return null;
  }

  return (
    <canvas
      ref={canvasRef}
      style={{ position: "fixed", top: 0, left: 0, zIndex: 9999 }}
    />
  );
};
