import axios from "axios";
import oc from "open-color";
import { useContext, useEffect, useState } from "react";
import {
  EXPORT_DATA_TYPES,
  EXPORT_SOURCE,
  VERSIONS,
} from "../../../../../../constants";
import Library from "../../../../../../data/library";
import { ExportedLibraryData } from "../../../../../../data/types";
import { ExcalidrawElement, NonDeleted } from "../../../../../../element/types";
import { exportToSvg } from "../../../../../../scene/export";
import {
  AppState,
  BinaryFiles,
  LibraryData,
  LibraryItem,
  LibraryItems,
} from "../../../../../../types";
import { uploadSVGToAzureBlob } from "../../../../../../utils/uploadImageToAzureBlob";
import { useIsMobile } from "../../../../../App";
import { UserProfileContext } from "../../../../../contexts/UserProfile.context";
import { uploadFilesToBlobStorage } from "../../../../../PublishLibrary";
import { Toast, ToastType } from "../../../../../Toast";
import CardWithEditing from "../../../../components/card/CardwithEditing";
import { CardWithSvg } from "../../../../components/card/CardWithSvg";
import {
  CollectionSidebarItemsEnum,
  SidebarItemsEnum,
} from "../../../../components/constant/SidebarItems";
import { STORAGE_KEYS } from "../../../../../../excalidraw-app/data/localStorage";
interface WorkInProgressLibraryTabProps {
  unpublishedItems: (
    | LibraryItem
    | {
        id: null;
        elements: readonly NonDeleted<ExcalidrawElement>[];
        collectionId: string | null;
      }
  )[];
  files: BinaryFiles;
  setDeleteElement: (val: number[]) => void;
  deleteElelement: number[];
  addToCollection: number[];
  setAddToCollection: (value: number[]) => void;
  selectedTab: SidebarItemsEnum | CollectionSidebarItemsEnum;
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>;
  onInsertShape: (elements: LibraryItem["elements"]) => void;
  selectedItems: LibraryItem["id"][];
  setPreviewDialog: (val: LibraryItem["elements"] | null) => void;
  appState: AppState;
  resetScene: (opts?: { resetLoadingState: boolean }) => void;
  setAppState: React.Component<any, AppState>["setState"];
  saveLib: boolean;
  getSelectedItems: (
    libraryItems: LibraryItems,
    selectedItems: LibraryItem["id"][],
  ) => LibraryItems;
  onPublishLibSuccess: (data: LibraryData) => void;
  isTrusted: number;
  library: Library;
  libraryItems: LibraryItems;
  setLibraryItems: (val: LibraryItems) => void;
  setSaveLib: (val: boolean) => void;
  addCollectionIdToUnPublishedItems: (id: string | null) => void;
}

export const WorkInProgressLibraryTab = (
  props: WorkInProgressLibraryTabProps,
) => {
  const {
    unpublishedItems,
    files,
    setDeleteElement,
    deleteElelement,
    addToCollection,
    setAddToCollection,
    selectedTab,
    setSelectedItems,
    onInsertShape,
    selectedItems,
    setPreviewDialog,
    appState,
    resetScene,
    setAppState,
    saveLib,
    getSelectedItems,
    onPublishLibSuccess,
    isTrusted,
    library,
    libraryItems,
    setSaveLib,
    addCollectionIdToUnPublishedItems,
  } = props;
  const isMobile = useIsMobile();
  const { image } = useContext(UserProfileContext);

  const [toastMessage, setToastMessage] = useState<{
    message: string;
    type: ToastType;
  } | null>(null);

  const [uploadedFiles, setUploadedFiles] = useState<BinaryFiles[]>([]);

  useEffect(() => {
    if (files && unpublishedItems && unpublishedItems.length > 0) {
      const allIds = unpublishedItems.flatMap((fileData) =>
        // @ts-ignore
        fileData?.elements?.map((element) => element?.fileId),
      );
      const matchingObjects = Object.keys(files)
        .filter((id) => allIds.includes(id))
        .map((id) => files[id]);
      setUploadedFiles([...matchingObjects] as any);
    }
  }, [unpublishedItems]);

  const handleSaveLibrary = async (item: any) => {
    let fileSizeGB = 0;
    let previewImg: string[] = [];
    const svg = await exportToSvg(
      item.elements,
      {
        exportBackground: false,
        viewBackgroundColor: oc.white,
        frameRendering: appState.frameRendering,
      },
      files,
    );

    const url = (await uploadSVGToAzureBlob(svg, "collecations")) || "";

    const response = await fetch(url);
    const blob = await response.blob(); // Get the file as a blob

    const sizeInBytes: number = blob.size; // Size in bytes
    const sizeInGB = sizeInBytes / Number(1024 * 1024 * 1024); // Size in GB

    fileSizeGB += sizeInGB;

    previewImg = [url || ""];

    const libContent: ExportedLibraryData = {
      type: EXPORT_DATA_TYPES.excalidrawLibrary,
      version: VERSIONS.excalidrawLibrary,
      source: EXPORT_SOURCE,
      libraryItems: [item],
    };

    const content = JSON.stringify(libContent, null, 2);
    const lib = new Blob([content], { type: "application/json" });
    const lessonId = new URLSearchParams(window.location.search)
      .get("lessonId")
      ?.replace(/\//g, "");

    const user: any = JSON.parse(localStorage.getItem("user")!);

    const uploadFile = await uploadFilesToBlobStorage(uploadedFiles);

    const formData = new FormData();
    const slug = new URLSearchParams(window.location.search).get("slug");

    formData.append("excalidrawLib", lib);
    formData.append("previewImage", JSON.stringify(previewImg));
    formData.append("previewImageType", "image/png");
    formData.append("title", "InProgress");
    formData.append("authorName", user?.displayName);
    // formData.append("githubHandle", libraryData.githubHandle);
    formData.append("name", `${user?.displayName} InProgress Library`);
    formData.append("description", "");
    // formData.append("twitterHandle", libraryData.twitterHandle);
    formData.append("website", "");
    formData.append("lessonId", lessonId as string);
    formData.append("userId", user.mail);
    formData.append("slug", slug || "");
    formData.append("isPublic", "false");
    formData.append("tag", "[]");
    formData.append("profileImg", image ? image : "");
    formData.append("files", JSON.stringify(uploadFile));
    formData.append("fileSizeInGb", String(fileSizeGB));
    formData.append("role", user.actualRole);
    formData.append("status", "InProgress");

    const apiResponse = await axios
      .post(`${process.env.REACT_APP_LIBRARY_BACKEND}/submit`, formData)
      .catch((err) => {
        console.log(err);
        alert("Something went wrong");
        return;
      });

    if (apiResponse && apiResponse.data) {
      addCollectionIdToUnPublishedItems(apiResponse.data.id);
      return apiResponse.data.id;
    }
  };

  return (
    <>
      <div
        className="row overflow-auto"
        style={{
          position: "relative",
          top: isMobile ? "7%" : "",
          height: "calc(100% - 23%)",
        }}
      >
        {appState.editingLibrary.isEditing && !saveLib && (
          <div
            className="col-lg-4 col-md-6 col-sm-6 col-xs-12 cursor-pointer"
            onClick={() => {
              setAppState({ isLibraryOpen: false });
              const isMyWorkSpace = localStorage.getItem("isMyWorkSpace");
              localStorage.removeItem(
                isMyWorkSpace === "true"
                  ? STORAGE_KEYS.LOCAL_STORAGE_WORKSPACE_ELEMENTS
                  : STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS,
              );
              resetScene({ resetLoadingState: true });
              setAppState({
                editingLibrary: {
                  ...appState.editingLibrary,
                  isEditing: true,
                },
              });
              localStorage.removeItem("selectedElement");
            }}
          >
            <div className={`card preview-card mb-3`}>
              <div className="card-img-top">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 12.75H6C5.59 12.75 5.25 12.41 5.25 12C5.25 11.59 5.59 11.25 6 11.25H18C18.41 11.25 18.75 11.59 18.75 12C18.75 12.41 18.41 12.75 18 12.75Z"
                    fill="#292D32"
                  />
                  <path
                    d="M12 18.75C11.59 18.75 11.25 18.41 11.25 18V6C11.25 5.59 11.59 5.25 12 5.25C12.41 5.25 12.75 5.59 12.75 6V18C12.75 18.41 12.41 18.75 12 18.75Z"
                    fill="#292D32"
                  />
                </svg>
              </div>
            </div>
          </div>
        )}
        {saveLib ? (
          <CardWithEditing
            libraryItems={
              getSelectedItems(libraryItems, selectedItems) as LibraryItems
            }
            appState={appState}
            onSuccess={onPublishLibSuccess}
            onError={(error) => window.alert(error)}
            updateItemsInStorage={() => library.saveLibrary(libraryItems)}
            onRemove={(id: string) =>
              setSelectedItems(selectedItems.filter((_id) => _id !== id))
            }
            files={files}
            isTrusted={isTrusted}
            setSaveLib={setSaveLib}
            setDeleteElement={setDeleteElement}
            setAddToCollection={setAddToCollection}
            setSelectedItems={setSelectedItems}
          />
        ) : (
          unpublishedItems.map((item, index) => (
            <CardWithSvg
              key={index}
              item={item}
              index={index}
              addToCollection={addToCollection}
              setAddToCollection={setAddToCollection}
              files={files}
              setDeleteElement={setDeleteElement}
              deleteElelement={deleteElelement}
              selectedTab={selectedTab}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              setPreviewDialog={setPreviewDialog}
              onInsertShape={onInsertShape}
              appState={appState}
              resetScene={resetScene}
              setAppState={setAppState}
              isMove={true}
              onSaveInProgressLibrary={() => handleSaveLibrary(item)}
              setToastMessage={setToastMessage}
              onAfterMovedToCategory={() => {}}
              templateId={item.collectionId ? item.collectionId : null}
              addCollectionIdToUnPublishedItems={
                addCollectionIdToUnPublishedItems
              }
            />
          ))
        )}
        {toastMessage !== null && (
          <Toast
            type={toastMessage.type}
            message={toastMessage.message}
            clearToast={() => setToastMessage(null)}
            className="style"
          />
        )}
      </div>
    </>
  );
};
