import axios from "axios";
import { useEffect, useRef, useState } from "react";
import docIcon from "../../../../../../assests/doc.png";
import pdfIcon from "../../../../../../assests/pdf.png";
import pptIcon from "../../../../../../assests/pptx.png";
import { getUserUsedStorage } from "../../../../../../excalidraw-app/api/collection";
import { getSettingData } from "../../../../../../excalidraw-app/api/userAPI";
import { formatFileSize } from "../../../../../../utils/fileSize";
import { Dialog } from "../../../../../Dialog";
import { Toast, ToastType } from "../../../../../Toast";
import { extractTextFromDOC, getDOCPageCount } from "./media/doc";
import { extractTextFromPDF, getPDFPageCount } from "./media/pdf";
import { extractTextFromPPT, getPPTSlideCount } from "./media/ppt";
import "./styles.scss";
interface AttachementModalProps {
  setOpen: (open: boolean) => void;
  open: boolean;
  selectedFiles: File[];
  extractedText: string;
  onAttachFiles: (files: File[], extractedText: string) => void;
  externalSources: {
    sources: string[];
    providedExternalSources: number;
    providedStorage: number;
    usedStorage: number;
  };
}

export const AttachementModal = (props: AttachementModalProps) => {
  const {
    setOpen,
    selectedFiles: exsitingFiles,
    open,
    onAttachFiles,
    extractedText: existingExtractedText,
    externalSources,
  } = props;

  // const [extractedText, setExtractedText] = useState<string>("");

  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [invalidFiles, setInvalidFiles] = useState<string[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>(exsitingFiles);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isShowFullScreen, setIsShowFullScreen] = useState<boolean>(false);

  const slug = new URLSearchParams(window.location.search).get("slug") || "";
  const user: any = JSON.parse(localStorage.getItem("user")!);
  useEffect(() => {
    const token = localStorage.getItem("access_token");
    const validSlug = slug ?? "";
    axios
      .post(
        `${process.env.REACT_APP_ACV_BACKEND_API}/api/startup/get-DB-data?slug=${validSlug}`,
        {
          tenantId: "12d105e3-e51a-4c7f-843b-50046ad42224",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        // setSubscription(res.data.subscription);
      })
      .catch((err) => {
        console.error(err);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Set a timer to hide the section after 3 seconds
    const timer = setTimeout(() => {
      setIsError(false);
    }, 5000);

    // Clear the timer if the component unmounts
    return () => clearTimeout(timer);
  }, [isError]);

  // Handle file selection
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    //check storage is available or not
    const usedStorage = await getUserUsedStorage(
      slug,
      user.mail,
      user.actualRole,
    );
    if (
      externalSources.providedExternalSources < selectedFiles.length ||
      externalSources.providedExternalSources < externalSources.sources?.length
    ) {
      setToastMessage("You have reached the limit of external sources");
    } else if (
      usedStorage?.result?.usedStorage >= usedStorage?.result?.providedStorage
    ) {
      window.parent.postMessage(
        { type: "STORAGE_REQUEST" },
        `${process.env.REACT_APP_PARENT_APP}`,
      );
      return;
    }
    const acceptedFileTypes = [".pdf", ".doc", ".docx", ".ppt", ".pptx"];
    const files = event.target.files;

    const validFiles: File[] = [];
    const invalidFiles: string[] = [];
    if (files) {
      Array.from(files).forEach((file) => {
        const fileExtension = `.${(
          file.name.split(".").pop() || ""
        ).toLowerCase()}`;
        if (acceptedFileTypes.includes(fileExtension)) {
          validFiles.push(file);
        } else {
          invalidFiles.push(file.name);
        }
      });
      if (invalidFiles.length > 0) {
        setIsError(true);
      }
      setInvalidFiles(invalidFiles);
      const totalSizeInGB = Array.from(validFiles).reduce((total, file) => {
        return total + file.size / (1024 * 1024 * 1024);
      }, 0);
      if (
        usedStorage?.result?.usedStorage + Number(totalSizeInGB) >=
        usedStorage?.result?.providedStorage
      ) {
        window.parent.postMessage(
          { type: "STORAGE_REQUEST" },
          `${process.env.REACT_APP_PARENT_APP}`,
        );
      } else {
        setSelectedFiles((prevFiles) => [
          ...prevFiles,
          ...Array.from(validFiles),
        ]);
      }
    }
  };

  // Remove file from the list
  const handleRemoveFile = (index: number) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const extractedText = useRef<string>(existingExtractedText);

  const [renderContent, setRenderContent] = useState<JSX.Element[]>([]);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const resetAndRenderFiles = async () => {
      setIsLoading(true);
      setRenderContent([]);
      if (selectedFiles.length === 0) {
        setIsLoading(false);
        return;
      }
      const updatedContent = await Promise.all(
        selectedFiles.map((file, index) => renderFilePreview(file, index)),
      );

      setRenderContent(updatedContent.filter(Boolean) as JSX.Element[]);
      setIsLoading(false);
    };

    resetAndRenderFiles();
    // eslint-disable-next-line
  }, [selectedFiles]);
  let limitSize = 10;
  const checkImportDocumentExceedLimit = async (file: File) => {
    //fir get setting data for pdf size
    const slug = new URLSearchParams(window.location.search).get("slug");

    const validSlug = slug ?? "";
    const res = await getSettingData(validSlug);
    limitSize = res.data[0]?.importedDocumentSizeInMB;
    if (limitSize) {
      const isExceededLimit = file.size > 1024 * 1024 * limitSize;

      return isExceededLimit;
    }
  };

  // Helper function to generate file previews or names
  const renderFilePreview = async (file: File, index: number) => {
    const fileSize = formatFileSize(file.size);
    const checkFileExceedLimit = await checkImportDocumentExceedLimit(file);
    if (checkFileExceedLimit) {
      //remove exceed limt file from selected files
      setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
      return setErrorMessage(
        `${file.name} exceeds the size limit of ${limitSize} MB`,
      );
    }
    const fileExtension = file.name.split(".").pop()?.toUpperCase();
    let newText = "";

    // Extract text based on file type
    if (file.type.startsWith("image/")) {
      extractedText.current = "";
    } else {
      newText = await extractFileText(file, fileExtension);
      extractedText.current = `${extractedText.current} ${newText}`;
    }

    const data = await fetchFileDetails(file, fileSize, fileExtension);
    return renderFileCard(file, index, fileExtension, data);
  };

  // Helper function to extract text based on file extension
  const extractFileText = async (file: File, fileExtension?: string) => {
    switch (fileExtension) {
      case "PDF":
        return await extractTextFromPDF(file);
      case "DOC":
      case "DOCX":
        return await extractTextFromDOC(file);
      case "PPT":
      case "PPTX":
        return await extractTextFromPPT(file);
      default:
        return "";
    }
  };

  // Helper function to fetch file details (size, pages)
  const fetchFileDetails = async (
    file: File,
    fileSize: string,
    fileExtension?: string,
  ) => {
    let pages = 0;

    switch (fileExtension) {
      case "PDF":
        pages = await getPDFPageCount(file);
        break;
      case "DOC":
      case "DOCX":
        pages = await getDOCPageCount(file);
        break;
      case "PPT":
      case "PPTX":
        pages = await getPPTSlideCount(file); // For PPT/PPTX, count the slides
        break;
      default:
        break;
    }

    return { size: fileSize, pages };
  };

  // Helper function to render the file card
  const renderFileCard = (
    file: File,
    index: number,
    fileExtension?: string,
    data?: { size: string; pages: number },
  ) => {
    const isPDF = fileExtension === "PDF";
    const isDOC = fileExtension === "DOC" || fileExtension === "DOCX";
    const isPPT = fileExtension === "PPT" || fileExtension === "PPTX";

    // Render image preview for image files
    if (file.type.startsWith("image/")) {
      return (
        <div key={index} className="file-preview m-2">
          <div className="d-flex justify-content-between w-100 p-2">
            <div className="file-info d-flex flex-column justify-content-start">
              <div className="file-name">{file.name.trim()}</div>
              <div className="file-type">
                {data?.size} {" • "} {fileExtension}
              </div>
            </div>
            <img
              src={URL.createObjectURL(file)}
              alt={file.name}
              className="preview-image"
            />
          </div>
          <button
            className="remove-btn"
            onClick={() => handleRemoveFile(index)}
          >
            &times; {/* Close icon */}
          </button>
        </div>
      );
    }

    // Render file card for PDF, DOC, and PPT files
    if (isPDF || isDOC || isPPT) {
      return (
        <div key={index} className="file-preview m-2">
          <div className="d-flex justify-content-between w-100 p-2">
            <div className="file-info d-flex flex-column justify-content-start">
              <div className="file-name">{file.name.trim()}</div>
              <div className="file-type">
                {data?.size}, {data?.pages} Pages {" • "} {fileExtension}
              </div>
            </div>
            <div className="file-icon">
              <img
                src={isPDF ? pdfIcon : isDOC ? docIcon : pptIcon}
                alt={`${fileExtension} Icon`}
                className="file-icon-img"
              />
            </div>
          </div>
          <button
            className="remove-btn"
            onClick={() => handleRemoveFile(index)}
          >
            &times; {/* Close icon */}
          </button>
        </div>
      );
    }
  };

  return (
    <>
      <Dialog
        isShowFullScreenButton={true}
        setIsShowFullScreen={setIsShowFullScreen}
        isShowFullScreen={isShowFullScreen}
        children={
          <>
            {errorMessage && (
              <Toast
                type={ToastType.ERROR}
                message={errorMessage}
                clearToast={() => {
                  setErrorMessage(""); // Clears the error message state
                }}
                style={{
                  left: "50% !important",
                }}
              />
            )}
            <div className="attachment-modal d-flex flex-column p-3">
              <div className="d-flex flex-column flex-grow-1 ">
                <p className="text-secondary attachment-note">
                  Files and Library will be added to your ACV blob storage
                </p>
                <div>
                  <label
                    htmlFor="file-upload"
                    className="file-btn btn btn-outline"
                  >
                    Add File
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    accept=".pdf,.doc,.docx,.ppt,.pptx"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    multiple // Allow multiple files
                  />
                </div>
                <p
                  style={{
                    fontSize: "12px",
                    color: "#494b83",
                  }}
                  className="m-0 p-0"
                >
                  Note: Supported file types: .pdf, .doc, .docx, .ppt, .pptx.
                </p>
                <div className="attachment-body my-2">
                  {isLoading ? (
                    <div className="d-flex h-100 justify-content-center align-items-center">
                      <span
                        className="loader-for-external-sources"
                        style={{
                          borderLeftColor: "#917ee0",
                        }}
                      ></span>
                    </div>
                  ) : selectedFiles.length > 0 ? (
                    <div className="file-preview-container ">
                      {renderContent}
                    </div>
                  ) : (
                    <p className="m-auto d-flex text-center ">
                      No attached files!!
                    </p>
                  )}
                </div>

                <div>
                  {isError && invalidFiles.length > 0 && (
                    <p
                      className="text-danger"
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      Error: The following files are not supported: <br />
                      {invalidFiles.join(", ")}
                    </p>
                  )}
                </div>
                {/* comment below section for RTM */}
                {/* <div>
                  <label
                    htmlFor="library-upload"
                    className="file-btn btn btn-outline"
                  >
                    Add Library
                  </label>
                </div>
                <div>
                  <label
                    htmlFor="acv-blob"
                    className="file-btn btn btn-outline"
                  >
                    Select from my ACV Blob
                  </label>
                </div>
                <div>
                  <label htmlFor="add-url" className="file-btn btn btn-outline">
                    Add URL
                  </label>
                </div>
                <div>
                  <label
                    htmlFor="open-source"
                    className="file-btn btn btn-outline"
                  >
                    Add Azure open AI Source..
                  </label>
                </div> */}
              </div>

              <div className="attachment-footer d-flex justify-content-between mt-3 flex-wrap">
                <div className="d-flex flex-column">
                  <div className="footer-note">
                    <span
                      style={{
                        color: "#494b83",
                      }}
                    >
                      {externalSources.usedStorage.toFixed(4)} GB{" "}
                    </span>{" "}
                    of {externalSources.providedStorage} GB used
                  </div>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{
                        width: `${
                          (externalSources.usedStorage /
                            externalSources.providedStorage) *
                          100
                        }%`,
                      }}
                      aria-valuenow={25}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    ></div>
                  </div>
                  <div className="footer-note text-secondary">
                    storage usage
                  </div>
                </div>

                <div className="d-flex gap-3 flex-wrap">
                  <button className="cancel-btn" onClick={() => setOpen(false)}>
                    Cancel
                  </button>
                  <button
                    className="update-btn"
                    disabled={!exsitingFiles.length && !selectedFiles.length}
                    onClick={() => {
                      onAttachFiles(selectedFiles, extractedText.current);

                      setOpen(false);
                    }}
                  >
                    Update
                  </button>
                  {toastMessage && (
                    <Toast
                      type={ToastType.ERROR}
                      message={toastMessage}
                      clearToast={() => setToastMessage(null)}
                      className="style"
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        }
        onCloseRequest={() => setOpen(false)}
        closeOnClickOutside={false}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
};
