import React, { useEffect, useState } from "react";
import "./TopicPicker.scss"; // Add your styles here

// Define the type for the props
interface TopicPickerProps {
  setSelectedTopics: (topics: string[]) => void; // Function to update the topics
}

const TopicPicker: React.FC<TopicPickerProps> = ({ setSelectedTopics }) => {
  const [topics, setTopics] = useState<string[]>([]); // Track the list of topics
  const [inputValue, setInputValue] = useState(""); // Track the current input value
  const [error, setError] = useState<string | null>(null);

  // Handle adding a new topic
  const handleAddTopic = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      if (!topics.includes(inputValue.trim())) {
        setTopics([...topics, inputValue.trim()]);
        setInputValue(""); // Clear the input field
        setError(null);
      } else {
        setError("Topic already exists.");
      }
    }
  };

  // Handle removing a topic
  const handleRemoveTopic = (topic: string) => {
    setTopics(topics.filter((t) => t !== topic));
  };

  useEffect(() => {
    setSelectedTopics(topics);
  }, [topics]);

  return (
    <div className="topic-picker">
      <div className="h-100">
        <div className="h-100">
          <div className="card-title w-100">Topic Picker</div>
          {error ? (
            <span className="error_msg">{error}</span>
          ) : (
            <span className={topics.length ? "topic_label" : "topic-label"}>Enter a topic and press Enter</span>
          )}
          {/* Input for adding topics */}
          <input
            type="text"
            className={topics.length ? "topic_input" : "topic-input"}
            placeholder="Enter a topic and press Enter"
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
              setError(null);
            }}
            onKeyDown={handleAddTopic}
          />

          {/* Render selected topics */}
          <div className="topics-container">
            {topics.map((topic) => (
              <div key={topic} className="topic-chip">
                {topic}
                <button
                  className="remove-button"
                  onClick={() => handleRemoveTopic(topic)}
                  aria-label={`Remove ${topic}`}
                >
                  ×
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopicPicker;
