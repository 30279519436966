import Bold from "@tiptap/extension-bold";
import BulletList from "@tiptap/extension-bullet-list";
import { Color } from "@tiptap/extension-color";
import FontFamily from "@tiptap/extension-font-family";
import Highlight from "@tiptap/extension-highlight";
import Italic from "@tiptap/extension-italic";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import Strike from "@tiptap/extension-strike";
import Text from "@tiptap/extension-text";
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import React, { useEffect, useRef, useState } from "react";
import { AppState } from "../../types";
import { StartEditingProps, useIsMobile } from "../App";
import {
  AIcon,
  BoldIcon,
  BulletsIcon,
  ItalicIcon,
  OrderListIcon,
  PenIcon,
  StrikeIcon,
  TextAlignCenterIcon,
  TextAlignLeftIcon,
  TextAlignRightIcon,
  UnderLineIcon,
} from "../icons";
import { Island } from "../Island";
import {
  FontsizeExtension,
  HtmlElementExtension,
  TextSelectionExtension,
} from "./extensions";
import "./TiptapEditor.scss";
// @ts-ignore
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";
import { CustomColorPicker } from "./CustomColorPicker";
import { TextwrapExtension } from "./extensions/TextwrapExtension";

export type EditorType = {
  getHTML: () => string;
};

type TiptapEditorProps = {
  startTextEditing: (data: StartEditingProps) => void;
  appState: AppState;
  onTextWithStylesAction: ({
    textString,
    show,
  }: {
    textString: string;
    show?: boolean;
  }) => void;
  setAppState: React.Component<any, AppState>["setState"];
  top: number;
  left: number;
};

export const TiptapEditor = (props: TiptapEditorProps) => {
  const isMobile = useIsMobile();
  const editorMaxWidth = isMobile
    ? window.innerWidth - props.appState.textEditor.sceneX - 10
    : 500;
  const [align, setAlign] = useState<boolean>(false);
  const [order, setOrder] = useState<boolean>(false);
  const [fontStyle, setFontStye] = useState<boolean>(false);
  const [currentFontFamily, setCurrentFontFamily] = useState<string>("Serif");
  const [currentFontSize, setCurrentFontSize] = useState<number | "">(20);
  const [width, setWidth] = useState<number>(editorMaxWidth); // Initial width of the resizable div
  const [height, setHeight] = useState<number>(55); // Initial height of the resizable div
  const [mount, setMount] = useState<boolean>(false);
  const [editorMaxSize, setEditorMaxSize] = useState({
    maxHeight: 0,
    maxWidth: 0,
  });
  const [openColorPicker, setOpenColorPicker] = useState<boolean>(false);
  const [openTextColorPicker, setOpenTextColorPicker] = useState<boolean>(
    false,
  );
  const [updatedcontent, setUpdatedContent] = useState<string>("");
  const [editorContentHightWidth, setEditorContentHightWidth] = useState({
    height: 0,
    width: 0,
  });

  useEffect(() => {
    const link = document.createElement("link");
    link.href = `https://fonts.googleapis.com/css2?family=${currentFontFamily}:wght@400;500;700&display=swap`;
    link.rel = "stylesheet";
    link.crossOrigin = "anonymous";

    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, [currentFontFamily]);

  const updateFontSize = (htmlString: string, updated: number) => {
    const zoomFactor = updated;

    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");

    // Get all span elements
    let spans = doc.querySelectorAll("span");

    // If there are no span elements, wrap the entire content in a span tag
    if (spans.length === 0) {
      const wrapperSpan = doc.createElement("span");
      wrapperSpan.style.fontSize = "20px";
      while (doc.body.firstChild) {
        wrapperSpan.appendChild(doc.body.firstChild);
      }
      doc.body.appendChild(wrapperSpan);
      spans = doc.querySelectorAll("span"); // Update the spans NodeList
    }

    // Iterate over all span elements
    spans.forEach((span) => {
      // Check if the span has a style attribute and it contains 'font-size'
      const style = span.getAttribute("style");
      if (style && style.includes("font-size")) {
        // Check if the span has a data-original-font-size attribute
        let originalFontSize = span.getAttribute("data-original-font-size");

        if (!originalFontSize) {
          // If not, extract the font-size value from the style attribute
          const fontSizeMatch = style.match(/font-size\s*:\s*([\d.]+)px/);
          if (fontSizeMatch) {
            // Set the original font size as a data attribute
            originalFontSize = fontSizeMatch[1];
            span.setAttribute("data-original-font-size", originalFontSize);
          }
        }

        if (originalFontSize) {
          // Convert the original font size to a number
          const currentFontSize = parseFloat(originalFontSize);

          // Apply the zoom factor and round to 2 decimal places
          const updatedFontSize = (currentFontSize * zoomFactor).toFixed(2);
          // Update the font-size in the style attribute
          span.style.fontSize = `${updatedFontSize}px`;
        }
      }
    });
    // Serialize the document back to a string
    const updatedHtmlString = doc.body.innerHTML;
    return updatedHtmlString;
  };

  // Example usage:
  const calculateFontSizeFromImageWidth = (htmlString?: string) => {
    const updateElementZoomFactor =
      props.appState.updatingTextElement?.zoomFactor;
    // if (updateElementZoomFactor?.isZoomFactorApplied) return;
    // Update font sizes in HTML content based on the calculated zoom factor
    const updatedContent = updateFontSize(
      htmlString ? htmlString : props.appState.textEditor.value,
      updateElementZoomFactor?.zoomFactor || 1,
    );

    if (props.appState.updatingTextElement) {
      const element_ = {
        ...props.appState.updatingTextElement,
        zoomFactor: {
          ...updateElementZoomFactor,
          isZoomFactorApplied: true,
          isFontSizeChanged: false,
        },
      };

      props.setAppState({
        updatingTextElement: element_,
      });
    }

    // Update state with the modified HTML content
    setUpdatedContent(updatedContent);
    // Optionally update app state with the modified HTML content

    props.setAppState({
      textEditor: { ...props.appState.textEditor, value: updatedContent },
    });
  };

  const handleEditorOverflow = () => {
    // localStorage.removeItem("resizewidth");
    // Canvas height and width
    const { width: canvasDOMWidth, height: canvasDOMHeight } = props.appState;
    const heightDifference = canvasDOMHeight - (props.top + 100);
    const widthDifference = canvasDOMWidth - (props.left + 100);

    if (
      props.appState.textEditor.width
      // &&
      // props.appState.textEditor.width < widthDifference
    ) {
      setWidth(props.appState.textEditor.width);
    }
    setEditorMaxSize({
      maxHeight: heightDifference,
      maxWidth: widthDifference,
    });
  };

  const handleZoomFactor = () => {
    //for handle updated element zoom factor
    const updatedElement = props.appState.updatingTextElement;
    if (Number(updatedElement?.zoomFactor?.zoomFactor) === 1) {
      return;
    }
    const currentWidth = editorContentHightWidth.width as number;
    const currentHeight = editorContentHightWidth.height as number;

    const zoomFactor = {
      ...updatedElement?.zoomFactor,
      zoomFactor: 1,
      isZoomFactorApplied: true,
      height: currentHeight,
      width: currentWidth,
      isFontSizeChanged: true,
    };
    if (updatedElement) {
      const element_ = {
        ...updatedElement,
        zoomFactor,
      };
      props.setAppState({
        updatingTextElement: element_,
      });
    }
  };

  const handleContentSize = (value?: string) => {
    const tiptapEditor = document.getElementsByClassName("tiptap")[0];
    const appState = props.appState;
    const contentHeight = tiptapEditor?.clientHeight;
    const contentWidth = tiptapEditor?.clientWidth;
    const {
      width: editorWidth,
      height: editorHeight,
      resizeWidth,
      resize,
    } = appState.textEditor;

    if (resize) {
      setWidth(resizeWidth);
    } else {
      contentWidth < editorMaxSize.maxWidth && setWidth(width);
    }

    contentHeight < editorMaxSize.maxHeight && setHeight(height);
    props.setAppState({
      textEditor: {
        ...appState.textEditor,
        width: editorWidth < contentWidth ? contentWidth : editorWidth,
        height: editorHeight < contentHeight ? contentHeight : editorHeight,
        value: value ? value : appState.textEditor.value,
        resize: resize ? resize : false,
      },
    });
    if (value) {
      handleZoomFactor();
    }
  };

  useEffect(() => {
    if (props.appState.textEditor.open && props.appState.textEditor.value) {
      calculateFontSizeFromImageWidth(editor?.getHTML());
    }
    // eslint-disable-next-line
  }, [props.appState.textEditor.open]);

  useEffect(() => {
    if (mount) {
      handleContentSize();
      handleEditorOverflow();
    }
    // eslint-disable-next-line
  }, [mount]);

  useEffect(() => {
    setMount(true);
  }, []);

  const colorPickerRef = useRef<HTMLInputElement>(null);
  const highlightPickerRef = useRef<HTMLInputElement>(null);

  const fontFamily = [
    "Inter",
    "Comic Sans MS",
    "Serif",
    "Monospace",
    "Cursive",
    "Open Sans",
    "Roboto",
    "Lato",
    "Poppins",
    "Raleway",
    "Montserrat",
    "Oswald",
    "Source Sans Pro",
    "Nunito",
    "Playfair Display",
    "LoRa",
    "Alegreya",
    "PT Sans",
    "Ubuntu",
    "Fira Sans",
    "Rubik",
    "Work Sans",
    "Cormorant",
    "Muli",
    "Roboto Slab",
    "Source Sans Pro font pairing",
    "Impact",
  ];

  const textAlign = [
    { icon: <TextAlignLeftIcon theme={props.appState.theme} />, type: "left" },
    {
      icon: <TextAlignCenterIcon theme={props.appState.theme} />,
      type: "center",
    },
    {
      icon: <TextAlignRightIcon theme={props.appState.theme} />,
      type: "right",
    },
  ];

  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        bulletList: false,
      }),
      Bold,
      Italic,
      Strike,
      Underline,
      TextStyle,
      FontFamily,
      Highlight.configure({
        multicolor: true,
      }),
      TextwrapExtension,
      FontsizeExtension,
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
      Color.configure({
        types: ["textStyle"],
      }),
      Text,
      BulletList.configure({
        keepMarks: true,
      }).extend({
        addKeyboardShortcuts() {
          return {
            Tab: () => {
              const { editor } = this;

              // Get the current selection and the position before it
              const { from } = editor.state.selection;
              const textBefore = editor.state.doc.textBetween(
                from - 1,
                from,
                undefined,
                " ",
              );

              // Check if the last character is a dash
              if (textBefore.trim() === "-") {
                // Create a transaction to delete the previous character (dash)
                editor
                  .chain()
                  .focus()
                  .deleteRange({ from: from - 1, to: from })
                  .run();

                // Toggle bullet list
                editor.commands.toggleBulletList();
                return true; // Indicates the command was handled
              }

              // If not in bullet or ordered list, toggle bullet list
              return this.editor.isActive("bulletList") ||
                this.editor.isActive("orderedList")
                ? editor.commands.sinkListItem("listItem")
                : editor.commands.toggleBulletList();
            },
          };
        },
      }),
      OrderedList.configure({
        itemTypeName: "listItem",
      }),
      ListItem,
      HtmlElementExtension,
      // KeyboardExtension,
      TextSelectionExtension,
    ],
    autofocus: "end",
    content: props.appState.textEditor.value,
    onUpdate: ({ editor }: { editor: EditorType }) => {
      // Now, 'editor' is expected to have a 'getHTML' method that returns a string.

      //update the textstring
      handleContentSize(editor.getHTML());

      //check editor height and width is not overflow of the canvas
      handleEditorOverflow();

      //get the editor height and width
      measureTextWidth(editor.getHTML());
      // eslint-disable-next-line
      return;
    },
    editorProps: {
      handlePaste(view, e, slice) {
        e.preventDefault();
        // Destructure clipboard data
        const { clipboardData } = e;
        // Check for clipboard data types
        const pastedContent = clipboardData?.getData("text/html");

        if (!pastedContent) {
          return false; // No HTML content pasted, nothing to do
        }
      },
    },
  });
  const fontSize = editor?.getAttributes("textStyle")?.fontSize?.fontSize || "";
  const finalFontSize = Math.floor(
    fontSize || editor?.getAttributes("textStyle")?.fontSize || currentFontSize,
  );

  const finalFontFamily =
    editor?.getAttributes("textStyle")?.fontFamily || "Serif";
  const editorContentRef = useRef<HTMLDivElement>(null);

  const measureTextWidth = (htmlContent: any) => {
    // Create a hidden element to render the HTML content
    const hiddenElement = document.createElement("div");
    hiddenElement.style.position = "absolute";
    hiddenElement.style.left = "-9999px";
    hiddenElement.innerHTML = htmlContent;
    document.body.appendChild(hiddenElement);

    // Remove the hidden element from the DOM
    document.body.removeChild(hiddenElement);
    setEditorContentHightWidth({
      height: hiddenElement.offsetHeight + 55,
      width: hiddenElement.offsetWidth,
    });
    return null;
  };

  useEffect(() => {
    if (editor) {
      editor
        .chain()
        .focus()
        // @ts-ignore
        .setFontsize(fontSize ? fontSize : 20)
        .run();
    }
  }, [editor]);

  useEffect(() => {
    if (editor && editor.getHTML() !== props.appState.textEditor.value) {
      editor.commands.setContent(props.appState.textEditor.value);
    }
  }, [props.appState.updatingTextElement, editor]);

  useEffect(() => {
    if (editor) {
      updatedcontent && editor.commands.setContent(updatedcontent);
    }
  }, [editor, updatedcontent]);

  useEffect(() => {
    if (!props.appState.textEditor.resize && editor) {
      editor?.commands.selectAll();
      editor
        ?.chain()
        // @ts-ignore
        .setTextwrap("normal")
        .run();
    }
  }, [props.appState.textEditor.resize, editor]);

  const removeFocus = () => {
    if (!editor) return;
    // @ts-ignore
    editor.chain().removeTextSelection().run();
    // setEditorFocused(false)
  };

  const handleBlur = () => {
    // Function to remove focus from the input
    const inputElement = document.getElementById(
      "fontSize-input",
    ) as HTMLInputElement;
    if (inputElement) {
      removeFocus();
    }
  };

  const handleFocus = () => {
    if (!editor) return;
    const { view, state } = editor;
    const { from, to } = view.state.selection;
    const text = state.doc.textBetween(from, to, "");
    // @ts-ignore
    text && editor.chain().textSelection().run();
  };

  const determineTextEditorWidth = () => {
    const maxWidth = editorMaxWidth;
    //if editor is reszied then set the width
    if (props.appState.textEditor.resize) return width;
    //if editor is not reszied and width is less than 500 then set the width to 500
    if (width < maxWidth) return maxWidth;
    return width;
  };

  if (!editor) return null;

  const handleBoldClick = () => {
    editor.chain().focus().toggleBold().run();
  };

  const handleItalicClick = () => {
    editor.chain().focus().toggleItalic().run();
  };

  const handleStrikeClick = () => {
    editor.chain().focus().toggleStrike().run();
  };

  const handleResize = (
    e: React.ChangeEvent,
    {
      size,
    }: {
      size: { width: number; height: number };
    },
  ) => {
    editor.commands.selectAll();
    editor
      .chain()
      // @ts-ignore
      .setTextwrap(size.width < editorMaxSize.maxWidth ? "normal" : "nowrap")
      .run();

    const tiptapEditor = document.getElementsByClassName("tiptap")[0];
    const height = tiptapEditor?.clientHeight;
    height &&
      props.setAppState({
        textEditor: {
          ...props.appState.textEditor,
          width: size.width,
          height: height < size.height ? size.height : height,
          resizeWidth: size.width,
          resizeHeight: height < size.height ? size.height : height,
          resize: true,
        },
      });

    if (size.width < editorMaxSize.maxWidth) {
      setWidth(size.width);
    }

    if (size.height > height && size.height < editorMaxSize.maxHeight) {
      setHeight(size.height);
    }
  };

  const toggleUnderline = () => {
    editor.chain().focus().toggleUnderline().run();
  };

  const handleFontfamily = (fontStyle: string) => {
    editor.chain().focus().setFontFamily(fontStyle).run();
  };

  const handleTextAlign = (align: string) => {
    editor.chain().focus().setTextAlign(align).run();
  };

  const toggleHighlight = (color: string) => {
    editor.chain().toggleHighlight({ color: color }).run();
  };

  const toggleBullet = () => {
    editor.chain().focus().toggleBulletList().run();
  };

  // Function to toggle ordered list in the editor
  const toggleOrderedList = () => {
    editor.chain().focus().toggleOrderedList().run();
  };

  const handleFontColor = (color: string) => {
    console.log("---color", color);
    editor.chain().focus().setColor(color).run();
  };

  const handleFontsize = (size: number | "") => {
    if (editor && editor.view.state.selection.empty) {
      return;
    }
    // @ts-ignore
    if (size) editor.chain().setFontsize(size).run();
    handleZoomFactor();
  };

  return (
    <div
      className="tiptap-editor"
      style={{
        width: width,
        ...(isMobile
          ? {
              maxWidth: editorMaxWidth,
              zIndex: 999,
              background: props.appState.viewBackgroundColor,
            }
          : {}),
      }}
    >
      <Island
        className={`shdw d-flex mb-${isMobile ? 2 : 5} flex-nowrap`}
        style={{
          ...(isMobile
            ? {
                width: editorMaxWidth,
                overflowX: "auto",
              }
            : { width: "fit-content" }),
        }}
      >
        <div
          className="p-3 d-flex align-items-center cursor-pointer"
          onClick={handleBoldClick}
        >
          <div style={{ width: "18px", height: "18px" }} className="d-flex">
            <BoldIcon theme={props.appState.theme} />
          </div>
        </div>
        <div
          className="p-3 d-flex align-items-center ps-0 cursor-pointer"
          onClick={handleItalicClick}
        >
          <div style={{ width: "18px", height: "18px" }} className="d-flex">
            <ItalicIcon theme={props.appState.theme} />
          </div>
        </div>
        <div
          className="p-3 d-flex align-items-center ps-0 cursor-pointer"
          onClick={toggleUnderline}
        >
          <div style={{ width: "18px", height: "18px" }} className="d-flex">
            <UnderLineIcon theme={props.appState.theme} />
          </div>
        </div>
        <div
          className="p-3 d-flex align-items-center ps-0 cursor-pointer border-end"
          onClick={handleStrikeClick}
        >
          <div style={{ width: "18px", height: "18px" }} className="d-flex">
            <StrikeIcon theme={props.appState.theme} />
          </div>
        </div>
        <div
          className="position-relative border-start border-end d-flex align-items-center justify-content-center px-2 font-family"
          onClick={() => {
            setFontStye(!fontStyle);
          }}
        >
          <span className="preview">{finalFontFamily}</span>
          {fontStyle && (
            <div
              style={{
                zIndex: 999,
                width: "100%",
                height: "100%",
                position: "fixed",
              }}
            >
              <div
                className="d-flex flex-column position-absolute bg-white shadow-sm font-type-list "
                style={{ top: "52%", width: "135px" }}
              >
                {fontFamily.map((font, i) => (
                  <p
                    className="mb-0 border-bottom px-2 py-1 cursor-pointer font-type"
                    key={`${font}-${i}`}
                    style={{ fontFamily: font }}
                    onClick={() => {
                      setCurrentFontFamily(font);
                      setFontStye(false);
                      handleFontfamily(font);
                    }}
                  >
                    {font}
                  </p>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="font-size d-flex align-items-center border-end">
          <input
            autoFocus
            onFocus={handleFocus}
            className="preview"
            id="fontSize-input"
            type="number"
            value={Number(finalFontSize)}
            onChange={(e) => {
              const value = e.target.value;
              const newFontSize = value === "" ? "" : Number(value);
              setCurrentFontSize(newFontSize);
              handleFontsize(newFontSize);
            }}
            onBlur={handleBlur}
          />
        </div>
        <div
          className="px-2 font-color d-flex"
          onClick={() => {
            colorPickerRef.current?.click();
          }}
        >
          <div className="d-flex flex-column align-items-center justify-content-center">
            <div
              style={{ width: "14px", height: "14px" }}
              className="d-flex cursor-pointer"
              onClick={() => {
                setOpenColorPicker(false);
                setOpenTextColorPicker(true);
              }}
            >
              <AIcon theme={props.appState.theme} />
            </div>
          </div>
        </div>
        <div
          className="px-2 font-highlight d-flex border-end"
          onClick={() => {
            highlightPickerRef.current?.click();
          }}
        >
          <div className="d-flex flex-column align-items-center justify-content-center">
            <div
              style={{ width: "14px", height: "14px" }}
              className="d-flex cursor-pointer"
              onClick={() => {
                setOpenTextColorPicker(false);
                setOpenColorPicker(true);
              }}
            >
              <PenIcon theme={props.appState.theme} />
            </div>
          </div>
        </div>

        <div
          className="p-3 position-relative cursor-pointer d-flex align-items-center border-end"
          onClick={() => {
            setAlign(!align);
          }}
        >
          <div style={{ width: "20px", height: "23px" }} className="">
            <TextAlignLeftIcon theme={props.appState.theme} />
          </div>
        </div>
        {align && (
          <div
            className="position-absolute bg-white d-flex shadow-sm cursor-pointer "
            style={{
              bottom: "-41px",
              right: "0px",
              borderRadius: "2px",
              zIndex: 999,
            }}
          >
            {textAlign.map((align, i) => (
              <div
                className="p-2 d-flex"
                key={`align-${i + 1}`}
                onClick={() => {
                  handleTextAlign(align.type);
                  setAlign(false);
                }}
              >
                <div style={{ width: "20px", height: "23px" }} className="">
                  {align.icon}
                </div>
              </div>
            ))}
          </div>
        )}
        <div
          className="p-3  position-relative cursor-pointer d-flex align-items-center"
          onClick={() => {
            setOrder(!order);
          }}
        >
          <div style={{ width: "20px", height: "23px" }} className="">
            <BulletsIcon theme={props.appState.theme} />
          </div>
        </div>
        {order && (
          <div
            className="position-absolute bg-white d-flex shadow-sm cursor-pointer "
            style={{
              bottom: "-41px",
              right: "0px",
              borderRadius: "2px",
              zIndex: 999,
            }}
          >
            <div
              className="p-2 d-flex"
              onClick={() => {
                setOrder(false);
                toggleBullet();
              }}
            >
              <div style={{ width: "20px", height: "23px" }} className="">
                <BulletsIcon theme={props.appState.theme} />
              </div>
            </div>
            <div
              className="p-2 d-flex"
              onClick={() => {
                setOrder(false);
                toggleOrderedList();
              }}
            >
              <div style={{ width: "20px", height: "23px" }} className="">
                <OrderListIcon theme={props.appState.theme} />
              </div>
            </div>
          </div>
        )}
      </Island>
      {openTextColorPicker && (
        <CustomColorPicker
          toggleHighlight={handleFontColor}
          openTextColorPicker={openTextColorPicker}
          openColorPicker={false}
          handleFocus={handleFocus}
          handleBlur={handleBlur}
          onCloseColorPicker={() =>
            setTimeout(() => {
              setOpenTextColorPicker(false);
            }, 100)
          }
        />
      )}
      {openColorPicker && (
        <CustomColorPicker
          toggleHighlight={toggleHighlight}
          openTextColorPicker={false}
          openColorPicker={openColorPicker}
          onCloseColorPicker={() =>
            setTimeout(() => {
              setOpenColorPicker(false);
            }, 100)
          }
          handleFocus={handleFocus}
          handleBlur={handleBlur}
        />
      )}
      <Resizable
        height={height}
        width={width}
        onResize={handleResize}
        // maxConstraints={[left, top]}
      >
        <div
          className="editor-wrapper"
          style={{
            width: determineTextEditorWidth(),
            minHeight: height,
          }}
        >
          <EditorContent
            ref={editorContentRef}
            editor={editor}
            id="tiptap-container"
            style={{
              minWidth: width,
              minHeight: height,
              maxHeight:
                editorMaxSize.maxHeight === 0 ? 55 : editorMaxSize.maxHeight,
              maxWidth:
                editorMaxSize.maxWidth === 0
                  ? editorMaxWidth
                  : editorMaxSize.maxWidth,
              overflow: "auto",
            }}
          />
        </div>
      </Resizable>
    </div>
  );
};
