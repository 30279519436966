import clsx from "clsx";
import { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ExcalidrawFrameElement } from "../../element/types";
import { STORAGE_KEYS } from "../../excalidraw-app/data/localStorage";
import { AppClassProperties, AppState } from "../../types";
import {
  celebrationToolIcon,
  close,
  laserPointerToolIcon,
  presentationToolIcon,
} from "../icons";
import { FrameMenuItem } from "./FrameMenuItem";
import { fitToContent } from "./PresentationMode";
import { ActionManager } from "../../actions/manager";
import MenuItem from "../Actions";

type OutlineSidebarProps = {
  app: AppClassProperties;
  isMobile?: boolean;
  setAppState: React.Component<any, AppState>["setState"];
  appState: AppState;
  actionManager: ActionManager;
  setSelectedNameOrTopic: (val: string) => void;
};

export const OutlineSidebar = ({
  isMobile = false,
  app,
  setAppState,
  appState,
  actionManager,
  setSelectedNameOrTopic,
}: OutlineSidebarProps) => {
  const [frames, setFrames] = useState<ExcalidrawFrameElement[]>([]);

  useEffect(() => {
    const elements = app.scene.getNonDeletedElements();
    const frames_ = elements.filter(
      (item: {
        type: string;
        isDeleted: boolean;
      }): item is ExcalidrawFrameElement =>
        item.type === "frame" && !item.isDeleted,
    );

    setFrames([...frames_]);
    // eslint-disable-next-line
  }, [app.scene.getNonDeletedElements()]);

  const sidebarStyle: React.CSSProperties = {
    position: "absolute",
    width: isMobile ? "100%" : 250,
    marginTop: 80,
    marginRight: 8,
    borderRadius: 16,
    right: 90,
  };

  const wrapperStyle = {
    borderRadius: 16,
    height: "80%",
    width: 250,
  };

  const headerStyle = {
    fontSize: "1.5rem",
    fontWeight: 600,
  };

  const closeIconStyle = {
    width: 12,
    marginTop: 5,
  };

  const isMyWorkSpace = localStorage.getItem("isMyWorkSpace") === "true";

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="d-flex right-side-panel h-100" style={sidebarStyle}>
        <div
          className={clsx(
            "layer-ui__wrapper__top-right zen-mode-transition d-block",
          )}
          style={wrapperStyle}
        >
          <div className="postion-fixed">
            <div className="pt-4 px-3 d-flex w-100 justify-content-between">
              <p style={headerStyle}>Outline</p>
              <div
                className="cursor-pointer"
                style={closeIconStyle}
                onClick={() => {
                  setAppState({ isOutlineOpen: false });
                  localStorage.setItem("isDragAndDrop", JSON.stringify(false));
                }}
              >
                {close(appState.theme === "light" ? "black" : "white")}
              </div>
            </div>
            <div className="d-flex ps-2 pe-3 py-1 border-bottom border-top">
              <div className="d-flex gap-2 justify-content-between w-100">
                {actionManager.renderAction("zoomToFit")}
                <button
                  onClick={() =>
                    setAppState({
                      presentationMode: true,
                      viewModeEnabled: true,
                      sidebarWrapper: false,
                    })
                  }
                  disabled={frames.length === 0}
                >
                  <div style={{ width: 20 }}>{presentationToolIcon}</div>
                </button>

                <button
                  onClick={() => {
                    app.setActiveTool({ type: "laser" });
                  }}
                >
                  <div style={{ width: 21 }}>{laserPointerToolIcon()}</div>
                </button>
                <button
                  onClick={() => {
                    setSelectedNameOrTopic("");
                    setAppState({ celebration: true });
                    setTimeout(() => setAppState({ celebration: false }), 5000); // Reset trigger after 5 seconds
                  }}
                  disabled={appState.celebration}
                >
                  <div style={{ width: 20 }}>{celebrationToolIcon()}</div>
                </button>
              </div>
            </div>
          </div>
          <div
            style={{
              overflow: "auto",
              height: "80%",
            }}
          >
            {frames.length > 0 ? (
              frames.map((item: ExcalidrawFrameElement, index: number) => (
                <FrameMenuItem
                  frame={item}
                  index={index}
                  key={index}
                  className={`p-3 m-2 d-flex justify-content-between position-relative ${!appState.presentationMode &&
                    (appState.editingFrame === item.id ||
                      appState.selectedElementIds[item.id])
                    ? " border active_frame_border_color"
                    : " border"
                    }`}


                  setAppState={setAppState}
                  onHover={() => {
                    setAppState({
                      frameToHighlight: item,
                    });
                    localStorage.setItem("isDragAndDrop", JSON.stringify(true));
                  }}
                  onMouseLeave={() => {
                    setAppState({
                      frameToHighlight: null,
                    });
                  }}
                  // onFrameNameClick={() => {
                  //   fitToContent();
                  //   setAppState({
                  //     frameToHighlight: item,
                  //   });
                  // }}
                  onMoveFrame={(dragIndex: number, dropIndex: number) => {
                    localStorage.setItem("isDragAndDrop", JSON.stringify(true));
                    const updatedFrames = [...frames];
                    const [draggedFrame] = updatedFrames.splice(dragIndex, 1);
                    updatedFrames.splice(dropIndex, 0, draggedFrame);

                    const updatedFramesWithOrder = updatedFrames.map(
                      (frame, index) => ({
                        ...frame,
                        order: index + 1,
                      }),
                    );

                    setFrames([...updatedFramesWithOrder]);

                    const allElements = isMyWorkSpace
                      ? JSON.parse(
                        localStorage.getItem(
                          STORAGE_KEYS.LOCAL_STORAGE_WORKSPACE_ELEMENTS,
                        ) ?? "[]",
                      )
                      : JSON.parse(
                        localStorage.getItem(
                          STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS,
                        ) ?? "[]",
                      );
                    const shapeElemets = allElements.filter(
                      (element: any) => element.type !== "frame",
                    );

                    localStorage.setItem(
                      isMyWorkSpace
                        ? STORAGE_KEYS.LOCAL_STORAGE_WORKSPACE_ELEMENTS
                        : STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS,
                      JSON.stringify([
                        ...updatedFramesWithOrder,
                        ...shapeElemets,
                      ]),
                    );
                    app.scene.replaceAllElements([
                      ...updatedFramesWithOrder,
                      ...shapeElemets,
                    ]);
                  }}
                  onFrameDoubbleClick={() => {
                    app.scrollToContent(item, {
                      fitToViewport: true,
                      animate: true,
                    });
                    setAppState({
                      frameToHighlight: item,
                    });
                  }}
                  appState={appState}
                  app={app}
                  setFrames={setFrames}
                  frames={frames}
                  allElements={
                    isMyWorkSpace
                      ? JSON.parse(
                        localStorage.getItem(
                          STORAGE_KEYS.LOCAL_STORAGE_WORKSPACE_ELEMENTS,
                        ) ?? "[]",
                      )
                      : JSON.parse(
                        localStorage.getItem(
                          STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS,
                        ) ?? "[]",
                      )
                  }
                />
              ))
            ) : (
              <div className="text-center my-3 text-secondary">
                No frames found!
              </div>
            )}
          </div>
        </div>
      </div>
    </DndProvider>
  );
};
