import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { actionSendToBack } from "../../actions";
import { ActionManager } from "../../actions/manager";
import { ExcalidrawElement, ExcalidrawFrameElement } from "../../element/types";
import { STORAGE_KEYS } from "../../excalidraw-app/data/localStorage";
import { AppClassProperties, AppState } from "../../types";
import { ZoomActions } from "../Actions";
import {
  backIcon,
  celebrationToolIcon,
  close,
  compress,
  expand,
  laserPointerToolIcon,
  layerIcon,
  settingOutlineIcon,
} from "../icons";
import { Popover } from "../Popover";
import { Section } from "../Section";
import Stack from "../Stack";
import { FrameMenuItem } from "./FrameMenuItem";
import "./styles.css";

type PresentationModeProps = {
  app: AppClassProperties;
  setAppState: React.Component<any, AppState>["setState"];
  appState: AppState;
  actionManager: ActionManager;
  intervalRef: any;
  countDownRef: any;
  appStateRef: any;
  countdownStates: any;
  chronometerScrollRef: any;
  stopwatchStates: any;
  countdownAnimation: any;
};

type FrameElement = ExcalidrawFrameElement & { order: number };

const ToolButton = ({
  icon,
  title,
  onClick,
  iconStyle = { width: 25, margin: 5 },
  disabled,
}: {
  icon: React.ReactNode;
  title: string;
  onClick?: () => void;
  iconStyle?: React.CSSProperties;
  disabled?: boolean;
}) => (
  <button
    style={{
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: disabled ? "not-allowed" : "pointer",
      opacity: disabled ? 0.3 : 1,
    }}
    title={title}
    onClick={onClick}
    disabled={disabled}
  >
    <div style={iconStyle}>{icon}</div>
  </button>
);

type ContextMenuItemProps = {
  text: string;
  onClick: () => void;
  className?: string;
  style?: React.CSSProperties;
};

const ContextMenuItem = ({
  text,
  onClick,
  className,
  style,
}: ContextMenuItemProps) => (
  <li className="d-flex">
    <button
      className={clsx("context-menu-option w-100", className)}
      onClick={onClick}
      style={{
        display: "inline-block",
        minWidth: "fit-content",
        ...style,
      }}
    >
      <div className="align-items-center d-flex justify-content-between">
        <span className="ps-1">{text}</span>
      </div>
    </button>
  </li>
);

export const fitToContent = () => {
  const getFitToContentButton = document.getElementById("fit-to-content");
  if (getFitToContentButton) {
    getFitToContentButton.click();
  }
};

export const PresentationMode = ({
  app,
  setAppState,
  appState,
  actionManager,
  intervalRef,
  countDownRef,
  appStateRef,
  countdownStates,
  chronometerScrollRef,
  stopwatchStates,
  countdownAnimation,
}: PresentationModeProps) => {
  const [settingPopover, setSettingPopover] = useState<boolean>(false);
  const [frameLayerPopover, setFrameLayerPopover] = useState<boolean>(false);
  const [frames, setFrames] = useState<FrameElement[]>([]);
  const [selectedFrame, setSelectedFrame] = useState<number>(1);
  const [allElements, setAllElements] = useState<ExcalidrawElement[]>([]);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const isMyWorkSpace = localStorage.getItem("isMyWorkSpace") === "true";

  // Utility function to fetch elements from localStorage
  const getElementsFromStorage = useCallback(() => {
    return JSON.parse(
      localStorage.getItem(
        isMyWorkSpace
          ? STORAGE_KEYS.LOCAL_STORAGE_WORKSPACE_ELEMENTS
          : STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS,
      ) || "[]",
    );
  }, [isMyWorkSpace]);

  useEffect(() => {
    const elements = getElementsFromStorage();
    setAllElements(elements);
    toggleFullScreen();
  }, [isMyWorkSpace]);

  const loadFrames = useCallback(() => {
    clearIntervalWidgets();
    if (!Array.isArray(allElements) || allElements.length === 0) {
      return;
    }
    const sortedFrames = allElements
      .filter((item) => item?.type === "frame")
      .sort((a, b) => (a.order ?? 0) - (b.order ?? 0));

    if (sortedFrames.length > 0) {
      setFrames([...sortedFrames]);
      const selectedFrameData =
        sortedFrames.find((frame) => frame.order === selectedFrame) ||
        sortedFrames[0];

      const updatedElements = updateElementVisibility(
        allElements,
        selectedFrameData?.id,
      );

      setAppState((prev) => {
        return {
          selectedElementIds: {
            [selectedFrameData.id]: true,
          },
        };
      });

      app.scene.replaceAllElements([...updatedElements]);
    }
  }, [selectedFrame, app, allElements]);

  useEffect(() => {
    actionManager.executeAction(actionSendToBack);
  }, [appState.selectedElementIds]);

  const clearIntervalWidgets = () => {
    // Check if any clock element exists within the current frame
    const isClockInCurrentFrame = frames.some(
      (ele: any) => ele.type === "clock" && ele.frameId, // Ensure it is a clock and has a frame ID
    );

    const isCountDownInCurrentFrame = frames.some(
      (ele: any) => ele.type === "countdown" && ele.frameId, // Ensure it is a countdown and has a frame ID
    );

    const isChronometerInCurrentFrame = frames.some(
      (ele: any) => ele.type === "chronometer" && ele.frameId, // Ensure it is a chronometer and has a frame ID
    );

    // If no clock element exists in any frame, clear the interval
    if (!isClockInCurrentFrame) {
      clearInterval(intervalRef.current);
      intervalRef.current = null; // Optionally, set to null after clearing
    }

    if (!isCountDownInCurrentFrame) {
      countdownStates.clear();
      if (countDownRef.current) {
        clearInterval(countDownRef.current);
        countDownRef.current = null;
      }

      Object.keys(countdownAnimation).forEach((id) => {
        cancelAnimationFrame(countdownAnimation[id]!);
        countdownAnimation[id] = null;
      });
    }

    if (!isChronometerInCurrentFrame) {
      stopwatchStates.clear();
    }
  };

  useEffect(() => {
    allElements &&
      setFrames(
        allElements.filter((item: ExcalidrawElement) => item?.type === "frame"),
      );
    loadFrames();
    setTimeout(() => {
      fitToContent();
    }, 200);
  }, [allElements, loadFrames]);

  // Utility function to update element visibility based on selected frame
  const updateElementVisibility = (
    elements: ExcalidrawElement[],
    selectedFrameId: string,
  ) => {
    return elements.map((item) => ({
      ...item,
      isVisible:
        selectedFrameId === item.frameId || item.id === selectedFrameId,
    }));
  };

  const moveFrame = useCallback(
    (dragIndex: number, dropIndex: number) => {
      const updatedFrames = [...frames];
      const [draggedFrame] = updatedFrames.splice(dragIndex, 1);
      updatedFrames.splice(dropIndex, 0, draggedFrame);

      const updatedFramesWithOrder = updatedFrames.map((frame, index) => ({
        ...frame,
        order: index + 1,
      }));

      setFrames([...updatedFramesWithOrder]);
      setSelectedFrame(updatedFramesWithOrder[dropIndex].order);

      const shapeElemets = (allElements ?? []).filter(
        (element: any) => element.type !== "frame",
      );

      const updatedElements = [...updatedFramesWithOrder, ...shapeElemets];
      localStorage.setItem(
        isMyWorkSpace
          ? STORAGE_KEYS.LOCAL_STORAGE_WORKSPACE_ELEMENTS
          : STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS,
        JSON.stringify(updatedElements),
      );

      setAllElements([...updatedElements]);
    },
    // eslint-disable-next-line
    [frames, app],
  );

  const handleFrameNavigation = (newFrameIndex: number) => {
    setSelectedFrame(newFrameIndex);

    const selectedFrameData = frames.find(
      (frame) => frame.order === newFrameIndex,
    ) as ExcalidrawFrameElement;

    const updatedElements = updateElementVisibility(
      allElements ?? [],
      selectedFrameData?.id ?? "",
    );
    setAppState((prev) => {
      return {
        selectedElementIds: {
          [selectedFrameData?.id]: true,
        },
      };
    });
    app.scene.replaceAllElements([...updatedElements]);
    fitToContent();
  };

  const handleCloseClick = () => {
    const updatedElements =
      allElements ??
      [].map((item: ExcalidrawElement) => ({
        ...item,
        isVisible: true,
      }));

    app.scene.replaceAllElements(updatedElements);
    setAppState({ presentationMode: false, viewModeEnabled: false });
    setIsFullscreen(false);
    toggleFullScreen();
  };

  const toggleFullScreen = () => {
    if (!isFullscreen) {
      document.documentElement
        .requestFullscreen()
        .then(() => setIsFullscreen(true))
        .catch((err) =>
          console.error("Failed to enable full-screen mode", err),
        );
    } else {
      document
        .exitFullscreen()
        .then(() => setIsFullscreen(false))
        .catch((err) => console.error("Failed to exit full-screen mode", err));
    }
  };
  return (
    <DndProvider backend={HTML5Backend}>
      <Section heading="shapes" className="bottom-menu-section-wrapper">
        <Stack.Col gap={5} align="start" className="d-flex ">
          <Stack.Row
            gap={2}
            className="shdw fit-content bottom-menu-section"
            style={{
              padding: "0 10px",
              ...(frameLayerPopover || settingPopover
                ? {
                    overflow: "",
                  }
                : {
                    overflow: "auto",
                  }),
            }}
          >
            <div className="d-flex">
              <ToolButton
                icon={backIcon(appState.theme === "light" ? "black" : "white")}
                title="Previous"
                onClick={() =>
                  handleFrameNavigation(Math.max(selectedFrame - 1, 1))
                }
                iconStyle={{ width: 22 }}
                disabled={selectedFrame === 1}
              />
              <div className="d-flex align-items-center text-nowrap px-2">
                {frames[selectedFrame - 1]
                  ? frames[selectedFrame - 1].name
                  : "Frame"}{" "}
                {selectedFrame}/{frames.length}
              </div>
              <ToolButton
                icon={backIcon(appState.theme === "light" ? "black" : "white")}
                title="Next"
                onClick={() =>
                  handleFrameNavigation(
                    Math.min(selectedFrame + 1, frames.length),
                  )
                }
                iconStyle={{ rotate: "180deg", width: 22 }}
                disabled={selectedFrame === frames.length}
              />
            </div>

            <ToolButton
              icon={laserPointerToolIcon(
                appState.theme === "light" ? "black" : "white",
              )}
              title="Laser Pointer"
              onClick={() => app.setActiveTool({ type: "laser" })}
            />
            <ToolButton
              icon={celebrationToolIcon(
                appState.theme === "light" ? "black" : "white",
              )}
              title="Celebration"
              onClick={() => {
                setAppState({ celebration: true });
                setTimeout(() => setAppState({ celebration: false }), 5000);
              }}
            />
            <ToolButton
              icon={
                !isFullscreen
                  ? compress(appState.theme === "light" ? "black" : "white")
                  : expand(appState.theme === "light" ? "black" : "white")
              }
              title="Toggle Fullscreen"
              onClick={() => {
                toggleFullScreen();
              }}
            />
            <div className="position-relative">
              <ToolButton
                icon={layerIcon(appState.theme === "light" ? "black" : "white")}
                title="Order"
                onClick={() => {
                  setFrameLayerPopover(!frameLayerPopover);
                  localStorage.setItem("isDragAndDrop", JSON.stringify(true));
                }}
              />
              {frameLayerPopover && (
                <Popover
                  top={10}
                  left={50}
                  style={{
                    transform: "translate(-50%, -100%)",
                    padding: 5,
                    width: 200,
                    maxHeight: 800,
                    overflow: "auto",
                  }}
                  onCloseRequest={() => {
                    setFrameLayerPopover(false);
                    localStorage.setItem(
                      "isDragAndDrop",
                      JSON.stringify(false),
                    );
                  }}
                >
                  {frames.map((frame, index) => (
                    <FrameMenuItem
                      key={index}
                      index={index}
                      onMoveFrame={moveFrame}
                      appState={appState}
                      app={app}
                      setFrames={setFrames}
                      frames={frames}
                      frame={frame}
                      className={`p-3 m-2 d-flex justify-content-between position-relative border`}
                      allElements={allElements ?? []}
                      setAllElements={setAllElements}
                      onFrameClick={() => handleFrameNavigation(frame.order)}
                      setAppState={setAppState}
                    />
                  ))}
                </Popover>
              )}
            </div>
            <div className="position-relative">
              <ToolButton
                icon={settingOutlineIcon(
                  appState.theme === "light" ? "black" : "white",
                )}
                title="Setting"
                onClick={() => setSettingPopover(!settingPopover)}
              />
              {settingPopover && (
                <Popover
                  top={-40}
                  style={{
                    width: 150,
                  }}
                  onCloseRequest={() => setSettingPopover(false)}
                >
                  <ContextMenuItem
                    key={"Dark"}
                    text={"Dark Mode"}
                    onClick={() => {
                      setAppState({ presentationTheme: "dark" });
                    }}
                  />
                  <ContextMenuItem
                    key={"Light"}
                    text={"Light Mode"}
                    onClick={() => {
                      setAppState({ presentationTheme: "light" });
                    }}
                  />
                </Popover>
              )}
            </div>
            <div className="mt-1">
              <ZoomActions
                renderAction={actionManager.renderAction}
                zoom={appState.zoom}
              />
            </div>

            <ToolButton
              icon={close(appState.theme === "light" ? "black" : "white")}
              title="close"
              onClick={handleCloseClick}
              iconStyle={{ width: 15, margin: 8 }}
            />
          </Stack.Row>
        </Stack.Col>
      </Section>
    </DndProvider>
  );
};
