import React, { useState, ChangeEvent } from "react";
import "./ImagePicker.scss";
import { ImageFile } from "./LayerUI";

interface ImagePickerProps {
  onImageSelect?: (images: ImageFile[]) => void;
  selectedImages: ImageFile[];
  pickRandomImage: (val: ImageFile[]) => void;
}

const ImagePicker: React.FC<ImagePickerProps> = ({
  onImageSelect,
  selectedImages,
  pickRandomImage,
}) => {
  const [localImages, setLocalImages] = useState<ImageFile[]>(selectedImages);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files && files.length > 0) {
      const newImages: ImageFile[] = Array.from(files).map((file) => ({
        file,
        url: URL.createObjectURL(file),
      }));

      const updatedImages = [...localImages, ...newImages];
      setLocalImages(updatedImages);

      if (onImageSelect) {
        onImageSelect(updatedImages);
      }
      pickRandomImage(updatedImages);
    }
  };

  const handleRemoveImage = (index: number) => {
    const updatedImages = localImages.filter((_, i) => i !== index);
    setLocalImages(updatedImages);

    if (onImageSelect) {
      onImageSelect(updatedImages);
    }
  };

  return (
    <div className="image-picker h-100">
      <div className="h-100">
        <div className="card-title w-100">Image Picker</div>
        <label
          className={selectedImages.length ? "upload_area" : "upload-area"}
          htmlFor="files"
        >
          Select images
        </label>
        <input
          type="file"
          id="files"
          name="files"
          accept="image/*"
          multiple
          className="hidden"
          onChange={handleFileChange}
        />
        <div className="container">
          {localImages.length > 0 && (
            <div className="row image_row_container">
              {localImages.map((image, index) => (
                <div key={index} className="col-md-4 p-1">
                  <div className="image-preview-wrapper">
                    <img
                      src={image.url}
                      alt={`preview-${index}`}
                      className="image-preview"
                    />
                    <button
                      className="remove-button"
                      onClick={() => handleRemoveImage(index)}
                    >
                      ×
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImagePicker;
