import { useEffect, useState } from "react";
import AddButton from "../../components/AddButton";
import ConfirmDialog from "../../components/ConfirmDialog";
import { Dialog } from "../../components/Dialog";
import FlexibleGroups from "../../components/flexibleGroups";
import { groupType, studentType } from "../../components/flexibleGroups/Types";
import {
  errorIcon_,
  SquareCheckIcon,
  SquareCloseIcon,
  start,
} from "../../components/icons";
import { ToolButton } from "../../components/ToolButton";
import { t } from "../../i18n";
import { AppState } from "../../types";
import { apiGet, apiPost, apiPut, getStudents } from "../api";
import { ACV_API_URL, API_URL } from "../api/constant";
import { getLessonId } from "../api/getuserInfo";
import "./FlexibleGroupDialog.scss";
import Portal from "./Portal";

const FlexibleGroupDialog = ({
  theme,
  handleClose,
  appState,
  onRoomCreate,
  onStartFlexibleGroupCollaboration,
  portal,
}: {
  theme: AppState["theme"];
  handleClose: () => void;
  appState: Partial<AppState>;
  onRoomCreate: (val: boolean) => void;
  onStartFlexibleGroupCollaboration: () => void;
  portal: Portal;
}) => {
  const [isShowInput, setIsShowInput] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [groupName, setGroupName] = useState<string>("");
  const [groups, setGroups] = useState<groupType[]>([]);
  const [students, setStudents] = useState<studentType[]>([]);
  const [activeGroup, setActiveGroup] = useState<groupType | null>(null);
  const [isFlexibleGroup, setIsFlexibleGroup] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [error, setError] = useState("");
  const [isShowFullScreen, setIsShowFullScreen] = useState<boolean>(false);

  useEffect(() => {
    window.localStorage.setItem("groups", JSON.stringify(groups));
  }, [groups]);

  const findData = groups.find((groups) => groups.name === groupName);
  const createGroup = async () => {
    const lessonId: any = await getLessonId();
    const clonedGroups = [...groups];

    const defaultGroup = {
      lessonId: "" as string,
      name: "" as string,
      students: [],
      page: {
        id: "" as string,
        isPage: false as boolean,
        name: "" as string,
        no: "" as string,
        dataURL: "" as string,
      },
      isOpen: true as boolean,
    };

    if (!!lessonId) {
      if (!!findData) {
        return;
      }

      defaultGroup.name = !!String(groupName).trim()
        ? groupName
        : `Group ${groups.length === 0 ? 1 : groups.length + 1}`;
      defaultGroup.lessonId = lessonId;

      window.parent.postMessage(
        { type: "EDIT_MODAL", isEditable: true },
        `${process.env.REACT_APP_PARENT_APP}`,
      );
      setIsEditable(true);
      clonedGroups.push(defaultGroup);
      setGroups(clonedGroups);
    }
  };

  useEffect(() => {
    if (groups.length > 0) {
      const activeGroup_ = groups.find((group, i) => group.isOpen);
      activeGroup_ ? setActiveGroup(activeGroup_) : setActiveGroup(null);
    }
  }, [groups]);

  useEffect(() => {
    const isCollaboratingWithFlexibleGroups_ = JSON.parse(
      localStorage.getItem("isCollaboratingWithFlexibleGroups") || "false",
    );

    setIsFlexibleGroup(isCollaboratingWithFlexibleGroups_);
  }, [localStorage.getItem("isCollaboratingWithFlexibleGroups")]);

  // check that student in specific group or not
  const groupHasStudent = (group: groupType, student: studentType) => {
    return Boolean(
      group.students.find((info) => info.studentEmail === student.studentEmail),
    );
  };

  // check that student is assigned to any group or not
  const isStudentInGroup = (groups: groupType[], student: studentType) => {
    const isInGroup = groups.some((group) => groupHasStudent(group, student));
    return isInGroup;
  };

  // adding isAssignedGroup field in all students records
  const filterStudents = async (rawStudents: studentType[]) => {
    const clonedStudents = [...rawStudents];
    const groups = await getGroupsData();
    const filteredStudents = clonedStudents.map((student, i) => {
      return {
        ...student,
        isAssignedGroup: isStudentInGroup(groups, student) ? true : false,
      };
    });
    return filteredStudents;
  };

  const getStudentsData = async () => {
    try {
      setIsLoading(true);
      const lessonId = await getLessonId();
      const slug =
        new URLSearchParams(window.location.search)
          .get("slug")
          ?.replace(/\//g, "") || "";

      const res = await getStudents(
        `${ACV_API_URL}/api/record/get-student-record-for-whiteBaord/${lessonId}?slug=${slug}`,
      );
      const studList = res?.result;
      const students = await studList.map((stud: any) => {
        return {
          studentEmail: stud.StudentEmailId,
          studentName: stud.StudentName,
          photo: `${stud.StudentPhoto.Foto}?${res?.SASToken}`,
          isAssignedGroup: false,
        };
      });
      const filteredStudents = await filterStudents(students);
      setStudents(filteredStudents);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error-from-'getStudentsData'", error);
    }
  };

  const getGroupsData = async () => {
    try {
      const lessonId = await getLessonId();
      const groups = await apiGet(
        `${API_URL}/flexible-group?lessonId=${lessonId}`,
      );
      if (groups.result && groups.result.length > 0) {
        const updatedGroups = groups.result.map((group: groupType) => {
          return {
            ...group,
            isOpen: false,
          };
        });

        window.localStorage.setItem("groups", JSON.stringify(updatedGroups));

        setGroups(updatedGroups);
        return updatedGroups;
      } else {
        setGroups([]);
        return [];
      }
    } catch (error) {
      console.log("error-from-'getGroupsData'", error);
    }
  };

  const handleUpdate = async (group: groupType) => {
    const updatedGroup = {
      ...group,
      students: group.students.map((student: studentType) => {
        return {
          ...student,
          isEnabled: true,
        };
      }),
    };
    portal.broadcastPageUpdate();
    const clonedGroups = [...groups];
    if (updatedGroup.students.length > 0) {
      if (updatedGroup.id) {
        const updatedWithApi = await apiPut(
          `${API_URL}/flexible-group`,
          updatedGroup,
        );
        setGroups(clonedGroups);
      } else {
        const createdGroup = await apiPost(
          `${API_URL}/flexible-group`,
          updatedGroup,
        );
        if (createdGroup.status === "Success") {
          setGroups(clonedGroups);
        } else {
          window.alert("Group has been not created!");
        }
      }
    }
  };

  useEffect(() => {
    getStudentsData();
  }, []);

  return (
    <Dialog
      onCloseRequest={handleClose}
      title={t("labels.flexibleGroup")}
      theme={theme}
      isShowInput={isShowInput}
      isEditable={isEditable}
      open={open}
      setOpen={setOpen}
      className="flexible-group"
      isShowFullScreenButton={true}
      setIsShowFullScreen={setIsShowFullScreen}
      isShowFullScreen={isShowFullScreen}
    >
      <div className="flexible-group-dialog-content-wrapper py-3 px-4">
        <div className="mt-2 mb-4 row mx-0">
          <div className="d-flex">
            <div
              className="px-0 col-12 col-sm-4 col-md-3 col-lg-3 col-xl-3 col-xxl-3"
              onClick={() => {
                if (groups.length > 24) {
                  setError(
                    "You are allowed to create up to 25 groups, and you cannot add any more.",
                  );
                } else {
                  window.parent.postMessage(
                    { type: "CONFIRMATION_MODAL", isShowInput: true },
                    `${`${process.env.REACT_APP_PARENT_APP}`}`,
                  );
                  window.parent.postMessage(
                    { type: "EDIT_MODAL", isEditable: true },
                    `${process.env.REACT_APP_PARENT_APP}`,
                  );
                  setError("");
                  setIsShowInput(true);
                }
              }}
            >
              <AddButton />
            </div>
            {error && (
              <div
                className="notes_div"
                style={{
                  border: "1px solid #c92a2a",
                  backgroundColor: "white",
                  marginBottom: "0px",
                }}
              >
                <div className="d-flex">
                  <span className="me-2">{errorIcon_}</span>
                  <span
                    className="text-danger"
                    style={{ marginTop: "1px", display: "flex" }}
                  >
                    {error}
                  </span>
                </div>
              </div>
            )}
            {isShowInput && (
              <>
                <div className="col-8 col-sm-6 col-md-7 col-lg-7 col-xl-7 col-xxl-7 px-0 pt-2 pt-sm-0 pt-md-0 ">
                  <input
                    className="h-100 w-100 border-0 border-bottom bg-transparent group-name-input"
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                    type="text"
                    placeholder={t("labels.addGroupPlaceholder")}
                  />
                  {!!findData && (
                    <small id="passwordHelp" className="text-danger">
                      Group with the same name already exists!
                    </small>
                  )}
                </div>
                <div className="align-self-center col-4 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 d-flex ps-2 px-0">
                  <div
                    className="icon-wrapper d-inline-block cursor-pointer"
                    onClick={() => {
                      window.parent.postMessage(
                        { type: "CONFIRMATION_MODAL", isShowInput: false },
                        `${`${process.env.REACT_APP_PARENT_APP}`}`,
                      );
                      window.parent.postMessage(
                        { type: "EDIT_MODAL", isEditable: false },
                        `${process.env.REACT_APP_PARENT_APP}`,
                      );
                      setIsShowInput(false);
                      createGroup();
                    }}
                  >
                    <SquareCheckIcon />
                  </div>
                  <div
                    className="icon-wrapper d-inline-block ms-2 cursor-pointer"
                    onClick={() => {
                      window.parent.postMessage(
                        { type: "CONFIRMATION_MODAL", isShowInput: false },
                        `${`${process.env.REACT_APP_PARENT_APP}`}`,
                      );
                      window.parent.postMessage(
                        { type: "EDIT_MODAL", isEditable: false },
                        `${process.env.REACT_APP_PARENT_APP}`,
                      );
                      setIsShowInput(false);
                    }}
                  >
                    <SquareCloseIcon />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {isLoading ? (
          <div className="d-flex align-items-center justify-content-center">
            <div className="spinner-border " role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : groups.length > 0 ? (
          <FlexibleGroups
            setGroups={setGroups}
            groups={groups}
            students={students}
            setStudents={setStudents}
            appState={appState}
            isLoading={isLoading}
            setIsEditable={setIsEditable}
            isEditable={isEditable}
            setError={setError}
            isShowFullScreen={isShowFullScreen}
          />
        ) : (
          <>
            <p className="mb-0 fw-bold">{t("labels.noGroupsMsg")}</p>
            <p className="fw-bold">
              {t("helpDialog.click").toUpperCase()}
              <span
                className="cursor-pointer"
                style={{ color: "#4c6ef5" }}
                onClick={() => {
                  window.parent.postMessage(
                    { type: "CONFIRMATION_MODAL", isShowInput: true },
                    `${`${process.env.REACT_APP_PARENT_APP}`}`,
                  );
                  window.parent.postMessage(
                    { type: "EDIT_MODAL", isEditable: true },
                    `${process.env.REACT_APP_PARENT_APP}`,
                  );
                  setIsShowInput(true);
                }}
              >
                {` ${t("labels.addGroup").toUpperCase()} `}
              </span>
              {t("labels.noGroupsMsg1")}
            </p>
          </>
        )}
        <div className={`mt-4 text-end ${groups.length > 0 ? "" : "d-none"}`}>
          <ToolButton
            className={"px-2 bg-brand continue-button"}
            onClick={() => {
              if (isFlexibleGroup) {
                activeGroup && handleUpdate(activeGroup);
              } else {
                activeGroup && handleUpdate(activeGroup);
                onStartFlexibleGroupCollaboration();
                onRoomCreate(true);
              }
              handleClose();
            }}
            type="button"
            title={t("buttons.continue")}
            aria-label={t("buttons.continue")}
            icon={start}
          >
            <span className="text-white">{t("buttons.savecontinue")}</span>
          </ToolButton>
        </div>
      </div>

      {open && (
        <ConfirmDialog
          onCancel={() => {
            setOpen(false);
          }}
          onConfirm={() => {
            handleClose();
            setIsEditable(false);
            window.parent.postMessage(
              { type: "EDIT_MODAL", isEditable: false },
              `${process.env.REACT_APP_PARENT_APP}`,
            );
          }}
          title={`Are you sure you?`}
          children={
            <>
              <p className="mb-0">
                If you are close this modal your data is not saved.
              </p>
            </>
          }
          open={open}
          setOpen={setOpen}
          isEditable={false}
          isShowInput={false}
        />
      )}
    </Dialog>
  );
};

export default FlexibleGroupDialog;
