import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import captchScreenImage from "../../data/getImage";
import { apiPost } from "../../excalidraw-app/api";
import { ACV_API_URL } from "../../excalidraw-app/api/constant";
import {
  deleteSelectedWorkspace,
  getActiveOrRestoreWorkspace,
  getArchiveWorkspaceData,
  getFilteredTags,
  getSharedWithMeWorkspace,
  getSortWorkspace,
  getWorkspaceUsers,
  renameWorkspace,
  updateExpirationDateTime,
  updateSharedWorkspace,
  updateWorkspaceStatus,
} from "../../excalidraw-app/api/storeElementData";
import { t } from "../../i18n";
import { AppState } from "../../types";
import { Dialog } from "../Dialog";
import { closeIcon_, sortAndFilterIcon } from "../icons";
import { Toast, ToastType } from "../Toast";
import { FilterdWorkspace } from "./FilteredWorkspace";
import "./style.scss";
import { Card } from "./views/card";
import { MyWorkspaceSidebarItemsEnum, Sidebar } from "./views/sidebar";

interface MyWorkspaceProps {
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
  resetScene: (opts?: { resetLoadingState: boolean }) => void;
  initializeSceneWithWorkspace: (
    id: string,
    sharedUserProfileEmail: string,
  ) => void;
  handleItemClick: (value: MyWorkspaceSidebarItemsEnum) => void;
  toggleZoomInZoomOut: (value: boolean) => void;
  workspaceUsers: [];
}

export interface CardData {
  id: string;
  title: string;
  thumbnail: string;
  profile: string;
  date: string;
  elements: any[]; // Add elements to store the actual workspace elements
  status: string | null;
  sharedUserProfile: string;
  sharedUserProfileEmail: string;
  sharable: boolean;
  expirationDate: string | null;
  roomDetails:
    | {
        roomId: string | null;
        roomKey: string | null;
        workspaceId: string | null;
        isCollaboration: boolean | false;
      }
    | {};
  tags: string[] | null;
}

export interface CardActions {
  label: string;
  value: string;
  onClick?: () => void;
  isClosePopover?: boolean;
}

export enum PopoverActionsENUM {
  RENAME = "Rename",
  DUPLICATE = "Duplicate",
  ARCHIVE = "Archive",
  RESTORE = "Restore",
  DELETE = "Delete",
  SETEXPIRATIONTIME = "Set Expiration Time",
}

export const MyWorkspace = (props: MyWorkspaceProps) => {
  const { appState, setAppState } = props;
  const [navToggle, setNavToggle] = useState(true);
  const [cardData, setCardData] = useState<CardData[]>([]);
  const [editTitle, setEditTitle] = useState("");
  const [title, setTitle] = useState("");
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [workspaceName, setWorkspaceName] = useState(
    `Workspace -${Date.now()}`,
  );
  const [createWorkpsaceloading, setCreateWorkpsaceloading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [expirationLoader, setExpirationLoader] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [shareableWorkspaceOrNot, setShareableWorkspaceOrNot] = useState(false);
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const url = window.location.href;
  const parsedUrl = new URL(url);
  const lessonId = parsedUrl.searchParams.get("lessonId");

  const [hasHandled, setHasHandled] = useState(false);
  const [tags, setTags] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState<CardData[]>([]);
  const [filteredTags, setFilteredTags] = useState<string[]>([]);
  const [authors, setAuthors] = useState<string[]>([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [selectedSortBy, setSelectedSortBy] = useState<number | null>(null);
  const isMyWorkSpace = localStorage.getItem("isMyWorkSpace");

  const sortByContet =
    appState.selectedWorkspaceTab === MyWorkspaceSidebarItemsEnum.SHARED_WITH_ME
      ? [
          t("collection.community.template.sortBy.title"),
          t("collection.community.template.sortBy.types.newest"),
          t("collection.community.template.sortBy.types.title"),
          t("collection.community.template.sortBy.types.author"),
        ]
      : [
          t("collection.community.template.sortBy.title"),
          t("collection.community.template.sortBy.types.newest"),
          t("collection.community.template.sortBy.types.title"),
        ];

  // Outside the component or with useState if you need it to reset
  const closedWorkspaces = new Set<string>();

  const checkExpirationDate = async () => {
    try {
      props.workspaceUsers
        .filter((wu: any) => wu.ownerUserEmail === user.mail)
        .forEach(({ roomDetails, expirationDate, workspaceId }: any) => {
          if (
            !roomDetails ||
            Object.keys(roomDetails).length === 0 ||
            !expirationDate ||
            !workspaceId ||
            closedWorkspaces.has(workspaceId)
          ) {
            return;
          }
          // Process all expired workspaces in parallel
          const currentTime = moment();
          const expirationTime = moment(expirationDate);
          console.log(
            currentTime.isAfter(expirationTime),
            moment(expirationDate).format("MM DD YYYY hh:mm:ssA"),
          );

          const getData = async (expiredWorkspaceId: string) => {
            await updateSharedWorkspace(expiredWorkspaceId, {});
            const workspaceData = await getActiveOrRestoreWorkspace(user?.mail);
            const res = await getWorkspaceUsers(); // Fetch users data
            const transformedData = workspaceData?.result?.map(
              (workspace: any) => {
                // Find matching user workspace data
                const matchingUserWorkspace = res?.find(
                  (userWorkspace: any) =>
                    userWorkspace.workspaceId === workspace.id,
                );

                const isSharable = !!matchingUserWorkspace; // Check if there's a match
                const expirationDate = isSharable
                  ? matchingUserWorkspace.expirationDate
                  : null; // Set expiration date if sharable
                const roomDetails = isSharable
                  ? matchingUserWorkspace.roomDetails
                  : null;

                return {
                  id: workspace.id,
                  title: workspace.name,
                  thumbnail: workspace.previewImage,
                  profile: getProfile().toUpperCase(),
                  date: getTimeAgo(workspace.createdAt),
                  elements: workspace.elements,
                  status: workspace?.status,
                  sharable: isSharable,
                  expirationDate, // Add expiration date
                  roomDetails,
                  tags: workspace.tags,
                };
              },
            );
            setCardData(transformedData);
          };

          if (currentTime.isAfter(expirationTime)) {
            console.log(`Closing workspace ${workspaceId} for expiration`);
            window.publicClosePortal(workspaceId);
            closedWorkspaces.add(workspaceId);
            getData(workspaceId);
          }
        });
    } catch (error) {
      console.error("Error checking expiration dates:", error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isMyWorkSpace === "true") checkExpirationDate();
    }, 10000); //10 sec

    return () => clearInterval(interval);
  }, [props.workspaceUsers]);

  useEffect(() => {
    const storeFilterData = async () => {
      if (
        appState.selectedWorkspaceTab ===
        MyWorkspaceSidebarItemsEnum.SHARED_WITH_ME
      ) {
        const data = await getSharedWithMeWorkspace(user.mail);
        const res = await getWorkspaceUsers(); // Fetch users data

        const transformedData = data?.data?.map((workspace: any) => {
          // Find matching user workspace data
          const matchingUserWorkspace = res?.find(
            (userWorkspace: any) => userWorkspace.workspaceId === workspace.id,
          );

          const isSharable = !!matchingUserWorkspace; // Check if there's a match
          const roomDetails = isSharable
            ? matchingUserWorkspace.roomDetails
            : null;
          return {
            id: workspace.id,
            title: workspace.name,
            thumbnail: workspace.previewImage, // Assuming previewImage is the thumbnail
            profile: getProfile().toUpperCase(), // Static or dynamic based on user
            date: getTimeAgo(workspace.createdAt),
            elements: workspace.elements, // Attach workspace elements
            status: workspace?.status,
            sharedUserProfile: getProfile(workspace.userEmail).toUpperCase(),
            sharedUserProfileEmail: workspace.userEmail,
            sharable: isSharable, // Sharable information
            roomDetails, // Room details if available
            tags: workspace.tags,
          };
        });

        const filteredData = transformedData.filter(
          (item: any) => item.sharedUserProfileEmail !== user.mail,
        );
        const uniqueEmails = [
          ...new Set(
            filteredData.map((item: any) => item.sharedUserProfileEmail),
          ),
        ];
        setAuthors(uniqueEmails as string[]);
        const allTags = filteredData
          .map((item: any) => item.tags)
          .filter(Boolean)
          .flat() as string[];

        setFilteredTags(allTags);
      } else {
        const user = JSON.parse(localStorage.getItem("user") || "{}");
        const data = await getFilteredTags(user?.mail);
        // Remove the first element and store the rest
        const remainingData = data?.data?.slice(1);
        setFilteredTags(remainingData);
      }
    };
    storeFilterData();
    // eslint-disable-next-line
  }, [appState.selectedWorkspaceTab]);

  // Update the screen width when the window is resized
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Add event listener on mount
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Define breakpoints for mobile and tablet
  const isMobile = screenWidth <= 480; // Mobile
  const isTablet = screenWidth > 480 && screenWidth <= 1024; // Tablet

  useEffect(() => {
    const filtered = cardData.filter((card) =>
      card.title.toLowerCase().includes(searchQuery.toLowerCase()),
    );
    setFilteredData(filtered);
    // eslint-disable-next-line
  }, [searchQuery]);

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      if (inputValue.trim() !== "") {
        setTags((prevTags) => [...prevTags, inputValue.trim()]);
        setInputValue("");
      }
    }
  };

  const getWorkspaceData = async (workspaceData: any) => {
    setLoading(true); // Start loading
    try {
      setHasHandled(true);
      const res = await getWorkspaceUsers(); // Fetch users data
      const transformedData = workspaceData?.data?.map((workspace: any) => {
        // Find matching user workspace data
        const matchingUserWorkspace = res?.find(
          (userWorkspace: any) => userWorkspace.workspaceId === workspace.id,
        );

        const isSharable = !!matchingUserWorkspace; // Check if there's a match
        const expirationDate = isSharable
          ? matchingUserWorkspace.expirationDate
          : null; // Set expiration date if sharable
        const roomDetails = isSharable
          ? matchingUserWorkspace.roomDetails
          : null;

        return {
          id: workspace.id,
          title: workspace.name,
          thumbnail: workspace.previewImage,
          profile: getProfile().toUpperCase(),
          date: getTimeAgo(workspace.createdAt),
          elements: workspace.elements,
          status: workspace?.status,
          sharable: isSharable,
          expirationDate, // Add expiration date
          roomDetails,
          tags: workspace.tags,
        };
      });
      setCardData(transformedData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const getSharedWithMeData = async (data: any) => {
    setLoading(true); // Start loading
    try {
      const res = await getWorkspaceUsers(); // Fetch users data

      const transformedData = data?.data?.map((workspace: any) => {
        // Find matching user workspace data
        const matchingUserWorkspace = res?.find(
          (userWorkspace: any) => userWorkspace.workspaceId === workspace.id,
        );

        const isSharable = !!matchingUserWorkspace; // Check if there's a match
        const roomDetails = isSharable
          ? matchingUserWorkspace.roomDetails
          : null;
        return {
          id: workspace.id,
          title: workspace.name,
          thumbnail: workspace.previewImage, // Assuming previewImage is the thumbnail
          profile: getProfile().toUpperCase(), // Static or dynamic based on user
          date: getTimeAgo(workspace.createdAt),
          elements: workspace.elements, // Attach workspace elements
          status: workspace?.status,
          sharedUserProfile: getProfile(workspace.userEmail).toUpperCase(),
          sharedUserProfileEmail: workspace.userEmail,
          sharable: isSharable, // Sharable information
          roomDetails, // Room details if available
          tags: workspace.tags,
        };
      });

      const filteredData = transformedData.filter(
        (item: any) => item.sharedUserProfileEmail !== user.mail,
      );

      setCardData(filteredData);
    } catch (error) {
      console.error(error);
    } finally {
      setHasHandled(false);
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    const getSortWorkspace_ = async () => {
      setLoading(true);
      const data = await getSortWorkspace(
        user?.mail,
        selectedSortBy,
        appState.selectedWorkspaceTab ===
          MyWorkspaceSidebarItemsEnum.SHARED_WITH_ME
          ? "sharedWithMe"
          : "myWorkSpace",
      );
      if (
        appState.selectedWorkspaceTab ===
        MyWorkspaceSidebarItemsEnum.SHARED_WITH_ME
      ) {
        getSharedWithMeData(data);
      } else {
        getWorkspaceData(data);
      }
    };
    selectedSortBy && getSortWorkspace_();
    // eslint-disable-next-line
  }, [selectedSortBy]);

  const handleRemoveTag = (indexToRemove: any) => {
    setTags((prevTags) =>
      prevTags.filter((_, index) => index !== indexToRemove),
    );
  };

  // Get hash (fragment identifier)
  const room = parsedUrl.hash.replace("#room=", ""); // Removes "#room=" if needed
  const isClicked = localStorage.getItem("isClicked");
  useEffect(() => {
    if (lessonId === "MyWorkSpace" && room) {
      if (isClicked !== "false") {
        // Update the AppState to reflect "Shared with Me" tab
        setAppState((prev) => ({
          ...prev,
          selectedWorkspaceTab: MyWorkspaceSidebarItemsEnum.SHARED_WITH_ME,
        }));
      } else {
        // Update the AppState to reflect "Shared with Me" tab
        setAppState((prev) => ({
          ...prev,
          selectedWorkspaceTab: MyWorkspaceSidebarItemsEnum.MYWORKSPACE,
        }));
      }
    }
    // eslint-disable-next-line
  }, [isClicked === "false" && lessonId, room]);

  const onSaveDate = async () => {
    if (selectedDate) {
      setExpirationLoader(true);
      const date = new Date(selectedDate);

      // Convert the date to UTC and format as "YYYY-MM-DDTHH:mm:ssZ"
      const formattedDate = date.toISOString();

      const selectedWorkspaceCardID = localStorage.getItem(
        "selectedWorkspaceCard",
      );

      await updateExpirationDateTime(
        selectedWorkspaceCardID,
        formattedDate,
      ).then((res) => {
        if (res) {
          setExpirationLoader(false);
          setShowInput(false);
          const date_ = new Date(formattedDate) // Ensure it's a Date object
            .toLocaleString("en-CA", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              hour12: false, // Use 24-hour format
            })
            .replace(", ", "T"); // Replace the separator for ISO-like format

          setSelectedDate(date_);
        }
      });
    } else {
      alert("Please select a date before saving!");
    }
  };

  const createWorkspace = async (workspaceName: string, tags: string[]) => {
    setCreateWorkpsaceloading(true);
    const dataUrl = await captchScreenImage(appState.currentPage);

    const workspaceData = {
      userEmail: user?.mail,
      name: workspaceName ? workspaceName : `Workspace -${Date.now()}`,
      status: null,
      elements: [],
      createdAt: new Date(),
      previewImage: dataUrl,
      tags: tags ? tags : null,
    };

    try {
      await apiPost(`${ACV_API_URL}/api/workspace/`, workspaceData).then(
        (res) => {
          localStorage.setItem("selectedWorkspaceCard", res.result.id);
          window.parent.postMessage(
            {
              type: "SELECTED_WORKSPACE",
              selectedWorkspaceCardId: res.result.id,
            },
            `${`${process.env.REACT_APP_PARENT_APP}`}`,
          );
        },
      );
      setAppState({
        ...(appState || {}),
        isMyWorkSpace: false,
        isShowWhiteboard: true,
        isFullScreen: true,
      });
      props.toggleZoomInZoomOut(true);
      window.publicClosePortal();
    } catch (error) {
      console.error("Error updating or creating workspace", error);
    } finally {
      setCreateWorkpsaceloading(false); // End loading
    }
  };

  const handleSave = () => {
    createWorkspace(workspaceName, tags);
  };

  const getTimeAgo = (timestamp: string) => {
    const date = new Date(timestamp);

    if (isNaN(date.getTime())) {
      console.error(`Invalid date format: ${timestamp}`);
      return "Invalid date";
    }

    return moment(date).fromNow();
  };

  const getProfile = (email?: string) => {
    const nameParts = email
      ? email.trim().split(" ")
      : user.displayName
      ? user.displayName.trim().split(" ")
      : user.mail.trim().split(" ");
    const abbreviation =
      nameParts.length >= 2
        ? `${nameParts[0][0]}${nameParts[1][0]}`
        : nameParts[0][0];
    return abbreviation;
  };

  useEffect(() => {
    const getWorkspaceData = async () => {
      setLoading(true); // Start loading
      try {
        setHasHandled(true);
        const workspaceData = await getActiveOrRestoreWorkspace(user?.mail);
        const res = await getWorkspaceUsers(); // Fetch users data
        const transformedData = workspaceData?.result?.map((workspace: any) => {
          // Find matching user workspace data
          const matchingUserWorkspace = res?.find(
            (userWorkspace: any) => userWorkspace.workspaceId === workspace.id,
          );

          const isSharable = !!matchingUserWorkspace; // Check if there's a match
          const expirationDate = isSharable
            ? matchingUserWorkspace.expirationDate
            : null; // Set expiration date if sharable
          const roomDetails = isSharable
            ? matchingUserWorkspace.roomDetails
            : null;

          return {
            id: workspace.id,
            title: workspace.name,
            thumbnail: workspace.previewImage,
            profile: getProfile().toUpperCase(),
            date: getTimeAgo(workspace.createdAt),
            elements: workspace.elements,
            status: workspace?.status,
            sharable: isSharable,
            expirationDate, // Add expiration date
            roomDetails,
            tags: workspace.tags,
          };
        });
        setCardData(transformedData);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false); // Stop loading
      }
    };
    if (
      appState.selectedWorkspaceTab === MyWorkspaceSidebarItemsEnum.MYWORKSPACE
    ) {
      getWorkspaceData();
    }
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    appState.selectedWorkspaceTab === MyWorkspaceSidebarItemsEnum.MYWORKSPACE,
  ]);

  const saveTitle = async (id: string) => {
    try {
      const updatedCardData = cardData.map((card) =>
        card.id === id ? { ...card, title } : card,
      );
      setCardData(updatedCardData);
      await renameWorkspace(id, title);
      setToastMessage(t("workspace.alert.update"));
    } catch {
      setToastMessage(t("workspace.alert.failedUpdate"));
    }
    setEditTitle("");
  };

  const closeDialog = () => {
    setIsOpen(false);
    setAppState({
      selectedWorkspaceTab: MyWorkspaceSidebarItemsEnum.MYWORKSPACE,
    });
  };

  const renderTitle = (item: CardData) => {
    if (editTitle === item.id) {
      return (
        <input
          type="text"
          autoFocus
          className="form-control"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          onBlur={() => saveTitle(item.id)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              saveTitle(item.id);
            }
          }}
        />
      );
    }
    return <h5>{item.title}</h5>;
  };

  const onDuplicateWorkspace = async (id: string) => {
    try {
      const card = cardData.find((card) => card.id === id);
      if (card) {
        const newCard = {
          ...card,
          id: `${card.id}-Copy`,
          title: `Copy of ${card.title}`,
        };
        setCardData([...cardData, newCard]);

        const body = {
          userEmail: user?.mail,
          name: `Copy of ${card.title}`,
          previewImage: card.thumbnail,
          status: card.status,
          elements: card.elements,
          createdAt: new Date(),
        };
        await apiPost(`${ACV_API_URL}/api/workspace/duplicate/`, body);
        setToastMessage(t("workspace.alert.duplicate"));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderContextMenu = useCallback(
    (item: CardData): CardActions[] => {
      if (
        appState.selectedWorkspaceTab ===
        MyWorkspaceSidebarItemsEnum.MYWORKSPACE
      ) {
        return [
          {
            label: t("workspace.popover.rename"),
            value: PopoverActionsENUM.RENAME,
            onClick: () => {
              setEditTitle(item.id);
              setTitle(item.title);
            },
            isClosePopover: true,
          },
          {
            label: t("workspace.popover.duplicate"),
            value: PopoverActionsENUM.DUPLICATE,
            onClick: () => {
              onDuplicateWorkspace(item.id);
            },
            isClosePopover: true,
          },
          {
            label: t("workspace.popover.archive"),
            value: PopoverActionsENUM.ARCHIVE,
            onClick: async () => {
              try {
                const newCardData = cardData.filter(
                  (card) => card.id !== item.id,
                );
                setCardData([...newCardData]);
                await updateWorkspaceStatus(item.id, "Archive");
                setToastMessage(t("workspace.alert.archived"));
              } catch (error) {
                console.error(error);
              }
            },
            isClosePopover: true,
          },
          {
            label: t("workspace.popover.setExpirationTime"),
            value: PopoverActionsENUM.SETEXPIRATIONTIME,
            onClick: () => {
              setShowInput(true);
              return true;
            },
            isClosePopover: false,
          },
        ];
      } else if (
        appState.selectedWorkspaceTab === MyWorkspaceSidebarItemsEnum.ARCHIVE
      ) {
        return [
          {
            label: t("workspace.popover.rename"),
            value: PopoverActionsENUM.RESTORE,
            onClick: async () => {
              try {
                const newCardData = cardData.filter(
                  (card) => card.id !== item.id,
                );
                setCardData([...newCardData]);
                await updateWorkspaceStatus(item.id, "Restore");
                setToastMessage(t("workspace.alert.restored"));
              } catch (error) {
                console.error(error);
              }
            },
            isClosePopover: true,
          },
          {
            label: t("workspace.popover.delete"),
            value: PopoverActionsENUM.DELETE,
            onClick: async () => {
              try {
                const newCardData = cardData.filter(
                  (card) => card.id !== item.id,
                );
                setCardData([...newCardData]);
                await deleteSelectedWorkspace(item.id);
                setToastMessage(t("workspace.alert.deleted"));
              } catch (error) {
                console.error(error);
              }
            },
            isClosePopover: true,
          },
        ];
      }
      return [];
    },
    // eslint-disable-next-line
    [appState.selectedWorkspaceTab, cardData],
  );

  useEffect(() => {
    // Logic to handle tab changes and update the card data accordingly
    switch (appState.selectedWorkspaceTab) {
      case MyWorkspaceSidebarItemsEnum.MYWORKSPACE:
        // Load "My Workspace" data
        document.getElementById("offcanvas-hide")?.click();
        setSelectedSortBy(0);
        setCardData(cardData);
        break;
      case MyWorkspaceSidebarItemsEnum.ARCHIVE:
        // Load archived workspaces
        const archivedWorkspaceData = async () => {
          document.getElementById("offcanvas-hide")?.click();
          setSelectedSortBy(0);
          setLoading(true); // Start loading
          try {
            const data = await getArchiveWorkspaceData(user?.mail);
            const transformedData = data?.result?.map((workspace: any) => ({
              id: workspace.id,
              title: workspace.name,
              thumbnail: workspace.previewImage, // Assuming previewImage is the thumbnail
              profile: getProfile().toUpperCase(), // Static or dynamic based on user
              date: getTimeAgo(workspace.createdAt),
              elements: workspace.elements, // Attach workspace elements
              status: workspace?.status,
              tags: workspace.tags,
            }));

            setCardData(
              transformedData.filter((card: any) => card.status === "Archive"),
            );
          } catch (error) {
            console.error(error);
          } finally {
            setLoading(false); // Stop loading
          }
        };
        archivedWorkspaceData();
        break;
      case MyWorkspaceSidebarItemsEnum.NEW_WORKSPACE:
        setIsOpen(true);
        break;
      case MyWorkspaceSidebarItemsEnum.SHARED_WITH_ME:
        document.getElementById("offcanvas-hide")?.click();
        setSelectedSortBy(0);
        const getSharedWithMeData = async () => {
          setLoading(true); // Start loading
          try {
            const data = await getSharedWithMeWorkspace(user.mail);
            const res = await getWorkspaceUsers(); // Fetch users data

            const transformedData = data?.data?.map((workspace: any) => {
              // Find matching user workspace data
              const matchingUserWorkspace = res?.find(
                (userWorkspace: any) =>
                  userWorkspace.workspaceId === workspace.id,
              );

              const isSharable = !!matchingUserWorkspace; // Check if there's a match
              const roomDetails = isSharable
                ? matchingUserWorkspace.roomDetails
                : null;
              return {
                id: workspace.id,
                title: workspace.name,
                thumbnail: workspace.previewImage, // Assuming previewImage is the thumbnail
                profile: getProfile().toUpperCase(), // Static or dynamic based on user
                date: getTimeAgo(workspace.createdAt),
                elements: workspace.elements, // Attach workspace elements
                status: workspace?.status,
                sharedUserProfile: getProfile(
                  workspace.userEmail,
                ).toUpperCase(),
                sharedUserProfileEmail: workspace.userEmail,
                sharable: isSharable, // Sharable information
                roomDetails, // Room details if available
                tags: workspace.tags,
              };
            });

            const filteredData = transformedData.filter(
              (item: any) => item.sharedUserProfileEmail !== user.mail,
            );

            setCardData(filteredData);
          } catch (error) {
            console.error(error);
          } finally {
            setHasHandled(false);
            setLoading(false); // Stop loading
          }
        };

        getSharedWithMeData();
        break;
      default:
        break;
    }
    // eslint-disable-next-line
  }, [appState.selectedWorkspaceTab, setAppState]);

  /* hasHandled: This state is created because the cardData is set when the default "My Workspace" tab is loaded, 
 and I want to call the handleUnauthorizedUsers function only when cardData is set for the "Shared with Me" tab, 
 not for any other tab.*/

  useEffect(() => {
    const hashParams = new URLSearchParams(window.location.hash.substring(1));
    const selectedWorkspaceCardID = hashParams.get("selectedWorkspaceCardID");
    if (
      appState.selectedWorkspaceTab ===
        MyWorkspaceSidebarItemsEnum.SHARED_WITH_ME &&
      cardData.length > 0 &&
      !hasHandled
    ) {
      // Mark as handled
      handleUnauthorizedUsers(selectedWorkspaceCardID);
    }
    // eslint-disable-next-line
  }, [
    cardData,
    hasHandled,
    // eslint-disable-next-line
    appState.selectedWorkspaceTab ===
      MyWorkspaceSidebarItemsEnum.SHARED_WITH_ME,
  ]);

  const handleUnauthorizedUsers = async (
    selectedWorkspaceCardID: string | null,
  ) => {
    if (!selectedWorkspaceCardID) {
      return;
    }
    const idx = cardData.findIndex(
      (data) => data.id === selectedWorkspaceCardID,
    );
    if (idx !== -1) {
      await handleCardClick(selectedWorkspaceCardID);
      return;
    }
    setOpenModal(true);
  };
  const handleCardClick = async (selectedWorkspaceCardID: string | null) => {
    if (!selectedWorkspaceCardID) {
      return;
    }

    localStorage.setItem("selectedWorkspaceCard", selectedWorkspaceCardID);
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    localStorage.setItem("isClicked", "true");
    props.initializeSceneWithWorkspace(selectedWorkspaceCardID, user?.mail);

    localStorage.setItem("sharedUserProfileEmail", user?.mail);

    window.parent.postMessage(
      {
        type: "SELECTED_WORKSPACE",
        selectedWorkspaceCardId: selectedWorkspaceCardID,
      },
      `${`${process.env.REACT_APP_PARENT_APP}`}`,
    );
  };

  return (
    <>
      <FilterdWorkspace
        cardData={cardData}
        filteredTags={filteredTags}
        authors={authors}
        selectedWorkspaceTab={appState.selectedWorkspaceTab}
        setCardData={setCardData}
        isDataLoaded={isDataLoaded}
        setLoading={setLoading}
      />
      <div className="w-100 d-flex my-workspace">
        <Sidebar
          navToggle={navToggle}
          setNavToggle={setNavToggle}
          setSelectedTab={setAppState}
          selectedTab={
            appState.selectedWorkspaceTab ||
            MyWorkspaceSidebarItemsEnum.MYWORKSPACE
          }
          resetScene={props.resetScene}
          handleItemClick={props.handleItemClick}
          isTablet={isTablet}
          isMobile={isMobile}
        />
        <div
          style={{
            width: navToggle ? "100%" : "87%",
            backgroundColor: "rgb(246 248 251/1)",
          }}
          className="p-5 overflow-auto"
          key={appState.selectedWorkspaceTab}
        >
          <div
            className="d-flex justify-content-between gap-2 filter-container"
            style={{
              flexWrap: isMobile ? "wrap" : "nowrap",
              marginBottom: isMobile ? "60px" : "10px",
            }}
          >
            <div className="d-flex form-group has-search w-100 justify-content-end">
              <input
                type="search"
                className="form-control search-input"
                style={{ width: isMobile ? "100%" : "30%" }}
                placeholder={t("workspace.search")}
                onChange={(e) => {
                  const value = e.target.value;
                  setSearchQuery(value); // Update search query state
                }}
                value={searchQuery}
              />
            </div>
            {(appState.selectedWorkspaceTab ===
              MyWorkspaceSidebarItemsEnum.MYWORKSPACE ||
              appState.selectedWorkspaceTab ===
                MyWorkspaceSidebarItemsEnum.SHARED_WITH_ME) && (
              <>
                {" "}
                <div className="d-flex justify-content-between">
                  {isMobile || isTablet ? (
                    <div className="d-flex align-items-center">
                      <select
                        className="custom-select"
                        value={selectedSortBy !== null ? selectedSortBy : 0} // Default to 0 if no value is selected
                        onChange={(e) =>
                          setSelectedSortBy(Number(e.target.value))
                        }
                      >
                        {sortByContet.map((sortText, index) => (
                          <option key={index} value={index}>
                            {sortText}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center pt-lg-0 pt-3 ms-auto me-4">
                      {sortByContet.map((sortText, index) => {
                        return (
                          <p
                            className="ps-3 text-nowrap mb-0"
                            style={{ cursor: "pointer" }}
                            onClick={() => setSelectedSortBy(index)}
                          >
                            {sortText}
                          </p>
                        );
                      })}
                    </div>
                  )}
                </div>{" "}
                <button
                  className="sort-filter__button px-3 py-0 "
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  onClick={async () => {
                    setIsDataLoaded(true);
                  }}
                >
                  {sortAndFilterIcon}{" "}
                  {t("collection.community.template.filter.title")}
                </button>
              </>
            )}
          </div>
          {isOpen && (
            <Dialog
              onCloseRequest={closeDialog}
              title="Rename-Worspace"
              className="rename-workspace"
              closeOnClickOutside={true}
              open={true}
              setOpen={() => false}
              children={
                <div className="workspace-container">
                  <div className="workspace-header">
                    <p className="workspace_name">Workspace name:</p>
                  </div>
                  <div className="name-input-container">
                    <input
                      className="name-input"
                      placeholder="Enter workspace name"
                      type="text"
                      id="name-input"
                      value={workspaceName}
                      onChange={(e) => setWorkspaceName(e.target.value)}
                    />
                  </div>
                  <div className="workspace-header">
                    <p className="workspace_name">Tags:</p>
                  </div>
                  <div className="tags-container">
                    <div className="name-input-container">
                      <input
                        className="name-input"
                        placeholder="Enter tags"
                        type="text"
                        id="name-input"
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                      />
                    </div>
                    {tags.map((tag, index) => (
                      <div className="tag" key={index}>
                        <span>{tag}</span>
                        <button
                          type="button"
                          className="remove-tag"
                          onClick={() => handleRemoveTag(index)}
                        >
                          {closeIcon_}
                        </button>
                      </div>
                    ))}
                  </div>
                  <div className="button-container">
                    <button
                      className={`save-button ${
                        createWorkpsaceloading ? "disabled-button" : ""
                      }`}
                      onClick={handleSave}
                      disabled={createWorkpsaceloading}
                    >
                      {createWorkpsaceloading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm save-spinner"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Save
                        </>
                      ) : (
                        "Save"
                      )}
                    </button>
                    <button className="cancel-button" onClick={closeDialog}>
                      Cancel
                    </button>
                  </div>
                </div>
              }
            />
          )}
          {cardData.length
            ? openModal && (
                <Dialog
                  onCloseRequest={() => setOpenModal(false)}
                  title="Access Denied"
                  className="access-workspace-dialog"
                  closeOnClickOutside={true}
                  open={true}
                  setOpen={() => false}
                  children={
                    <div className="access-container">
                      <div className="access-header">
                        <p className="access-message">
                          This workspace is not shared with you. If you need
                          access to this workspace, please ask the admin to
                          share it with you.
                        </p>
                      </div>
                    </div>
                  }
                />
              )
            : null}

          {loading ? (
            <div className="w-100 d-flex justify-content-center h-100 align-items-center">
              <div className="spinner-border" role="status" />
            </div>
          ) : (searchQuery ? filteredData.length : cardData.length) > 0 ? (
            <div className="d-grid flex-wrap gap-2 card-container">
              {(searchQuery ? filteredData : cardData).map((card, index) => (
                <Card
                  key={index}
                  cardData={card}
                  actions={renderContextMenu(card)}
                  {...(card.id === editTitle
                    ? { renderTitle: () => renderTitle(card) }
                    : {})}
                  appState={appState}
                  setAppState={setAppState}
                  initializeSceneWithWorkspace={
                    props.initializeSceneWithWorkspace
                  }
                  setOpenModal={setOpenModal}
                  showInput={showInput}
                  setShowInput={setShowInput}
                  onSaveDate={onSaveDate}
                  setSelectedDate={setSelectedDate}
                  selectedDate={selectedDate}
                  expirationLoader={expirationLoader}
                  setShareableWorkspaceOrNot={setShareableWorkspaceOrNot}
                  shareableWorkspaceOrNot={shareableWorkspaceOrNot}
                  workspaceAllCards={cardData}
                />
              ))}
            </div>
          ) : (
            <div className="w-100 d-flex justify-content-center h-100 align-items-center">
              <p>No data found</p>
            </div>
          )}
        </div>
        {toastMessage && (
          <Toast
            type={ToastType.WARNING}
            message={toastMessage}
            clearToast={() => setToastMessage(null)}
            className="style"
          />
        )}
      </div>
    </>
  );
};
