import { useEffect, useState } from "react";
import { getLessonId } from "../../excalidraw-app/api/getuserInfo";
import { getElemetDataFromDatabase } from "../../excalidraw-app/api/storeElementData";
import { AppState } from "../../types";
import {
  ArrowSquareDownIcon,
  ArrowSquareUpIcon,
  PlusIcon,
  SquareCheckIcon,
  deleteIcon,
  edit,
} from "../icons";
import Avatars from "./Avatars";
import Pages from "./Pages";
import Students from "./Students";
import { groupType, studentType } from "./Types";
import "./group.scss";

const Group = ({
  group,
  onDelete,
  i,
  onSave,
  setStudents,
  students,
  appState,
  handleToggle,
  onChange,
  isOpen,
  setSelectedStudent,
  selectedStudent,
  setIsEditable,
  isEditable,
  groups,
  setGroups,
  setError,
  isShowFullScreen,
}: {
  group: groupType;
  onDelete: (val: string, index: number) => void;
  onSave: (group: groupType, i: number) => void;
  onChange: (group: groupType, i: number) => void;
  setStudents: (students: studentType[]) => void;
  students: studentType[];
  i: number;
  appState: Partial<AppState>;
  handleToggle: (i: number) => void;
  isOpen: boolean;
  setSelectedStudent: Function;
  selectedStudent: boolean;
  isEditable: boolean;
  setIsEditable: (value: boolean) => void;
  groups: groupType[];
  setGroups: (groups: groupType[]) => void;
  setError: (val: string) => void;
  isShowFullScreen: boolean;
}) => {
  const [updatedGroup, setUpdatedGroup] = useState<groupType | null>(null);
  const [pages, setPages] = useState<any>([]);
  const [isPagesLoading, setIsPagesLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchStudents, setSearchStudents] = useState("");
  const [filteredStudents, setFilteredStudents] = useState(students);

  useEffect(() => {
    if (searchStudents.trim() === "") {
      setFilteredStudents(students);
    } else {
      setFilteredStudents(
        students.filter((student) =>
          student.studentEmail
            .toLowerCase()
            .includes(searchStudents.toLowerCase()),
        ),
      );
    }
  }, [searchStudents, students]);

  useEffect(() => {
    const filteredPages = pages.filter((page: any) => {
      return (
        (!page.isAssignedGroup &&
          `Page ${page.page}` &&
          `Page ${page.page}`
            .toLowerCase()
            .includes(searchQuery.toLowerCase())) ||
        `Page${page.page}`.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
    setPages(filteredPages);
  }, [searchQuery]);

  useEffect(() => {
    !searchQuery && getAllPages();
  }, [!searchQuery]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filterPages = (pages: any) => {
    const filteredPages = pages.map((page: any) => {
      return {
        ...page,
        isAssignedGroup: group.page.isPage ? group.page.id === page.id : false,
      };
    });

    const sortedPages = filteredPages.sort(
      (a: { page: number }, b: { page: number }) =>
        a.page > b.page ? 1 : b.page > a.page ? -1 : 0,
    );
    return sortedPages;
  };

  const getAllPages = async () => {
    try {
      setIsPagesLoading(true);
      const lessonId = await getLessonId();
      const isMyWorkSpace = localStorage.getItem("isMyWorkSpace");
      if (isMyWorkSpace === "true") {
        return;
      }
      const res = await getElemetDataFromDatabase(lessonId || "", false);
      setPages(filterPages(res.result));
      setIsPagesLoading(false);
      return res.result;
    } catch (error) {
      console.error("error-from-'getAllPages'", error);
    }
  };

  useEffect(() => {
    getAllPages();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setUpdatedGroup(group);
    // eslint-disable-next-line
  }, [JSON.stringify({ group })]);

  useEffect(() => {
    updatedGroup && onChange(updatedGroup, i);
    // eslint-disable-next-line
  }, [updatedGroup]);

  const handleUpdate = () => {
    if (updatedGroup) {
      onSave(
        {
          ...updatedGroup,
          students: group.students.map((student: studentType) => {
            return {
              ...student,
              isEnabled: true,
            };
          }),
        },
        i,
      );
    }
  };

  const handleUpdatePages = (group: groupType) => {
    onSave(group, i);
  };

  return group?.isOpen ? (
    <div className="open-group-wrapper row mx-0">
      <div className="left-section col-md-6 col-sm-12 p-2">
        <input
          type="text"
          placeholder="Search students..."
          value={searchStudents}
          onChange={(e) => setSearchStudents(e.target.value)}
          style={{
            borderRadius: "8px",
            marginTop: "13px",
            marginBottom: "13px",
          }}
          className="w-100 p-2 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 placeholder-gray-400"
        />
        <div
          className="upper-block d-flex justify-content-between "
          style={{ marginTop: "6px" }}
        >
          <div
            className="group-icon-left cursor-pointer"
            style={{ display: "flex" }}
            onClick={() => {
              if (selectedStudent) {
                handleToggle(i);
              } else {
                setIsEditable(!isEditable);
              }
            }}
          >
            <ArrowSquareUpIcon />
          </div>
          {isEditable ? (
            <div className="form-floating">
              <input
                style={{
                  height: "10px",
                  outline: "0",
                  borderWidth: "0 0px 1px",
                  paddingBottom: "14px",
                }}
                type="text"
                className="form-control"
                id="floatingInput"
                value={updatedGroup?.name}
                onChange={(e) => {
                  if (updatedGroup) {
                    setUpdatedGroup({
                      ...updatedGroup,
                      name: e.target.value,
                    });
                  }
                }}
              />
              <label
                htmlFor="floatingInput"
                style={{
                  padding: "0.5rem 0.75rem",
                  fontSize: "15px",
                  top: "-22%",

                  color: "black",
                }}
              >
                Edit your group name
              </label>
            </div>
          ) : (
            <h4 className="text-center group-name fw-bold mb-0">
              {updatedGroup?.name}
            </h4>
          )}
          <div className="d-flex align-items-center justify-content-center">
            {isEditable ? (
              <div
                className="group-icon-right me-3 cursor-pointer"
                onClick={() => {
                  window.parent.postMessage(
                    { type: "EDIT_MODAL", isEditable: !isEditable },
                    `${process.env.REACT_APP_PARENT_APP}`,
                  );
                  handleUpdate();
                  setIsEditable(!isEditable);
                }}
              >
                <SquareCheckIcon />
              </div>
            ) : (
              <div
                className="group-icon-right me-3 cursor-pointer"
                onClick={() => {
                  window.parent.postMessage(
                    { type: "EDIT_MODAL", isEditable: !isEditable },
                    `${process.env.REACT_APP_PARENT_APP}`,
                  );
                  setIsEditable(!isEditable);
                }}
              >
                {edit}
              </div>
            )}

            <div
              className="group-icon-right cursor-pointer"
              onClick={() => {
                if (updatedGroup) {
                  onDelete(updatedGroup.id || "", i);
                }
                setError("");
              }}
            >
              {deleteIcon}
            </div>
          </div>
        </div>
        <div className="lower-block mt-4 px-3">
          <div className="students-list d-flex flex-column">
            <Students
              students={filteredStudents}
              setStudents={setFilteredStudents}
              group={updatedGroup || null}
              setGroup={setUpdatedGroup}
              isEditable={isEditable}
              setIsEditable={setIsEditable}
              setSelectedStudent={setSelectedStudent}
              isOpen={isOpen}
              groups={groups}
              setGroups={setGroups}
            />
          </div>
        </div>
      </div>
      <div className="right-section d-flex flex-column justify-content-center col-md-6 col-sm-12 p-2">
        <div className="group-pages-wrapper h-100 py-1">
          <input
            type="text"
            placeholder="Search pages..."
            value={searchQuery}
            onChange={handleSearchChange}
            style={{ borderRadius: "8px", marginTop: "10px" }}
            className="w-100 p-2 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 placeholder-gray-400"
          />
          <Pages
            appState={appState}
            group={updatedGroup || null}
            setGroup={setUpdatedGroup}
            onSave={handleUpdatePages}
            pages={pages}
            setPages={setPages}
            isEditable={isEditable}
            setIsEditable={setIsEditable}
            isPagesLoading={isPagesLoading}
            isShowFullScreen={isShowFullScreen}
          />
        </div>
      </div>
    </div>
  ) : (
    <div
      className="group-wrapper p-2 d-flex align-items-center justify-content-between flex-wrap"
      style={{ margin: isShowFullScreen ? "7px" : "0px" }}
    >
      <div className="d-flex align-items-center justify-content-center">
        <div
          className="group-icon-left cursor-pointer"
          onClick={() => {
            handleToggle(i);
          }}
        >
          <ArrowSquareDownIcon />
        </div>
        <h4 className="text-center group-name fw-bold mb-0 ">
          {updatedGroup?.name}
        </h4>
        <div className="ms-4">
          <Avatars group={group} />
          <p
            className="text-center group-name mb-0"
            style={{ display: "contents" }}
          >
            {updatedGroup?.students.length} Students
          </p>
        </div>
      </div>
      {group.page.isPage ? (
        <div
          className="group-page-wrapper d-flex align-items-center justify-content-center"
          style={{ height: "100px" }}
        >
          <div
            className="group-page d-flex align-items-center justify-content-center"
            style={{ width: "200px" }}
          >
            <p className="mb-0 d-inline-block fw-bold text-nowrap text-center w-100 px-1 text-truncate">
              <img
                src={
                  group?.page?.dataURL
                    ? group?.page?.dataURL +
                      process.env.REACT_APP_AZURE_STORAGE_SAS_TOKEN
                    : "https://t4.ftcdn.net/jpg/04/73/25/49/360_F_473254957_bxG9yf4ly7OBO5I0O5KABlN930GwaMQz.jpg"
                }
                className="w-100 h-auto"
                alt=""
              />
            </p>
          </div>
          <p className="mb-0 d-inline-block fw-bold text-nowrap text-center w-100 px-1 text-truncate">
            {group.page.name ? group.page.name : `Page ${group.page.no}`}
          </p>
        </div>
      ) : (
        <div
          className="group-page-wrapper d-flex align-items-center justify-content-center cursor-pointer"
          onClick={() => {
            handleToggle(i);

            window.parent.postMessage(
              { type: "EDIT_MODAL", isEditable: true },
              `${process.env.REACT_APP_PARENT_APP}`,
            );
            setIsEditable(true);
          }}
        >
          <div className="group-page d-flex align-items-center justify-content-center">
            <div className="square-box-icon-outside">
              <PlusIcon color="#DBDBDB" />
            </div>
          </div>
        </div>
      )}
      <div className="d-flex align-items-center justify-content-center">
        <div
          className="group-icon-right edit-icon cursor-pointer"
          onClick={() => {
            handleToggle(i);

            window.parent.postMessage(
              { type: "EDIT_MODAL", isEditable: true },
              `${process.env.REACT_APP_PARENT_APP}`,
            );
            setIsEditable(true);
          }}
        >
          {edit}
        </div>
        <div
          className="group-icon-right cursor-pointer"
          onClick={() => {
            if (updatedGroup) {
              onDelete(updatedGroup.id || "", i);
            }
            setError("");
          }}
        >
          {deleteIcon}
        </div>
      </div>
    </div>
  );
};

export default Group;
