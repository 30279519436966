import React, {
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { t } from "../i18n";
import { StartEditingProps, useApp, useIsMobile } from "./App";

import axios from "axios";
import clsx from "clsx";
import { trackEvent } from "../analytics";
import {
  ExcalidrawTextWithStyleElement,
  NonDeletedExcalidrawElement,
} from "../element/types";
import { apiPut } from "../excalidraw-app/api";
import { ACV_API_URL } from "../excalidraw-app/api/constant";
import { AppState, BinaryFiles } from "../types";
import {
  ConvertHtmlTexttoTiptapEditorTextString,
  viewportCoordsToSceneCoords,
} from "../utils";
import { convertHtmlToCanvas } from "../utils/canvas";
import AlertDialog from "./AlertDialog";
import { Dialog } from "./Dialog";
import "./GPTDialog.scss";
import {
  codeBlockRegex_,
  diagramExample,
  diagramKeywords,
  diagramSyntax,
} from "./GptPrompts";
import { useOnClickOutside } from "./LibraryMenu";
import { Popover } from "./Popover";
import Spinner from "./Spinner";
import { ToolButton, ToolButtonEnum } from "./ToolButton";
import { close, CoinIcon, GptIcon, redo, undo, zoomIn, zoomOut } from "./icons";
import {
  convertMermaidToExcalidraw,
  insertToEditor,
  MermaidToExcalidrawLibProps,
} from "./mermaidToExcalidraw/common";
import { UserProfileContext } from "./contexts/UserProfile.context";

const languages = [
  "English",
  "Español",
  "Português",
  "Français",
  "Deutsch",
  "हिन्दी",
  "日本語",
  "Italiano",
];

enum CreateType {
  LESSON_PLAN = "a lesson plan",
  RESOURCE = "a resource",
  EXEMPLAR = "an exemplar",
  DIAGRAM = "a diagram",
  // SOMETHING_ELSE = "something else",
}

const creates = [
  CreateType.LESSON_PLAN,
  CreateType.RESOURCE,
  CreateType.EXEMPLAR,
  CreateType.DIAGRAM,
  // CreateType.SOMETHING_ELSE,
];

enum ChartType {
  FLOWCHART = "of flowchart",
  FLOWCHART_SYNTAX = "a flowchart syntax",
}
const diagramOptions = [
  ["Flowchart", "flowchart TD"],
  ["Sequence Diagram", "sequenceDiagram"],
  ["Class Diagram", "classDiagram"],
  ["Mindmap", "mindmap"],
  ["Timeline", "timeline"],
  ["Pie Chart", "pie"],
  ["Gantt Chart", "Gantt Chart"],
  // ["XY Chart", "xychart-beta"],
  ["State Diagram", "State Diagram"],
  ["Entity Relationship Diagram", "erDiagram"],
  // ["Quadrant Chart Diagram", "quadrantChart"],
  ["Journey Diagram", "journey"],
  // ["Requirement Diagram", "requirementDiagram"],
  ["Git Graph", "gitGraph"],
  // ["Sankey Diagram", "sankey-beta"],
];

type UndoRedoState = {
  undo: string[];
  redo: string[];
};

type GPTPropsTypes = {
  appState: AppState;
  startTextEditing: (data: StartEditingProps) => void;
  setOpenGPTDialog: (value: boolean) => void;
  openGPTDialog: boolean;
  setAppState: React.Component<any, AppState>["setState"];
  onTextWithStylesAction: ({
    textString,
    show,
    handleCloseDialogue,
    selectedElements,
    isAIGenerated,
    width,
  }: {
    textString: string;
    show?: boolean;
    handleCloseDialogue?: VoidFunction;
    selectedElements?: ExcalidrawTextWithStyleElement[];
    isAIGenerated?: boolean;
    width?: number;
  }) => void;
};
type CurrentUserType = {
  slug: string;
  schoolInfo: {
    OwnStgUrl: string;
    School: string;
    ContactPhone: number;
    ContactEmail: string;
    SchoolLogo: string | null;
    SchoolAddress: string;
  };
  role: string;
  mail: string;
  accessToken: string;
};

type CreditRequestType = {
  id: string;
  TeacherEmailID: string;
  TeacherName: string;
  role: string;
  status: string;
  tenantDB: string;
  credit: Number;
  createdAt: string;
  updatedAt: string;
};

const DropDown = ({
  options,
  selected,
  onChange,
  classes,
  disabled,
}: {
  options: string[];
  selected: string;
  onChange: (value: string) => void;
  classes?: String;
  disabled: boolean;
}) => {
  return (
    <>
      <select
        disabled={disabled}
        className={`form-select form-select-sm mb-3 ${classes}`}
        aria-label=".form-select-sm example"
        value={selected}
        onChange={(e) => onChange(e.target.value)}
      >
        {options.length &&
          options.map((option, index) => (
            <option value={option} key={index}>
              {option}
            </option>
          ))}
      </select>
    </>
  );
};

const isMermaidSyntax = (text: string) => {
  // Convert text to lowercase for case-insensitive matching
  const lowerText = text.toLowerCase();

  // List of common Mermaid syntax keywords
  const mermaidKeywords = [
    "graph",
    "sequence",
    "state",
    "gantt",
    "classDiagram",
    "pie",
    "erDiagram",
    "flowchart",
    "sequenceDiagram",
  ];

  // Check if the text starts with 'graph' or other specific keywords
  for (const keyword of mermaidKeywords) {
    if (lowerText.startsWith(keyword)) {
      return true;
    }
  }

  // Check if the text contains common Mermaid syntax features such as '->', '-->', '---', etc.
  const mermaidSyntaxPatterns = [
    /->/, // Directs
    /-->/, // Directs with open arrow
    /---/, // Other patterns
    /\{/, // Checking for graph { } start
    /\}/, // Checking for graph { } end
  ];

  // Check for each pattern in the text
  for (const pattern of mermaidSyntaxPatterns) {
    if (pattern.test(text)) {
      return true;
    }
  }

  // Return false if no Mermaid-specific patterns or keywords are found
  return false;
};

export const GPTDialog = (props: GPTPropsTypes) => {
  const { openGPTDialog, setOpenGPTDialog } = props;
  const [open, setOpen] = useState(false);
  const isMobile = useIsMobile();
  const theme = props.appState.theme || "light";

  const [language, setLanguage] = useState("English");
  const [create, setCreate] = useState(CreateType.LESSON_PLAN);
  const [promptInput, setPromtInput] = useState("");
  const [mermaidSytax, setMermaidSyntax] = useState("");
  const [generatedContent, setGeneratedContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar, setIsOpenSnackbar] = useState(false);
  const [currentUser, setCurrentUser] = useState<CurrentUserType>();
  const [isRequested, setIsRequested] = useState(false);
  const [generatePreviewLoader, setGeneratePreviewLoader] = useState(false);
  const [previewImg, setPreviewImg] = useState(null);
  const [chart, setChart] = useState("");
  const { gptCredit, setGptCredit } = useContext(UserProfileContext);
  const someRandomDivRef = useRef<HTMLDivElement>(null);
  const divRef = useRef<HTMLDivElement | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [
    mermaidToExcalidrawLib,
    setMermaidToExcalidrawLib,
  ] = useState<MermaidToExcalidrawLibProps>({
    loaded: false,
    api: import("@excalidraw/mermaid-to-excalidraw"),
  });
  const [diagramType, setDiagramType] = useState("");
  const [isTextAreaFocused, setIsTextAreaFocused] = useState(false);
  const [openMermaidSyntaxTextArea, setOpenMermaidSyntaxTextArea] = useState(
    false,
  );
  const [isOpenViewAsMermaidLoader, setIsOpenViewAsMermaidLoader] = useState(
    false,
  );
  const [openRegenerateChartModal, setOpenRegenerateChartModal] = useState(
    false,
  );
  const [
    isOpenRegenerateChartLoader,
    setIsOpenRegenerateChartLoader,
  ] = useState(false);
  const app = useApp();
  const dragRef = useRef<HTMLDivElement>(null);
  const dragStartX = useRef<number>(0);
  const dragStartY = useRef<number>(0);

  const textareas: RefObject<HTMLTextAreaElement>[] = [
    useRef(null),
    useRef(null),
    // Add more refs for other textareas
  ];

  const [undoRedoState, setUndoRedoState] = useState<{
    [key: number]: UndoRedoState;
  }>({});

  const handleInput = (id: number) => {
    const textarea = textareas[id].current;
    if (textarea) {
      setUndoRedoState((prev) => ({
        ...prev,
        [id]: {
          undo: [...(prev[id]?.undo || []), textarea.value],
          redo: [],
        },
      }));
    }
  };

  const handleUndo = (id: number) => {
    const { undo = [], redo = [] } = undoRedoState[id] || {};
    if (undo.length) {
      const newUndo = [...undo];
      const lastState = newUndo.pop()!;
      setUndoRedoState((prev) => ({
        ...prev,
        [id]: {
          undo: newUndo,
          redo: [textareas[id].current?.value || "", ...redo],
        },
      }));
      if (id === 0) {
        setPromtInput(lastState);
      } else {
        setMermaidSyntax(lastState);
      }
      // if (textareas[id].current) {
      //   textareas[id].current.value = lastState;
      // }
    }
  };

  const handleRedo = (id: number) => {
    const { undo = [], redo = [] } = undoRedoState[id] || {};
    if (redo.length) {
      const newRedo = [...redo];
      const nextState = newRedo.shift()!;
      setUndoRedoState((prev) => ({
        ...prev,
        [id]: {
          undo: [...undo, textareas[id].current?.value || ""],
          redo: newRedo,
        },
      }));
      // if (textareas[id].current) {
      //   textareas[id].current.value = nextState;
      // }
      if (id === 0) {
        setPromtInput(nextState);
      } else {
        setMermaidSyntax(nextState);
      }
    }
  };

  const renderUndoRedo = (id: number) => {
    return (
      <div
        className={clsx("undo-redo-buttons zen-mode-transition pb-2")}
        style={{
          position: "absolute",
          zIndex: 2,
          bottom:
            create === CreateType.DIAGRAM && !openMermaidSyntaxTextArea
              ? "4%"
              : 0,
          right: "2%",
        }}
      >
        <button className="bg-brand undo-gpt" onClick={() => handleUndo(id)}>
          {undo}
        </button>
        <button className="bg-brand undo-gpt" onClick={() => handleRedo(id)}>
          {redo}
        </button>
      </div>
    );
  };

  // Effect to open the modal when title changes
  useEffect(() => {
    if (promptInput) {
      setOpenRegenerateChartModal(true);
    }
  }, [promptInput]);

  useEffect(() => {
    if (
      create === CreateType.DIAGRAM &&
      !isOpenViewAsMermaidLoader &&
      generatedContent
    ) {
      setMermaidSyntax(generatedContent);
    }
  }, [create, isOpenViewAsMermaidLoader, generatedContent]);

  useEffect(() => {
    const diagram_Example = diagramExample(diagramType, language);
    if (create === CreateType.DIAGRAM && diagramType && !promptInput) {
      setMermaidSyntax(diagram_Example);
    }
    // eslint-disable-next-line
  }, [create, diagramType, language, someRandomDivRef.current, openGPTDialog]);

  useEffect(() => {
    const observerCallback = () => {
      const parentDiv = document.getElementById("parentDiv");
      const dragDiv = document.getElementById("mydiv");
      const childDiv = document.getElementById("childDiv");

      if (parentDiv && dragDiv && childDiv) {
        const parentWidth = parentDiv.getBoundingClientRect().width;
        dragDiv.style.width = `${parentWidth}px`;
        childDiv.style.width = `${parentWidth}px`;
      }
    };

    // Initial call to set the width
    const timeoutId = setTimeout(observerCallback, 0);

    // Create a resize observer to handle resizing of the parent div
    const observer = new ResizeObserver(observerCallback);
    const parentDiv = document.getElementById("parentDiv");
    if (parentDiv) {
      observer.observe(parentDiv);
    }

    // Add event listener for window resize
    const handleWindowResize = () => observerCallback();
    window.addEventListener("resize", handleWindowResize);

    return () => {
      // Cleanup observer and window resize listener
      clearTimeout(timeoutId);
      observer.disconnect();
      window.removeEventListener("resize", handleWindowResize);
    };
    // eslint-disable-next-line
  }, [openGPTDialog, openMermaidSyntaxTextArea]);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isTextAreaFocused) {
      return;
    }
    e.preventDefault();

    dragStartX.current = e.clientX;
    dragStartY.current = e.clientY;

    document.onmouseup = handleMouseUp;
    document.onmousemove = handleMouseMove;
  };

  const handleMouseMove = (e: MouseEvent) => {
    e.preventDefault();

    if (dragRef.current) {
      const deltaX = dragStartX.current - e.clientX;
      const deltaY = dragStartY.current - e.clientY;

      dragStartX.current = e.clientX;
      dragStartY.current = e.clientY;

      dragRef.current.style.top = `${dragRef.current.offsetTop - deltaY}px`;
      dragRef.current.style.left = `${dragRef.current.offsetLeft - deltaX}px`;
    }
  };

  const handleMouseUp = () => {
    document.onmouseup = null;
    document.onmousemove = null;
  };

  useEffect(() => {
    const fn = async () => {
      await mermaidToExcalidrawLib.api;
      setMermaidToExcalidrawLib((prev) => ({ ...prev, loaded: true }));
    };
    fn();
  }, [mermaidToExcalidrawLib.api]);

  useEffect(() => {
    const user = localStorage.getItem("user");
    const convertUser = JSON.parse(user || "");
    setCurrentUser(convertUser);
  }, []);

  useEffect(() => {
    if (currentUser) {
      getPendingRequest();
    }
    // eslint-disable-next-line
  }, [currentUser, gptCredit]);

  useOnClickOutside(divRef, (event) => {
    // If click on the library icon, do nothing.
    if ((event.target as Element).closest(".ToolIcon__library")) {
      return;
    }
    setOpenGPTDialog(false);
  });

  const [zoomFactor, setZoomFactor] = useState(1);

  const handleZoomIn = () => {
    setZoomFactor(zoomFactor + 0.1); // Increase zoom factor
  };

  const handleZoomOut = () => {
    setZoomFactor(Math.max(0.1, zoomFactor - 0.1)); // Decrease zoom factor, but not less than 0.1
  };

  // useEffect hook to call convertMermaidToExcalidraw when zoomFactor changes
  useEffect(() => {
    // Define an async function to call convertMermaidToExcalidraw
    const updateCanvas = async () => {
      try {
        if (!generatedContent && !mermaidSytax) {
          return;
        }
        // Call convertMermaidToExcalidraw with necessary parameters
        await convertMermaidToExcalidraw({
          canvasRef: someRandomDivRef,
          mermaidToExcalidrawLib,
          mermaidDefinition: mermaidSytax ? mermaidSytax : generatedContent,
          setError,
          data,
          zoomFactor,
          appState: props.appState,
          setAppState: props.setAppState,
        });
      } catch (error) {
        console.error("Error updating canvas:", error);
      }
    };
    updateCanvas();
    // eslint-disable-next-line
  }, [zoomFactor, mermaidSytax, someRandomDivRef.current, openGPTDialog]);

  const getPendingRequest = () => {
    const config: any = {
      method: "get",
      url: `${ACV_API_URL}/api/credit/get-credit-request-by-status${currentUser?.slug}&TeacherEmailID=${currentUser?.mail}&status=pending`,
      headers: {
        Authorization: `Bearer ${currentUser?.accessToken}`,
      },
    };

    axios
      .request(config)
      .then(
        (response: {
          data: { result: React.SetStateAction<{} | CreditRequestType> };
        }) => {
          if (Object?.keys(response?.data?.result)?.length) {
            setIsRequested(true);
            return response.data.result;
          }
          setIsRequested(false);
        },
      )
      .catch((error: any) => {
        console.error(error);
      });
  };

  const handleButtonClick = (newSyntax: string, promptIndex: number) => {
    setZoomFactor(1);
    setDiagramType(newSyntax);
    setGeneratedContent("");
    setPromtInput("");
    setError(null);
  };

  const data = useRef<{
    elements: readonly NonDeletedExcalidrawElement[];
    files: BinaryFiles | null;
  }>({ elements: [], files: null });

  const ref = useRef<HTMLDivElement | null>(null);

  const handleCloseDialogue = () => {
    setIsLoading(false);
    setOpenGPTDialog(false);
    // setCreate(CreateType.LESSON_PLAN);
    // setLanguage("English");
    setPromtInput("");
    setGeneratedContent("");
    setPreviewImg(null);
    setChart("");
    setError(null);
    // setDiagramType("");
    setMermaidSyntax("");
    setOpenMermaidSyntaxTextArea(false);
  };

  const exportToCanvas = async (event: any) => {
    setIsLoading(true);
    const { x: sceneX, y: sceneY } = await viewportCoordsToSceneCoords(
      event,
      props.appState,
    );

    const finalContent = ConvertHtmlTexttoTiptapEditorTextString(
      generatedContent,
    );

    await props.onTextWithStylesAction({
      textString: finalContent,
      show: true,
      handleCloseDialogue,
      isAIGenerated: true,
    });
    props.setAppState({
      textEditor: {
        ...props.appState.textEditor,
        open: false,
        sceneX: sceneX / 2,
        sceneY: sceneY / 2,
        value: finalContent,
      },
    });
  };

  const handleSelect = (value: string) => {
    if (value === "English") {
      setLanguage("English");
    } else if (value === "Español") {
      setLanguage("Español");
    } else if (value === "Português") {
      setLanguage("Português");
    } else if (value === "Français") {
      setLanguage("Français");
    } else if (value === "Deutsch") {
      setLanguage("Deutsch");
    } else if (value === "हिन्दी") {
      setLanguage("हिन्दी");
    } else if (value === "日本語") {
      setLanguage("日本語");
    } else if (value === "Italiano") {
      setLanguage("Italiano");
    }
  };

  const determineDiagramType = (inputString: string): string | undefined => {
    // Convert string to lowercase and remove spaces
    const normalizedString = inputString.toLowerCase().replace(/\s+/g, "");

    // Loop through the keywords to determine the diagram type
    for (const [diagramType, keywords] of Object.entries(diagramKeywords)) {
      if (keywords.some((keyword) => normalizedString.includes(keyword))) {
        return diagramType;
      }
    }

    // Return undefined if no match is found
    return undefined;
  };

  async function generatePrompt(
    event: any,
    loader?: boolean,
    regenerate?: boolean,
  ) {
    if (!promptInput) {
      return setOpen(true);
    }
    setError(null);
    if (regenerate) {
      setOpenRegenerateChartModal(true);
      setIsOpenRegenerateChartLoader(true);
    } else {
      loader && setOpenRegenerateChartModal(false);
    }

    if (loader) {
      setGeneratePreviewLoader(!loader);
      setIsOpenViewAsMermaidLoader(true);
    } else {
      !regenerate && setGeneratePreviewLoader(true);
    }
    let promptMessage = "";
    const type = diagramType ? diagramType : determineDiagramType(promptInput);
    setZoomFactor(1);

    const diagram_Syntax = diagramSyntax(type ?? "flowchart TD", language);

    if (create === CreateType.DIAGRAM) {
      const defaultType = "flowchart diagram";
      const defaultKeyword = "flowchart TD";
      const typeMessage = type ? type : defaultType;
      const keywordMessage = type ? type : defaultKeyword;

      promptMessage = `Could you please provide the Mermaid syntax representation of a ${typeMessage} for ${promptInput}?
      The syntax should start with the ${keywordMessage} keyword. Ensure each connection is indicated with arrows and labels where applicable.
      Please provide this type of ${type}.
      Here is an example format and please follow this format: ${diagram_Syntax} must return a response in mermaid syntax.      
      please convert string value to ${language} language if possible
      
      `;
    } else {
      promptMessage = `I would like to develop ${create} for the students. The topic of the lesson will be '${promptInput}' Could you please provide me with the content for this lesson in HTML format? and the html code content in only ${language} language.`;
    }
    const params = {
      // model: "gpt-3.5-turbo",
      messages: [{ role: "user", content: promptMessage }],
      max_tokens: 2000,
      temperature: 0.7,
      // n: 1,
    };

    axios
      .post(`${process.env.REACT_APP_EP_URL_WHITEBOARD}/gpt`, params)
      .then(
        async (res: {
          data: { result: { choices: { message: { content: string } }[] } };
        }) => {
          const article = res.data.result.choices[0].message.content.replace(
            /¿/g,
            "?",
          );
          setGeneratedContent(article);
          if (create === CreateType.DIAGRAM) {
            // Use regex to extract the code block
            const codeBlockRegex = /```mermaid([\s\S]*?)```/;
            let match = article.match(codeBlockRegex); // Change const to let
            if (!match) {
              const codeBlockRegex = codeBlockRegex_(type as string);
              match = article.match(codeBlockRegex); // Assign to existing 'match' using let

              if (!match) {
                const codeBlockRegex = /```html([\s\S]*?)```/;
                match = article.match(codeBlockRegex);
              }
            }
            // Check if a match is found
            if (match && match.length > 1) {
              const codeBlockString = match[1].replace(/¿/g, "?");
              setGeneratedContent(codeBlockString);
              await convertMermaidToExcalidraw({
                canvasRef: someRandomDivRef,
                data,
                mermaidToExcalidrawLib,
                setError,
                mermaidDefinition: codeBlockString,
                zoomFactor,
                appState: props.appState,
                setAppState: props.setAppState,
              });
              setGeneratePreviewLoader(false);
              setIsOpenViewAsMermaidLoader(false);
              setIsOpenRegenerateChartLoader(false);
              setOpenRegenerateChartModal(false);
              trackEvent("ai", "mermaid parse success", "ttd");
            } else {
              setError(new Error("No code block found please try again"));
              setGeneratePreviewLoader(false);
              setIsOpenViewAsMermaidLoader(false);
              setIsOpenRegenerateChartLoader(false);
              setOpenRegenerateChartModal(false);
              trackEvent("ai", "mermaid parse error", "ttd");
            }
          } else {
            const regex = /```html([\s\S]*?)```/;
            const match = article.match(regex);
            const extractedContent = match
              ? match[1].trim().replace(/¿/g, "?")
              : article.replace(/¿/g, "?");
            setGeneratedContent(extractedContent);

            setGptCredit(gptCredit - 1);

            convertHtmlToCanvas(
              extractedContent,
              "textImage.png",
              props.appState.textEditor,
            ).then((res: any) => {
              setGeneratePreviewLoader(false);
              setIsOpenViewAsMermaidLoader(false);
              setIsOpenRegenerateChartLoader(false);
              setOpenRegenerateChartModal(false);
              const { dataUrl } = res;
              setPreviewImg(dataUrl);
            });
          }

          const user = JSON.parse(localStorage.getItem("user") || "");
          const slug = new URLSearchParams(window.location.search).get("slug");
          const validSlug = slug ?? "";

          apiPut(
            `${process.env.REACT_APP_ACV_BACKEND_API}/api/user/update-user-credit?slug=${validSlug}`,
            {
              credit: gptCredit - 1,
              mail: user.mail,
            },
          );
        },
      )
      .catch((err: Error) => {
        setGeneratePreviewLoader(false);
        setIsOpenViewAsMermaidLoader(false);
        setIsOpenRegenerateChartLoader(false);
        setOpenRegenerateChartModal(false);
        console.error("Error:", err);
      });
  }

  async function purchaseCredit() {
    const slug = new URLSearchParams(window.location.search).get("slug");
    const validSlug = slug ?? "";
    const user = localStorage.getItem("user");
    const convertUser = JSON.parse(user || "");
    const accessToken = localStorage.getItem("access_token");
    axios
      .post(
        `${process.env.REACT_APP_ACV_BACKEND_API}/api/credit/generate?slug=${validSlug}`,
        {
          TeacherEmailID: convertUser.mail,
          TeacherName: convertUser.name,
          role: convertUser.role,
          status: "pending",
          tenantDB: convertUser.dbData.tenantDB,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        },
      )
      .then((res: { data: { status: string } }) => {
        if (res.data.status === "Success") {
          setIsOpenSnackbar(true);
          setIsRequested(true);
        }
        return res.data;
      });
  }

  return (
    <>
      {openSnackbar && (
        <div
          className="alert alert-success"
          style={{
            position: "absolute",
            right: "125%",
            width: "435%",
            alignItems: "center",
            display: "flex",
          }}
        >
          <strong>Request send successflully!</strong>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            style={{
              width: "5px",
              height: "5px",
              marginLeft: "10px",
            }}
          ></button>
        </div>
      )}
      {open && (
        <AlertDialog
          title="Warning"
          onClose={() => {
            setOpen(false);
          }}
          small={true}
        >
          <p>Please enter your Magic sentence</p>
        </AlertDialog>
      )}
      <div ref={ref} className="d-flex align-items-start">
        <div
          className="tool-button"
          onClick={() => {
            setOpenGPTDialog(!openGPTDialog);
            props.setAppState({ ...props.appState, selectedElementIds: {} });
          }}
          id="gpt"
          role="button"
          tabIndex={0}
        >
          <ToolButton
            data-testid="gpt-button"
            icon={<GptIcon theme={theme} />}
            type={ToolButtonEnum.BUTTON}
            aria-label={"Magic ACV bar"}
            showAriaLabel={isMobile}
            title={t("buttons.gpt")}
            id="gpt-button"
          />
        </div>
        {openGPTDialog && (
          <Dialog
            onCloseRequest={handleCloseDialogue}
            title={"Magic ACV bar ✨"}
            className="gpt-dialog-container overflow-hidden"
            children={
              <>
                <div
                  className={`row gpt-dialog ${
                    theme === "dark" ? "bg-dark" : ""
                  } ${
                    !openMermaidSyntaxTextArea && `overflow-auto  py-2 py-md-0`
                  }`}
                  id="gpt-model"
                >
                  <div>
                    <p className="description d-flex flex-wrap">
                      <span className="mt-1">I want to create</span>
                      <span className="mx-2">
                        {
                          <DropDown
                            options={creates}
                            selected={create}
                            onChange={(create) => {
                              setChart(ChartType.FLOWCHART);
                              setCreate(create as CreateType);
                              setPromtInput("");
                              setPreviewImg(null);
                              if (create !== CreateType.DIAGRAM) {
                                //set someDivref value default null
                                const canvasNode = someRandomDivRef.current;
                                if (canvasNode) {
                                  setMermaidSyntax("");
                                  const parent = canvasNode.parentElement;
                                  if (!parent) {
                                    return;
                                  }
                                  if (parent) {
                                    parent.style.background = "";
                                  }
                                  canvasNode.replaceChildren();
                                }
                              }
                            }}
                            classes={theme === "dark" ? "dark" : ""}
                            disabled={false}
                          />
                        }
                      </span>

                      <span className="mx-2">
                        {
                          <DropDown
                            options={languages}
                            selected={language}
                            onChange={handleSelect}
                            classes={theme === "dark" ? "dark" : ""}
                            disabled={false}
                          />
                        }
                      </span>
                    </p>
                    {create === CreateType.DIAGRAM ? (
                      <div
                        className="d-flex"
                        style={{
                          marginBottom: "6px",
                        }}
                      >
                        <div className="d-flex gap-2 flex-wrap">
                          {diagramOptions.map((option, index) => (
                            <button
                              className={`${
                                diagramType === option[1]
                                  ? "active_template"
                                  : "deactive_template"
                              }`}
                              onClick={() =>
                                handleButtonClick(option[1], index)
                              }
                            >
                              {option[0]}
                            </button>
                          ))}
                        </div>
                      </div>
                    ) : chart === ChartType.FLOWCHART ? (
                      <p>
                        Currently we use Mermaid as a middle step, so you'll get
                        best results if you describe a diagram, workflow, flow
                        chart, and similar.
                      </p>
                    ) : (
                      <p>
                        You can check the result in Preview and insert it into
                        the current page.
                        {/* <span className="text-primary">image</span> in
                        whiteboard. */}
                      </p>
                    )}
                    {create === CreateType.DIAGRAM && (
                      <p className="flowchart_type">
                        The diagram types Flowchart, Sequence and Class are
                        editable in Whiteboard, any other type is editable by
                        double-clicking on it using Sirena syntax.
                      </p>
                    )}
                  </div>
                  <div className="row mx-0">
                    <div className="col-md-12 col-lg-6 px-0 pe-3">
                      <div className="d-flex justify-content-between align-items-center "></div>
                      <div
                        id="parentDiv"
                        className="d-flex flex-column justify-content-between"
                        style={{
                          height:
                            create === CreateType.DIAGRAM ? "95%" : "100%",
                        }}
                      >
                        <div className="">
                          <div className="d-flex justify-content-between ">
                            <p
                              className="title"
                              style={{ marginBottom: "6px" }}
                            >
                              Prompt
                            </p>
                          </div>
                          <div style={{ position: "relative" }}>
                            {renderUndoRedo(0)}
                            <div className="prompt-preview">
                              <textarea
                                rows={isMobile ? 9 : 18}
                                className={`w-100 h-100 myTextarea ${
                                  theme === "dark" ? "dark" : ""
                                }`}
                                value={promptInput}
                                placeholder="Write magic sentence here..."
                                onChange={(e) => {
                                  setPromtInput(e.target.value);
                                }}
                                onMouseEnter={(e) => {
                                  e.preventDefault();
                                  setIsTextAreaFocused(true);
                                }}
                                onMouseLeave={(e) => {
                                  e.preventDefault();
                                  setIsTextAreaFocused(false);
                                }}
                                ref={textareas[0]}
                                onInput={() => handleInput(0)}
                              ></textarea>
                            </div>
                          </div>
                        </div>{" "}
                        <div
                          className="credit_div"
                          id="childDiv"
                          style={{
                            position:
                              openMermaidSyntaxTextArea && !isMobile
                                ? "absolute"
                                : "relative",
                            gap: create !== CreateType.DIAGRAM ? "25px" : "0px",
                          }}
                        >
                          <div className="d-flex">
                            <div
                              className="credit-limit-wrapper d-flex p-1 shadow"
                              style={{ width: "fit-content" }}
                            >
                              <div className="credit-icon-wrapper">
                                <CoinIcon theme={theme} />
                              </div>
                              <div
                                className="credit-score px-2 fw-bold text-muted"
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  alignContent: "center",
                                }}
                              >
                                {gptCredit}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-end">
                            <p className="mb-0">
                              Review AI limits on our
                              <a
                                href="https://www.acv.app/terms"
                                target="_blank"
                                rel="noreferrer noopener"
                                className="tos-text ms-1"
                              >
                                Terms of Service
                              </a>
                              .
                            </p>

                            {gptCredit <= 0 ? (
                              currentUser?.role === "SuperAdmin" ? (
                                <a
                                  className={`button d-flex ${
                                    generatePreviewLoader && "disabled"
                                  }`}
                                  style={{
                                    fontSize: "15px",
                                    textDecoration: "none",
                                  }}
                                  href="https://www.acv.app/purchase-credit"
                                  target="_blank"
                                  rel="noreferrer noopener"
                                >
                                  Purchase credit
                                </a>
                              ) : (
                                <button
                                  className={`button d-flex ${
                                    (generatePreviewLoader || isRequested) &&
                                    "disabled"
                                  }`}
                                  onClick={purchaseCredit}
                                  disabled={isRequested}
                                >
                                  {isRequested
                                    ? "Requested"
                                    : "Request for credits"}
                                  {generatePreviewLoader && (
                                    <span>
                                      <Spinner className="mt-1 ms-1" />
                                    </span>
                                  )}
                                </button>
                              )
                            ) : (
                              <button
                                className={`button d-flex ${
                                  openMermaidSyntaxTextArea && "disabled"
                                }`}
                                onClick={async (e) => {
                                  if (create === CreateType.DIAGRAM) {
                                    const isMermaid = isMermaidSyntax(
                                      promptInput,
                                    );

                                    if (!isMermaid) {
                                      await generatePrompt(e);
                                      return null;
                                    }
                                    await convertMermaidToExcalidraw({
                                      canvasRef: someRandomDivRef,
                                      data,
                                      mermaidToExcalidrawLib,
                                      setError,
                                      mermaidDefinition: promptInput,
                                      zoomFactor,
                                      appState: props.appState,
                                      setAppState: props.setAppState,
                                    });
                                  } else {
                                    await generatePrompt(e);
                                  }
                                }}
                                disabled={
                                  openMermaidSyntaxTextArea ||
                                  generatePreviewLoader ||
                                  gptCredit <= 0
                                }
                                style={{
                                  background:
                                    gptCredit === 0 ||
                                    openMermaidSyntaxTextArea ||
                                    generatePreviewLoader
                                      ? "lightgray"
                                      : "#585a96",
                                }}
                              >
                                Generate 🌟
                                {generatePreviewLoader && (
                                  <span>
                                    <Spinner className="mt-1 ms-1" />
                                  </span>
                                )}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6 d-flex flex-column py-3 py-lg-0 justify-content-between px-0 ps-3">
                      <div>
                        <div
                          className="d-flex justify-content-between"
                          style={{
                            top: "0",
                            ...(create === CreateType.DIAGRAM &&
                            !openMermaidSyntaxTextArea
                              ? { position: "relative" }
                              : {}),
                          }}
                        >
                          <div className="preview_with_view_as_mermaid">
                            <p className="title" style={{ margin: "0px" }}>
                              Preview
                            </p>{" "}
                            {openMermaidSyntaxTextArea && (
                              <div
                                ref={isTextAreaFocused ? null : dragRef}
                                id="mydiv"
                                style={{
                                  position: "absolute",
                                  zIndex: 9,
                                  cursor: isTextAreaFocused ? "text" : "move",
                                  width: "100%",
                                }}
                                onMouseDown={handleMouseDown}
                              >
                                <Popover
                                  style={{
                                    ...(isMobile
                                      ? {
                                          transform: "translate(0, -105%)",
                                          top: "7%",
                                        }
                                      : {
                                          transform: "translate(-50%, 0)",
                                          top: "7%",
                                        }),
                                  }}
                                >
                                  <div className="p-4">
                                    <div className="d-flex justify-content-between">
                                      <h5
                                        className="title"
                                        style={{ marginBottom: "6px" }}
                                      >
                                        Mermaid syntax
                                      </h5>
                                      <div
                                        className="close_popover"
                                        onClick={() =>
                                          setOpenMermaidSyntaxTextArea(false)
                                        }
                                      >
                                        {close("black")}
                                      </div>
                                    </div>
                                    <div className="prompt-preview">
                                      {renderUndoRedo(1)}
                                      <textarea
                                        ref={textareas[1]}
                                        rows={10}
                                        className={`w-100 h-100 myTextarea ${
                                          theme === "dark" ? "dark" : ""
                                        }`}
                                        value={mermaidSytax}
                                        placeholder="Write magic sentence here..."
                                        onChange={(e) =>
                                          setMermaidSyntax(e.target.value)
                                        }
                                        onMouseEnter={(e) => {
                                          e.preventDefault();
                                          setIsTextAreaFocused(true);
                                        }}
                                        onMouseLeave={(e) => {
                                          e.preventDefault();
                                          setIsTextAreaFocused(false);
                                        }}
                                        style={{
                                          fontSize: "15px",
                                          filter: openRegenerateChartModal
                                            ? "blur(2px)"
                                            : "blur(0px)",
                                        }}
                                        onInput={() => handleInput(1)}
                                      ></textarea>
                                      {openRegenerateChartModal && (
                                        <Popover id="regenerate_chart">
                                          <div className="d-flex flex-column justify-content-center align-items-center">
                                            <h6>
                                              Please regenerate your chart
                                              beacuse your prompt has change
                                            </h6>
                                            <button
                                              className={`button d-flex regenerate_button`}
                                              disabled={isLoading}
                                              onClick={(e) =>
                                                generatePrompt(e, false, true)
                                              }
                                            >
                                              Regenerate syntax
                                              {isOpenRegenerateChartLoader && (
                                                <span className="regenerate_loader">
                                                  <Spinner className="mt-1 ms-1" />
                                                </span>
                                              )}
                                            </button>
                                          </div>
                                        </Popover>
                                      )}
                                    </div>
                                  </div>
                                </Popover>
                              </div>
                            )}
                            {create === CreateType.DIAGRAM && generatedContent && (
                              <div className="mermaid-link">
                                <div
                                  className="mermaid-link"
                                  style={{
                                    fontSize: "14px",
                                    marginBottom: "1rem",
                                  }}
                                  onClick={async (e) => {
                                    setOpenMermaidSyntaxTextArea(
                                      !openMermaidSyntaxTextArea,
                                    );
                                  }}
                                >
                                  View as Mermaid{" "}
                                  <span
                                    style={{
                                      width: "1em",
                                      margin: " 0px 0.5ex",
                                      display: "inline-block",
                                      lineHeight: "0",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <svg
                                      aria-hidden="true"
                                      focusable="false"
                                      role="img"
                                      viewBox="0 0 20 20"
                                      className=""
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    >
                                      <g stroke-width="1.25">
                                        <path d="M4.16602 10H15.8327"></path>
                                        <path d="M12.5 13.3333L15.8333 10"></path>
                                        <path d="M12.5 6.66666L15.8333 9.99999"></path>
                                      </g>
                                    </svg>
                                  </span>
                                  {isOpenViewAsMermaidLoader && (
                                    <span>
                                      <Spinner className="mt-1 ms-1" />
                                    </span>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                          <span>
                            <ToolButton
                              type={ToolButtonEnum.BUTTON}
                              icon={zoomIn}
                              aria-label={t("buttons.zoomIn")}
                              size="small"
                              onClick={handleZoomIn}
                              style={{ width: 30, height: 30 }}
                            />
                            <ToolButton
                              type={ToolButtonEnum.BUTTON}
                              icon={zoomOut}
                              aria-label={t("buttons.zoomOut")}
                              size="small"
                              onClick={handleZoomOut}
                              style={{ width: 30, height: 30 }}
                            />
                          </span>
                        </div>
                        <div
                          className={`${
                            someRandomDivRef ? "preview-img" : ""
                          } `}
                          style={{
                            height:
                              create === CreateType.DIAGRAM ? "438px" : "450px",
                            top: create === CreateType.DIAGRAM ? 0 : 0,
                          }}
                        >
                          {previewImg && create !== CreateType.DIAGRAM ? (
                            <div
                              style={{
                                transform: `scale(${zoomFactor})`,
                                transformOrigin: "top left",
                              }}
                            >
                              <img
                                src={previewImg}
                                alt="" // Use an empty alt attribute for decorative images
                                style={{
                                  width: "100%", // Maintain full width of the image
                                  height: "auto",
                                  paddingTop: "0.85rem",
                                  maxHeight: 400,
                                }}
                              />
                            </div>
                          ) : (
                            <>
                              {error && (
                                <div
                                  data-testid="ttd-dialog-output-error"
                                  className="ttd-dialog-output-error"
                                >
                                  Error! <p>{error.message}</p>
                                </div>
                                // <h3 className="text-danger">{error.message}</h3>
                              )}
                              {mermaidToExcalidrawLib.loaded ? (
                                <div
                                  ref={someRandomDivRef}
                                  style={{
                                    opacity: error ? "0.15" : 1,
                                    display: "flex",
                                    width: "100%",
                                    height: "100%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexGrow: 1,
                                    transform: `scale(${zoomFactor})`, // Apply zoom transformation
                                    transformOrigin: "top left", // Adjust the origin of the transformation
                                  }}
                                  className="ttd-dialog-output-canvas-container"
                                ></div>
                              ) : (
                                <Spinner size="2rem" />
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      <div className="d-flex flex-column">
                        <div className="d-flex justify-content-end">
                          <button
                            className={`button d-flex ${
                              !previewImg && create !== CreateType.DIAGRAM
                                ? "disabled"
                                : ""
                            }`}
                            disabled={
                              isLoading ||
                              (!previewImg && create !== CreateType.DIAGRAM)
                            }
                            onClick={async (e) => {
                              if (create !== CreateType.DIAGRAM) {
                                exportToCanvas(e);
                              } else {
                                insertToEditor({
                                  app,
                                  data,
                                  handleCloseDialogue,
                                });
                              }
                            }}
                          >
                            Insert it 👉
                            {isLoading && (
                              <span>
                                <Spinner className="mt-1 ms-1" />
                              </span>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }
            closeOnClickOutside={false}
            open={openGPTDialog}
            setOpen={setOpenGPTDialog}
          />
        )}
      </div>
    </>
  );
};
