import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { DropTargetMonitor, useDrag, useDrop } from "react-dnd";
import { ExcalidrawElement, ExcalidrawFrameElement } from "../../element/types";
import { AppClassProperties, AppState } from "../../types";
import { del, dragIcon, edit, moreIcon } from "../icons";
import { Popover } from "../Popover";
import { clearFrameElementsCache } from "../../element/newElement";
import { STORAGE_KEYS } from "../../excalidraw-app/data/localStorage";

type FrameMenuItemProps = {
  frame: ExcalidrawFrameElement;
  index: number;
  onMoveFrame: (dragIndex: number, dropIndex: number) => void;
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
  app: AppClassProperties;
  setFrames: React.Dispatch<React.SetStateAction<ExcalidrawFrameElement[]>>;
  frames: ExcalidrawFrameElement[];
  allElements: ExcalidrawElement[];
  onFrameNameClick?: () => void;
  className?: string;
  onHover?: () => void;
  onMouseLeave?: () => void;
  setAllElements?: React.Dispatch<React.SetStateAction<ExcalidrawElement[]>>;
  onFrameClick?: () => void;
  onFrameDoubbleClick?: () => void;
};

const ItemType = "FRAME";

export const FrameMenuItem = ({
  frame,
  index,
  onMoveFrame,
  onFrameNameClick,
  className,
  onHover,
  onMouseLeave,
  setAppState,
  appState,
  app,
  frames,
  setFrames,
  allElements,
  setAllElements,
  onFrameClick,
  onFrameDoubbleClick,
}: FrameMenuItemProps) => {
  const [isFrameNameClicked, setIsFrameNameClicked] = useState(false);
  const ref = useRef<HTMLLIElement>(null); // Ref to the entire li element
  const dragImageRef = useRef<HTMLDivElement>(null); // Ref for custom drag preview
  const [isEditing, setIsEditing] = useState<string>("");
  const [frameName, setFrameName] = useState("");
  const [frameActions, setFrameActions] = useState<string>("");

  const handleEditClick = (id: string) => {
    setIsEditing(id); // Enter edit mode
    setFrameActions(""); // Close actions menu
    localStorage.setItem("isDragAndDrop", JSON.stringify(false));
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFrameActions(""); // Close actions menu
    setFrameName(e.target.value); // Update frame name
  };

  const handleBlur = () => {
    setIsEditing(""); // Exit edit mode when input loses focus
    setFrameName(""); // Reset frame name
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setIsEditing(""); // Save changes on Enter key press
      setFrameName(""); // Reset frame name
      localStorage.setItem("isDragAndDrop", JSON.stringify(true));

      const updatedElements = allElements.map((element: any) => {
        if (element.id === isEditing) {
          return {
            ...element,
            name: frameName,
          };
        }
        return element;
      });
      const isMyWorkSpace = localStorage.getItem("isMyWorkSpace");
      localStorage.setItem(
        isMyWorkSpace === "true"
          ? STORAGE_KEYS.LOCAL_STORAGE_WORKSPACE_ELEMENTS
          : STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS,
        JSON.stringify(updatedElements),
      );
      if (setAllElements) {
        setAllElements([...updatedElements]);
      } else {
        app.scene.replaceAllElements([...updatedElements]);
      }
    }
  };

  const [, drop] = useDrop({
    accept: ItemType,
    drop(item: { index: number }) {
      if (!ref.current || localStorage.getItem("isDragAndDrop") === "false")
        return;

      const dragIndex = item.index;
      const dropIndex = index;

      if (dragIndex === dropIndex) return;
      onMoveFrame(dragIndex, dropIndex);
      item.index = dropIndex;
    },
  });

  useEffect(() => {
    // Ensure drag-and-drop is enabled when not editing
    if (isEditing) {
      localStorage.setItem("isDragAndDrop", JSON.stringify(false));
    }
  }, [isEditing]);

  const [{ isDragging }, drag, dragPreview] = useDrag({
    type: ItemType,
    item: { id: frame.id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: isEditing
      ? false
      : localStorage.getItem("isDragAndDrop") === "true",
  });

  // useEffect(() => {
  //   // Set up custom drag preview
  //   if (dragImageRef.current) {
  //     dragPreview(dragImageRef.current, {
  //       offsetX: 0,
  //       offsetY: 0,
  //     });
  //   }
  // }, [dragPreview]);

  drag(drop(ref));

  useEffect(() => {
    // Capture the current drag image reference
    const dragImage = dragImageRef.current;

    // Clean up drag preview
    return () => {
      if (dragImage) {
        dragImage.remove();
      }
    };
  }, []);

  return (
    <li
      ref={ref}
      style={{
        opacity: isDragging ? 0.5 : 1,
      }}
      className="d-flex gap-2 align-items-center"
    >
      <button
        className={clsx("w-100 d-flex mx-2 frameMenuBtn", className)}
        style={{
          display: "inline-block",
          minWidth: "fit-content",
          borderRadius: 0,
        }}
        onMouseEnter={onHover}
        onMouseLeave={!isFrameNameClicked ? onMouseLeave : () => false}
        onClick={(e) => {
          e.stopPropagation();
          onFrameClick && onFrameClick();
        }}
        onDoubleClick={(e) => {
          e.stopPropagation();
          onFrameDoubbleClick && onFrameDoubbleClick();
        }}
      >
        <div className="align-items-center d-flex justify-content-between w-100">
          <div className="d-flex gap-2 align-items-center">
            <div
              ref={dragImageRef}
              className="d-flex align-items-center gap-2 "
              style={{
                opacity: 0.8,
                cursor: "grabbing",
              }}
            >
              {dragIcon(appState.theme === "light" ? "#2e3436" : "#fff")}
            </div>
            {frame.id === isEditing ? (
              <input
                className="form-control ps-1"
                value={frameName}
                onChange={handleNameChange}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                autoFocus
              />
            ) : (
              <span
                className={`ps-1 cursor-pointer`}
                onClick={(e) => {
                  e.stopPropagation();
                  onFrameNameClick && onFrameNameClick();
                  setIsFrameNameClicked(true);
                }}
                title={frame.name ?? ""}
                style={{
                  color: appState.theme === "light" ? "#2e3436" : "#fff",
                  whiteSpace: "nowrap", // Prevent line breaks
                  overflow: "hidden", // Hide overflow
                  textOverflow: "ellipsis", // Show ellipsis
                  maxWidth: "100px", // Set a max width for truncation
                }}
              >
                {frame.name}
              </span>
            )}
          </div>
          <div
            className="position-relative cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              setFrameActions(frame.id);
            }}
          >
            <div className="more_icon">
              {" "}
              {moreIcon(appState.theme === "light" ? "black" : "white")}
            </div>

            {frameActions === frame.id && (
              <Popover
                onCloseRequest={() => setFrameActions("")}
                left={40}
                top={20}
                style={{
                  transform: "translate(-100%, 0)",
                  zIndex: 100,
                }}
              >
                <div
                  style={{ color: "#777" }}
                  className="w-100 d-flex gap-2 context-menu-option action_icon_wrapper"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEditClick(frame.id);
                    setFrameName(frame.name ?? "Frame");
                  }}
                >
                  <div
                    style={{
                      width: 15,
                    }}
                  >
                    {edit}
                  </div>
                  Rename
                </div>
                <div
                  style={{
                    color: "#d33228",
                  }}
                  className="w-100 d-flex gap-2 context-menu-option action_icon_wrapper"
                  onClick={(e) => {
                    e.stopPropagation();
                    clearFrameElementsCache();
                    // Filter out the deleted frame
                    const updatedFrames = frames.filter(
                      (frame) => frame.id !== frame.id,
                    );
                    setFrames([...updatedFrames]);

                    // Filter out the deleted frame from all elements
                    const updatedElements = allElements.filter(
                      (element: any) => element.id !== frame.id,
                    );

                    // Update the order of the remaining frames
                    const reorderedElements = updatedElements.map(
                      (element: any, index: number) => {
                        if (element.type === "frame" && !element.isDeleted) {
                          return { ...element, order: index + 1 }; // Reassign sequential order
                        }
                        return element;
                      },
                    );
                    const isMyWorkSpace = localStorage.getItem("isMyWorkSpace");
                    // Update localStorage
                    localStorage.setItem(
                      isMyWorkSpace === "true"
                        ? STORAGE_KEYS.LOCAL_STORAGE_WORKSPACE_ELEMENTS
                        : STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS,
                      JSON.stringify(reorderedElements),
                    );

                    // Update state or scene elements
                    if (setAllElements) {
                      setAllElements([...reorderedElements]);
                    } else {
                      app.scene.replaceAllElements([...reorderedElements]);
                    }
                  }}
                >
                  <div
                    style={{
                      width: 15,
                    }}
                  >
                    {del}
                  </div>{" "}
                  Delete
                </div>
              </Popover>
            )}
          </div>
        </div>
      </button>
    </li>
  );
};
